import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import TablePaginationDemo from "../../../common/Pagenation";
import DynamicTable from "../../../common/DynamicTable";
import { Button } from "../../../common/Button";
import { ArrowUpDown } from "lucide-react";
import { ColumnDef } from "@tanstack/react-table";
import { Fetch_Milestone_List } from "../../../common/services/Project";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import Search2 from "../../../SearchBar/Search2";

interface IMilestone {
  id: number;
  project_id: number;
  milestone_name: string;
  hours: number;
  actual_hours: number;
  price: number | null;
  total_cost: number;
  allocated_cost: number;
  actual_cost: number;
  currency: string;
  profit: number;
  billing_date: string;
  customer_id: number;
  status: string;
  description: string | null;
  created_by: number;
  modified_by: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface DetailsProps {
  heading?: string;
  data?: string | any;
  currency?: string;
}

const BillingCard: React.FC<DetailsProps> = ({ heading, data, currency }) => {
  let textColor = "text-[#1F2937]";

  if (heading === "Total Profit / Loss") {
    textColor =
      data > 0
        ? "text-green-500"
        : data < 0
        ? "text-red-500"
        : "text-[#1F2937]";
  }

  return (
    <div className="p-4 border border-[#E5E7EB] bg-white shadow-sm rounded-lg flex-grow">
      <div className="flex flex-col gap-2.5">
        <p className="text-base font-[Inter] font-semibold text-[#4B5563]">
          {heading}
        </p>
        <p className={`text-2xl font-[Inter] font-semibold ${textColor}`}>
        {currency} {typeof data === "number" ? Math.abs(data).toFixed(2) : data}
        </p>
      </div>
    </div>
  );
};

interface BillingCostingProps {
  contractualCost: number | string;
}

const BillingCosting: React.FC<BillingCostingProps> = ({ contractualCost }) => {
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const [tableData3, setTableData3] = useState<IMilestone[]>([]);
  const [countMile, setCountMile] = useState(0);
  const [numOfItems3, setNumOfItems3] = useState(10);
  const [page3, setPage3] = useState(1);
  const [searchTerm3, setSearchTerm3] = useState("");
  const [totalEstimated, setTotalEstimated] = useState<number>(0);
  const [totalAllocated, setTotalAllocated] = useState<number>(0);
  const [totalActual, setTotalActual] = useState<number>(0);
  const [totalProfit, setTotalProfit] = useState<number>(0);
  const updateSearchTerm2 = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm3(event.target.value);
      setPage3(1);
    }, 100),
    [setSearchTerm3]
  );

  function handleNumberOfPages3(value: number) {
    setNumOfItems3(value);
  }

  function handlePageChange3(value: number) {
    setPage3(value);
  }

  const fetchMilestoneData = useCallback(async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Milestone_List({
        customer_id: projectId,
        numOfItems: numOfItems3,
        page: page3,
        search: searchTerm3,
      });
      if (response.status === 200) {
        setTableData3(response.data?.result?.rows || []);
        setCountMile(response.data?.result?.count ?? 0);
        let totalEstimatedCost = 0;
        let totalActualCost = 0;
        let totalProfit = 0;
        let totalAllocated = 0;
        response.data?.result?.rows?.map((row: IMilestone) => {
          totalEstimatedCost += row?.total_cost ?? 0;
          totalActualCost += row?.actual_cost ?? 0;
          totalAllocated += row?.allocated_cost ?? 0;
        });
        totalProfit = totalEstimatedCost - totalActualCost;
        setTotalActual(totalActualCost);
        setTotalEstimated(totalEstimatedCost);
        setTotalAllocated(totalAllocated);
        setTotalProfit(totalProfit);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setLoading(false);
  }, [page3, numOfItems3, searchTerm3, projectId]);

  useEffect(() => {
    fetchMilestoneData();
  }, [fetchMilestoneData]);

  const columns3: ColumnDef<IMilestone>[] = [
    {
      accessorKey: "milestone_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Milestone Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="py-3">
            {row?.original?.milestone_name
              ? `${row?.original?.milestone_name}`
              : null}
          </div>
        );
      },
    },

    {
      accessorKey: "hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Estimated hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.hours ?? "-",
      cell: ({ row }) => {
        const name: string | number = row?.original?.hours ?? "-";
        return (
          <div className="">
            {row?.original?.hours ? `${row?.original?.hours} hrs` : "-"}
          </div>
        );
      },
    },

    {
      accessorKey: "actual_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Actual hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.actual_hours ?? "--",
      cell: ({ row }) => {
        const name: string | number = row?.original?.actual_hours ?? "-";
        return (
          <div className="">
            {name
              ? `${(name).toFixed(2)} hrs`
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "total_cost",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Allocated Cost
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.total_cost ?? "-",
      cell: ({ row }) => {
        const name: number = row?.original?.total_cost ?? "-";
        const currency: string = row?.original?.currency;
        return (
          <div className="">
            {currency && row?.original?.total_cost
              ? `${currency} ${(name).toFixed(2)}`
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "actual_cost",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Actual Cost
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.actual_cost ?? "-",
      cell: ({ row }) => {
        const name: number = row?.original?.actual_cost ?? "-";
        const currency: string = row?.original?.currency;
        return (
          <div className="">
            {currency && row?.original?.actual_cost
              ? `${currency} ${(name).toFixed(2)}`
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "profit",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Profit / Loss
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => (row?.total_cost ?? 0) - (row?.actual_cost ?? 0),
      cell: ({ row }) => {
        const allocatedCostValue = row?.original?.total_cost ?? 0;
        const actualCostValue = row?.original?.actual_cost ?? 0;
        const profit = allocatedCostValue - actualCostValue;
        const currency = row?.original?.currency ?? "";

        return (
          <div
            className={
              profit > 0
                ? "text-green-500"
                : profit < 0
                ? "text-red-500"
                : "text-gray-500"
            }
          >
            {currency} {(Math.abs(profit)).toFixed(2)}
          </div>
        );
      },
    },
    {
      accessorKey: "billing_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Billing Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.billing_date
          ? new Date(row?.billing_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.billing_date
          ? new Date(row?.original?.billing_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
  ];

  return (
    <div>
      <div className="overflow-x-auto mt-5">
        <div className="flex justify-between items-center gap-4 flex-wrap pb-3">
          <BillingCard
            heading="Contractual Cost"
            data={contractualCost}
            currency={tableData3[0]?.currency}
          />
          <BillingCard
            heading="Total Allocated Cost"
            data={totalAllocated}
            currency={tableData3[0]?.currency}
          />
          <BillingCard
            heading="Total Actual Cost"
            data={totalActual}
            currency={tableData3[0]?.currency}
          />
           <BillingCard
            heading="Miscellaneous Cost"
            data={"-"}
            currency={tableData3[0]?.currency}
          />
          <BillingCard
            heading="Total Profit / Loss"
            data={Number(contractualCost) - totalActual} //
            currency={tableData3[0]?.currency}
          />
        </div>
        {/* <div className="flex justify-between mt-4 mb-2">
          <Search2 onChange={updateSearchTerm2} label="Search" />
        </div> */}
        <div className="table_main_content w-full relative overflow-auto bg-white mt-2 ">
          <DynamicTable<IMilestone>
            data={tableData3}
            loading={false}
            columns={columns3}
            enableSorting
            enablePagination
          />
        </div>

        {countMile >= 10 && (
          <TablePaginationDemo
            count={countMile}
            handleItemsChange={handleNumberOfPages3}
            handlePageChange={handlePageChange3}
            currentPage={page3}
            numOfItems={numOfItems3}
          />
        )}
      </div>
    </div>
  );
};

export default BillingCosting;
