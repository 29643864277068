// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_dialog__M7rIA {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.Modal_dialog__M7rIA::-webkit-scrollbar {
  display: none; /* WebKit */
}

.Modal_closeButton__MjWPx {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.Modal_closeButton__MjWPx:hover {
  color: red;
}

`, "",{"version":3,"sources":["webpack://./src/components/UI/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB,EAAE,YAAY;EACnC,wBAAwB,EAAE,0BAA0B;AACtD;;AAEA;EACE,aAAa,EAAE,WAAW;AAC5B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".dialog {\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* Internet Explorer 10+ */\n}\n\n.dialog::-webkit-scrollbar {\n  display: none; /* WebKit */\n}\n\n.closeButton {\n  position: absolute;\n  top: 10px;\n  left: 10px;\n  background-color: transparent;\n  border: none;\n  font-size: 20px;\n  cursor: pointer;\n}\n\n.closeButton:hover {\n  color: red;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": `Modal_dialog__M7rIA`,
	"closeButton": `Modal_closeButton__MjWPx`
};
export default ___CSS_LOADER_EXPORT___;
