import React, { useCallback, ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { debounce } from "lodash";
import toast from "react-hot-toast";

import download from "../../assets/download_new.svg";
import view from "../../assets/Crud_Icons/view.svg";
import profile from "../../assets/Ellipse 580.png";

import Search from "../SearchBar/Search";
import TablePaginationDemo from "../common/Pagenation";
import { Fetch_Asset_Request_List } from "../common/services/Asset";
import TableSkeleton from "../common/TableSkeleton";
import moment from "moment";

interface AssetRequestData {
  id: number;
  status: string;
  application_date: string;
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  AssetCategory: {
    id: number;
    category: string;
  };
  Project: {
    project_name: string;
  }
}

function AssetRequest() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<AssetRequestData[]>([]);

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    }, 100),
    []
  );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Asset_Request_List(
        customer_id,
        numOfItems,
        page,
        searchTerm
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);
      } else {
        toast.error("Failed to fetch data");
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customer_id, page, numOfItems, searchTerm]);

  return (
    <div className="pt-12">
      <div className="flex flex-col gap-5">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with employee Name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full">
                <button className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text">
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end pr-1 gap-[31px]">
          <div className="flex flex-row justify-between gap-1 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
            </svg>
            <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
              Approved
            </span>
          </div>

          <div className="flex flex-row justify-between gap-1 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FF9950" />
            </svg>
            <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
              Pending
            </span>
          </div>
          <div className="flex flex-row justify-between gap-1 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FA1464" />
            </svg>
            <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
              Rejected
            </span>
          </div>
        </div>
        <div className="overflow-x-auto overflow-y-auto shadow-sm">
          {loading ? (
            <>
              <table className="w-full min-w-max text-left table-auto bg-white">
                <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                  <tr className="h-11 w-full">
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-5">
                      Employee Name & ID
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                      Assigned Project
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                      Application Date
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                      Asset Type
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pr-5 flex justify-end">
                      Action
                    </th>
                  </tr>
                </thead>
              </table>
              <TableSkeleton />
            </>
          ) : (
            <table className="w-full min-w-max text-left table-auto bg-white">
              <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                <tr className="h-11 w-full">
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-5">
                    Employee Name & ID
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                    Assigned Project
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                    Application Date
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                    Asset Type
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pr-5 flex justify-end">
                    Action
                  </th>
                </tr>
              </thead>
              {tableData?.map((item) => (
                <tbody className="border border-[#EBEBEB]">
                  <tr className="h-12 border-t-0 border-l-0 border-r-0 border-b border-[#EBEBEB] hover:bg-gray-100">
                    <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] pl-2">
                      <div className="flex items-center gap-2 h-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <circle
                            cx="3.99537"
                            cy="3.74537"
                            r="3.49537"
                            fill={
                              item.status === "Approved"
                                ? "#00CBA0"
                                : item.status === "Pending"
                                ? "#FF9950"
                                : item.status === "Rejected"
                                ? "#FA1464"
                                : "#D3D3D3"
                            }
                          />
                        </svg>
                        <div className="h-[40px] flex items-center gap-2.5">
                          <div className="h-full w-[40px] rounded-full">
                            <img
                              src={item?.User?.profile_image || profile}
                              alt="profile"
                              className="h-full w-full rounded-full"
                            />
                          </div>
                          <div className="h-full flex flex-col justify-center">
                            <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                              {item?.User?.firstName}{" "}{item?.User?.lastName}
                            </span>
                            <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                              {item?.User?.employee_id}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                      {item?.Project?.project_name}
                    </td>
                    <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                      {item?.application_date === "Invalid date" ? "--" : moment(item?.application_date).format("DD MMM, YYYY") }
                    </td>
                    <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                      {item?.AssetCategory?.category}
                    </td>

                    <td className="pr-7">
                      <div className="flex justify-end items-center gap-2">
                        <img
                          className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                          onClick={() =>
                            navigate(`/view-asset-request`, {
                              state: { id: item.id },
                            })
                          }
                          src={view}
                          alt="View"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          )}
          {tableData.length === 0 ? (
            <div className="w-full h-10 px-2.5 py-1.5 flex justify-center items-center bg-white shadow-sm font-[Poppins] text-base text-[#1D1A22] border border-[#EBEBEB]">
              Data not Found!
            </div>
          ) : (
            <></>
          )}
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>
    </div>
  );
}

export default AssetRequest;
