import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../redux/reducers/Auth/authSlice";
import onboardingSlice from "./reducers/Onboarding/onboardingSlice";
import signup from "./reducers/Signup/signupSlice";
import userManagementSlice from "./reducers/userManagement/userManagementSlice";
import CustomerManagementSlice from "./reducers/CustomerManagement/CustomerManagementSlice";
import RoleManagementSlice from "./reducers/roleManagement/roleManagementSlice";
import companyStructureSlice from "./reducers/CompanyStructure/CompanyStructureSlice";
import policyManagementSlice from "./reducers/PolicyManagement/PolicyManagementSlice";
import invoiceSlice from "./reducers/Invoice/invoiceSlice";
import SideBarSlice from "./reducers/SideBar/SideBarSlice";
import RopaRegisterSlice from "./reducers/Ropa/ropaRegisterSlice";
import createPolicySlice from "./reducers/CreatePolicy/CreatePolicySlice";
import changePasswordSlice from "./reducers/ChangePassword/ChangePasswordSlice";
import AboutusSlice from "./reducers/AboutUs/AboutUsSlice";
import userProfileSlice from "./reducers/Profile/UserProfileSlice";
import routeSlice from "./reducers/Routes/RoutesSlice";
import activityLogSlice from "./reducers/ActivityLog/ActivityLogSlice";
import SupportSlice from "./reducers/Support/SupportSlice";
import riskAssessmentSlice from "./reducers/Assessment/AssessmentSlice";
import EmployeeSlice from "./reducers/Employee/EmployeeSlice";
import EmployeeListSlice from "./reducers/Employee/EmployeeListSlice";
import AttendenceListSlice from "./reducers/AttendenceSlice/AttendenceSlice";
import LeaveListSlice from "./reducers/Leave/LeaveSlice";
import ExpenseTypeSlice from "./reducers/ExpenseType/ExpenseTypeSlice";
import ProjectListSlice from "./reducers/Project/ProjectSlice";
//import CompanyManagementSlice from "./reducers/CompanyManagementSlice/ComapnyManagementSlice";
 
const rootReducer = combineReducers({
  auth: authReducer,
  signup,
  invoice: invoiceSlice.reducer,
  activityLog:activityLogSlice.reducer,
  onboarding: onboardingSlice.reducer,
  userManagement: userManagementSlice.reducer,
  roleManagement: RoleManagementSlice.reducer,
  CompanyStructure: companyStructureSlice.reducer,
  policyManagement: policyManagementSlice.reducer,
  CustomerManagement: CustomerManagementSlice.reducer,
  SideBar: SideBarSlice.reducer,
  ropaRegister: RopaRegisterSlice.reducer,
  createPolicy: createPolicySlice.reducer,
  changePassword: changePasswordSlice.reducer,
  aboutus: AboutusSlice.reducer,
  userProfile: userProfileSlice.reducer,
  routes: routeSlice.reducer,
  support:SupportSlice.reducer,
  riskAssessment: riskAssessmentSlice.reducer,
  employee:EmployeeSlice.reducer,
  emplist:EmployeeListSlice.reducer,
  attendence:AttendenceListSlice.reducer,
  leave:LeaveListSlice.reducer,
  expenseType:ExpenseTypeSlice.reducer,
  project:ProjectListSlice.reducer,
  //company:CompanyManagementSlice.reducer
});
 
const persistConfig = {
  key: "root",
  storage,
};
 
const persistedReducer = persistReducer(persistConfig, rootReducer);
 
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});
 
export const persistor = persistStore(store);
 
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;