import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  GoogleMap,
  OverlayView,
  InfoWindow,
  // Marker,
} from "@react-google-maps/api";
import { useGoogleMaps } from "../../../Context/GoogleMapContext";
import dayjs from "dayjs";
import clock from "../../../assets/clock.svg";
import pin from "../../../assets/location.svg";
import profile from "../../../assets/avatar.svg";
import { useSelector } from "react-redux";
// import { MapPin } from "lucide-react";

interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  employee_id: string;
  profile_image?: string | null;
  department_id: number;
  group_id: number;
}

interface Location {
  lat: number;
  lng: number;
  profileImage?: string | null;
  id: number;
  user_id: number;
  created_at: string;
  location: string;
  User?: IUser;
  Group?: {
    id: number;
    businessUnit_address?: string;
    businessUnit_coordinates?: [number, number];
  };
}

interface Props {
  locations: Location[];
  containerStyle?: React.CSSProperties;
}

const jitterLocations = (locations: Location[]) => {
  const grouped: Record<string, Location[]> = {};

  locations.forEach((loc) => {
    const key = `${loc.lat.toFixed(6)}-${loc.lng.toFixed(6)}`;
    grouped[key] = grouped[key] || [];
    grouped[key].push(loc);
  });

  const adjustedLocations: Location[] = [];
  Object.values(grouped).forEach((group) => {
    const angleStep = (2 * Math.PI) / Math.max(group.length, 1);
    const radius = 0.00005;

    group.forEach((location, index) => {
      const angle = index * angleStep;
      adjustedLocations.push({
        ...location,
        lat: location.lat + radius * Math.cos(angle),
        lng: location.lng + radius * Math.sin(angle),
      });
    });
  });

  return adjustedLocations;
};
const defaultCenter = { lat: 52.52, lng: 13.4049 }; // Berlin,Europe
const GoogleMapComponent: React.FC<Props> = ({ locations, containerStyle }) => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const finalContainerStyle = {
    width: "100%",
    height: "500px",
    ...containerStyle,
  };
  const mapRef = useRef<google.maps.Map | null>(null);
  const { isLoaded } = useGoogleMaps();
  const [hoveredLocation, setHoveredLocation] = useState<Location | null>(null);
  // const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  // const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const finalLocations = useMemo(() => jitterLocations(locations), [locations]);

  const businessUnit = useMemo(() => {
    const firstLocation = loginData?.businessUnit?.[0];
    return firstLocation?.businessUnit_coordinates
      ? {
          lat: firstLocation?.businessUnit_coordinates[0],
          lng: firstLocation?.businessUnit_coordinates[1],
          address: firstLocation.businessUnit_address || "Business Unit",
        }
      : null;
  }, [loginData?.businessUnit]);

  console.log({ businessUnit, loginData });

  const center = useMemo(
    () => businessUnit || finalLocations[0] || defaultCenter,
    [businessUnit, finalLocations]
  );

  const [zoom, setZoom] = useState(9);

  useEffect(() => {
    if (!isLoaded || !mapRef.current || finalLocations.length === 0) return;

    const map = mapRef.current;
    const bounds = new window.google.maps.LatLngBounds();

    [...finalLocations, ...(businessUnit ? [businessUnit] : [])].forEach(
      (location) => bounds.extend(location)
    );

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
      setTimeout(() => {
        setZoom(Math.min(map.getZoom() || 14, 14));
      }, 500);
    }
  }, [isLoaded, finalLocations, businessUnit]);

  if (!isLoaded) return <p>Loading maps...</p>;

  return (
    <GoogleMap
      mapContainerStyle={finalContainerStyle}
      zoom={zoom}
      center={center}
      onLoad={(map) => {
        mapRef.current = map;
      }}
      options={{
        minZoom: 2,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        disableDefaultUI: false,
        keyboardShortcuts: false,
        styles: [
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          { featureType: "transit", stylers: [{ visibility: "off" }] },
          {
            featureType: "road.highway",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ],
      }}
    >
      {finalLocations.map((location, index) => (
        <OverlayView
          key={index}
          position={location}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div
            style={{
              position: "relative",
              textAlign: "center",
              cursor: "pointer",
            }}
            onMouseEnter={() => setHoveredLocation(location)}
            onMouseLeave={() => setHoveredLocation(null)}
          >
            <img
              src={location?.profileImage || profile}
              alt="Profile"
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                border: "3px solid white",
                boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
              }}
            />
          </div>
        </OverlayView>
      ))}

      {/* {businessUnit && (
        <Marker
          position={businessUnit}
          icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
          onMouseOver={() => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
              timeoutRef.current = null;
            }
            setIsInfoWindowOpen(true);
          }}
          onMouseOut={() => {
            timeoutRef.current = setTimeout(() => {
              setIsInfoWindowOpen(false);
            }, 300);
          }}
        />
      )}

      {isInfoWindowOpen && (
        <InfoWindow
          position={{ lat: businessUnit?.lat, lng: businessUnit?.lng }}
          onCloseClick={() => setIsInfoWindowOpen(false)}
        >
          <div
            className="flex gap-1 items-center p-2 bg-white shadow-lg rounded-md"
            style={{ pointerEvents: "auto" }}
            onMouseEnter={() => {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
              }
              setIsInfoWindowOpen(true);
            }}
            onMouseLeave={() => {
              timeoutRef.current = setTimeout(() => {
                setIsInfoWindowOpen(false);
              }, 300);
            }}
          >
            <MapPin size={15} className="text-[#494849]" />
            <span className="text-[12px] font-medium text-[#494849]">
              {businessUnit?.address ?? "-"}
            </span>
          </div>
        </InfoWindow>
      )} */}

      {hoveredLocation && (
        <InfoWindow
          position={{ lat: hoveredLocation.lat, lng: hoveredLocation.lng }}
          onCloseClick={() => setHoveredLocation(null)}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center space-x-2">
              <img
                className="w-8 h-8 rounded-full"
                src={hoveredLocation?.User?.profile_image || profile}
                alt="Profile"
              />
              <div className="flex flex-col">
                <span className="font-medium text-[14px] text-[#020617]">
                  {hoveredLocation?.User?.firstName}{" "}
                  {hoveredLocation?.User?.lastName}
                </span>
                <span className="text-[#64748B] font-normal text-[14px]">
                  {hoveredLocation?.User?.employee_id || ""}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex gap-1 items-center">
                <img src={clock} alt="time" className="h-[12px] w-[12px]" />
                <span className="text-[12px] font-medium text-[#020617]">
                  Last Updated at-{" "}
                  {hoveredLocation?.created_at
                    ? dayjs(hoveredLocation?.created_at).format("h:mm A")
                    : "-"}
                </span>
              </div>
              <div className="flex gap-1 items-center">
                <img src={pin} alt="location" className="h-[12px] w-[12px]" />
                <span className="text-[12px] font-medium text-[#938F99]">
                  {hoveredLocation?.location ?? "-"}
                </span>
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default GoogleMapComponent;
