import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { cannotLogin } from "../../redux/actions/authActions";
import { authActions } from "../../redux/reducers/Auth/authSlice";
import Icon from "../../assets/tick.svg";
import Header from "../common/header";
import logo from "../../image/MicrosoftTeams-image (3).png"
import "../../index.css";
import { RECOVERY } from "../../utils/routeConstant";

// import styles from "./LoginScreen.module.css";

const CannotLogin: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [recoveryCode, setRecoveryCode] = useState(false);

  const handleCannotLogin = async () => {
    // Validation: Check if all details are provided
    if (!email.trim()) {
      toast.dismiss();
      toast.error("Please fill complete details.");

      return;
    }
    if (!isValidEmail(email)) {
      toast.dismiss();
      toast.error("Please provide a valid email address");

      return;
    }

    setRecoveryCode(true);
    // Dispatch cannotLogin action

    try {
      toast.dismiss();
      toast.loading("Sending code...");
      const responseData = await dispatch<any>(cannotLogin(email));
      if (responseData.success) {
        // Redirect to recovery (you can use React Router or any other routing library)
        toast.dismiss();
        toast.success(<b>Code send successfully!</b>);
        dispatch(authActions.setLoginDetails([{ email }, false, ""])); // Dispatch action to save token in Redux
        navigate(`${RECOVERY}`);
      }
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.response.data.message);
    } finally {
      setRecoveryCode(false);
    }
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="h-[100vh] Mainbg">
      {/* <Header /> */}
      <div className="flex justify-center gap-8">
        <div className="w-[29.3%] h-[15%] ml-[8.5%] mt-[13.5%] flex-col justify-start gap-5 items-start inline-flex text-white">
          <section className="flex-col justify-start items-start flex w-[27rem] h-[15rem]">
            <div className="mb-6 w-full">
            <div className="flex flex-row items-center justify-start gap-[12.2px] shrink-0 [debug_commit:69da668] z-[2]">
                <img
                  className="logocss relative min-h-[61px]"
                  loading="lazy"
                  alt=""
                  src={logo}
                />
                <div className="flex flex-col items-start justify-center pt-[18px] px-0 pb-[17.9px] gap-[3.7px]">
                  {/* <div className="flex flex-row items-end justify-start">
                    <b className="relative leading-[57%] font-semibold mq800:text-[28px] mq800:leading-[16px] mq450:text-[21px] mq450:leading-[12px]">
                      GO-EMS
                    </b>
                  </div> */}
                  <div className="w-[95px] relative text-[14.6px] tracking-[0.09em] capitalize text-gainsboro hidden">
                    trust to go
                  </div>
                </div>
              </div>
              <div className="text-white text-4xl font-semibold font-['Poppins'] whitespace-nowrap h-[3rem]">
                Welcome to GO-EMS
              </div>
              <div className="text-white text-base font-normal font-['Inter'] uppercase leading-normal">
              Employee Management System
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-4 flex w-[24rem] h-36">
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Spectacular reduction of compliance costs
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Regulatory framework proof
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Unmatched easy of mind
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  One-click certification per the highest EU standards
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="w-[21.3rem] h-[18rem] bg-white flex flex-col items-center ml-[24%] mr-[15%] mt-[12%] mb-[13rem] rounded-xl">
          <div className="text-neutral-800 text-3xl font-semibold font-['Poppins'] mt-[1.5rem] mb-[1rem]">
            Forget Password?
          </div>
          <form className="flex flex-col px-8">
            <label
              className="text-neutral-800 text-xs font-semibold font-['Poppins'] mt-2 mb-[4px] ml-5 text-left"
              htmlFor="email"
            >
              We'll send a recovery code to
            </label>
            <div className="flex justify-center">
              <input
                className="w-[18.75rem] h-10 border-opacity-30 rounded-[3px] border border-[#262626] ml-5 mr-[20px] px-2"
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
                required
              />
            </div>
            <div className="flex justify-center mt-6">
              <button
                className="text-white w-[19rem] h-10 buttoncss rounded-[8px] text-sm font-semibold font-['Poppins']"
                type="button"
                onClick={handleCannotLogin}
                disabled={recoveryCode}
              >
                Send recovery code
              </button>
            </div>

            <div className="flex flex-col md:flex-row mt-4">
              <div className="mb-4 md:mb-0">
                <NavLink
                  to="/"
                  className="text-blue-400 text-[12px] font-[600] font-['Poppins'] ml-[7.8rem]"
                >
                  Return to log in
                </NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CannotLogin;
