import React from "react";
import { useNavigate } from "react-router-dom";

import right from "../../../image/chevron-right.png";
import { Box, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function Initialization() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-2.5">
      <div className="h-12 flex justify-between items-start">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/appraisal-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
            Add Employee Appraisal
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            onClick={() => navigate("/appraisal-management")}
          >
            <div className="text text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
          >
            <div
              className="text-white text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize"
              onClick={() => navigate("/configure-managers")}
            >
              Save & Continue
            </div>
          </button>
        </div>
      </div>
      <div className="w-full bg-white rounded-lg shadow-custom p-6">
        <div className="flex flex-col gap-[30px]">
          <div className="flex flex-col gap-[30px]">
            <span className="font-[Poppins] font-medium text-[16px] leading-[16px]">
              Appraisal Details
            </span>
            <Box component="form" noValidate autoComplete="off">
              <div className="grid grid-cols-3 gap-y-[30px] gap-x-[33px]">
                <TextField
                  required
                  select
                  id="outline-required"
                  label="Business Unit"
                  name="business"
                ></TextField>
                <TextField
                  select
                  id="outline-required"
                  label="Department"
                  name="department"
                ></TextField>
                <TextField
                  required
                  select
                  id="outline-required"
                  label="Year Range"
                  name="year"
                ></TextField>
                <TextField
                  required
                  select
                  id="outline-required"
                  label="Appraisal Mode"
                  name="mode"
                ></TextField>
                <TextField
                  required
                  select
                  id="outline-required"
                  label="Appraisal Period"
                  name="period"
                ></TextField>
                <TextField
                  required
                  select
                  id="outline-required"
                  label="Appraisal Status"
                  name="status"
                ></TextField>
                <TextField
                  required
                  select
                  id="outline-required"
                  label="Enable to"
                  name="employee"
                ></TextField>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label="Employee's Due Date" className="w-full" />
                </LocalizationProvider>
                <TextField
                  required
                  select
                  id="outline-required"
                  label="Eligibility"
                  name="eligibility"
                ></TextField>
              </div>
            </Box>
            <span className="font-[Poppins] font-medium text-[16px] leading-[16px]">
              Appraisal Details
            </span>
            <Box component="form" noValidate autoComplete="off">
              <div className="grid grid-cols-3 gap-y-[30px] gap-x-[33px]">
                <TextField
                  id="outline-required"
                  label="Rating"
                  name="rating"
                  disabled
                  value={"1"}
                />
                <TextField
                  id="outline-required"
                  label="Rating Label"
                  name="label"
                />
                <div></div>
                <TextField
                  id="outline-required"
                  label="Rating"
                  name="rating"
                  disabled
                  value={"2"}
                />
                <TextField
                  id="outline-required"
                  label="Rating Label"
                  name="label"
                />
                <div></div>
                <TextField
                  id="outline-required"
                  label="Rating"
                  name="rating"
                  disabled
                  value={"3"}
                />
                <TextField
                  id="outline-required"
                  label="Rating Label"
                  name="label"
                />
                <div></div>
                <TextField
                  id="outline-required"
                  label="Rating"
                  name="rating"
                  disabled
                  value={"4"}
                />
                <TextField
                  id="outline-required"
                  label="Rating Label"
                  name="label"
                />
                <div></div>
                <TextField
                  id="outline-required"
                  label="Rating"
                  name="rating"
                  disabled
                  value={"5"}
                />
                <TextField
                  id="outline-required"
                  label="Rating Label"
                  name="label"
                />
                <div></div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Initialization;
