import React, { useEffect, useState } from "react";
import right from "../../../image/chevron-right.png";
import { useNavigate, useParams } from "react-router-dom";
import upload from "../../../image/Mask group.svg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MyStatefulEditor from "../Editor";
import FileUpload from "../../ExpenseManagement/FileUpload";
import TextFieldWithPopup from "../TextFeildPopup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { MenuItem } from "@mui/material";
import { createTaskApi, Fetch_Task_List } from "../../common/services/Project";
import toast from "react-hot-toast";
import eye from "../../../assets/Crud_Icons/view.svg";
import edit from "../../../assets/Crud_Icons/edit.svg";
import TablePaginationDemo from "../../common/Pagenation";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

interface FormValues {
  task_name: string;
  estimated_hours: string;
  actual_hours: string;
  status: string;
}

interface RowData {
  task_name: string;
  estimated_hours: string;
  actual_hours: string;
  start_date: string;
  end_date: string;
  id: string;
}

const EditTask = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [startDate, setStartData] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [open, setOpen] = useState(false);
  const [editorContent, setEditorContent] = useState<string>("");
  const [numOfItems, setNumOfItems] = useState(10);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [dateError, setDateError] = useState(false);
  const [projectResources, setProjectResources] = useState<
    { user_id: number; project_id: number }[]
  >([]);
  const param = useParams();

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
  };

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<number[]>([]);

  const handleSelectedEmployeesChange = (ids: number[]) => {

    if (!param.id) {
      console.error("param.id is undefined");
      return;
    }

    const projectId = parseInt(param.id);
    if (isNaN(projectId)) {
      console.error("param.id is not a valid number");
      return;
    }

    const newResources = ids.map((id) => ({
      user_id: id,
      project_id: projectId,
    }));
    setProjectResources([...newResources]);
  };

  const Status = [
    { value: "upcoming", label: "Up Coming" },
    { value: "ongoing", label: "On Going" },
    { value: "completed", label: "Completed" },
    { value: "onhold", label: "On Hold" },
  ];

  const [formValues, setFormValues] = useState<FormValues>({
    task_name: "",
    estimated_hours: "",
    actual_hours: "",
    status: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (formValues.task_name === "")
      tempErrors.task_name = "Task Name is required";
    if (formValues.status === "") tempErrors.status = "Task Status is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleSave = () => {
    !startDate && setDateError(true);
    const res = validate();
    if (!dateError && res) postData();
    else toast.error("Please fill all the required fields");
  };

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const postData = async () => {
    // setLoading(true);
    try {
      const response = await createTaskApi(
        // customer_id,
        // uploadedFiles.map((files) => files?.originalName.split(".")),
        //projectResources
        formValues.task_name,
        param.id,
        formValues.estimated_hours,
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        editorContent.replace(/<[^>]+>/g, ""),
        formValues.status,
        uploadedFiles.map((files) => files?.url),
        projectResources
      );

      setOpen(true);

      setTimeout(() => {
        setShow(false);
        fetchData();
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    //  setLoading(false);
  };

  const fetchData = async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Task_List(
        param.id,
        numOfItems,
        page
        // startDate,
        // endDate
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);
        // setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, numOfItems]);

  return (
    <>
      {show ? (
        <div className="mt-6">
          <div className="flex flex-row items-center h-12 justify-between">
            <div className="flex flex-row gap-[13px] justify-center items-center">
              <button onClick={() => setShow(false)}>
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                Add Task Details
              </span>
            </div>
            <div className="flex flex-start gap-5">
              <button
                onClick={() => setShow(false)}
                className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center rounded-lg self-stretch borderButton h-12"
                //onClick={(event) => handleCurrentPageChange("table", event)}
              >
                <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Cancel
                </div>
              </button>
              <button
                type="submit"
                form="form"
                className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
                onClick={() => handleSave()}
              >
                <div
                  // onClick={handleOpen}
                  className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
                >
                  Save
                </div>
              </button>
            </div>
          </div>
          <div className="mt-10 rounded-md overflow-hidden shadow-custom bg-white">
            <div className="container mx-auto p-4">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1 },
                }}
                noValidate
                autoComplete="off"
              >
                <div className="grid grid-cols-3 gap-4">
                  <TextField
                    required
                    id="outlined-required"
                    label="Task Name"
                    placeholder="Enter Declaration"
                    name="task_name"
                    value={formValues.task_name}
                    onChange={handleFrormChange}
                    error={!!errors.task_name}
                    helperText={errors.task_name}
                  />
                  <TextField
                    id="outlined-required"
                    label="Estimate Hours"
                    placeholder="Enter Declaration"
                    name="estimated_hours"
                    value={formValues.estimated_hours}
                    onChange={handleFrormChange}
                  />
                  {/* <TextField
                    id="outlined-required"
                    label="Actual Hours"
                    placeholder="Enter Declaration"
                    name="actual_hours"
                    value={formValues.actual_hours}
                    onChange={handleFrormChange}
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date *"
                      value={startDate}
                      onChange={(newValue) => {
                        setStartData(newValue);
                        setDateError(false);
                      }}
                      slotProps={{
                        textField: {
                          error: dateError,
                          helperText: dateError
                            ? "Please select a start date"
                            : "",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      //className="w-full"
                    />
                  </LocalizationProvider>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Status"
                    placeholder="Enter Declaration"
                    name="status"
                    value={formValues.status}
                    onChange={handleFrormChange}
                    error={!!errors.status}
                    helperText={errors.status}
                  >
                    {Status.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className="col-span-3">
                    <TextFieldWithPopup
                      onSelectedEmployeesChange={handleSelectedEmployeesChange}
                    />
                  </div>
                </div>

                <div className="mt-6 px-2">
                  <MyStatefulEditor onChange={setEditorContent} />
                </div>

                <FileUpload onFilesChange={handleFilesChange} />
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div className="py-6">
          <div className="flex flex-row items-center h-12 justify-between">
            <div className="flex flex-row gap-[13px] justify-center items-center">
              <button
                onClick={() =>
                  navigate(`/edit-resources-details/${param.id}`, {
                    state: { id: param.id },
                  })
                }
              >
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                Edit Task
              </span>
            </div>
          </div>

          <div className="flex justify-end mt-[30px]">
            <button
              onClick={() => setShow(true)}
              className="flex items-center font-[600] text-[14px] px-4 buttoncss text-white rounded-lg h-12"
            >
              <img className="w-5 h-5 mr-2" src={upload} alt="plus icon" />
              Add Task
            </button>
          </div>

          <div className="overflow-x-auto mt-4">
            <table className="w-full text-left border-[0.6px] bg-white border-[#E0E3E7]">
              <thead className="bg-[#E1E1E1] w-full">
                <tr className="h-11">
                  <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22] w-1/4">
                    Task Name
                  </th>
                  <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                    Estimate Hours
                  </th>
                  <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                    Actual Hours
                  </th>
                  <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                    Variance
                  </th>
                  <th className="pl-14 pr-0.5 py-2.5 text-[14px] text-[#1D1A22]">
                    Start Date
                  </th>
                  <th className="pl-14 pr-0.5 py-2.5 text-[14px] text-[#1D1A22]">
                    End Date
                  </th>
                  <th className="pl-8 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((items) => (
                  <tr
                    key={items.id}
                    className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100"
                  >
                    <td className="pl-6 pr-2.5 py-1 w-[25%]">
                      <div className="h-full w-32 overflow-hidden text-ellipsis whitespace-nowrap">
                        <span className="text-[#3F4254] font-[500] text-[12px]">
                          {items.task_name}
                        </span>
                      </div>
                    </td>
                    <td className="text-[#3F4254] pl-16 font-[500] text-[12px] py-1">
                      {items.estimated_hours || "--"}
                    </td>
                    <td className="text-[#3F4254] pl-14 font-[500] text-[12px] pr-2.5 py-1">
                      {items.actual_hours || "--"}
                    </td>
                    <td className="text-[#3F4254] pl-14 font-[500] text-[12px] pr-2.5 py-1">
                      {Number(items.actual_hours) -
                        Number(items.estimated_hours)}
                    </td>
                    <td className="text-[#3F4254] font-[500] text-[12px] pl-14 py-1">
                      {items.start_date || "--"}
                    </td>
                    <td className="text-[#3F4254] font-[500] text-[12px] pl-14 py-1">
                      {items.end_date || "--"}
                    </td>
                    <td className="flex items-center gap-2.5 pl-6 pr-2.5 py-1">
                      <img
                        src={eye}
                        onClick={() =>
                          navigate("/view-task-details", {
                            state: { id: items.id },
                          })
                        }
                        alt="View"
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      />
                      <img
                        src={edit}
                        onClick={() =>
                          navigate("/edit-task", { state: { id: items.id } })
                        }
                        alt="Edit"
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={1}
              numOfItems={10}
            />
          )}
        </div>
      )}
    </>
  );
};

export default EditTask;
