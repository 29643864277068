import React from "react";

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancelDelete: () => void;
}> = ({ onDelete, onCancelDelete }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Asset Category
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this!
        </h4>
        <div className="flex space-x-4 justify-center">
          <button
            onClick={onDelete}
            className="buttoncss text-white rounded"
          >
            <span className="px-12">Yes</span>
          </button>
          <button
            onClick={onCancelDelete}
            className="borderButton text-black rounded"
          >
            <span className="px-7">No</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;