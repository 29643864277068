import React, { useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import profile from "../../assets/Ellipse 580.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  approveRejectApi,
  fetchPolicyDetails,
} from "../common/services/companyManagement";
import ModalViewer from "../ExpenseManagement/ModalViewer";
import Receipt from "../ExpenseManagement/ImageView";
import toast from "react-hot-toast";

interface PolicyData {
  policyName: string;
  PolicyCategory: string;
  businessUnit: string;
  approverFirstName: string;
  reviewerLastName: string;
  reviewerFirstName: string;
  approverLastName: string;
  reviewer_id: number;
  approver_id: number;
  effective_date: string;
  reccurence: string;
  department: string;
  version: number;
  reviewer_status: string;
  approver_status: string;
  description: string;
  document_url: string[];
}

interface ApiResponse {
  document_url: string[];
}

export const ViewPolicy = () => {
  const navigate = useNavigate();
  const [receipts, setReceipts] = useState<ApiResponse[]>([]);
  const [status, setStatus] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);

  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };

  const [PolicyData, setPolicyData] = useState<PolicyData>({
    policyName: "",
    PolicyCategory: "",
    businessUnit: "",
    approverFirstName: "",
    approverLastName: "",
    reviewer_id: 0,
    approver_id: 0,
    effective_date: "",
    reccurence: "",
    department: "",
    version: 0,
    reviewer_status: "",
    approver_status: "",
    description: "",
    reviewerLastName: "",
    reviewerFirstName: "",
    document_url: [],
  });
  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    fetchPolicyDetails(id)
      .then((data) => {
        if (data.result) {
          setPolicyData({
            policyName: data.result?.name,
            PolicyCategory: data.result?.PolicyCategory.name,
            businessUnit: data.result?.Group.name,
            approverFirstName: data.result?.Approver?.firstName,
            approverLastName: data.result?.Approver?.lastName,
            reviewer_id: data.result?.Reviewer?.id,
            reviewerLastName: data.result?.Reviewer?.lastName,
            reviewerFirstName: data.result?.Reviewer?.firstName,
            approver_id: data.result?.Approver.id,
            effective_date: data.result?.effective_date,
            reccurence: data.result?.recurrence,
            department: data.result?.Department?.name,
            version: data.result?.version_no,
            reviewer_status: data.result?.reviewer_status,
            approver_status: data.result?.approver_status,
            description: data.result?.PolicyCategory?.description,
            document_url: data.result?.document_url,
          });
          setReceipts(data?.result);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const closeModal = () => {
    setSelectedFileUrl(null);
  };

  const handleReject = () => {
    postData();
  };

  const handleApproved = () => {
    postData2();
  };

  const postData2 = async () => {
    // setLoading(true);
    setStatus("approved");
    const status = "approved";
    try {
      const response = await approveRejectApi(
        //  customer_id,
        id,
        status
      );
      // console.log(response,"respost--->>>");

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/policies");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  const postData = async () => {
    // setLoading(true);
    setStatus("rejected");
    const status = "rejected";
    try {
      const response = await approveRejectApi(
        //  customer_id,
        id,
        status
      );
      // console.log(response,"respost--->>>");

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/policies");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  console.log(receipts, "receiptsreceipts policy----->>>>>>>");

  return (
    <div className="flex flex-col px-4 pt-6 gap-[50px] pb-12">
      <div className="flex flex-row justify-between items-center h-12">
        <div className="flex flex-row justify-between items-center h-[30px] w-44">
          <button onClick={() => navigate("/policies")}>
            <img
              src={right}
              alt="right arrow"
              className="h-6 w-6 bg-black rounded-full"
            />
          </button>
          <span className="h-[30px] font-['Poppins'] font-semibold text-xl text-[#1D1A22] w-[135px]">
            Policy Details
          </span>
        </div>
        <div className="flex flex-row justify-between items-center h-full w-[352px]">
          <button
            onClick={handleReject}
            className="h-12 w-[166px] rounded-lg bg-[#B3261E] py-[11px] px-[19px] flex flex-row justify-center items-center hover:bg-[#96150e]"
          >
            <div className="flex flex-col justify-center items-center">
              <span className="text-white font-['Poppins'] font-medium text-[14px] leading-[29px]">
                Reject
              </span>
            </div>
          </button>
          <button
            onClick={handleApproved}
            className="h-12 w-[166px] rounded-lg bg-[#359349] py-[11px] px-[19px] flex flex-row justify-center items-center hover:bg-[#1f8735]"
          >
            <div className="flex flex-col justify-center items-center">
              <span className="text-white font-['Poppins'] font-medium text-[14px] leading-[29px]">
                Approve
              </span>
            </div>
          </button>
        </div>
      </div>
      <div
        className="flex flex-col gap-5 p-5 shadow rounded-lg h-auto"
        style={{ boxShadow: "0px 2px 15px 0px rgba(72, 71, 71, 0.1)" }}
      >
        <div className="w-full h-6">
          <span className="font-['Inter'] font-semibold text-[20px] leading-[24.2px] text-black">
            Policy Details
          </span>
        </div>
        <div
          className="flex flex-col gap-[15px] px-2.5 py-5 rounded-md bg-gradient-to-r"
          style={{
            background:
              "linear-gradient(90deg, rgba(65, 101, 196, 0.05) 0%, rgba(142, 31, 210, 0.05) 100%)",
          }}
        >
          <div className="grid grid-cols-3 gap-x-2">
            <div className="flex flex-col gap-[7px] h-full justify-between">
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                Policy Name
              </span>
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#1D1A22]">
                {PolicyData.policyName}
              </span>
            </div>
            <div className="flex flex-col gap-[7px] h-full justify-between">
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                Policy Categories
              </span>
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#1D1A22]">
                {PolicyData.PolicyCategory}
              </span>
            </div>
            <div className="flex flex-col gap-[7px] h-full justify-between">
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                Business Unit
              </span>
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#1D1A22]">
                {PolicyData.businessUnit}
              </span>
            </div>
          </div>
          <hr className="px-2.5 border-b-[2px] border-[#EDEDED]" />
          <div className="grid grid-cols-3 gap-x-2">
            <div className="flex flex-row w-full h-[60px] justify-between">
              <div className="flex flex-col gap-[7px]">
                <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                  Policy Reviewer
                </span>
                <div className="flex flex-row gap-[7px]">
                  <div className="h-[31px] w-[31px]">
                    <img
                      src={profile}
                      alt=""
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      {PolicyData.reviewerFirstName}{" "}
                      {PolicyData.reviewerLastName}
                    </span>
                    <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      {PolicyData.reviewer_id}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full h-[60px] justify-between">
              <div className="flex flex-col gap-[7px]">
                <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                  Policy Approver
                </span>
                <div className="flex flex-row gap-[7px]">
                  <div className="h-[31px] w-[31px]">
                    <img
                      src={profile}
                      alt=""
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      {PolicyData.approverFirstName}{" "}
                      {PolicyData.approverLastName}
                    </span>
                    <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      {PolicyData.approver_id}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full h-[60px] justify-between">
              <div className="flex flex-col gap-[7px]">
                <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                  Effective Date
                </span>
                <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#1D1A22]">
                  {PolicyData.effective_date}
                </span>
              </div>
            </div>
          </div>
          <hr className="px-2.5 border-b-[2px] border-[#EDEDED]" />
          <div className="grid grid-cols-3 gap-x-2">
            <div className="flex flex-col gap-[7px] h-full justify-between">
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                Reccurence Period
              </span>
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#1D1A22]">
                {PolicyData.reccurence}
              </span>
            </div>
            <div className="flex flex-col gap-[7px] h-full justify-between">
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                Department
              </span>
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#1D1A22]">
                {PolicyData.department}
              </span>
            </div>
            <div className="flex flex-col gap-[7px] h-full justify-between">
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                Version Number
              </span>
              <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#1D1A22]">
                {PolicyData.version}
              </span>
            </div>
          </div>
          <hr className="px-2.5 border-b-[2px] border-[#EDEDED]" />
          <div className="grid grid-cols-3 gap-x-2">
            <div className="flex flex-row w-full h-[60px] justify-between">
              <div className="flex flex-col gap-[7px]">
                <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                  Policy Reviewer Status
                </span>
                <div className="flex flex-row gap-[7px]">
                  <div className="h-[31px] w-[31px]">
                    <img
                      src={profile}
                      alt=""
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      {PolicyData.reviewerFirstName}{" "}
                      {PolicyData.reviewerLastName}
                    </span>
                    <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      {PolicyData.reviewer_id}
                    </span>
                  </div>
                  <div className="px-[1px] py-[9px]">
                    <div className="flex flex-row justify-center items-center w-[88px] h-[26px] rounded-sm bg-[#D4F8C7]">
                      <span className="font-['Poppins'] font-normal text-[14px] leading-[24px] text-[#262626]">
                        {PolicyData.reviewer_status}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full h-[60px] justify-between">
              <div className="flex flex-col gap-[7px]">
                <span className="font-['Inter'] font-medium text-[16px] leading-[20px] text-[#99A1B7]">
                  Policy Approver Status
                </span>
                <div className="flex flex-row gap-[7px]">
                  <div className="h-[31px] w-[31px]">
                    <img
                      src={profile}
                      alt=""
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      {PolicyData.approverFirstName}{" "}
                      {PolicyData.approverLastName}
                    </span>
                    <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      {PolicyData.approver_id}
                    </span>
                  </div>
                  <div className="px-[1px] py-[9px]">
                    <div className="flex flex-row justify-center items-center w-[88px] h-[26px] rounded-sm bg-[#D4F8C7]">
                      <span className="font-['Poppins'] font-normal text-[14px] leading-[24px] text-[#262626]">
                        {PolicyData.approver_status}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="border-b-[2px] border-[#EDEDED]" />
          <div className="flex flex-col gap-[7px] w-full">
            <div className="w-full h-[19px]">
              <span className="font-['Inter'] font-medium text-[16px] leading-[19.36px] text-[#99A1B7]">
                Description
              </span>
            </div>
            <div className="w-full pr-8">
              <p className="font-['Poppins'] font-medium text-base tracking-[0.15px] text-[#322F37]">
                {PolicyData.description}
              </p>
            </div>
          </div>
          <hr className="border-b-[2px] border-[#EDEDED]" />
          <div className="w-full">
            <span className="font-['Inter'] font-medium text-[16px] leading-[19.36px] text-[#99A1B7]">
              Policy Document
            </span>
            <br />
            <div className="grid grid-cols-2 gap-4 mt-2 mb-4">
              {PolicyData?.document_url?.map((r, i) => (
                <Receipt
                  key={i}
                  fileName={r?.split("/")?.pop() || `Receipt ${i + 1}`}
                  fileSize={`${(Math?.random() * 2 + 1)?.toFixed(2)} MB`}
                  onView={() => handleView(r)}
                />
              ))}
            </div>
            {selectedFileUrl && (
              <ModalViewer
                isOpen={!!selectedFileUrl}
                onRequestClose={closeModal}
                fileUrl={selectedFileUrl}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
