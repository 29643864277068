import { api_key_work as api_key, session_query } from "../../../utils/helperData";
import axios from "axios";
import { Dayjs } from "dayjs";
import { ANNOUNCEMENT } from "../api";

export const addAnnouncement = async (
  title: string,
  status: string,
  group_id: Number,
  department_id: Number[],
  description: string,
  date: string | null | Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    title: title,
    status: status,
    group_id: group_id,
    department_id: department_id,
    description: description,
  };
  if (date !== "Invalid date") data.date = date;

  try {
    const response = await axios.post(ANNOUNCEMENT, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Announcement_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number,
  searchTerm: string
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  return axios.get(`${ANNOUNCEMENT}${query}&${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Announcement_Details = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axios.get(`${ANNOUNCEMENT}/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateAnnouncement = async (
  id: string,
  title: string,
  status: string,
  group_id: Number,
  department_id: Number[],
  description: string,
  date: string | null | Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  const body: any = {
    title: title,
    status: status,
    group_id: group_id,
    department_id: department_id,
    description: description,
  };
  if (date !== "Invalid date") body.date = date;

  try {
    const response = await axios.patch(`${ANNOUNCEMENT}/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
