import {
  api_key_work as api_key,
  session_query,
} from "../../../utils/helperData";
import axios from "axios";
import moment from "moment";
import {
  PROJECT_TASK,
  PROJECT_TASK_RESOURCE,
  PROJECT_USER,
  PROJECTREPORT,
} from "../api";

export const Fetch_Project_Report_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axios.get(`${PROJECTREPORT}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Project_Task_List = async ({
  search,
  startDate,
  endDate,
  id,
}: {
  search?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  id: string;
}) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";
  if (search !== undefined) {
    query += `&search=${search}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  try {
    const response = await axios.get(`${PROJECT_TASK}/${id}?${query}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const Fetch_Project_Task_Resource_List = async ({
  search,
  startDate,
  endDate,
  id,
}: {
  search: string;
  startDate: Date | null;
  endDate: Date | null;
  id: string;
}) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";
  if (search !== undefined) {
    query += `&search=${search}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  try {
    const response = await axios.get(
      `${PROJECT_TASK_RESOURCE}/${id}?${query}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Project_User_List = async ({
  search,
  startDate,
  endDate,
  id,
}: {
  search: string;
  startDate: Date | null;
  endDate: Date | null;
  id: string;
}) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";
  if (search !== undefined) {
    query += `&search=${search}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  try {
    const response = await axios.get(`${PROJECT_USER}/${id}?${query}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
