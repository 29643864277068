import React, { useCallback, ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { debounce } from "lodash";

import maskPlus from "../../image/Mask group.svg";
import Search from "../SearchBar/Search";
import filter from "../../assets/filter.svg";
import view from "../../assets/Crud_Icons/view.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import profile from "../../assets/Ellipse 580.png";

import TablePaginationDemo from "../common/Pagenation";

function Appraisal() {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    }, 100),
    []
  );

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-6">
        <div className="flex justify-between items-start">
          <span className="font-[Poppins] font-semibold text-[20px] leading-[30px] text-[#1D1A22]">
            Employee Appraisals / Performance
          </span>
          <button className="flex items-center font-['Poppins'] font-[600] text-[14px] px-4 buttoncss text-white rounded-lg h-12" onClick={() => navigate("/add-appraisal")}>
            <img className="w-5 h-5 mr-2" src={maskPlus} alt="plus icon" />
            Add Employee Appraisal
          </button>
        </div>

        <div className="flex flex-col gap-2 5">
          <div className="h-10 flex justify-between items-center">
            <Search
              label="Search with Employee Name"
              onChange={updateSearchTerm}
            />
            <button className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white hover:bg-gray-100">
              <img src={filter} alt="filter" className="h-3.5 w-3.5" />
              <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                Filter
              </span>
            </button>
          </div>
          <div className="flex justify-end gap-8">
            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                Open Appraisal
              </span>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FA1464" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                Closed Appraisal
              </span>
            </div>
          </div>
          <div className="overflow-x-auto shadow-sm">
            <table className="w-full min-w-max text-left table-auto bg-white border border-[#E0E0E0]">
              <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                <tr className="w-full">
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5 pl-5">
                    Employee Name & ID
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                    Business Unit
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                    Department
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                    Financial Year
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                    Appraisal Mode
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                    Period
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5 flex justify-end items-center pr-5">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="w-full hover:bg-gray-100">
                  <td className="pl-4 py-2.5">
                    <div className="flex gap-2 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <circle
                          cx="3.99537"
                          cy="3.74537"
                          r="3.49537"
                          //   fill={
                          //     item.status === "published"
                          //       ? "#00CBA0"
                          //       : item.status === "scheduled"
                          //       ? "#FF9950"
                          //       : "#D3D3D3"
                          //   }
                          fill="#D3D3D3"
                        />
                      </svg>
                      <div className="flex items-center gap-2 5">
                        <img
                          src={profile}
                          alt="profile image"
                          className="h-10 w-10"
                        />
                        <div className="flex flex-col">
                          <span className="font-[Poppins] font-medium text-[14px] leading[21px] text-[#1D1A22]">
                            Brooklyn Simmons
                          </span>
                          <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                            EMP0062
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="py-2.5">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                      AppVin, Noida
                    </span>
                  </td>
                  <td className="py-2.5">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                      Human Resource
                    </span>
                  </td>
                  <td className="py-2.5">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                      2024-2024
                    </span>
                  </td>
                  <td className="py-2.5">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                      Monthly
                    </span>
                  </td>
                  <td className="py-2.5">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                      Jan
                    </span>
                  </td>
                  <td className="py-2.5 pr-4">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F] flex justify-end items-center">
                      <img
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                        src={view}
                        alt="View"
                        onClick={() => navigate("/view-appraisal")}
                      />
                      <img
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                        src={edit}
                        alt="Edit"
                        onClick={() => navigate("/edit-appraisal")}
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={1}
              numOfItems={10}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Appraisal;
