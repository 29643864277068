import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

interface TimeRangePickerProps {
  label: string;
  value: { start: Dayjs | null; end: Dayjs | null };
  onChange: (value: { start: Dayjs | null; end: Dayjs | null }) => void;
  onError: (error: string | null) => void;
}

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({ label, value, onChange, onError }) => {
  const [start, setStart] = useState<Dayjs | null>(value.start);
  const [end, setEnd] = useState<Dayjs | null>(value.end);
  const [error, setError] = useState<string | null>(null);

  const handleStartChange = (newValue: Dayjs | null) => {
    setStart(newValue);
    onChange({ start: newValue, end });
    validateTimeRange(newValue, end);
  };

  const handleEndChange = (newValue: Dayjs | null) => {
    setEnd(newValue);
    onChange({ start, end: newValue });
    validateTimeRange(start, newValue);
  };

  const validateTimeRange = (start: Dayjs | null, end: Dayjs | null) => {
    if (start && end && start.isAfter(end)) {
      const errorMsg = 'End time must be after start time';
      setError(errorMsg);
      onError(errorMsg);
    } else {
      setError(null);
      onError(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='flex gap-4'>
        <TimePicker
          label={`${label} Start`}
          value={start}
          onChange={handleStartChange}
          className='w-[95%]'
         // renderInput={(params) => <TextField {...params} error={!!error} helperText={error} />}
        />
        <TimePicker
          label={`${label} End`}
          value={end}
          onChange={handleEndChange}
          className='w-[95%]'
          //renderInput={(params) => <TextField {...params} error={!!error} helperText={error} />}
        />
      </div>
    </LocalizationProvider>
  );
};

export default TimeRangePicker;
