import React, { useState, useEffect, useCallback, ChangeEvent } from "react";
import { debounce } from "lodash";
import profile from "../../assets/Ellipse 580.png";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import {
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  Chip,
  Grid,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import cross from "../../assets/cross_new.svg";

import Search from "../SearchBar/Search";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-root": {
      flexWrap: "wrap",
      gap: theme.spacing(0.5),
      padding: theme.spacing(1),
      "& .MuiInputAdornment-root": {
        height: "auto",
        maxWidth: "100%",
        margin: 0,
        whiteSpace: "normal",
      },
    },
    "& .MuiInputBase-input": {
      width: "auto",
      flexGrow: 1,
    },
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
  },
  chip: {
    margin: "2px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#49454F",
  },
}));

interface Employee {
  firstName: string;
  lastName: string;
  employee_id: string;
  id: number;
  profile_image: string;
}

interface TextFieldWithPopupProps {
  onSelectedEmployeesChange: (ids: number[]) => void;
}

const TextFieldWithPopup: React.FC<TextFieldWithPopupProps> = ({
  onSelectedEmployeesChange,
}) => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const classes = useStyles();

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000),
    [setSearchTerm]
  );

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const fetchEmployees = async () => {
    try {
      const access_token = localStorage.getItem("access_token");
      let query = `?customer_id=${customer_id}&size=200&page=1`;
      if (searchTerm !== "") {
        query += `&search=${searchTerm}`;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API}/api/v1/user/list${query}`,
        {
          headers: {
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
          },
        }
      );
      const data = await response.json();
      const result: Employee[] = data.result.userList.rows;

      setEmployees(result);
    } catch (error) {
      console.error("Failed to fetch employees:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [customer_id, searchTerm]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = (employee: Employee) => {
    setSelectedEmployees((prev) => {
      const updatedSelected = prev.some((emp) => emp.id === employee.id)
        ? prev.filter((emp) => emp.id !== employee.id)
        : [...prev, employee];

      // Call the prop function to update parent state
      onSelectedEmployeesChange(updatedSelected.map((emp) => emp.id));
      return updatedSelected;
    });
  };

  const handleRemove = (id: number) => {
    setSelectedEmployees((prev) => {
      const updatedSelected = prev.filter((emp) => emp.id !== id);

      // Call the prop function to update parent state
      onSelectedEmployeesChange(updatedSelected.map((emp) => emp.id));
      return updatedSelected;
    });
  };

  const handleAssign = () => {
    handleClose();
  };

  return (
    <div>
      <div className="pr-[14px]">
        <TextField
          label="Select assigned"
          onClick={handleOpen}
          variant="outlined"
          fullWidth
          className={classes.textField}
          InputProps={{
            startAdornment: selectedEmployees.length > 0 && (
              <InputAdornment
                position="start"
                className={classes.chipContainer}
              >
                {selectedEmployees.map((employee) => (
                  <Chip
                    key={employee.id}
                    avatar={<Avatar src={employee.profile_image} />}
                    label={employee.firstName}
                    onDelete={() => handleRemove(employee.id)}
                    className={classes.chip}
                  />
                ))}
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ paddingBottom: "40px" }}
      >
        <div className="flex justify-between pr-6">
          <DialogTitle
            style={{
              width: "500px",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "19.5px",
              lineHeight: "23.4px",
              color: "#1D1A22",
            }}
          >
            Resource list
          </DialogTitle>
          <img
            src={cross}
            alt="cross"
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div className="w-full h-12 bg-[#E1E1E1] flex justify-start items-center px-4 py-3">
          <span className="font-[Poppins] font-medium ext-[14px] leading-[14px] text-[#3F4254]">
            Manager/Employee name
          </span>
        </div>
        <div className="py-4 px-6 flex justify-between items-center">
          <Search label="Search Manager/Employee" onChange={updateSearchTerm} />
          <button
            className="buttoncss text-white px-6 py-2.5 rounded-lg font-[Poppins] font-semibold text-[14px] leading-[29px]"
            onClick={handleAssign}
          >
            Assign
          </button>
        </div>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <List>
              {employees?.map((employee, index) => (
                <>
                  <ListItem
                    key={employee.id}
                    button
                    onClick={() => handleToggle(employee)}
                  >
                    <Grid container alignItems="center">
                      <Grid item>
                        <Checkbox
                          edge="end"
                          checked={selectedEmployees.some(
                            (emp) => emp.id === employee.id
                          )}
                          tabIndex={-1}
                          disableRipple
                          color="primary"
                          style={{ height: "18px", width: "18px" }}
                        />
                      </Grid>
                      <ListItemAvatar>
                        <Avatar
                          src={employee?.profile_image || profile}
                          style={{
                            height: "35px",
                            width: "35px",
                            marginLeft: "30px",
                          }}
                        />
                      </ListItemAvatar>
                      <Grid item xs>
                        <ListItemText
                          primary={
                            employee?.firstName + " " + employee?.lastName
                          }
                          secondary={employee?.employee_id}
                          style={{
                            paddingLeft: "20px",
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            fontSize: "50px",
                            lineHeight: "13.98px",
                            color: "#49454F",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  {/* {index < employees.length - 1 && <Divider variant="inset" />} */}
                </>
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TextFieldWithPopup;
