// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_main_content::-webkit-scrollbar {
  width: 6px;  /* Vertical scrollbar width */
  height: 6px; /* Horizontal scrollbar height */
}

.table_main_content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 8px; /* Rounded corners for the track */
}

.table_main_content::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 8px; /* Rounded corners for the thumb */
}

.table_main_content::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover color for the thumb */
}
`, "",{"version":3,"sources":["webpack://./src/components/roleManagement/roleManagement.css"],"names":[],"mappings":"AAAA;EACE,UAAU,GAAG,6BAA6B;EAC1C,WAAW,EAAE,gCAAgC;AAC/C;;AAEA;EACE,mBAAmB,EAAE,gBAAgB;EACrC,kBAAkB,EAAE,kCAAkC;AACxD;;AAEA;EACE,gBAAgB,EAAE,gBAAgB;EAClC,kBAAkB,EAAE,kCAAkC;AACxD;;AAEA;EACE,gBAAgB,EAAE,8BAA8B;AAClD","sourcesContent":[".table_main_content::-webkit-scrollbar {\n  width: 6px;  /* Vertical scrollbar width */\n  height: 6px; /* Horizontal scrollbar height */\n}\n\n.table_main_content::-webkit-scrollbar-track {\n  background: #f1f1f1; /* Track color */\n  border-radius: 8px; /* Rounded corners for the track */\n}\n\n.table_main_content::-webkit-scrollbar-thumb {\n  background: #888; /* Thumb color */\n  border-radius: 8px; /* Rounded corners for the thumb */\n}\n\n.table_main_content::-webkit-scrollbar-thumb:hover {\n  background: #555; /* Hover color for the thumb */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
