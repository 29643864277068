import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import TablePaginationDemo from "../common/Pagenation";
import { Fetch_Project_Task_List } from "../common/services/ProjectReport";

interface ProjectAndTask {
  id: number;
  name: string;
  duration: number;
}

function ProjectAndTask({
  search,
  startDate,
  endDate,
}: {
  search: string;
  startDate: Date | null;
  endDate: Date | null;
}) {
  const [count, setCount] = useState(0);
  const location = useLocation();
  const id = location.state?.id;
  const [numOfItems, setNumOfItems] = useState(10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<ProjectAndTask[]>([]);

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  useEffect(() => {
    setLoading(true);
    Fetch_Project_Task_List({
      id,
      search,
      startDate,
      endDate,
    })
      .then((data) => {
        if (data.result) {
          setTableData(data.result.tasks);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [endDate, id, search, startDate]);

  return (
    <div className="pt-4 flex flex-col gap-4">
      <div className="flex flex-col gap-3">
        <span className="font-[Poppins] font-semibold text-base text-[#1D1A22]">
          Tasks
        </span>
        {!loading && tableData?.length === 0 ? (
          <div className="w-full flex justify-center items-center h-10">
            No Tasks Found
          </div>
        ) : null}
        {loading ? (
          <div className="w-full flex justify-center items-center h-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4 task:grid-cols-1">
            {tableData?.map((item) => (
              <div className="p-5 card-shadow rounded-md border-l-4 border-[#AD8CF1] flex justify-between items-center bg-white">
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22] w-[74%]">
                  {item?.name}
                </span>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  {item?.duration} Hrs.
                </span>
              </div>
            ))}
          </div>
        )}

        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>
    </div>
  );
}

export default ProjectAndTask;
