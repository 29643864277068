// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollBar::-webkit-scrollbar {
  width: 0.4em;
}

.scrollBar::-webkit-scrollbar-track {
    background: #e5e7eb;
    border-radius: 6px;
}

.scrollBar::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":[".scrollBar::-webkit-scrollbar {\n  width: 0.4em;\n}\n\n.scrollBar::-webkit-scrollbar-track {\n    background: #e5e7eb;\n    border-radius: 6px;\n}\n\n.scrollBar::-webkit-scrollbar-thumb {\n    background-color: black;\n    border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
