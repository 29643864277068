import { Dispatch } from "redux";
import axios from "axios";
import { api_key_work as api_key , base_api} from "../../utils/helperData";

export const signUpUser =
  (
    email: string,
    phone: string,
    firstName: string,
    lastName: string,
    country_code: string
  ) =>
  async (dispatch: Dispatch) => {
    const response = await axios.post(
      base_api + "/api/v1/sign-up",
      { email, phone, firstName, lastName, country_code },
      {
        headers: {
          "x-api-key": api_key,
        },
      }
    );
    if (response.status === 200) {
      const userData = response?.data?.result || null;
      return userData; // Return the user data on successful authentication
    }
    return response;
  };

export const verifyEmail =
  (email: string, otp: string) => async (dispatch: Dispatch) => {
    const response = await axios.post(
      base_api + "/api/v1/verify-otp",
      { email, otp },
      {
        headers: {
          "x-api-key": api_key,
        },
      }
    );
    if (response.status === 200) {
      const userData = response?.data || null;
      console.log(userData);
      return userData; // Return the user data on successful authentication
    }
    return response;
  };

export const sendOrganisationDetails =
  (data: {}, customer_id: string, access_token: string) =>
  async (dispatch: Dispatch) => {
    const response = await axios.patch(
      base_api + `/api/v1/customer/${customer_id}`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: access_token,
        },
      }
    );
    if (response.status === 200) {
      const userData = response?.data || null;
      console.log(userData);
      return userData; // Return the user data on successful authentication
    }
    return response;
  };

export const signup =
  (email: string, password: string, access_token: string) =>
  async (dispatch: Dispatch) => {
    // Make API call to authenticate user
    const response = await axios.patch(
      base_api + "/api/v1/update-password",
      { email, password },
      {
        headers: {
          "x-api-key": api_key,
          Authorization: access_token,
        },
      }
    );
    if (response.status === 200) {
      const userData = response?.data?.result || null;
      return userData; // Return the user data on successful authentication
    }
    return response;
  };

  export const resetPassword =
  (email: string, password: string, confirm_password:string, access_token: string) =>
  async (dispatch: Dispatch) => {
    // Make API call to authenticate user
    const response = await axios.post(
      base_api + "/api/v1/reset-password",
      { email, password, confirm_password },
      {
        headers: {
          "x-api-key": api_key,
          Authorization: access_token,
        },
      }
    );
    if (response.status === 200) {
      const userData = response?.data?.result || null;
      return userData; // Return the user data on successful authentication
    }
    return response;
  };

export const checkEmailExist =
  (email: string) => async (dispatch: Dispatch) => {
    try {
      const res = await axios.post(
        base_api + "/api/v1/check-email",
        { email },
        {
          headers: {
            "x-api-key": api_key,
          },
        }
      );
      if (res.status === 200) {
        const userData = res?.data?.result || null;
        return userData;
      }
    } catch (error: any) {
      // since the status code is 302, thus it is going inside the catch block, as it is treating as error.
      return error.message;
    }
  };
