import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { revertAll } from '../../ResetRedux/RevertAll';
 
interface activityLogState {
    selectedNavItem: string;
}
 
const initialState: activityLogState = {
    selectedNavItem: "ACTIVITY",
    
};

const activityLogSlice = createSlice({
    name: 'activityLog',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setSelectedNavItem(state, action: PayloadAction<any>){
            state.selectedNavItem = action.payload;
        },
    }
});
 
export const activityLogActions = activityLogSlice.actions;
export default activityLogSlice;