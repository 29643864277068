import { Dispatch } from "redux";
import { SideBarActions } from "../../reducers/SideBar/SideBarSlice";
import { Fetch_Sidebar_Api } from "../../../components/common/services/sidebar";
import InternalServer from "../../../components/common/InternalServer";

export const fetchSideBarData = () => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      const response = await Fetch_Sidebar_Api();

      const resData = await response.json();
       console.log(resData,"resdataa---->>>");
      if (
        typeof resData !== "object" ||
        resData === null ||
        !("result" in resData)
      ) {
        throw new Error("Invalid API response format!");
      }

      const data = resData?.result;

      if (!response.ok) {
        if(response.status === 500) {
          return <InternalServer />;
        }
        throw new Error("Could not fetch service data!");
      }

      return data;
    };

    try {
      const data = await fetchData();
       console.log(data,"data--reducer--->>>>");

      dispatch(
        SideBarActions.setSideBarData({
          data,
        })
      );
    } catch (error) {
      if (error instanceof Error) {
        console.log("Fetching Service data failed!");
      } else {
        console.log("An unknown error occurred:", error);
      }
    }
  };
};