import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import check from "../../image/checkmark-circle-02.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField, Box, Typography, Modal, MenuItem} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MyStatefulEditor from "../ProjectManagement/Editor";
import FileUpload from "../ExpenseManagement/FileUpload";
import { Fetch_Policy_Category_List } from "../common/services/companyManagement";
import { useSelector } from "react-redux";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface RowData{
id:string,
name:string
}

export const CreatePolicies = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigate("/policies");
  };

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
    console.log(loginData.customer_id, "loginData.customer_id--->>>>");
  }

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
    console.log("Files updated:", files);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Policy_Category_List(
        customer_id,
        searchTerm,
        numOfItems,
        page
        //sortType,
        // startDate,
        // endDate
      );
      if (response.status === 200) {
        console.log("Data: Policy categories", response.data);
       // setTableData(response.data?.result);
        //setCount(response.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    console.log("after fetch page", page);
  }, [
    // searchTerm,
    page,
    numOfItems,
  ]);

  return (
    <div className="px-4 py-6">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Policy created successfully!
          </Typography>
        </Box>
      </Modal>
      <div className="w-full flex flex-row justify-between">
        <div className="w-full flex flex-row px-4 items-center h-12 justify-between">
          <div className="flex flex-row gap-[26px] justify-center items-center">
            <button onClick={() => navigate("/policies")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid border-[#242424] h-12">
              <div className="text-[#2C2C2C] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <button
              type="submit"
              form="form"
              onClick={handleOpen}
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Create
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="mt-6 rounded overflow-hidden shadow-lg bg-white m-4">
        <div className="container mt-10 mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Policy Name"
                placeholder="Enter Project"
                value=""
                name="policy_name"
              />
              <TextField
                required
                id="outlined-required"
                label="Policy category"
                select
                placeholder="Policy Category"
                name="policy_category"
              >
                {
                  tableData.map((option)=>(
                    <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                  ))
                }
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="Business Unit"
                select
                // placeholder="Enter Declaration"
                value=""
                name="business_unit"
              ></TextField>
              <TextField
                required
                id="outlined-required"
                label="Policy Reviewer"
                select
                // placeholder="Enter Declaration"
                value=""
                name="policy_reviewer"
              ></TextField>
              <TextField
                required
                id="outlined-required"
                label="Policy Approver"
                select
                // placeholder="Enter Declaration"
                value=""
                name="policy_approver"
              ></TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Effective Date" />
              </LocalizationProvider>
              <TextField
                required
                id="outlined-required"
                label="Reccurence Period"
                select
                // placeholder="Enter Declaration"
                value=""
                name="reccurence_period"
              ></TextField>
              <TextField
                required
                id="outlined-required"
                label="Department"
                select
                // placeholder="Enter Declaration"
                value=""
                name="department"
              ></TextField>
              <TextField
                required
                id="outlined-required"
                label="Version Number"
                select
                // placeholder="Enter Declaration"
                value=""
                name="version_number"
              ></TextField>
            </div>
            <div className="mt-4 pl-2">
              <span className="font-['Poppins'] font-medium text-[12px] leading-normal text-[#322F37]">
                Policy Description
              </span>
              <MyStatefulEditor />
            </div>
            <div className="mt-4 pl-2">
              <span className="font-['Poppins'] font-medium text-[12px] leading-normal text-[#322F37]">
                Documents
              </span>
              <FileUpload onFilesChange={handleFilesChange} />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};
