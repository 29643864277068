import React, { useState, useEffect, useRef } from "react";
import RichTextEditor, { EditorValue } from "react-rte";

interface MyStatefulEditorProps {
  content?: string;
  onChange?: (value: string) => void;
}

const MyStatefulEditor: React.FC<MyStatefulEditorProps> = ({
  content,
  onChange,
}) => {
  const [value, setValue] = useState<EditorValue>(() => {
    return content
      ? RichTextEditor.createValueFromString(content, "html")
      : RichTextEditor.createEmptyValue();
  });

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (content) {
      const newValue = RichTextEditor.createValueFromString(content, "html");
      if (value.toString("html") !== newValue.toString("html")) {
        setValue(newValue);
      }
    }
  }, [content]);

  const handleChange = (newValue: EditorValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue.toString("html"));
    }
  };

  return (
    <RichTextEditor
      value={value}
      onChange={handleChange}
      className="h-[300px]"
      editorClassName="h-full"
    />
  );
};

export default MyStatefulEditor;
