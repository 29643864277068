import React from "react";
import Modal from "react-modal";
import { SaveAlt } from "@mui/icons-material";

interface ModalViewerProps {
  isOpen: boolean;
  onRequestClose: () => void;
  fileUrl: string;
}

const downloadFile = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const ModalViewer: React.FC<ModalViewerProps> = ({
  isOpen,
  onRequestClose,
  fileUrl,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "90%",
      maxHeight: "90%",
      overflowY: "auto",
      padding: "20px",
      borderRadius: "8px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="File Viewer"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "90vw",
          height: "90vh",
          border: "0px",
          padding: "20px",
          borderRadius: "8px",
          background: "transparent",
          overflow: "initial",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 100,
        },
      }}
    >
      <div className="flex flex-col items-center w-full h-full ">
        <div className="flex w-full justify-end gap-2">
          <button
            onClick={() => downloadFile(fileUrl, "receipt")}
            className="mt-4  border-white border-2 bg-transparent rounded-lg font-[Poppins] text-white px-5 py-[10px] "
          >
            <SaveAlt />
          </button>
          <button
            onClick={onRequestClose}
            className="mt-4  border-white border-2 bg-transparent rounded-lg font-[Poppins] text-white px-5 py-[10px] "
          >
            Close
          </button>
        </div>
        {fileUrl.endsWith(".pdf") ? (
          <embed
            src={fileUrl}
            type="application/pdf"
            width="600"
            height="500"
          />
        ) : (
          <img
            src={fileUrl}
            alt="Receipt"
            className="object-contain w-10/12 h-[90%]"
          />
        )}
      </div>
    </Modal>
  );
};

export default ModalViewer;
