import React, { useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../redux/reducers/Auth/authSlice";
import toast from "react-hot-toast";
import { resetPassword, signup } from "../../redux/actions/signupActions";
import Header from "../common/header";
import Icon from "../../assets/tick.svg";
import Modal from "../UI/Modal";
import { LOGIN } from "../../utils/routeConstant";

// import styles from "./Signup.module.css";

const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const userData = useSelector((state: any) => state.signup.details);
  const signupData = useSelector(
    (state: any) => state.auth?.login?.login_details
  );

  let email: string = "";
  let signup_token: string = "";
  let correctDetails: boolean = true;

  if (userData) {
    email = userData.sign_up_details.email;
    signup_token = userData.signup_token;
  } else if (signupData) {
    email = signupData.email;
    signup_token = localStorage?.getItem("token") || "";
  }

  const handleChecked = () => {
    setIsChecked((prev) => !prev);
  };

  const handleLogin = async () => {
    if (!isChecked) {
      toast.dismiss();
      toast.error("Please agree on our terms and conditions");
    } else {
      if (!newPassword.trim() || !confirmPassword.trim()) {
        correctDetails = false;
        toast.dismiss();
        toast.error("Please provide password.");
      }

      if (newPassword !== confirmPassword) {
        correctDetails = false;
        toast.dismiss();
        toast.error("Passwords do not match. Please try again.");
      }

      // Dispatch login action

      if (correctDetails) {
        setIsSigningIn(true);
        try {
          const userData = await dispatch<any>(
            resetPassword(email, newPassword, confirmPassword, signup_token)
          );
          // Dispatch action to save userData in Redux
          if (userData) {
            setTimeout(() => {
              const access_token = userData.token;
              dispatch(
                authActions.setLoginDetails([
                  {
                    access_token,
                  },
                  false,
                  "",
                ])
              );
            }, 2000);
            navigate("/");
          }
        } catch (error: any) {
          toast.dismiss();
          console.log(error?.response.data.result.error);
          toast.error(error?.response?.data?.result?.error);
        } finally {
          setIsSigningIn(false);
        }
      }
    }
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="h-[100vh] Mainbg">
      {/* <Header /> */}
      <div className="flex justify-center gap-8">
        <div className="w-[29.3%] h-[15%] ml-[8.5%] mt-[13.5%] flex-col justify-start gap-5 items-start inline-flex text-white">
          <section className="flex-col justify-start items-start flex w-[27rem] h-[15rem]">
            <div className="mb-6 w-full">
              <div className="text-white text-4xl font-semibold font-['Poppins'] whitespace-nowrap h-[3rem]">
                Welcome to GO-EMS
              </div>
              <div className="text-white text-base font-normal font-['Inter'] uppercase leading-normal">
              Employee Management System
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-4 flex w-[24rem] h-36">
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Spectacular reduction of compliance costs
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Regulatory framework proof
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Unmatched easy of mind
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  One-click certification per the highest EU standards
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="w-[23.3rem] h-[24rem] bg-white flex flex-col items-center ml-[24%] mr-[15%] mt-[8.5%] mb-[13rem] rounded-xl">
          <div className="text-neutral-800 text-xl font-semibold font-['Poppins'] mt-[1.75rem] mb-[1.6rem]">
            Set up the new password
          </div>
          <div className="text-zinc-600 text-sm font-medium font-['Poppins']">
            Enter your new password
          </div>
          <form className="flex flex-col gap-2 mt-[1rem]">
            <div className="flex justify-center">
              <div className="relative">
                <input
                  className="w-[18.75rem] h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] px-2 text-neutral-800 text-xs font-medium font-['Inter'] leading-[14px]"
                  id="password"
                  placeholder="New password"
                  type={showNewPassword ? "text" : "password"}
                  name="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                {showNewPassword ? (
                  <svg
                    className="absolute top-0 right-0 mt-2 mr-3 w-4 h-4 md:w-6 md:h-6 cursor-pointer"
                    onClick={toggleNewPasswordVisibility}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="vuesax/bold/eye">
                      <path
                        id="Vector"
                        d="M21.25 9.15018C18.94 5.52017 15.56 3.43018 12 3.43018C10.22 3.43018 8.49 3.95018 6.91 4.92018C5.33 5.90018 3.91 7.33017 2.75 9.15018C1.75 10.7202 1.75 13.2702 2.75 14.8402C5.06 18.4802 8.44 20.5602 12 20.5602C13.78 20.5602 15.51 20.0402 17.09 19.0702C18.67 18.0902 20.09 16.6602 21.25 14.8402C22.25 13.2802 22.25 10.7202 21.25 9.15018ZM12 16.0402C9.76 16.0402 7.96 14.2302 7.96 12.0002C7.96 9.77018 9.76 7.96018 12 7.96018C14.24 7.96018 16.04 9.77018 16.04 12.0002C16.04 14.2302 14.24 16.0402 12 16.0402Z"
                        fill="#262626"
                      />
                      <path
                        id="Vector_2"
                        d="M12 9.14014C10.43 9.14014 9.15002 10.4201 9.15002 12.0001C9.15002 13.5701 10.43 14.8501 12 14.8501C13.57 14.8501 14.86 13.5701 14.86 12.0001C14.86 10.4301 13.57 9.14014 12 9.14014Z"
                        fill="#262626"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    className="absolute top-0 right-0 mt-2 mr-3 w-4 h-4 md:w-6 md:h-6 cursor-pointer"
                    onClick={toggleNewPasswordVisibility}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.83 9L15 12.16V12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9H11.83ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.77 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 11.21 7.2 10.47 7.53 9.8ZM2 4.27L4.28 6.55L4.73 7C3.08 8.3 1.78 10 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.81 19.08L19.73 22L21 20.73L3.27 3M12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 12.64 16.87 13.26 16.64 13.82L19.57 16.75C21.07 15.5 22.27 13.86 23 12C21.27 7.61 17 4.5 12 4.5C10.6 4.5 9.26 4.75 8 5.2L10.17 7.35C10.74 7.13 11.35 7 12 7Z"
                      fill="black"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div className="flex justify-center p-4">
              <div className="relative">
                <input
                  className="w-[18.75rem] h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] px-2 text-neutral-800 text-xs font-medium font-['Inter'] leading-[14px]"
                  id="password"
                  placeholder="Confirm password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                {showConfirmPassword ? (
                  <svg
                    className="absolute top-0 right-0 mt-2 mr-3 w-4 h-4 md:w-6 md:h-6 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="vuesax/bold/eye">
                      <path
                        id="Vector"
                        d="M21.25 9.15018C18.94 5.52017 15.56 3.43018 12 3.43018C10.22 3.43018 8.49 3.95018 6.91 4.92018C5.33 5.90018 3.91 7.33017 2.75 9.15018C1.75 10.7202 1.75 13.2702 2.75 14.8402C5.06 18.4802 8.44 20.5602 12 20.5602C13.78 20.5602 15.51 20.0402 17.09 19.0702C18.67 18.0902 20.09 16.6602 21.25 14.8402C22.25 13.2802 22.25 10.7202 21.25 9.15018ZM12 16.0402C9.76 16.0402 7.96 14.2302 7.96 12.0002C7.96 9.77018 9.76 7.96018 12 7.96018C14.24 7.96018 16.04 9.77018 16.04 12.0002C16.04 14.2302 14.24 16.0402 12 16.0402Z"
                        fill="#262626"
                      />
                      <path
                        id="Vector_2"
                        d="M12 9.14014C10.43 9.14014 9.15002 10.4201 9.15002 12.0001C9.15002 13.5701 10.43 14.8501 12 14.8501C13.57 14.8501 14.86 13.5701 14.86 12.0001C14.86 10.4301 13.57 9.14014 12 9.14014Z"
                        fill="#262626"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    className="absolute top-0 right-0 mt-2 mr-3 w-4 h-4 md:w-6 md:h-6 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.83 9L15 12.16V12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9H11.83ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.77 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 11.21 7.2 10.47 7.53 9.8ZM2 4.27L4.28 6.55L4.73 7C3.08 8.3 1.78 10 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.81 19.08L19.73 22L21 20.73L3.27 3M12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 12.64 16.87 13.26 16.64 13.82L19.57 16.75C21.07 15.5 22.27 13.86 23 12C21.27 7.61 17 4.5 12 4.5C10.6 4.5 9.26 4.75 8 5.2L10.17 7.35C10.74 7.13 11.35 7 12 7Z"
                      fill="black"
                    />
                  </svg>
                )}
                <PasswordStrengthBar password={confirmPassword} />
              </div>
            </div>
            <div className="flex flex-row gap-2 ml-[1.3rem] mt-[-14px] items-center">
              <div className="w-6 h-6 mt-2.5 rounded-lg">
                <input
                  type="checkbox"
                  className="w-4 h-4 border-2 rounded-lg form-checkbox form-checkbox checked:bg-blue-400"
                  checked={isChecked}
                  onChange={handleChecked}
                ></input>
              </div>

              <div className="flex w-[16.6rem] h-[2.5rem] ml-[-5px] items-center">
                <div className="text-neutral-800 text-[10px] font-medium font-['Inter'] items-center mt-[8px]">
                  By clicking below, you agree to the <span className="underline">GO-EMS Terms of Services </span>
                  and <span className="underline"> Privacy Policy</span>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <button
                className="text-white w-[19rem] h-10 buttoncss rounded-[8px] text-sm font-semibold font-['Poppins']"
                type="button"
                onClick={handleLogin}
                // disabled={isSigningIn}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
