import { useSelector } from "react-redux";
import { api_key_work as api_key } from "../../../utils/helperData";
import { FETCH_SIDEBAR } from "../api";

export const Fetch_Sidebar_Api = () => {
  const access_token = localStorage.getItem("access_token");

  return fetch(`${FETCH_SIDEBAR}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
