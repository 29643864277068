import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface RoleManagementState {
  count: number;
  RoleManagementData: any[];
  currentPage: number;
  itemsPerPage: number;

}

const initialState: RoleManagementState = {
  count: 0,
  RoleManagementData: [],
  currentPage: 1,
  itemsPerPage: 10,
};

const RoleManagementSlice = createSlice({
  name: "RoleManagement",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setRoleManagementData(state, action: PayloadAction<any>) {
      state.RoleManagementData = action.payload.rows;
      state.count = action.payload.count;
      console.log(action.payload.rows);
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload;
    },
    setItemsPerPage(state, action: PayloadAction<any>) {
      state.itemsPerPage = action.payload;
    },
  },
});

export const RoleManagementActions = RoleManagementSlice.actions;
export default RoleManagementSlice;
