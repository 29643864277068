import {
  api_key_work as api_key,
  session_query,
} from "../../../utils/helperData";
import axios from "axios";
import {
  ASSET_APPROVAL,
  ASSET_CATEGORY,
  ASSET_MANAGEMENT,
  ASSET_REQUEST,
  PROJECT_LIST,
} from "../api";

export const Fetch_Asset_Catergory = (
  customer_id: number,
  numOfItems: number,
  page: number,
  searchTerm: string
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  return axios.get(`${ASSET_CATEGORY}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Add_Asset_Category = async (
  category: string,
  quantity: number
) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    category: category,
    quantity: quantity,
  };

  try {
    const response = await axios.post(ASSET_CATEGORY, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Delete_Asset_Category = async (id: number) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axios.delete(`${ASSET_CATEGORY}/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const View_Asset_Category = async (id: number) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(`${ASSET_CATEGORY}/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Update_Asset_Category = async (
  id: number,
  category: string,
  quantity: number
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    category: category,
    quantity: quantity,
  };

  try {
    const response = await axios.patch(`${ASSET_CATEGORY}/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Add_Asset = async (data: any) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axios.post(ASSET_MANAGEMENT, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Asset_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  searchTerm: string
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  return axios.get(`${ASSET_MANAGEMENT}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const View_Asset = async (id: number) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(`${ASSET_MANAGEMENT}/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Asset_Request_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  searchTerm: string
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  return axios.get(`${ASSET_REQUEST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const View_Asset_Request = async (id: number) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(`${ASSET_REQUEST}/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Project_List = (customer_id: number) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;

  return axios.get(`${PROJECT_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const approveRejectApi = async (id: number, status: string) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    status: status,
  };

  try {
    const response = await axios.put(`${ASSET_APPROVAL}/${id}`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Update_Asset = async (
  id: number,
  data: any
) => {
  const access_token = localStorage.getItem("access_token");

  try {
    const response = await axios.patch(`${ASSET_MANAGEMENT}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
