import React, { useEffect, useState } from "react";
import right from "../../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EmployeeUnit } from "../../../redux/actions/Employee/Employee";
import { useSelector } from "react-redux";
import { api_key_work as api_key } from "../../../utils/helperData";
import axios from "axios";
import profile from "../../../assets/Ellipse 580.png";

interface LeaveType {
  LeaveType: {
    leave_type_name: string;
  };
  allotted_leaves: string;
  used_leave: string;
}

interface LeaveBalanceDetails {
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string;
  employee_id: string;
}

const ViewLeaveBalance = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [leaves, setLeaves] = useState<LeaveType[]>([]);
  const [leaveBalanceDetails, setLeaveBalanceDetails] =
    useState<LeaveBalanceDetails>({
      firstName: "",
      middleName: "",
      lastName: "",
      employee_id: "",
      profile_image: "",
    });

  const dispatch = useDispatch();

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        await dispatch<any>(EmployeeUnit(customer_id, navigate));
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    // Fetch the leave data from the API
    const fetchLeaveData = async () => {
      const access_token = localStorage.getItem("access_token");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API}/api/v1/leave-balance-details?user_id=${id}`,
          {
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );

        setLeaveBalanceDetails({
          firstName: response.data.result?.firstName,
          middleName: response.data.result?.middleName,
          lastName: response.data.result?.lastName,
          employee_id: response.data.result?.employee_id,
          profile_image: response.data.result?.profile_image,
        });
        setLeaves(response.data.result.UserLeaves);
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };

    fetchLeaveData();
  }, []);


  return (
    <>
      <div className="bg-[#F9F9F9] py-6 px-4">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/leave-balance")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Employee Leave Balance Details
            </span>
          </div>
        </div>

        <div className="p-6 bg-white rounded-lg mt-6 shadow-custom">
          <h2 className="text-lg font-semibold mb-4">Leave Balance Details</h2>
          <div className="viewBackground p-4 rounded-md">
            <div className="flex flex-col h-[72px] items-start justify-center py-5 gap-2.5">
              <span className="h-5 font-['Inter'] font-medium text-sm text-[#49454F]">
                Employee ID
              </span>
              <div className="flex flex-row gap-3.5">
                <div className="h-11 w-11">
                  <img
                    src={leaveBalanceDetails.profile_image || profile}
                    alt=""
                    className="h-full w-full rounded-full"
                  />
                </div>
                <div className="flex flex-col">
                  <span className="font-['Poppins'] font-medium text-sm text-[#1D1A22]">
                    {leaveBalanceDetails?.firstName}{" "}
                    {leaveBalanceDetails?.middleName}{" "}
                    {leaveBalanceDetails?.lastName}
                  </span>
                  <span className="font-['Poppins'] font-medium text-sm text-[#605D66]">
                    {leaveBalanceDetails?.employee_id}
                  </span>
                </div>
              </div>
            </div>

            <div className="overflow-x-auto mt-4">
              <table className="w-full min-w-max text-left table-auto border-[0.6px] bg-white border-[#E0E3E7]">
                <thead className="bg-[#E1E1E1]">
                  <tr className="h-11">
                    <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                      Leave Type
                    </th>
                    <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                      Total Leave
                    </th>
                    <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                      Used Leave
                    </th>
                    <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                      Remaining Leave
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {leaves.map((items) => (
                    <tr className="h-10 border-[0.6px] border-solid border-[#E0E3E7] cursor-pointer hover:bg-gray-100">
                      <td className="text-[#3F4254] text-center font-[500] text-[12px] pl-6 pr-2.5 py-1">
                        {items.LeaveType.leave_type_name}
                      </td>
                      <td className="text-[#3F4254] text-center font-[500] text-[12px] pl-6 pr-2.5 py-1">
                        {items.allotted_leaves}
                      </td>
                      <td className="text-[#3F4254] text-center font-[500] text-[12px] pl-6 pr-2.5 py-1">
                        {items.used_leave}
                      </td>
                      <td className="text-[#3F4254] text-center font-[500] text-[12px] pl-6 pr-2.5 py-1">
                        {Number(items.allotted_leaves) -
                          Number(items.used_leave)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewLeaveBalance;
