import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import check from "../../image/checkmark-circle-02.svg";

interface SuccessModalProps {
  label: string;
  isOpen: boolean;
  onclose: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const SuccessModal: React.FC<SuccessModalProps> = ({
  label,
  isOpen,
  onclose,
}) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onclose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <img style={{ margin: "auto" }} src={check} alt="" />
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2, fontWeight: 600, fontFamily: "Poppins", textAlign: "center" }}>
              {label}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default SuccessModal;
