import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface AboutUsData {
    id: number;
    client_name: string;
    version: string;
    about: string;
    warning: string;
    terms_and_conditions: string[];
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
}

interface AboutUsState {
    data: AboutUsData | null,
}

const initialState: AboutUsState = {
    data: null,
};

const AboutusSlice = createSlice({
    name: "aboutus",
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        resetAboutUsData(state) {
            state.data = null;
        },

        setAboutUsData(state, action: PayloadAction<AboutUsData>) {
            state.data = action.payload;
        },
    },
});

export const AboutUsActions = AboutusSlice.actions;
export default AboutusSlice;
