import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface AttendenceData {
  count: number;
  AttendenceData: any[];
  AttendenceEditData: any[];
  currentPage: number;
  itemsPerPage: number;
  startDate: Date | null;
  endDate: Date | null;
}

const initialState: AttendenceData = {
  count: 0,
  AttendenceData: [],
  AttendenceEditData:[],
  currentPage: 1,
  itemsPerPage: 10,
  startDate: null,
  endDate: null,
};

const AttendenceListSlice = createSlice({
  name: "EmployeeData",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setAttendenceData(state, action: PayloadAction<any>) {
      state.AttendenceData = action.payload;
      state.count = action.payload.count;
      console.log(action.payload, "message attendence ------>>>>");
    },
    setEditAttendenceData(state, action: PayloadAction<any>){
      state.AttendenceEditData = action.payload;
      state.count = action.payload.count;
      console.log(action.payload, "message edit attendence ------>>>>");
    },
    setStartDate(state, action: PayloadAction<Date | null>) {
      state.startDate = action.payload;
    },
    setEndDate(state, action: PayloadAction<Date | null>) {
      state.endDate = action.payload;
    },
  },
});

export const AttendanceActionData = AttendenceListSlice.actions;
export default AttendenceListSlice;
