import { Dispatch } from "redux";
import axios from "axios";
import { api_key_work as api_key , base_api} from "../../utils/helperData";

export const loginUser =
  (email: string, password: string) => async (dispatch: Dispatch) => {
    // Make API call to authenticate user
    const response = await axios.post(
      base_api + "/api/v1/login",
      { email, password, device_type: "WEB" },
      {
        headers: {
          "x-api-key": api_key,
        },
      }
    );
    if (response.status === 200) {
      const userData = response?.data?.result || null;
      return userData; // Return the user data on successful authentication
    }
    return response;
  };


export const cannotLogin = (email: string) => async (dispatch: Dispatch) => {
  const response = await axios.post(
    base_api + "/api/v1/forgot-password",
    { email, forgot_type: "WEB" },
    {
      headers: {
        "x-api-key": api_key,
      },
    }
  );
  if (response.status === 200) {
    const responseData = response?.data || null;
    return responseData; // Return the user data on successful authentication
  }
  return response;
};

export const recovery =
  (email: string, otp: string) => async (dispatch: Dispatch) => {
    const response = await axios.post(
      base_api + "/api/v1/verify-otp",
      { email, otp },
      {
        headers: {
          "x-api-key": api_key,
        },
      }
    );
    if (response.status === 200) {
      const userData = response?.data || null;
      console.log(userData);
      if(userData){
        localStorage.setItem("token",userData?.result.token);
      }
      return userData; // Return the user data on successful authentication
    }
    return response;
  };
