import { api_key_work as api_key } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_ANNOUNCEMENT_LIST, FETCH_BIRTHDAY_ANNIVERSARY_LIST, FETCH_DASHBOARD_WFH_LIST, FETCH_LEAVE_DASHBORAD_LIST, FETCH_WORK_FROM_OFFICE_LIST } from "../api";

export const Fetch_WFH_Dashboard_List = (
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  return axios.get(`${FETCH_DASHBOARD_WFH_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_WFH_Dashboard_List_All = (
  numOfItems: number,
  page: number,
  searchTerm: string,
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  return axios.get(`${FETCH_DASHBOARD_WFH_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Leave_Dashboard_List = (
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  return axios.get(`${FETCH_LEAVE_DASHBORAD_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Leave_Dashboard_List_All = (
  numOfItems: number,
  page: number,
  searchTerm: string,
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  return axios.get(`${FETCH_LEAVE_DASHBORAD_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Birthday_Anniversary_Lst = (
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;
  
  return axios.get(`${FETCH_BIRTHDAY_ANNIVERSARY_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Announcement_Lst = (
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  return axios.get(`${FETCH_ANNOUNCEMENT_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Work_From_Office = (
  numOfItems: number,
  page: number,
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  return axios.get(`${FETCH_WORK_FROM_OFFICE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Work_From_Office_All = (
  numOfItems: number,
  page: number,
  searchTerm: string,
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  return axios.get(`${FETCH_WORK_FROM_OFFICE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};