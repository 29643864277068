import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface ExpenseType {
  count: number;
  ExpenseType: any[];
  ExpenseTypeData: any[];
  currentPage: number;
  itemsPerPage: number;

}

const initialState: ExpenseType = {
  count: 0,
  ExpenseType: [],
  ExpenseTypeData: [],
  currentPage: 1,
  itemsPerPage: 10,
};

const ExpenseTypeSlice = createSlice({
  name: "ExpenseType",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setExpenseTypeData(state, action: PayloadAction<any>) {
      state.ExpenseType = action.payload;
      state.count = action.payload.count;
      console.log(action.payload,"message ------>>>>");
    },
    setExpenseAllTypeData(state, action: PayloadAction<any>) {
      state.ExpenseTypeData = action.payload;
      state.count = action.payload.count;
      console.log(action.payload,"message leave-type ------>>>>");
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload;
    },
    setItemsPerPage(state, action: PayloadAction<any>) {
      state.itemsPerPage = action.payload;
    },
  },
});

export const ExpenseActions = ExpenseTypeSlice.actions;
export default ExpenseTypeSlice;
