import {
  REPORTING_MANAGER,
  SELECT_BUSINESS_UNIT,
  SELECT_DEPARTMENT_UNIT,
  SELECT_DESIGNATION,
  SELECT_EMPLOYEE_LIST,
  SELECT_ROLE,
  TEAM_MANAGER,
} from "../api";
import {
  api_key_work as api_key,
  base_api,
  session_query,
} from "../../../utils/helperData";
import axios from "axios";
import { Dayjs } from "dayjs";

export const Select_Unit = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SELECT_BUSINESS_UNIT}?customer_id=${customerId}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Dept_Unit = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SELECT_DEPARTMENT_UNIT}/${customerId}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Role_Unit = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SELECT_ROLE}?customer_id=${customerId}&size=100&page=1`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Reporting_Manager = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${TEAM_MANAGER}?department_id=${customerId}&size=200&page=1`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Designation_Manager = (
  customerId: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(
    `${SELECT_DESIGNATION}?customer_id=${customerId}&size=10&page=${page}`,
    {
      method: "GET",
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Select_Team_Manager = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(
    `${REPORTING_MANAGER}?customer_id=${customerId}&size=200&page=1`,
    {
      method: "GET",
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Employee_Management_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number,
  searchTerm: string
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  return axios.get(`${SELECT_EMPLOYEE_LIST}${query}&${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_Management_List_All = (
  customer_id: number,
  numOfItems: number,
  page: number,
  searchTerm: string
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  return axios.get(`${SELECT_EMPLOYEE_LIST}${query}&${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_Management_List1 = (customer_id: number) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;

  return axios.get(`${SELECT_EMPLOYEE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  searchQuery: string
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  if (searchQuery !== "") {
    query += `&search=${searchQuery}`;
  }

  return axios.get(`${SELECT_EMPLOYEE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createEmployee = async (
  employee_code: string,
  employee_id: string,
  email: string,
  phone: string,
  prefix: string,
  firstName: string,
  middleName: string,
  lastName: string,
  country_code: string,
  group_id: Number,
  customerId: number,
  role_id: Number,
  department_id: Number,
  designation_id: Number,
  team_manager: Number | null,
  reporting_manager: Number,
  status: string,
  mode_of_employment: string,
  experience: Number,
  date_of_birth: string | null | Dayjs,
  date_of_joining: string | null | Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    // employee_code: employee_code,
    // employee_id: employee_id,
    email: email,
    // phone: phone,
    // prefix: prefix,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    // country_code: country_code,
    group_id: group_id,
    customer_id: customerId,
    role_id: role_id,
    department_id: department_id,
    designation_id: designation_id,
    // team_manager: team_manager,
    reporting_manager: reporting_manager,
    status: status,
    mode_of_employment: mode_of_employment,
    experience: experience,
    // date_of_birth: date_of_birth,
    date_of_joining: date_of_joining,
  };
  if (employee_code !== "") data.employee_code = employee_code;
  if (employee_id !== "") data.employee_id = employee_id;
  if (prefix !== "") data.prefix = prefix;
  if (phone !== "") data.phone = phone;
  if (country_code !== "") data.country_code = country_code;
  if (date_of_birth && date_of_birth !== "Invalid Date")
    data.date_of_birth = date_of_birth;
  if (team_manager) data.team_manager = team_manager;

  try {
    const response = await axios.post(`${base_api}/api/v1/user`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEditEmployee = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(`${base_api}/api/v1/user/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateEmployee = async (
  id: string,
  employee_code: string,
  employee_id: string,
  email: string,
  phone: string,
  prefix: string,
  firstName: string,
  middleName: string,
  lastName: string,
  country_code: string,
  group_id: Number,
  customerId: number,
  role_id: Number,
  department_id: Number,
  designation_id: Number,
  team_manager: Number | null,
  reporting_manager: Number,
  status: string,
  mode_of_employment: string,
  experience: Number,
  date_of_birth: string | null | Dayjs,
  date_of_joining: string | null | Dayjs,
  date_of_leaving: string | null | Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  const body: any = {
    // employee_code: employee_code,
    // employee_id: employee_id,
    email: email,
    phone: phone,
    prefix: prefix,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    country_code: country_code,
    group_id: group_id,
    customer_id: customerId,
    role_id: role_id,
    department_id: department_id,
    designation_id: designation_id,
    reporting_manager: reporting_manager,
    team_manager: team_manager || null,
    status: status,
    mode_of_employment: mode_of_employment,
    experience: experience,
    // date_of_birth: date_of_birth,
    date_of_joining: date_of_joining,
  };

  if (date_of_leaving && date_of_leaving !== "Invalid date") body.date_of_leaving = date_of_leaving;
  if (date_of_birth && date_of_birth !== "Invalid date") body.date_of_birth = date_of_birth;
  if (employee_code && employee_code !== "") body.employee_code = employee_code;
  if (employee_id && employee_id !== "") body.employee_id = employee_id;
  // if(team_manager !== null) body.team_manager = team_manager;
  // if (team_manager) body.team_manager = team_manager;
  // if (!team_manager) body.team_manager = reporting_manager;

  // if (team_manager || team_manager!==null || team_manager !== "") {
  //   body.team_manager = team_manager;
  // } else {
  //   body.team_manager = null;
  // }

  try {
    const response = await axios.put(`${base_api}/api/v1/user/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
