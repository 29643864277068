import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import EditAppraisalDetails from "./EditAppraisalDetails";
import EditLineManagers from "./EditLineManagers";
import EditParameters from "./EditParameters";

function EditAppraisal() {
  const history = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState<string>("1");
  const id = location.state?.id;

  useEffect(() => {
    if (location.pathname === "/edit-initialization") {
      setValue("1");
    } else if (/^\/edit-line-managers\/[^/]+$/.test(location.pathname)) {
      setValue("2");
    } else if (/^\/edit-parameters\/[^/]+$/.test(location.pathname)) {
      setValue("3");
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "1") {
      history("/edit-initialization");
    } else if (newValue === "2") {
      history(`/edit-line-managers`);
    } else if (newValue === "3") {
      history(`/edit-parameters`);
    }
  };

  return (
    <>
      <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
        <div className="">
          <Box>
            <TabContext value={value}>
              <div className="flex justify-between">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      "& .MuiTab-root": {
                        color: "#89888E",
                        fontFamily: "Poppins",
                      },
                      "& .Mui-selected": {
                        color: "#000 !important",
                        fontFamily: "Poppins",
                      },
                    }}
                  >
                    <Tab
                      className="text-[#89888E]"
                      label="Appraisal Details"
                      value="1"
                    />
                    <Tab label="Line Manager Details" value="2" />
                    <Tab label="Appraisal Parameters" value="3" />
                  </TabList>
                </Box>
              </div>
              <TabPanel className="tabpanel" value="1">
                <EditAppraisalDetails />
              </TabPanel>
              <TabPanel className="tabpanel" value="2">
                <EditLineManagers />
              </TabPanel>
              <TabPanel className="tabpanel" value="3">
                <EditParameters />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
}

export default EditAppraisal;
