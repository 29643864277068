import React, { ChangeEvent } from "react";
import { Department_Unit } from "../../redux/actions/Employee/Employee";
import search from "../../assets/Search.svg";
import { DebouncedFunc } from "lodash";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface ModalProps {
  onChange:
    | DebouncedFunc<(event: ChangeEvent<HTMLInputElement>) => void>
    | ((event: ChangeEvent<HTMLInputElement>) => void);
  label: string;
  value?: string;
}

const Search: React.FC<ModalProps> = ({ onChange, label, value }) => {
  return (
    <div className="h-full w-[226px] border-[1px] border-[#CDCDCD] rounded-md py-3 pl-3 pr-2.5 flex justify-between items-center bg-white">
      <input
        type="text"
        value={value}
        placeholder={label}
        className="font-[Poppins] font-medium text-xs outline-none w-[174px]"
        onChange={onChange}
      />
      <img
        src={search}
        alt="search sign"
        className="h-[19px] w-[19px] text-[#79747E]"
      />
    </div>
  );
};

export default Search;
