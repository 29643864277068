import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface RopaAuditLog {
  auditLogData: [];
  pagination: {
    count: number;
    page: number;
    itemsPerPage: number;
  };
}

interface RopaActivity {
  activityData: [];
  pagination: {
    count: number;
    page: number;
    itemsPerPage: number;
  };
}

interface RopaRegisterState {
  ropaRegisterData: any[];
  collaboratorsList: any[];
  ropaReviewData: [];
  selected_entity_id: number;
  policy_listing: any[];
  ropaActivity: RopaActivity;
  ropaAuditLog: RopaAuditLog;
  validationCategory: any[];
  isValid: { [key: string]: any };
}

const initialState: RopaRegisterState = {
  ropaRegisterData: [],
  collaboratorsList: [],
  ropaReviewData: [],
  selected_entity_id: 0,
  policy_listing: [],
  ropaActivity: {
    activityData: [],
    pagination: {
      count: 0,
      page: 1,
      itemsPerPage: 10,
    },
  },
  ropaAuditLog: {
    auditLogData: [],
    pagination: {
      count: 0,
      page: 1,
      itemsPerPage: 10,
    },
  },
  validationCategory: [],
  isValid: {}
};

const ropaRegisterSlice = createSlice({
  name: "ropaRegister",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setRopaRegisterData(state, action: PayloadAction<any>) {
      state.ropaRegisterData = action.payload;
    },

    setCollaboratorsList(state, action: PayloadAction<any>) {
      state.collaboratorsList = action.payload;
    },

    setRopaReviewData(state, action: PayloadAction<any>) {
      state.ropaReviewData = action.payload;
    },
    setSelectedEntityId(state, action: PayloadAction<any>) {
      state.selected_entity_id = action.payload;
    },
    setPolicyListing(state, action: PayloadAction<any>) {
      state.policy_listing = action.payload;
    },
    setRopaAuditLogData(state, action: PayloadAction<any>) {
      state.ropaAuditLog.auditLogData = action.payload;
    },
    setRopaAuditLogPaginationCount(state, action: PayloadAction<any>) {
      state.ropaAuditLog.pagination.count = action.payload;
    },
    setRopaAuditLogPaginationPage(state, action: PayloadAction<any>) {
      state.ropaAuditLog.pagination.page = action.payload;
    },
    setRopaAuditLogPaginationItemsPerPage(state, action: PayloadAction<any>) {
      state.ropaAuditLog.pagination.itemsPerPage = action.payload;
    },
    setValidationData(state, action: PayloadAction<any>){
      state.validationCategory = action?.payload;
    }
  },
});

export const ropaRegisterActions = ropaRegisterSlice.actions;
export default ropaRegisterSlice;
