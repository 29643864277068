import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import { Fetch_Edit_Leave_List_List } from "../../../components/common/services/Leave";
import { LeaveListActions } from "../../reducers/Leave/LeaveSlice";
import { Edit_Leave_Type } from "../../../components/common/services/LeaveType";

const handleErrorResponse = (status: number, navigate: NavigateFunction) => {
  if ([401, 402, 403, 405, 500].includes(status)) {
    navigate("/server-error");
    return true;
  }
  return false;
};

// export const Department_Unit =
//   (customer_id: number, navigate: NavigateFunction) =>
//   async (dispatch: Dispatch) => {
//     console.log("customer_id", customer_id);
//     try {
//       const response = await Fetch_Edit_Leave_List_List(customer_id);
//       console.log("responsive department-----che>>>>", response);
//       if (!response.ok) {
//         if (handleErrorResponse(response.status, navigate)) return;
//         throw new Error("Could not fetch Ticket history data!");
//       }
//       const data = await response.json();
//       console.log();
//       // const setData = {name: data.result[0].name, id: data.result[0].id}
//       // dispatch(EmployeeActions.setDevelopment(setData))
//       // console.log("dataaaa", data)
//       return data;
//     } catch (error) {
//       console.error("Error fetching history data:", error);
//       throw error;
//     }
//   };

export const Leave_Unit =
  (customer_id: number, navigate: NavigateFunction, target_id: number) =>
  async (dispatch: Dispatch) => {
    console.log("customer_id", customer_id);
    try {
      const response = await Fetch_Edit_Leave_List_List(customer_id);
      console.log("responsive department-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log("Fetched data:", data);

      // Apply the find method to filter data by target_id
      const foundItem = data.result.rows.find(
        (item: { id: number }) => item.id === target_id
      );
      if (!foundItem) {
        throw new Error(`Item with id ${target_id} not found`);
      }

      console.log("Found item:", foundItem);

      // Dispatch the found item
      dispatch(LeaveListActions.setLeaveData(foundItem));
      return foundItem;
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };

export const Leave_Type_Unit =
  (navigate: NavigateFunction, target_id: number) =>
  async (dispatch: Dispatch) => {
    //console.log("customer_id", customer_id);
    try {
      const response = await Edit_Leave_Type();
      console.log("responsive department-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log("Fetched data:", data);

      // Apply the find method to filter data by target_id
      const foundItem = data.result.rows.find(
        (item: { id: number }) => item.id === target_id
      );
      if (!foundItem) {
        throw new Error(`Item with id ${target_id} not found`);
      }

      console.log("Found item:", foundItem);

      // Dispatch the found item
      dispatch(LeaveListActions.setLeaveTypeData(foundItem));
      return foundItem;
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };
