import React, { useEffect, useState } from "react";
import right from "../../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import check from "../../../image/checkmark-circle-02.svg";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Leave_Type_Unit } from "../../../redux/actions/Leave/Leave";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import { updateLeaveType } from "../../common/services/LeaveType";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormValues {
  leaveType: string;
  no_of_leaves: string;
}

const EditWFHType = () => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const leave = useSelector((state: any) => state.leave.LeaveTypeData);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    validate();
    submitChanges();
  };
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    navigate("/leave-type");
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/leave-type");
  };

  const { api } = useParams();
  const location = useLocation();
  const id = location.state?.id;

  const [formValues, setFormValues] = useState<FormValues>({
    leaveType: "",
    no_of_leaves: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};
    if (formValues.leaveType === "")
      tempErrors.leaveType = "Leave Type is required";
    if (formValues.no_of_leaves === "")
      tempErrors.no_of_leaves = "Number of Leaves is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  useEffect(() => {
    console.log("callll----->>>>");
    dispatch<any>(Leave_Type_Unit(navigate, id));
  }, []);

  useEffect(() => {
    if (leave) {
      setFormValues({
        leaveType: leave?.leave_type_name,
        no_of_leaves: leave?.allotted_leaves,
      });
    }
  }, [leave]);

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    // console.log("Request body:", body); // Print the body

    setLoading(true);
    try {
      const data = await updateLeaveType(
        id,
        formValues.leaveType,
        formValues.no_of_leaves
      );

      if (data && data.result) {
        console.log("final data:", data);
        //toast.success(data.message)
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          navigate("/leave-type");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  return (
    <div className="min-h-[90vh] px-4 py-6 bg-[#F9F9F9]">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Leave Type Updated Successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/leave-type")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Edit Leave Type
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={handleCancel}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Update
            </div>
          </button>
        </div>
      </div>

      <div className="rounded-md overflow-hidden shadow-custom bg-white mt-10">
        <div className="container mx-auto p-4">
          <div className="text-[#262626] font-[500] text-[14px] ml-4 font-['Poppins']">
            Leave Type Details
          </div>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 mt-4 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Leave Types"
                defaultValue=" "
                name="leaveType"
                value={formValues.leaveType}
                onChange={handleFrormChange}
                error={!!errors.leaveType}
                helperText={errors.leaveType}
              />
              <TextField
                required
                id="outlined-required"
                label="No Of Leaves"
                defaultValue=" "
                name="no_of_leaves"
                value={formValues.no_of_leaves}
                onChange={handleFrormChange}
                error={!!errors.no_of_leaves}
                helperText={errors.no_of_leaves}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EditWFHType;
