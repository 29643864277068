import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Box from "@mui/material/Box";
import check from "../../image/checkmark-circle-02.svg";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import EmployeeSearch from "./EmployeeSearch";
import { createAttendence } from "../common/services/Attendence";
import toast from "react-hot-toast";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const AddAttendence = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (selectedEmployeeId) { 
      !checkIn && setDateError(true);
      if (checkIn && !checkOut) postData();
      else if (checkIn && checkOut) {
        checkIn?.isBefore(checkOut)
          ? postData()
          : toast.error("CheckIn Time cannot be after CheckOut Time");
      }
    } else {
      setError("Please select an Employee")
    }
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/attendance-management");
  };

  const [checkIn, setCheckIn] = React.useState<Dayjs | null>(null);
  const [checkOut, setCheckOut] = React.useState<Dayjs | null>(null);
  const [date, setDate] = React.useState<string>();
  const [dateError, setDateError] = useState(false);
  const [error, setError] = useState("");
  const maxDate = dayjs();

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEmployeeSelect = (employeeId: string) => {
    // setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
    setError("")
  };

  const postData = async () => {
    // setLoading(true);
    try {
      await createAttendence(
        selectedEmployeeId,
        "CHECKOUT",
        checkOut,
        checkIn,
        date
      );
      // console.log(response,"respost--->>>");

      setOpen(true);

      // dispatch(
      //   userManagementActions.resetUserRoleData({
      //     count: 0,
      //     rows: [],
      //     currentPage: 1,
      //     itemsPerPage: 10,
      //     isNext: true,
      //   })
      // );

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/attendance-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    //setLoading(false);
  };

  const handleCheckInChange = (newValue: Dayjs | null) => {
    setCheckIn(newValue);
    setDate(newValue?.format("YYYY-MM-DD"));
    setDateError(false);
  };

  const handleCheckOutChange = (newValue: Dayjs | null) => {
    setCheckOut(newValue);
  };
  console.log(selectedEmployeeId, "chhghgcfcgbfc-------->>");
  console.log(date, "chhghgcfcgbfc-------->>");

  return (
    <div className="min-h-[90vh] px-4 py-6 bg-[#F9F9F9]">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Attendence added successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/attendance-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
            Add Employee Attendance
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate("/attendance-management")}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
          </button>
        </div>
      </div>

      <div className="rounded-md mt-10  shadow-custom bg-white">
        <div className="container mx-auto p-4">
          {/* <div className="text-[#262626] font-[500] text-[14px] ml-4 font-['Poppins']">Sick Leave Details</div> */}
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <EmployeeSearch
                onEmployeeSelect={handleEmployeeSelect}
                onEmployeeSelectId={handleEmployeeSelectId}
                error={error}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Check In Time"
                  value={checkIn}
                  onChange={(e) => handleCheckInChange(e)}
                  maxDate={maxDate}
                  slotProps={{
                    textField: {
                      error: dateError,
                      helperText: dateError ? "Please select CheckIn time" : "",
                    },
                  }}
                />
              </LocalizationProvider>
              <div className="flex flex-col items-start w-full pr-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="CheckOut Time"
                    views={["year", "month", "day", "hours", "minutes"]}
                    value={checkOut}
                    onChange={handleCheckOutChange}
                    maxDate={maxDate}
                    className="w-full"
                  />
                </LocalizationProvider>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddAttendence;
