import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import logo from "../../image/MicrosoftTeams-image (3).png";
import toast from "react-hot-toast";
import { CountryCode } from "libphonenumber-js/core";
import { signUpUser, checkEmailExist } from "../../redux/actions/signupActions";
// import { authActions } from "../../redux/reducers/Auth/authSlice";
import { signupActions } from "../../redux/reducers/Signup/signupSlice";
import Header from "../common/header";
import Icon from "../../assets/tick.svg";
import {
  LOGIN,
  ORGANISATION_DETAILS,
  SET_PASSWORD,
  VERIFY_EMAIL,
} from "../../utils/routeConstant";
import { MuiTelInput } from "mui-tel-input";
import {
  UserManagementForm,
  flattenGroupData,
  labelStyle,
  validateEmail,
  validatePhoneNumber,
  inputStyle,
  menuStyle,
} from "../../utils/helperData";
import { Link } from "react-router-dom";

const Signup: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = React.useState<string>("");
  const [fullName, setFullName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [countryCode, setCountryCode] = React.useState("");
  const [countryInitial, setCountryInitial] = useState<CountryCode>();
  const [onboardingStatus, setOnboardingStatus] = useState<string>("");
  const [nationalNumber, setNationalNumber] = useState("");

  const signupData = useSelector((state: any) => state.signup.details);

  const [latitude, setLatitude] = useState<number | undefined>(undefined);
  const [longitude, setLongitude] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Check if geolocation is supported by the browser
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    // Fetch country based on latitude and longitude
    async function fetchCountry() {
      try {
        const response = await fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
        if (response.ok) {
          const data = await response.json();
          setCountryInitial(data.countryCode);
        } else {
          console.error("Failed to fetch country.");
        }
      } catch (error) {
        console.error("Error fetching country:", error);
      }
    }

    if (latitude !== undefined && longitude !== undefined) {
      fetchCountry();
    }
  }, [latitude, longitude]);

  const handlePhoneChange = (value: string, info: any) => {
    setPhone(value);
    console.log("Hi", info);
    setNationalNumber(info?.nationalNumber);
    setCountryCode(`+${info?.countryCallingCode}`);
  };

  // const handleSignup=()=>{
  //   navigate("/orgnisatioal-details")
  // }

  const handleSignup = async () => {
    // if (!isChecked) {
    //   toast.dismiss();
    //   // toast.error("Please fill complete details");
    // }

    if (isChecked) {
      if (!email.trim() || !phone.trim() || !fullName.trim()) {
        toast.dismiss();
        toast.error("Please fill complete details.");

        return;
      }
      if (!isValidEmail(email)) {
        toast.dismiss();
        toast.error("Please provide a valid email address");

        return;
      }
      setIsSignUp(true);
      if (signupData !== null) {
        const onboardingStatus = signupData.sign_up_details.onboarding_status;

        if (onboardingStatus !== "") {
          localStorage.setItem("signup_token", signupData?.signup_token);
          if (onboardingStatus === "USER_CREATED") {
            navigate(`${ORGANISATION_DETAILS}`);
            return;
          }

          if (onboardingStatus === "ORGANISATION_UPDATED") {
            navigate(`${VERIFY_EMAIL}`);
            return;
          }

          if (onboardingStatus === "EMAIL_VERIFIED") {
            navigate(`${SET_PASSWORD}`);
            return;
          }
          if (onboardingStatus === "PASSWORD_UPDATED") {
            toast.dismiss();
            toast.success("Please login to continue!");
            navigate(`${LOGIN}`);
            return;
          }
        }
      }

      const names = fullName.split(" ");
      let firstName = names.slice(0, -1).join(" ");
      let lastName: string = "";
      if (firstName) {
        lastName = names[names.length - 1];
      } else {
        firstName = names[names.length - 1];
        lastName = "";
      }

      setIsSignUp(true);
      // Dispatch login action
      try {
        toast.dismiss();
        toast.loading("Processing...");
        const userData = await dispatch<any>(
          signUpUser(email, nationalNumber, firstName, lastName, countryCode)
        );
        if (userData) {
          const signup_token = userData?.data?.result?.signup_token;
          const customer_id: string = userData?.data?.result?.customer_id;
          const onboarding_status: string =
            userData?.data?.result?.onboarding_status;
          setOnboardingStatus(onboarding_status);

          if (signup_token) {
            // setting token to local storage
            localStorage.setItem("signup_token", signup_token);
            dispatch(
              signupActions.setSignupDetails({
                signup_token: signup_token,
                sign_up_details: {
                  email,
                  phone: nationalNumber,
                  firstName,
                  lastName,
                  countryCode,
                  customer_id,
                  onboarding_status: onboardingStatus,
                },
                organisation_details: null,
              })
            );
          }
        }
        // Dispatch action to save token in Redux
        // Redirect to dashboard (you can use React Router or any other routing library)
        navigate(`${ORGANISATION_DETAILS}`);
        toast.dismiss();
      } catch (error: any) {
        toast.dismiss();
        toast.error(error.response.data.message);
      } finally {
        setIsSignUp(false);
      }
    } else if (!email.trim() || !phone.trim() || !fullName.trim()) {
      toast.dismiss();
      toast.error("Please fill complete details.");

      return;
    } else if (!isValidEmail(email)) {
      toast.dismiss();
      toast.error("Please provide a valid email address");

      return;
    } else if (!isChecked) {
      toast.dismiss();
      toast.error(
        "You must agree to our terms and conditions before continuing."
      );
    }
    // Validation: Check if all details are provided
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChecked = () => {
    setIsChecked((prev) => !prev);
  };

  const handleEmailExist = async () => {
    if (email) {
      try {
        const userData = await dispatch<any>(checkEmailExist(email));
        if (userData.signup_token) {
          setEmail(userData.email);
          setPhone(userData.phone);
          setCountryCode(userData.country_code);
          setFullName(userData.firstName + " " + userData.lastName);
          setOnboardingStatus(userData.onboarding_status);

          localStorage.setItem("signup_token", userData.signup_token);
          dispatch(
            signupActions.setSignupDetails({
              signup_token: userData.signup_token,
              sign_up_details: {
                email,
                phone,
                firstName: userData.firstName,
                lastName: userData.lastName,
                countryCode,
                customer_id: userData.customer_id,
                onboarding_status: userData.onboarding_status,
              },
              organisation_details: null,
            })
          );
        }
        // Further logic with userData
      } catch (error) {
        // Handle errors
      }
    }
  };

  return (
    <div className="h-[100vh] Mainbg">
      {/* <Header /> */}
      <div className="flex justify-center gap-8">
        <div className="w-[29.3%] h-[15%] ml-[8.5%] mt-[13.5%] flex-col justify-start gap-5 items-start inline-flex text-white">
          <section className="flex-col justify-start items-start flex w-[27rem] h-[15rem]">
            <div className="mb-6 w-full">
            <div className="flex flex-row items-center justify-start gap-[12.2px] shrink-0 [debug_commit:69da668] z-[2]">
                <img
                  className="logocss relative min-h-[61px]"
                  loading="lazy"
                  alt=""
                  src={logo}
                />
                <div className="flex flex-col items-start justify-center pt-[18px] px-0 pb-[17.9px] gap-[3.7px]">
                  {/* <div className="flex flex-row items-end justify-start">
                    <b className="relative leading-[57%] font-semibold mq800:text-[28px] mq800:leading-[16px] mq450:text-[21px] mq450:leading-[12px]">
                      GO-EMS
                    </b>
                  </div> */}
                  <div className="w-[95px] relative text-[14.6px] tracking-[0.09em] capitalize text-gainsboro hidden">
                    trust to go
                  </div>
                </div>
              </div>
              <div className="text-white text-4xl font-semibold font-['Poppins'] whitespace-nowrap h-[3rem]">
                Welcome to Go-EMS
              </div>
              <div className="text-white text-base font-normal font-['Inter'] uppercase leading-normal">
              Employee Management System
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-4 flex w-[24rem] h-36">
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Spectacular reduction of compliance costs
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Regulatory framework proof
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Unmatched easy of mind
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  One-click certification per the highest EU standards
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="w-[21.3rem] h-[31rem] bg-white flex flex-col items-center ml-[24%] mr-[15%] mt-[2.5%] mb-[7.5%] rounded-xl">
          <div className="text-neutral-800 text-[30px] font-semibold font-['Poppins'] mt-[1.75rem] mb-[1.6rem] w-[51%] h-[9%]">
            Get Started
          </div>
          <form className="flex flex-col">
            <label
              className="text-neutral-800 text-xs font-semibold font-['Poppins'] mb-[4px] ml-5 text-left"
              htmlFor="email"
            >
              Work Email
            </label>
            <div className="flex justify-center mt-1">
              <input
                className="w-[18.75rem] h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] ml-5 mr-[20px] px-2"
                id="email"
                type="email"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleEmailExist}
                required
              />
            </div>
            <div className="mt-[1rem]">
              <div>
                <label
                  className="text-neutral-800 text-xs font-semibold font-['Poppins'] mt-[0.87rem] mb-[4px] ml-5"
                  htmlFor="phone"
                >
                  Phone Number
                </label>
                <div className="flex flex-row gap-2 mt-1">
                  <MuiTelInput
                    // label="Phone"
                    id="phone"
                    name="phone"
                    // placeholder="Enter Phone Number"
                    defaultCountry={countryInitial}
                    focusOnSelectCountry
                    autoComplete="off"
                    className="w-[18.75rem] h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] ml-5 mr-[20px] px-2"
                    // required
                    // error={isValid[`phone`]}
                    // helperText={
                    //   isValid[`phone`] === 2
                    //     ? "This field is required!"
                    //     : isValid[`phone`] === 1
                    //     ? "Length must be of 8-16 characters long!"
                    //     : ""
                    // }
                    InputLabelProps={{
                      shrink: true,
                      // sx: labelStyle,
                    }}
                    InputProps={{
                      sx: {
                        ...inputStyle,
                        alignItems: "center",
                        flexDirection: "row",
                        marginLeft: "20px",
                        borderRadius: "3px",
                        height: "2.5rem",
                        // width: "300px",
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px #fff inset",
                          WebkitTextFillColor: "inherit",
                        },
                      },
                    }}
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                  {/* <PhoneInput
                    className="my-custom-class"
                    defaultCountry={countryInitial}
                    value={phone}
                    onChange={handlePhoneChange}
                    autoComplete="off"
                  /> */}
                </div>
              </div>

              <div className="mt-[1rem]">
                <label
                  className="text-neutral-800 text-xs font-semibold font-['Poppins'] mt-[0.87rem] ml-5"
                  htmlFor="full-name"
                >
                  Full Name
                </label>
                <div className="flex justify-center mt-1">
                  <input
                    className="w-[18.75rem] h-[2.5rem] border-opacity-30 rounded-sm border border-[#262626] px-2"
                    id="full-name"
                    type="text"
                    value={fullName}
                    autoComplete="off"
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2 ml-[1.3rem] mt-[6px] items-center">
              <div className="w-6 h-6 mt-2.5 rounded-lg">
                <input
                  type="checkbox"
                  className="w-4 h-4 border-2 rounded-lg form-checkbox form-checkbox checked:bg-blue-400"
                  checked={isChecked}
                  onChange={handleChecked}
                ></input>
              </div>

              <div className="flex w-[16.6rem] h-[2.5rem] ml-[-5px] items-center">
                <div className="text-neutral-800 text-[10px] font-medium font-['Inter'] items-center mt-[8px]">
                  By clicking below, you agree to the <Link className="underline" to={""}>Go-EMS Terms of Services </Link>
                  and <Link className="underline" to={""}>Privacy Policy</Link>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-4">
              <button
                className="text-white w-[18.75rem] h-[2.5rem] buttoncss rounded-[8px] text-sm font-semibold font-['Poppins']"
                type="button"
                onClick={handleSignup}
                //disabled={isSignUp}
              >
                Continue
              </button>
            </div>

            <div className="mt-[1rem] flex flex-col md:flex-row gap-[1.5rem] ml-[2.3rem]">
              <div>
                <span className="text-black text-[12px] font-[400] font-['Poppins']">
                  Already have an GO-EMS account?{" "}
                </span>
                <NavLink to="/">
                  <span className="text-blue-400 text-xs font-semibold font-['Poppins']">
                    Log in
                  </span>
                </NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
