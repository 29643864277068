import React, { useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import MyStatefulEditor from "./Editor";
import FileUpload from "../ExpenseManagement/FileUpload";
import { MenuItem } from "@mui/material";
import { Fetch_Clients_List_Data } from "../common/services/Clients";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { createProjectsApi } from "../common/services/Project";
import toast from "react-hot-toast";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

interface FormValues {
  project_name: string;
  status: string;
  currency: string;
  client: string;
  Estimate_cost: number | null;
  Actual_cost: number | null;
  start_date: string;
  end_date: string;
  estimate_hours: number | null;
  actual_hours: number | null;
  project_type: string;
}

interface Leave_Type {
  id: string;
  client_name: string;
}

const AddProjectDetails = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<Leave_Type[]>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [dateError, setDateError] = useState(false);
  const [startDate, setStartData] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [editorContent, setEditorContent] = useState<string>("");

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
  };

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const [formValues, setFormValues] = useState<FormValues>({
    project_name: "",
    status: "",
    currency: "",
    client: "",
    Estimate_cost: null,
    Actual_cost: null,
    start_date: "",
    end_date: "",
    estimate_hours: null,
    actual_hours: null,
    project_type: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (formValues.project_name === "")
      tempErrors.project_name = "Project Name is required";
    if (formValues.status === "")
      tempErrors.status = "Project Status is required";
    if (formValues.project_type === "")
      tempErrors.project_type = "Project Type is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const Currency = [
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
  ];

  const Status = [
    { value: "upcoming", label: "Up Coming" },
    { value: "ongoing", label: "On Going" },
    { value: "completed", label: "Completed" },
    { value: "onhold", label: "On Hold" },
  ];

  const fetchLeaveType = async () => {
    //  setLoading(true);
    try {
      const firstResponse = await Fetch_Clients_List_Data(customer_id, 10, 1);

      if (firstResponse.status === 200) {
        const totalCount = firstResponse?.data?.result.count || 0;
        const totalPages = Math.ceil(totalCount / 10);

        const remainingPages = [];
        for (let num = 2; num <= totalPages; num++) {
          remainingPages.push(num);
        }

        const remainingResponses = await Promise.all(
          remainingPages.map(async (num) => {
            return await Fetch_Clients_List_Data(customer_id, 10, num);
          })
        );

        const allClients = [
          ...firstResponse.data?.result.rows,
          ...remainingResponses.flatMap(
            (response) => response?.data?.result.rows || []
          ),
        ];
        setTableData(allClients);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, project_type: e.target.value });
    if (e.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, project_type: "" }));
    }
  };

  const { param } = useParams();

  const postData = async () => {
    setLoading(true);
    try {
      const response = await createProjectsApi(
        // customer_id,
        // uploadedFiles.map((files) => files?.originalName.split(".")),

        formValues.project_name,
        formValues.currency,
        formValues.client,
        Number(formValues.Estimate_cost),
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        Number(formValues.estimate_hours),
        formValues.status,
        uploadedFiles.map((files) => files.url),
        formValues.project_type,
        editorContent?.replace(/<[^>]+>/g, "")
      );
      const projectId = response.data.result.id;

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate(`/add-resources/${projectId}`, {
          state: { id: projectId },
        });
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  const handleOpen = () => {
    !startDate && setDateError(true);
    const res = validate();
    if (!dateError && res) postData();
    else toast.error("Please fill all the required fields");
  };

  return (
    <div>
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button
            onClick={() => navigate("/project-management")}
            //  onClick={() => navigate("/client")}
          >
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Add Project Details
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate("/project-management")}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center rounded-lg self-stretch borderButton h-12"
            //onClick={(event) => handleCurrentPageChang  e("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={() => handleOpen()}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Continue
            </div>
          </button>
        </div>
      </div>

      <div className="mt-10 rounded-md overflow-hidden shadow-custom bg-white">
        <div className="container mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Project Name"
                placeholder="Enter Project"
                value={formValues.project_name}
                name="project_name"
                onChange={handleFrormChange}
                error={!!errors.project_name}
                helperText={errors.project_name}
              />
              <TextField
                required
                id="outlined-required"
                label="Status"
                select
                value={formValues.status}
                onChange={handleFrormChange}
                placeholder="Status"
                name="status"
                error={!!errors.status}
                helperText={errors.status}
              >
                {Status?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-required"
                label="Currency"
                select
                // placeholder="Enter Declaration"
                value={formValues.currency}
                onChange={handleFrormChange}
                name="currency"
              >
                {Currency.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-required"
                label="Client"
                select
                value={formValues.client}
                onChange={handleFrormChange}
                onFocus={fetchLeaveType}
                placeholder="Select Client"
                name="client"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        scrollbarWidth: "thin"
                      },
                    },
                  },
                }}
              >
                {tableData?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.client_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-required"
                label="Estimate Cost"
                placeholder="Enter Estimated Cost"
                value={formValues.Estimate_cost}
                onChange={handleFrormChange}
                name="Estimate_cost"
              />
              {/* <TextField
                id="outlined-required"
                label="Actual Cost"
                placeholder="Enter Actual Cost"
                name="Actual_cost"
                value={formValues.Actual_cost}
                onChange={handleFrormChange}
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date *"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartData(newValue);
                    setDateError(false);
                  }}
                  slotProps={{
                    textField: {
                      error: dateError,
                      helperText: dateError ? "Please select a start date" : "",
                    },
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                />
              </LocalizationProvider>
              <TextField
                id="outlined-required"
                label="Estimate Hours"
                placeholder="Enter estimated Hours"
                value={formValues.estimate_hours}
                onChange={handleFrormChange}
                name="estimate_hours"
              />
              {/* <TextField
                id="outlined-required"
                label="Actual Hours"
                placeholder="Enter Actual Hours"
                name="actual_hours"
                value={formValues.actual_hours}
                onChange={handleFrormChange}
              /> */}
            </div>

            <div className="mt-4 pl-2">
              <FormControl error={!!errors.project_type}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Project Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formValues.project_type}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="Billable"
                    control={<Radio />}
                    label="Billable"
                  />
                  <FormControlLabel
                    value="Non Billable"
                    control={<Radio />}
                    label="Non Billable"
                  />
                  <FormControlLabel
                    value="Revenue Generation"
                    control={<Radio />}
                    label="Revenue Generation"
                  />
                </RadioGroup>
              </FormControl>
              <FormHelperText>
                <span className="text-[#d32f2f]">{errors.project_type}</span>
              </FormHelperText>
            </div>

            <div className="mt-6 pl-2">
              <span className="font-[Poppins] py-1">Description</span>
              <MyStatefulEditor onChange={setEditorContent} />
            </div>
            <div className="mt-8 pl-2">
              <span className="font-[Poppins] py-1">Attachements</span>
              <FileUpload onFilesChange={handleFilesChange} />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddProjectDetails;
