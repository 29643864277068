import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_LEAVE_TYPE } from "../api";

export const createLeaveType = async (
  leave_type_name: string | null,
  allotted_leaves: string | null
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    leave_type_name: leave_type_name,
    allotted_leaves: allotted_leaves,
  };

  try {
    const response = await axios.post(`${base_api}/api/v1/leave-types`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Edit_Leave_Type = () => {
  const access_token = localStorage.getItem("access_token");
  // console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${FETCH_LEAVE_TYPE}`);
  return fetch(`${FETCH_LEAVE_TYPE}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const updateLeaveType = async (
  id: number,
  leave_type_name: string,
  allotted_leaves: string
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    leave_type_name: leave_type_name,
    allotted_leaves: allotted_leaves,
  };

  try {
    const response = await axios.patch(
      `${base_api}/api/v1/leave-types/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
