import React from "react";
import SelectDropdown from "../EmployeeManagement/SelectDropDown";
import { Department_Unit } from "../../redux/actions/Employee/Employee";
import DatePicker from "react-datepicker";
import { createGlobalStyle } from "styled-components";
import date from "../../assets/date.svg";

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface ModalProps {
  isOpen: boolean;
  isDate: boolean;
  isOther: boolean;
  heading: string;
  onClose: () => void;
  onApply: () => void;
  selectnameDepartment: string;
  optionsDepartment?: Business_Unit[] | Department_Unit[] | undefined;
  onOptionSelectDepartment: (option: Business_Unit | Department_Unit) => void;
  selectnameBusiness: string;
  optionsBusiness?: Business_Unit[] | Department_Unit[] | undefined;
  onOptionSelectBusiness: (option: Business_Unit | Department_Unit) => void;
  // selectnameProject: string;
  // optionsProject: Business_Unit[] | Department_Unit[] | undefined;
  // onOptionSelectProject: (option: Business_Unit | Department_Unit) => void;

  // date range
  selected: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  isCalendarOpen: boolean;
  handleDateChange: (dates: [Date | null, Date | null]) => void;
  handleCalanderClick: () => void;
}

const Filter: React.FC<ModalProps> = ({
  isOpen,
  isDate,
  isOther,
  heading,
  onClose,
  onApply,
  selectnameDepartment,
  optionsDepartment,
  onOptionSelectDepartment,
  selectnameBusiness,
  optionsBusiness,
  onOptionSelectBusiness,
  // selectnameProject,
  // optionsProject,
  // onOptionSelectProject,
  selected,
  startDate,
  endDate,
  isCalendarOpen,
  handleDateChange,
  handleCalanderClick,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-[10px] w-[360px] py-8 px-10 flex flex-col gap-6">
        <div className="flex flex-col gap-12">
          <div className="flex flex-col gap-8">
            <span className="font-[Poppins] font-medium text-[20px] leading-4 text-[#262626]">
              {heading}
            </span>
            {isDate && (
              <div className="flex h-10 pr-2 justify-between items-center rounded-md border-[1px] border-solid border-[#CACACA]">
                <GlobalStyle />
                <DatePicker
                  selected={selected}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  className="w-full flex py-2.5 px-1 text-[12px] h-[38px] justify-between items-center flex-[1,0,0] bg-white border-white"
                  customInput={<input />}
                  isClearable
                  dateFormat="MMMM d, yyyy"
                  placeholderText="Month Day, Year - Month Day, Year"
                  open={isCalendarOpen}
                  onInputClick={handleCalanderClick}
                  // onClickOutside={() => {setIsCalendarOpen(!isCalendarOpen)}}
                  // onCalendarClose={() => {setIsCalendarOpen(!isCalendarOpen)}}
                  // onCalendarOpen={() => {setIsCalendarOpen(!isCalendarOpen)}}
                />
                {
                  <img
                    src={date}
                    alt="date icon"
                    onClick={handleCalanderClick}
                    className="flex items-center justify-between relative cursor-pointer"
                  ></img>
                }
              </div>
            )}
            {isOther && (
              <>
                <SelectDropdown
                  selectname={selectnameDepartment}
                  options={optionsDepartment}
                  onOptionSelect={onOptionSelectDepartment}
                />
                <SelectDropdown
                  selectname={selectnameBusiness}
                  options={optionsBusiness}
                  onOptionSelect={onOptionSelectBusiness}
                />
              </>
            )}
            {/* <SelectDropdown
                selectname={selectnameProject}
                options={optionsProject}
                onOptionSelect={onOptionSelectProject}
              /> */}
          </div>
        </div>
        <div className="flex justify-between items center">
          <button className="text borderButton w-[130px]" onClick={onClose}>
            Cancel
          </button>
          <button
            className="buttoncss rounded-lg text-white w-[130px]"
            onClick={onApply}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
