import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { revertAll } from '../../ResetRedux/RevertAll';

interface UserProfileState {
    id: number | null;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    isEditing: boolean,
    profile_image:string
}

const initialState: UserProfileState = {
    id: null,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    isEditing: false,
    profile_image:""
};

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {

        resetUserRoleData(state, action: PayloadAction<UserProfileState>) {
            return initialState;
        },

        setUserProfileData(state, action: PayloadAction<Partial<UserProfileState>>) {
            return { ...state, ...action.payload };
        },

        setEditingState(state, action: PayloadAction<boolean>) {
            state.isEditing = action.payload;
        },

        updateUserProfile(state, action: PayloadAction<{profile_image?:string}>) {
            return { ...state, ...action.payload };
        },
    }, 
});


export const userProfileActions = userProfileSlice.actions;
export default userProfileSlice;