import React, { useEffect, useState, ChangeEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import veiw from "../../assets/Crud_Icons/view.svg";
import delete001 from "../../assets/Crud_Icons/delete.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import check from "../../image/checkmark-circle-02.svg";
import close from "../../assets/close.svg";
import TablePaginationDemo from "../common/Pagenation";
import { TextField, Box, Typography, Modal } from "@mui/material";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import Search from "../SearchBar/Search";
import {
  Fetch_Policy_Category_List,
  fetchEditPolicyCategory,
  updatePolicyCategory,
} from "../common/services/companyManagement";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const style1 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "791px",
  height: "465px",
  bgcolor: "#fff",
  boxShadow: 24,
  borderRadius: "10px",
  zIndex: "1001",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const style2 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "548px",
  height: "352.6px",
  bgcolor: "#ffffff",
  boxShadow: 24,
  borderRadius: "8px",
  padding: "30px",
  zIndex: "1001",
};

const overlayStyle = {
  position: "fixed" as "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1000,
};

interface RowData {
  name: string;
  description: string;
  id: string;
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancelDelete: () => void;
}> = ({ onDelete, onCancelDelete }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Policy Category
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this!
        </h4>
        <div className="flex space-x-4 justify-center">
          <button
            onClick={onDelete}
            className="buttoncss text-white px-12 py-2 rounded mr-2"
          >
            Yes Sure
          </button>
          <button
            onClick={onCancelDelete}
            className="borderButton text-black rounded px-12 py-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const ViewPolicyModal: React.FC<{
  onQuit: () => void;
  policyCategoryDetails: { name: string; description: string; id: string };
}> = ({ onQuit, policyCategoryDetails }) => {
  return (
    <>
      <div style={overlayStyle} onClick={onQuit}></div>
      <Box sx={style2}>
        <div className="flex flex-col gap-5 w-full">
          <div className="w-full h-4 flex justify-end">
            <img
              src={close}
              alt="close"
              onClick={onQuit}
              className="cursor-pointer text-[#605D66]"
            />
          </div>
          <div className="w-full h-6 px-[10px]">
            <span className="font-['Inter'] font-semibold text-[20px] leading-[24.2px] text-[#000000]">
              Policy Category Details
            </span>
          </div>
          <div
            className="h-[213px] border rounded-md px-2.5 py-5 bg-gradient-to-r"
            style={{
              background:
                "linear-gradient(90deg, rgba(65, 101, 196, 0.05) 0%, rgba(142, 31, 210, 0.05) 100%)",
            }}
          >
            <div className="flex flex-col gap-[15px]">
              <div className="px-2.5 flex flex-col gap-[7px] h-[45px]">
                <span className="h-[19px] font-['Inter'] font-medium text-[16px] leading-[19.36px] text-[#99A1B7]">
                  Policy Category
                </span>
                <span className="h-[19px] font-['Inter'] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                  {policyCategoryDetails?.name}
                </span>
              </div>
              <hr />
              <div className="pl-2.5 pr-1.5">
                <div className="flex flex-col gap-[7px] h-[98px]">
                  <span className="h-[19px] font-['Inter'] font-medium text-[16px] leading-[19.36px] text-[#99A1B7]">
                    Description
                  </span>
                  <span className="h-[19px] font-['Inter'] font-medium text-base text-[#322F37]">
                    {policyCategoryDetails?.description}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

const EditPolicyCategoryModal: React.FC<{
  onUpdate: (policyCategory: string, description: string) => void;
  onCancel: () => void;
  policyCategoryDetails: { name: string; description: string; id: string };
}> = ({ onUpdate, onCancel, policyCategoryDetails }) => {
  const [policyCategory, setPolicyCategory] = useState("");
  const [description, setDescription] = useState("");



  useEffect(() => {
    if (policyCategoryDetails) {
      setPolicyCategory(policyCategoryDetails.name);
      setDescription(policyCategoryDetails.description);
    }
  }, [policyCategoryDetails]);

  const handleSave = () => {
    onUpdate(policyCategory, description);
  };
  return (
    <>
      <div style={overlayStyle} onClick={onCancel}></div>
      <Box sx={style1}>
        <div className="px-[35px] py-[50px]">
          <span className="font-['Poppins'] font-medium text-[20px] leading-4 text-[#262626]">
            Edit Policy Category Details
          </span>
          <div className="pt-10 w-full h-[53px] flex flex-col gap-[29px]">
            <div className="w-full h-full">
              <TextField
                required
                id="outlined-required"
                label="Policy Category"
                value={policyCategory}
                onChange={(e) => setPolicyCategory(e.target.value)}
                name="policy_category"
                className="w-[344px] font-[Poppins] font-meduim text-[14px] leading-[21] text-[#49454F]"
              ></TextField>
            </div>
            <div className="w-full h-[117px]">
              <TextField
                required
                id="outlined-required"
                label="Description"
                // placeholder="Enter Declaration"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                name="description"
                sx={{
                  width: "100%",
                  marginTop: "50px",
                  "& .MuiInputBase-root": {
                    height: "117px",
                    alignItems: "flex-start",
                    textWrap: "wrap",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "117px",
                    "& input": {
                      padding: "10px 14px",
                      textWrap: "wrap",
                    },
                  },
                }}
              ></TextField>
            </div>
            <div className="w-full flex flex-row justify-end gap-5 mt-[79px]">
              <button
                className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
                onClick={onCancel}
              >
                <div className="text-[#2C2C2C] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize text">
                  Cancel
                </div>
              </button>
              <button
                type="submit"
                form="form"
                onClick={handleSave}
                className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
              >
                <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Update
                </div>
              </button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export const PolicyCategories = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [selectedItem, setSelectedItem] = useState<RowData | null>(null);
  const [editItemId, setEditItemId] = useState("");

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
   
  }

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Policy_Category_List(
        customer_id,
        searchTerm,
        numOfItems,
        page
      );
      if (response.status === 200) {
        setTableData(response.data?.result);
        setCount(response.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm, page, numOfItems, startSession, endSession]);

  const [open, setOpen] = React.useState(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [editItemDetails, setEditItemDetails] = useState<any>(null);

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      // fetchData(event.target.value); // call API to fetch data
    }, 100), // delay in ms
    []
  );

  const handledelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/policy-category/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item.id !== selectedItem.id)
          );
        } else {
          console.error("Failed to delete the item");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        //setShowModal(false);
        setShowDeleteModal(false);
        setSelectedItem(null);
      }
    }
    setShowDeleteModal(false);
  };

  const handleEditClick = async (id: string) => {
    try {
      const data = await fetchEditPolicyCategory(id);
      if (data.result) {
        const resultArray = Array.isArray(data.result)
          ? data.result
          : [data.result];
        const formattedRows = resultArray.map((item: any) => ({
          name: item.name,
          description: item.description,
          id: item.id,
        }));
        setEditItemDetails(formattedRows[0]);
      }
    } catch (error) {}
    setEditItemId(id);
    setShowEditModal(true);
  };
  const handleDeleteClick = (item: RowData) => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/policy-categories");
  };

  const handleView = async (id: any) => {
    try {
      const data = await fetchEditPolicyCategory(id);
      if (data.result) {
        const resultArray = Array.isArray(data.result)
          ? data.result
          : [data.result];
        const formattedRows = resultArray.map((item: any) => ({
          name: item.name,
          description: item.description,
          id: item.id,
        }));
        setEditItemDetails(formattedRows[0]);
      }
    } catch (error) {}
    setShowViewModal(true);
  };

  const handleUpdate = async (policyCategory: string, description: string) => {

    try {
      const response = await updatePolicyCategory(
        Number(editItemId),
        policyCategory,
        description
      );

      setTimeout(() => {
        // setOpen(false);
        setShowEditModal(false);
        setOpen(true);
        navigate("/policy-categories");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    // setOpen(true);
    // setShowEditModal(false);
  };

  // const [count, setCount] = useState(0);
  // const [numOfItems, setNumOfItems] = useState(10);
  // const [page, setPage] = useState(1);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  return (
    <div className="w-full pt-[50px]">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Policy Category Edited Successfully!
          </Typography>
        </Box>
      </Modal>
      <div className="flex flex-col gap-5">
        <div className="h-10 w-full">
          <div className="h-full w-60">
            <Search
              onChange={updateSearchTerm}
              label="Search with Policy Category"
            />
          </div>
        </div>
        <div className="w-full">
          <table>
            <thead
              className="h-[46px] border border-[#E0E0E0] rounded-t-[3px] rounded-b-none bg-gradient-to-r px-[19px] py-4"
              style={{
                background:
                  "linear-gradient(90deg, rgba(65, 101, 196, 0.2) 0%, rgba(142, 31, 210, 0.2) 100%)",
              }}
            >
              <th className="h-3.5 px-3 font-['Inter'] font-semibold text-[14px] leading-[14px] text-[#1D1A22]">
                Policy Category
              </th>
              <th className="h-3.5 px-3 font-['Inter'] font-semibold text-[14px] leading-[14px] text-[#1D1A22]">
                Description
              </th>
              <th className="h-3.5 px-3 font-['Inter'] font-semibold text-[14px] leading-[14px] text-[#1D1A22] text-center">
                Action
              </th>
            </thead>
            <tbody className="border-[1px] border-[#EBEBEB] pl-[26px] pr-[21px]">
              {tableData?.map((item) => (
                <tr className="hover:bg-gray-100">
                  <td className="font-['Poppins'] px-3 w-1/4 font-normal text-[14px] leading-6 tracking-[0.15px] text-[#49454F]">
                    {item.name}
                  </td>
                  <td className="font-['Poppins'] w-[60%] px-3 font-normal text-[14px] leading-6 tracking-[0.15px] text-[#49454F]">
                    {item.description}
                  </td>
                  <td className="px-3">
                    <div className="flex justify-center items-center gap-2 p-0 m-0">
                      <img
                        src={veiw}
                        alt="view"
                        onClick={() => handleView(item.id)}
                        className="text-[#49454F] cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      />
                      <img
                        src={edit}
                        alt="edit"
                        onClick={() => handleEditClick(item.id)}
                        className="text-[#49454F] cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      />
                      <img
                        src={delete001}
                        alt="delete"
                        onClick={() => handleDeleteClick(item)}
                        className="text-[#49454F] cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>

      {showEditModal && (
        <EditPolicyCategoryModal
          // policyCategory={policyCategory}
          // description={description}
          onUpdate={handleUpdate}
          onCancel={() => setShowEditModal(false)}
          policyCategoryDetails={editItemDetails}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          onDelete={handledelete}
          onCancelDelete={() => setShowDeleteModal(false)}
        />
      )}
      {showViewModal && (
        <ViewPolicyModal
          onQuit={() => setShowViewModal(false)}
          policyCategoryDetails={editItemDetails}
        />
      )}
    </div>
  );
};
