import StackedBarChart from "../../common/Charts/StackedBarChart";
import DoughnutChart, {
  centerTextPlugin,
} from "../../common/Charts/DoughnutChart";
import eye from "../../../assets/Crud_Icons/view.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "../../common/Button";
import filter from "../../../assets/filter.svg";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
import Deductions from "../Deduction/Deductions";
import ReactDatePicker from "react-datepicker";
import { createGlobalStyle } from "styled-components";
import date2 from "../../../assets/date2.svg";
import toast from "react-hot-toast";
import {
  Fetch_Attendence_Overview_Stats,
  Fetch_Attendence_Stats,
  Fetch_Department_Wise_Attendance,
} from "../../common/services/Attendence";
import { useSelector } from "react-redux";
import LineChart from "../../common/Charts/LineChart";
import ShowInOffice from "./ShowInOffice";
import ShowTotal from "./ShowTotalEmployee";
import ShowOnSite from "./ShowOnSite";
import ShowOther from "./ShowOther";
import ShowWFH from "./ShowWFH";
import ShowOnLeave from "./ShowOnLeave";

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

interface IAttendanceStatus {
  WFH: number;
  WFO: number;
  DELAYED: number;
  NOT_CHECKED_IN: number;
}

interface IWeeklyAttendance {
  WFO: number;
  WFH: number;
  OnLeave: number;
}

interface AttendanceStats {
  WFH: number;
  WFO: number;
  DELAYED: number;
  OTHER: number;
  ON_SITE: number;
  ON_TIME: number;
  ON_LEAVE: number;
  NOT_CHECKED_IN: number;
}

interface IDepWiseAttendance {
  department_id: number;
  department_name: string;
  DELAYED: number;
  ON_TIME: number;
  ON_LEAVE: number;
  NOT_CHECKED_IN: number;
  TOTAL_USERS: number;
}

interface AttOverviewProps {
  groupIds: (number | null)[];
}

const AttendanceOverview: React.FC<AttOverviewProps> = ({ groupIds }) => {
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const [showTotal, setShowTotal] = useState<boolean>(false);
  const [showInOffice, setShowInOffice] = useState<boolean>(false);
  const [showOnSite, setShowOnSite] = useState<boolean>(false);
  const [showElsewhere, setShowElsewhere] = useState<boolean>(false);
  const [showWFH, setShowWFH] = useState<boolean>(false);
  const [showOnLeave, setShowOnLeave] = useState<boolean>(false);

  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const selectedCalendarRef = useRef<HTMLDivElement>(null);
  const [isSelectedOpen, setIsSelectedOpen] = useState(false);
  const handleSelectedDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue ?? dayjs());
  };
  const handleSelectedCalanderClick = () => {
    setIsSelectedOpen(!isSelectedOpen);
  };
  const [stats, setStats] = useState<AttendanceStats>();
  const [depWiseAttendance, setDepWiseAttendance] = useState<
    IDepWiseAttendance[]
  >([]);

  const fetchAttStats = useCallback(async () => {
    // setLoading1(true);
    try {
      const response = await Fetch_Attendence_Overview_Stats(
        customer_id,
        selectedDate,
        groupIds
      );
      if (response.status === 200) {
        setStats(response?.data?.result ?? {});
      } else {
        toast.error("Error fetching attendence stats.");
      }
    } catch {}
    // setLoading1(false);
  }, [customer_id, selectedDate, groupIds]);

  useEffect(() => {
    if (groupIds?.length) {
      fetchAttStats();
    }
  }, [fetchAttStats]);

  const fetchDepartmentWiseAttendance = useCallback(async () => {
    // setLoading1(true);
    try {
      const response = await Fetch_Department_Wise_Attendance(
        customer_id,
        selectedDate,
        groupIds
      );
      if (response.status === 200) {
        setDepWiseAttendance(response?.data?.result ?? []);
      } else {
        toast.error(response?.data?.result?.error);
      }
    } catch {
      toast.error("Error fetching department wise attendence.");
    }
    // setLoading1(false);
  }, [customer_id, selectedDate, groupIds]);

  useEffect(() => {
    if (groupIds?.length) {
      fetchDepartmentWiseAttendance();
    }
  }, [fetchDepartmentWiseAttendance]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectedCalendarRef.current &&
        !selectedCalendarRef.current.contains(event.target as Node)
      ) {
        setIsSelectedOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const attendanceStats = [
    {
      label: "On Time",
      value: stats?.ON_TIME ?? 0,
      color: "#8AB3FF",
    },
    {
      label: "Not Checked-In",
      value: stats?.NOT_CHECKED_IN ?? 0,
      color: "#CEDFFF",
    },
    {
      label: "Delayed Check-In",
      value: stats?.DELAYED ?? 0,
      color: "#FDAAAA",
    },
  ];

  const totalEmpCount =
    (stats?.WFH ?? 0) +
    (stats?.WFO ?? 0) +
    (stats?.OTHER ?? 0) +
    (stats?.ON_SITE ?? 0) +
    (stats?.ON_LEAVE ?? 0) +
    (stats?.NOT_CHECKED_IN ?? 0);

  const attendanceChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showCenterText: true,
    // centerText: `Total\n${totalEmpCount}`,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          generateLabels: (chart: any) => {
            const data = chart?.data;
            if (data.labels && data?.datasets?.length) {
              return data?.labels?.map((label: any, i: any) => ({
                text: `${label} (${data?.datasets[0]?.data[i]})`,
                fillStyle: data?.datasets[0]?.backgroundColor[i],
                strokeStyle: data?.datasets[0]?.backgroundColor[i],
                // lineWidth: 1,
              }));
            }
            return [];
          },
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        titleFont: { weight: "bold" },
        bodyFont: { size: 12 },
        displayColors: true,
        borderColor: "#E5E7EB",
        borderWidth: 1,
        borderRadius: 4,
        callbacks: {
          title: function () {
            return "";
          },
          label: function (tooltipItem: any) {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            const value = dataset.data[index];
            const label = dataset.labels?.[index] || tooltipItem.label;
            return ` ${label} ${value}`;
          },
        },
      },
      centerTextPlugin,
    },
  };

  const attendanceGraphData = [
    {
      label: "On Time",
      data: Object.values(depWiseAttendance)?.map((i) => i?.ON_TIME ?? 0),
      color: "#AEDCAE",
    },
    {
      label: "Delayed Check-In",
      data: Object.values(depWiseAttendance)?.map((i) => i?.DELAYED ?? 0),
      color: "#EEAA5B",
    },
    {
      label: "Not Checked-In",
      data: Object.values(depWiseAttendance)?.map(
        (i) => i?.NOT_CHECKED_IN ?? 0
      ),
      color: "#6496F4",
    },
    {
      label: "On Leave",
      data: Object.values(depWiseAttendance)?.map((i) => i?.ON_LEAVE ?? 0),
      color: "#FDAAAA",
    },
  ];

  const attendanceLabels =
    depWiseAttendance?.map((department) => department?.department_name) || [];

  const attendenceOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          borderRadius: 4,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        borderColor: "#E5E7EB",
        borderWidth: 1,
        borderRadius: 4,
        callbacks: {
          title: (tooltipItems: any) => {
            const dataIndex = tooltipItems[0]?.dataIndex;
            const totalUsers = depWiseAttendance?.[dataIndex]?.TOTAL_USERS || 0;
            return `${tooltipItems[0].label}: ${totalUsers}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        offset: true,
      },
      y: {
        stacked: true,
        grid: { display: false },
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          callback: function (value:any) {
            return Number.isInteger(value) ? value : '';
          },
        },
      },
    },
  };

  return (
    <>
      <div>
        <div className="h-[40px] w-full mt-2">
          <div className="flex justify-between items-center gap-5">
            <div className="flex h-full text-default-gray-800 text-xl font-semibold font-['Inter']">
              <div className="pl-1">
                {selectedDate && dayjs().isSame(selectedDate, "day")
                  ? "Today's Attendance"
                  : selectedDate?.format("DD MMM YYYY")}
              </div>
            </div>
            <div className="flex h-full items-center gap-5">
              <div className="h-full flex justify-end items-center">
                <div
                  className="flex h-10 p-4 justify-between items-center rounded-md border-[1px] border-solid border-custom-border-gray bg-white"
                  ref={selectedCalendarRef}
                >
                  <img
                    src={date2}
                    alt="date icon"
                    className="w-5 h-5 cursor-pointer"
                    onClick={handleSelectedCalanderClick}
                  />
                  <GlobalStyle />
                  <ReactDatePicker
                    className="w-[110px] text-center text-['Inter'] font-[400] cursor-pointer"
                    dateFormat={"dd MMM yyyy"}
                    selected={selectedDate?.toDate() || null}
                    onChange={(date: Date | null) => {
                      handleSelectedDateChange(date ? dayjs(date) : null);
                      handleSelectedCalanderClick();
                    }}
                    open={isSelectedOpen}
                    onInputClick={handleSelectedCalanderClick}
                  />
                </div>
              </div>
              {/* <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button> */}
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-4 flex-wrap mt-2">
          <div className="flex p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
            <div className="flex w-full justify-between text-[16px] font-medium leading-6 text-default-gray-600 font-[Poppins]">
              Total Employee
              {totalEmpCount > 0 && (
                <button
                  className="flex h-[32px] px-3 justify-center items-center gap-[0.5rem] rounded-[6px] border border-[#E2E8F0] bg-white text-[12px] text-gray-800 font-medium font-poppins"
                  onClick={() => setShowTotal(true)}
                >
                  View All
                </button>
              )}
            </div>
            <div className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
              {totalEmpCount || 0}
            </div>
          </div>
          <div className="flex p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
            <div className="flex w-full justify-between text-[16px] font-medium leading-6 text-default-gray-600 font-[Poppins]">
              In Office
              {stats?.WFO ? (
                <button
                  className="flex h-[32px] px-3 justify-center items-center gap-[0.5rem] rounded-[6px] border border-[#E2E8F0] bg-white text-[12px] text-gray-800 font-medium font-poppins"
                  onClick={() => setShowInOffice(true)}
                >
                  View All
                </button>
              ) : null}
            </div>
            <div className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
              {stats?.WFO ?? 0}
            </div>
          </div>
          <div className="flex p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
            <div className="flex w-full justify-between text-[16px] font-medium leading-6 text-default-gray-600 font-[Poppins]">
              On Site
              {stats?.ON_SITE ? (
                <button
                  className="flex h-[32px] px-3 justify-center items-center gap-[0.5rem] rounded-[6px] border border-[#E2E8F0] bg-white text-[12px] text-gray-800 font-medium font-poppins"
                  onClick={() => setShowOnSite(true)}
                >
                  View All
                </button>
              ) : null}
            </div>
            <div className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
              {stats?.ON_SITE ?? 0}
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-4 flex-wrap pt-2">
          <div className="flex p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
            <div className="flex w-full justify-between text-[16px] font-medium leading-6 text-default-gray-600 font-[Poppins]">
              Work From Elsewhere
              {stats?.OTHER ? (
                <button
                  className="flex h-[32px] px-3 justify-center items-center gap-[0.5rem] rounded-[6px] border border-[#E2E8F0] bg-white text-[12px] text-gray-800 font-medium font-poppins"
                  onClick={() => setShowElsewhere(true)}
                >
                  View All
                </button>
              ) : null}
            </div>
            <div className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
              {stats?.OTHER ?? 0}
            </div>
          </div>
          <div className="flex p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
            <div className="flex w-full justify-between text-[16px] font-medium leading-6 text-default-gray-600 font-[Poppins]">
              Work From Home
              {stats?.WFH ? (
                <button
                  className="flex h-[32px] px-3 justify-center items-center gap-[0.5rem] rounded-[6px] border border-[#E2E8F0] bg-white text-[12px] text-gray-800 font-medium font-poppins"
                  onClick={() => setShowWFH(true)}
                >
                  View All
                </button>
              ) : null}
            </div>
            <div className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
              {stats?.WFH ?? 0}
            </div>
          </div>
          <div className="flex p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
            <div className="flex w-full justify-between text-[16px] font-medium leading-6 text-default-gray-600 font-[Poppins]">
              On Leave
              {stats?.ON_LEAVE ? (
                <button
                  className="flex h-[32px] px-3 justify-center items-center gap-[0.5rem] rounded-[6px] border border-[#E2E8F0] bg-white text-[12px] text-gray-800 font-medium font-poppins"
                  onClick={() => setShowOnLeave(true)}
                >
                  View All
                </button>
              ) : null}
            </div>
            <div className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
              {stats?.ON_LEAVE ?? 0}
            </div>
          </div>
        </div>
        <div className="flex gap-4 justify-between pt-4">
          <div className="bg-white border border-default-gray-200 rounded-md p-4 h-[350px] w-[25%]">
            <div className="flex justify-between items-center mb-2">
              <p className="text-light-black font-semibold text-base font-['Poppins']">
                Attendance Check-in Insights
              </p>
            </div>
            <div className="flex justify-center w-full h-[92%] mt-2">
              <DoughnutChart
                chartData={attendanceStats}
                options={attendanceChartOptions}
              />
            </div>
          </div>
          <div className="bg-white border border-default-gray-200 rounded-md p-4 h-[350px] w-[75%]">
            <div className="flex justify-between items-center mb-2">
              <p className="text-light-black font-semibold text-base font-['Poppins']">
                Department Wise-Attendance Insights
              </p>
            </div>
            <div className="flex justify-center w-full h-[90%] mt-2">
              <LineChart
                chartData={attendanceGraphData}
                labels={attendanceLabels}
                options={attendenceOptions}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FCFCFC] border rounded-md p-4 flex flex-col gap-2 mt-5">
        <Deductions />
      </div>
      {showTotal && (
        <ShowTotal
          isOpen={showTotal}
          onClose={() => setShowTotal(false)}
          date={selectedDate}
          groupIds={groupIds}
        />
      )}
      {showInOffice && (
        <ShowInOffice
          isOpen={showInOffice}
          onClose={() => setShowInOffice(false)}
          date={selectedDate}
          groupIds={groupIds}
        />
      )}
      {showOnSite && (
        <ShowOnSite
          isOpen={showOnSite}
          onClose={() => setShowOnSite(false)}
          date={selectedDate}
          groupIds={groupIds}
        />
      )}
      {showElsewhere && (
        <ShowOther
          isOpen={showElsewhere}
          onClose={() => setShowElsewhere(false)}
          date={selectedDate}
          groupIds={groupIds}
        />
      )}
      {showWFH && (
        <ShowWFH
          isOpen={showWFH}
          onClose={() => setShowWFH(false)}
          date={selectedDate}
          groupIds={groupIds}
        />
      )}
      {showOnLeave && (
        <ShowOnLeave
          isOpen={showOnLeave}
          onClose={() => setShowOnLeave(false)}
          date={selectedDate}
          groupIds={groupIds}
        />
      )}
    </>
  );
};

export default AttendanceOverview;
