import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { revertAll } from '../../ResetRedux/RevertAll';

interface RoutesInterface {
    routesArray: string[];
}

const initialState: RoutesInterface = {
    routesArray: [],
};

const routeSlice = createSlice({
    name: 'routes',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setRoutesArray(state, action: PayloadAction<string[]>) {
            state.routesArray = action.payload;
        },
    },
});

export const { setRoutesArray } = routeSlice.actions;
export default routeSlice;
