// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .css-1gsv261 {
    border-bottom: none !important;
} */



/* .css-1aquho2-MuiTabs-indicator{
    background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);
    height: 4px;
} */
/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color: #1D1A22 !important;
    font-weight: 500;
    font-size: 22px;
    font-family: "Poppins";
} */

/* .css-13xfq8m-MuiTabPanel-root {
    padding: 0 !important;
} */

.tabpanel{
  padding: 0px !important;
}

.indicator-default {
    background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);
    height: 4px;
  }
  
  .indicator-employee-management {
    background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);
    height: 4px;
  }
  
  .indicator-exit-procedure {
    background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);
    height: 4px;
  }`, "",{"version":3,"sources":["webpack://./src/components/EmployeeManagement/employee.css"],"names":[],"mappings":"AAAA;;GAEG;;;;AAIH;;;GAGG;AACH;;;;;GAKG;;AAEH;;GAEG;;AAEH;EACE,uBAAuB;AACzB;;AAEA;IACI,4DAA4D;IAC5D,WAAW;EACb;;EAEA;IACE,4DAA4D;IAC5D,WAAW;EACb;;EAEA;IACE,4DAA4D;IAC5D,WAAW;EACb","sourcesContent":["/* .css-1gsv261 {\n    border-bottom: none !important;\n} */\n\n\n\n/* .css-1aquho2-MuiTabs-indicator{\n    background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);\n    height: 4px;\n} */\n/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{\n    color: #1D1A22 !important;\n    font-weight: 500;\n    font-size: 22px;\n    font-family: \"Poppins\";\n} */\n\n/* .css-13xfq8m-MuiTabPanel-root {\n    padding: 0 !important;\n} */\n\n.tabpanel{\n  padding: 0px !important;\n}\n\n.indicator-default {\n    background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);\n    height: 4px;\n  }\n  \n  .indicator-employee-management {\n    background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);\n    height: 4px;\n  }\n  \n  .indicator-exit-procedure {\n    background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);\n    height: 4px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
