import { useCallback, useEffect, useRef, useState } from "react";
import DoughnutChart from "../../common/Charts/DoughnutChart";
import StackedBarChart from "../../common/Charts/StackedBarChart";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../common/Button";
import { ArrowUpDown } from "lucide-react";
import { useNavigate } from "react-router-dom";
import eye from "../../../assets/Crud_Icons/view.svg";
import DynamicTable from "../../common/DynamicTable";
import { useSelector } from "react-redux";
import PieChart from "../../common/Charts/PieChart";
import {
  Fetch_Loss_Making_Projects,
  Fetch_Profitable_Projects,
  Fetch_Projects_Chart_Data,
} from "../../common/services/Project";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import {
  resetProjectEndDate,
  resetProjectStartDate,
  resetViewProjectTab,
  setProjectId,
} from "../../../redux/reducers/ProjectManagement/ProjectSlice2";
import { Fetch_Financial_year } from "../../common/services/Dashboard";
import { TextField, MenuItem } from "@mui/material";

export interface IAttendanceRecord {
  id: number;
  user_id: number;
  customer_id: number;
  status: string;
  start_time: string;
  end_time: string;
  date: string;
  check_in_address: string;
  check_out_address: string;
  checkout_by: string;
  mode_of_work: string;
  mode_of_work_checkout: string;
  check_in_status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  duration: string;
  User: {
    id: number;
    prefix: string;
    firstName: string;
    lastName: string;
    employee_id: string;
    department_id: number;
    profile_image: string;
  };
  selected_address: string;
  selected_checkout_address: string;
}

interface IEMPStats {
  WFO: number;
  WFH: number;
  OnLeave: number;
  totalWorking: number;
}

interface IAttendanceStatus {
  WFH: number;
  WFO: number;
  DELAYED: number;
  NOT_CHECKED_IN: number;
}

interface IWeeklyAttendance {
  WFO: number;
  WFH: number;
  OnLeave: number;
  totalWorking: number;
}

interface IEMPStats {
  WFO: number;
  WFH: number;
  OnLeave: number;
}

interface IProjectTypeDistribution {
  billing_type: string | null;
  total_count: string | number | null;
  billable_count: string | number | null;
  non_billable_count: string | number | null;
  revenue_generation_count: string | number | null;
  other_count: string | number | null;
  missing_count: string | number | null;
}

interface IProjectStatus {
  status: string;
  count: string | number;
}

interface IClientStatus {
  status: string;
  count: string | number;
}

interface IProfitableList {
  id: number;
  project_name: string;
  profit: string;
}

interface ILossMakingList {
  id: number;
  project_name: string;
  loss: string;
}

interface IFinancialYear {
  month: number;
  year: number;
  monthName: string;
  totalProfit: number;
  totalLoss: number;
}

const formatSession = (
  startDate: string | null,
  endDate: string | null
): string => {
  if (startDate && endDate) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    const currentFYStart =
      dayjs().month() >= 3
        ? dayjs().startOf("year").month(3)
        : dayjs().subtract(1, "year").startOf("year").month(3);

    const currentFYEnd = currentFYStart.add(1, "year").subtract(1, "day");

    if (
      start.isSame(currentFYStart, "day") &&
      end.isSame(currentFYEnd, "day")
    ) {
      return "Current Financial Year";
    } else {
      const startYear = start.year();
      const endYear = end.year();
      return `${startYear}-${endYear.toString().slice(-2)}`;
    }
  }

  return "Current Financial Year";
};

function ProjectDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");
  const session = formatSession(startSession, endSession);
  const [projectCount, setProjectCount] = useState<number>(0);
  const [clientCount, setClientCount] = useState<number>(0);
  const [projectTypeDistribution, setProjectTypeDistribution] = useState<
    IProjectTypeDistribution[]
  >([]);
  const [projectStatusOverview, setProjectStatusOverview] = useState<
    IProjectStatus[]
  >([]);
  const [clientStatusOverview, setClientStatusOverview] = useState<
    IClientStatus[]
  >([]);
  const [weeklyAttendance, setWeeklyAttendance] = useState<
    IWeeklyAttendance | {}
  >({});
  const [profitableList, setProfitableList] = useState<IProfitableList[]>([]);
  const [profitablePage, setProfitablePage] = useState<number>(1);
  const [profitableListCount, setProfitableListCount] = useState<number>(0);
  const [profitableAmount, setProfitableAmount] = useState<number>(0);

  const [lossMakingList, setLossMakingList] = useState<ILossMakingList[]>([]);
  const [lossMakingPage, setLossMakingPage] = useState<number>(1);
  const [lossMakingListCount, setLossMakingListCount] = useState<number>(0);
  const [lossMakingAmount, setLossMakingAmount] = useState<number>(0);

  const [financialYear, setFinancialYear] = useState("last3months");
  const [earnings, setEarnings] = useState<IFinancialYear[]>([]);

  const numOfItems = 10;
  const [hasMore, setHasMore] = useState(false);
  const { ref: profitableRef, inView: profitableInView } = useInView();
  useEffect(() => {
    if (profitableInView && hasMore) {
      setProfitablePage((prev) => prev + 1);
    }
  }, [profitableInView, hasMore]);

  const [hasMore2, setHasMore2] = useState(false);
  const { ref: lossMakingRef, inView: lossMakingInView } = useInView();
  useEffect(() => {
    if (lossMakingInView && hasMore2) {
      setLossMakingPage((prev) => prev + 1);
    }
  }, [lossMakingInView, hasMore2]);

  const fetchProjectChartData = async () => {
    try {
      const response = await Fetch_Projects_Chart_Data(customer_id);
      if (response.status === 200) {
        setProjectTypeDistribution(
          response?.data?.result?.projectTypeDistribution ?? {}
        );
        setProjectStatusOverview(
          response?.data?.result?.projectStatusOverview ?? {}
        );
        setClientStatusOverview(
          response?.data?.result?.clientStatusOverview ?? {}
        );
        setProjectCount(
          response?.data?.result?.projectStatusOverview
            ?.map((item: any) => Number(item.count))
            ?.reduce((sum: number, count: number) => sum + count, 0)
        );
        setClientCount(
          response?.data?.result?.clientStatusOverview
            ?.filter(
              (item: any) =>
                item?.status === "active" || item?.status === "inactive"
            )
            ?.map((item: any) => Number(item?.count))
            ?.reduce((sum: number, count: number) => sum + count, 0)
        );
      } else {
        console.error("Error:", response.status, response.statusText);
        toast.error(response?.data?.result?.error);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error fetching data");
    }
  };
  useEffect(() => {
    fetchProjectChartData();
  }, [customer_id]);

  const fetchProfitableProjects = useCallback(async () => {
    try {
      const response = await Fetch_Profitable_Projects(
        customer_id,
        profitablePage,
        numOfItems
      );
      if (response.status === 200) {
        const newProjects = response?.data?.result?.profitableProjects ?? [];
        const profitableProjectTotal =
          response?.data?.result?.summary?.totalProfitableProjects ?? 0;
        setProfitableList(newProjects);
        // setProfitableList((prev) => [...prev, ...newProjects]);
        // if (profitablePage * numOfItems >= profitableProjectTotal) {
        //   setHasMore(false);
        // }
        setProfitableListCount(profitableProjectTotal);
        setProfitableAmount(
          response?.data?.result?.summary?.totalProfitAmount ?? 0
        );
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error fetching profitable projects.");
    }
  }, [customer_id, profitablePage]);
  useEffect(() => {
    fetchProfitableProjects();
  }, [fetchProfitableProjects]);

  const fetchLossMakingProjects = useCallback(async () => {
    try {
      const response = await Fetch_Loss_Making_Projects(
        customer_id,
        lossMakingPage,
        numOfItems
      );
      if (response.status === 200) {
        const newProjects = response?.data?.result?.LossProjects ?? [];
        setLossMakingList(newProjects);
        const lossMakingProjectTotal =
          response?.data?.result?.summary?.totalLossProjects ?? 0;
        // setLossMakingList((prev) => [...prev, ...newProjects]);
        // if (lossMakingPage * numOfItems >= lossMakingProjectTotal) {
        //   setHasMore2(false);
        // }
        setLossMakingListCount(lossMakingProjectTotal);
        setLossMakingAmount(
          response?.data?.result?.summary?.totalLossAmount ?? 0
        );
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error fetching loss maaking projects.");
    }
  }, [customer_id, lossMakingPage]);
  useEffect(() => {
    fetchLossMakingProjects();
  }, [fetchLossMakingProjects]);

  const fetchFinancialYear = useCallback(async () => {
    try {
      const response = await Fetch_Financial_year({
        set:
          financialYear === "year" ||
          formatSession(startSession, endSession) !== "Current Financial Year"
            ? "year"
            : financialYear,
      });

      if (response?.status === 200) {
        setEarnings(response?.data?.result || []);
        if (
          formatSession(startSession, endSession) !== "Current Financial Year"
        ) {
          setFinancialYear("year"); // Auto-select 'This Year' for non-current years
        }
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [financialYear, startSession, endSession]);

  useEffect(() => {
    fetchFinancialYear();
  }, [fetchFinancialYear]);

  const columns: ColumnDef<IProfitableList>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "project_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.project_name ?? "-",
      cell: ({ row }) => {
        return <div className="py-1">{row?.original?.project_name ?? "-"}</div>;
      },
    },
    {
      accessorKey: "profit",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Total Profit
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        return row?.original?.profit
          ? Number(row?.original?.profit)?.toFixed(2)
          : "-";
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                dispatch(setProjectId(row?.original?.id));
                dispatch(resetProjectStartDate());
                dispatch(resetProjectEndDate());
                dispatch(resetViewProjectTab());
                e.stopPropagation();
                navigate("/view-project-details", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const columns2: ColumnDef<ILossMakingList>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "project_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.project_name ?? "-",
      cell: ({ row }) => {
        return <div className="py-1">{row?.original?.project_name ?? "-"}</div>;
      },
    },
    {
      accessorKey: "loss",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Total Loss
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        return row?.original?.loss
          ? Number(row?.original?.loss)?.toFixed(2)
          : "-";
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                dispatch(setProjectId(row?.original?.id));
                dispatch(resetProjectStartDate());
                dispatch(resetProjectEndDate());
                dispatch(resetViewProjectTab());
                e.stopPropagation();
                navigate("/view-project-details", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const projectStats = [
    {
      label: "Ongoing",
      value:
        Number(
          projectStatusOverview.find((status) => status.status === "ongoing")
            ?.count
        ) || 0,
      color: "#8AB3FF",
    },
    {
      label: "On Hold",
      value:
        Number(
          projectStatusOverview.find((status) => status.status === "onhold")
            ?.count
        ) || 0,
      color: "#F4C1C1",
    },
    {
      label: "Completed",
      value:
        Number(
          projectStatusOverview.find((status) => status.status === "completed")
            ?.count
        ) || 0,
      color: "#BEE5B9",
    },
    {
      label: "Upcoming",
      value:
        Number(
          projectStatusOverview.find((status) => status.status === "upcoming")
            ?.count
        ) || 0,
      color: "#FFFFA3",
    },
  ];

  const projectStatsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          generateLabels: (chart: any) => {
            const data = chart?.data;
            if (data.labels && data?.datasets?.length) {
              return data?.labels?.map((label: any, i: any) => ({
                text: `${label} (${data?.datasets[0]?.data[i]})`,
                fillStyle: data?.datasets[0]?.backgroundColor[i],
                strokeStyle: data?.datasets[0]?.backgroundColor[i],
                // lineWidth: 1,
              }));
            }
            return [];
          },
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        titleFont: { weight: "bold" },
        bodyFont: { size: 12 },
        displayColors: true,
        borderColor: "#E5E7EB",
        borderWidth: 1,
        borderRadius: 4,
        callbacks: {
          title: function () {
            return "";
          },
          label: function (tooltipItem: any) {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            const value = dataset.data[index];
            const label = dataset.labels?.[index] || tooltipItem.label;
            return ` ${label} ${value}`;
          },
        },
      },
    },
  };

  const clientGraphStats = [
    {
      label: "Active",
      value:
        Number(
          clientStatusOverview.find((item) => item.status === "active")?.count
        ) || 0,
      color: "#CDEBC5",
    },
    {
      label: "Inactive",
      value:
        Number(
          clientStatusOverview.find((item) => item.status === "inactive")?.count
        ) || 0,
      color: "#F4C1C1",
    },
  ];

  const clientStatsChartData = {
    labels: clientGraphStats?.map((stat) => stat?.label),
    datasets: [
      {
        data: clientGraphStats?.map((stat) => stat?.value),
        backgroundColor: clientGraphStats?.map((stat) => stat?.color),
        borderColor: clientGraphStats?.map(() => "#transparent"),
        borderWidth: 0,
        hoverOffset: 0,
        hoverBorderWidth: 0,
        hoverBorderColor: "transparent",
      },
    ],
  };

  const clientStatsChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          generateLabels: (chart: any) => {
            const data = chart?.data;
            if (data.labels && data?.datasets?.length) {
              return data?.labels?.map((label: any, i: any) => ({
                text: `${label} (${data?.datasets[0]?.data[i]})`,
                fillStyle: data?.datasets[0]?.backgroundColor[i],
                strokeStyle: data?.datasets[0]?.backgroundColor[i],
                // lineWidth: 1,
              }));
            }
            return [];
          },
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        titleFont: { weight: "bold" },
        bodyFont: { size: 12 },
        displayColors: true,
        borderColor: "#E5E7EB",
        borderWidth: 1,
        borderRadius: 4,
        callbacks: {
          title: function () {
            return "";
          },
          label: function (tooltipItem: any) {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            const value = dataset.data[index];
            const label = dataset.labels?.[index] || tooltipItem.label;
            return ` ${label} ${value}`;
          },
        },
      },
    },
  };

  const attendanceGraphData = [
    {
      label: "Fixed Cost",
      data: Object.values(weeklyAttendance)?.map((week) => week.WFO),
      color: "#BEE5B9",
    },
    {
      label: "Man Month",
      data: Object.values(weeklyAttendance)?.map((week) => week.WFH),
      color: "#ACC9FF",
    },
    {
      label: "Time & Material",
      data: Object.values(weeklyAttendance)?.map((week) => week.OnLeave),
      color: "#F4C1C1",
    },
  ];

  const attendanceLabels = Object?.keys(weeklyAttendance)?.map(
    (week, index) => `Week ${index + 1}`
  );

  const attendenceOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        callbacks: {
          title: (tooltipItems: any) => {
            return tooltipItems[0].label;
          },
          // label: (tooltipItem: any) => {
          //   let datasetLabel = data?.datasets[tooltipItem.datasetIndex].label;
          //   let value = tooltipItem.raw;
          //   return `${datasetLabel}: ${value}`;
          // },
          footer: (tooltipItems: any) => {
            // Calculate total sum for the hovered bar
            const total = tooltipItems?.reduce(
              (sum: number, item: any) => sum + item.raw,
              0
            );
            return `Total: ${total}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    },
  };

  const projectTypeGraphData = [
    {
      label: "Other",
      data: Object.values(projectTypeDistribution)?.map(
        (week) => Number(week?.other_count) ?? 0
      ),
      color: "#8AB3FF",
    },
    {
      label: "Revenue Generation",
      data: Object.values(projectTypeDistribution)?.map(
        (week) => Number(week?.revenue_generation_count) ?? 0
      ),
      color: "#8AB3FF",
    },
    {
      label: "Non Billable",
      data: Object.values(projectTypeDistribution)?.map(
        (week) => Number(week?.non_billable_count) ?? 0
      ),
      color: "#8AB3FF",
    },
    {
      label: "Billable",
      data: Object.values(projectTypeDistribution)?.map(
        (week) => Number(week?.billable_count) ?? 0
      ),
      color: "#8AB3FF",
    },
  ];

  const projectTypeGraphLabels = ["Fixed Cost", "Man Month", "Time & Material"];

  const projecTypeGraphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          borderRadius: 4,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        displayColors: false,
        borderColor: "#E5E7EB",
        borderWidth: 1,
        borderRadius: 4,
        callbacks: {
          title: (tooltipItems: any) => {
            const index = tooltipItems[0]?.dataIndex;
            const totalCount = projectTypeDistribution[index]?.total_count || 0;
            return `${tooltipItems[0]?.label}: ${totalCount}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          // stepSize: 1,
        },
      },
    },
    // datasets: {
    //   bar: {
    //     borderRadius: {
    //       topLeft: 10,
    //       topRight: 10,
    //       bottomLeft: 0,
    //       bottomRight: 0,
    //     },
    //   },
    // },
  };

  const profitableProjectsRef = useRef<HTMLDivElement>(null);
  const handleProfitClick = () => {
    profitableProjectsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const lossProjectsRef = useRef<HTMLDivElement>(null);
  const handleLossClick = () => {
    lossProjectsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const FinancialYearOptions = [
    { label: "Last 3 Months", value: "last3months"},
    { label: "Last 6 Months", value: "last6months" },
    { label: "This Year", value: "year" },
  ];

  const financialData = [
    {
      label: "Profit",
      data: Object.values(earnings)?.map((item) => item.totalProfit ?? 0),
      color: "#CDEBC5",
      stack: "stack1",
    },
    {
      label: "Loss",
      data: Object.values(earnings)?.map((item) => -(item.totalLoss ?? 0)),
      color: "#F4C1C1",
      stack: "stack2",
    },
  ];

  const financialLabels = earnings.map(
    (item) => `${item.monthName} ${item.year}`
  );

  const formatValue = (value: number): string => {
    const absValue = Math.abs(value);

    if (absValue >= 1e12) return `${(value / 1e12).toFixed(1)}T`; // Trillions
    if (absValue >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billions
    if (absValue >= 1e6) return `${(value / 1e6).toFixed(1)}M`; // Millions
    if (absValue >= 1e3) return `${(value / 1e3).toFixed(1)}K`; // Thousands
    return value.toString();
  };

  const financialOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        callbacks: {
          title: (tooltipItems: any[]) => {
            const dataIndex = tooltipItems[0].dataIndex;
            return `${earnings[dataIndex].monthName} ${earnings[dataIndex].year}`;
          },
          label: () => "",
          afterBody: (tooltipItems: any[]) => {
            const dataIndex = tooltipItems[0].dataIndex;
            const totalProfit = (earnings[dataIndex].totalProfit ?? 0).toFixed(
              2
            );
            const totalLoss = (earnings[dataIndex].totalLoss ?? 0).toFixed(2);

            const netValue = (
              parseFloat(totalProfit) - parseFloat(totalLoss)
            ).toFixed(2);

            return netValue > "0.00"
              ? [`Total Profit: ${formatValue(parseFloat(netValue))}`]
              : [`Total Loss: ${formatValue(Math.abs(parseFloat(netValue)))}`];
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        ticks: {},
      },
      y: {
        beginAtZero: true,
        suggestedMin: -5000,
        suggestedMax: 5000,
        ticks: {
          stepSize: 1000,
          minTicksLimit: 5,
          maxTicksLimit: 10,
          callback: (value: any) => formatValue(value), // Dynamic formatting
        },
        grid: {
          drawOnChartArea: true,
          drawTicks: true,
          zeroLineColor: "#000",
          zeroLineWidth: 2,
          color: (context: any) =>
            context.tick.value === 0 ? "#CDCDCD" : "rgba(0, 0, 0, 0.1)",
          lineWidth: (context: any) => (context.tick.value === 0 ? 1.2 : 1),
        },
      },
    },
  };
  const totalEarning = profitableAmount - lossMakingAmount;

  return (
    <div className="flex flex-col gap-3 mt-2">
      <div className="flex justify-between gap-4 flex-wrap">
        <div className="flex min-h-[90px] p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
          <div className="flex gap-2 font-[Poppins]">
            <div className="text-[16px] font-medium leading-6 text-default-gray-600">
              Total Earning{" "}
            </div>

            <div className="text-[10px] font-normal leading-6 text-default-gray-700">
              ({session})
            </div>
          </div>

          <div className="flex gap-2">
            <span className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
              {formatValue(totalEarning) ?? 0}
            </span>
            <span
              onClick={handleProfitClick}
              className="cursor-pointer flex items-center justify-center gap-[10px] p-[2px_10px] my-[3px] rounded-[2px] bg-[rgba(15,148,70,0.10)] text-[12px] font-medium leading-4 text-completed-green font-[Poppins]"
            >
              Profit: {formatValue(profitableAmount) ?? 0}
            </span>
            <span
              onClick={handleLossClick}
              className="cursor-pointer flex items-center justify-center gap-[10px] p-[2px_10px] my-[3px] rounded-[2px] bg-[rgba(217,37,37,0.10)] text-[12px] font-medium leading-4 text-hold-red font-[Poppins]"
            >
              Loss: {formatValue(lossMakingAmount) ?? 0}
            </span>
          </div>
        </div>{" "}
        <div className="flex min-h-[90px] p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
          <div className="text-[16px] font-medium leading-6 text-default-gray-600 font-[Poppins]">
            Total Projects
          </div>
          <div className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
            {projectCount || "-"}
          </div>
        </div>
        <div className="flex min-h-[90px] p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
          <div className="text-[16px] font-medium leading-6 text-default-gray-600 font-[Poppins]">
            Total Clients
          </div>
          <div className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
            {clientCount || "-"}
          </div>
        </div>
      </div>

      <div className="grid lg:md:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-4">
        <div className="flex flex-wrap w-full h-[345px] p-4 items-start gap-[14px] rounded-[8px] border border-default-gray-200 bg-white shadow-sm">
          <div className="text-[14px] font-medium leading-[20px] tracking-[-0.42px] text-light-black font-[Poppins]">
            Project Type Distribution
          </div>
          <div className="flex justify-center h-[85%] my-2 w-full">
            <StackedBarChart
              chartData={projectTypeGraphData}
              labels={projectTypeGraphLabels}
              options={projecTypeGraphOptions}
            />
          </div>
        </div>

        <div className="flex w-full h-[345px] p-4 pb-2 flex-col justify-between items-start rounded-[6px] border border-custom-border-gray bg-white">
          <div className="text-[14px] font-medium leading-[20px] tracking-[-0.42px] text-light-black font-[Poppins]">
            Project Status Overview
          </div>
          <div className="flex justify-center h-[91%] mt-2 w-full">
            <DoughnutChart
              chartData={projectStats}
              options={projectStatsOptions}
            />
          </div>
        </div>

        <div className="flex flex-grow w-full h-[345px] p-4 flex-col justify-between items-start rounded-[6px] border border-custom-border-gray bg-white">
          <div className="text-[14px] font-medium leading-[20px] tracking-[-0.42px] text-light-black font-[Poppins]">
            Client Status Overview
          </div>
          <div className="flex justify-center h-[91%] mt-2 mb-4 w-full">
            <PieChart
              data={clientStatsChartData}
              options={clientStatsChartOptions}
            />
          </div>
        </div>
      </div>

      <div className="flex h-[313px] p-4 flex-col items-start self-stretch rounded-[6px] border border-default-gray-200 bg-white">
        <div className="flex justify-between items-center mb-2">
          <p className="text-light-black font-semibold text-base font-['Poppins']">
            Financial Year Earnings Breakdown
          </p>
        </div>

        <div className="flex flex-wrap items-center justify-end gap-2 w-full pr-4 mt-[-30px]">
          <TextField
            id="outlined-required"
            select
            placeholder=""
            name="set"
            value={financialYear}
            className="min-w-[70px] w-full sm:w-[120px] md:w-[150px]"
            sx={{
              height: "40px",
              backgroundColor: "white",
              borderRadius: "0.5rem",
              "& .MuiOutlinedInput-root": {
                height: "100%",
                borderRadius: "0.5rem",
                border: "none",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "29px",
                color: "#49454F",
                "& fieldset": {
                  border:
                    "1px solid rgb(205 205 205 / var(--tw-border-opacity, 1))",
                },
                "&:hover fieldset": {
                  borderColor: "rgb(205 205 205 / var(--tw-border-opacity, 1))",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgb(205 205 205 / var(--tw-border-opacity, 1))",
                  borderWidth: "1px",
                },
              },
            }}
            onChange={(e) => setFinancialYear(e.target.value)}
            SelectProps={{ displayEmpty: true }}
          >
            {formatSession(startSession, endSession) ===
            "Current Financial Year"
              ? FinancialYearOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : FinancialYearOptions.filter(
                  (option) => option.value === "year"
                ).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </div>

        <div className="flex justify-center w-full h-[91%] mt-2">
          <StackedBarChart
            chartData={financialData}
            labels={financialLabels}
            options={financialOptions}
          />
        </div>
      </div>

      <div className="w-full grid lg:grid-cols-2 md:grid-cols-1 gap-4">
        <div
          ref={profitableProjectsRef}
          className="flex p-4 flex-col items-start gap-4 self-stretch rounded-[8px] border border-default-gray-200 bg-white shadow-sm"
        >
          <div className="text-[14px] font-medium leading-[20px] tracking-[-0.42px] text-light-black font-[Poppins]">
            Profitable Projects <span>({profitableListCount || 0})</span>
          </div>
          <div className="overflow-x-auto w-full">
            <div className="table_main_content w-full max-h-[300px] relative overflow-auto bg-white rounded-s">
              <DynamicTable<IProfitableList>
                onRowClick={(row) => {
                  dispatch(setProjectId(row?.id));
                  dispatch(resetProjectStartDate());
                  dispatch(resetProjectEndDate());
                  dispatch(resetViewProjectTab());
                  navigate("/view-project-details", {
                    state: { id: row?.id },
                  });
                }}
                data={profitableList}
                loading={false}
                columns={columns}
                enableSorting
                enablePagination={false}
              />
              {/* {hasMore && (
                <div
                  ref={profitableRef}
                  className="text-center p-4 text-sm text-gray-500"
                >
                  Loading more...
                </div>
              )} */}
            </div>
          </div>
        </div>

        <div
          ref={lossProjectsRef}
          className="flex p-4 flex-col items-start gap-4 self-stretch rounded-[8px] border border-default-gray-200 bg-white shadow-sm"
        >
          <div className="text-[14px] font-medium leading-[20px] tracking-[-0.42px] text-light-black font-[Poppins]">
            Loss-Making Projects <span>({lossMakingListCount || 0})</span>
          </div>
          <div className="overflow-x-auto w-full">
            <div className="table_main_content w-full max-h-[300px] relative overflow-auto bg-white rounded-s">
              <DynamicTable<ILossMakingList>
                onRowClick={(row) => {
                  dispatch(setProjectId(row?.id));
                  dispatch(resetProjectStartDate());
                  dispatch(resetProjectEndDate());
                  dispatch(resetViewProjectTab());
                  navigate("/view-project-details", {
                    state: { id: row?.id },
                  });
                }}
                data={lossMakingList}
                loading={false}
                columns={columns2}
                enableSorting
                enablePagination={false}
              />
              {/* {hasMore2 && (
                <div
                  ref={lossMakingRef}
                  className="text-center p-4 text-sm text-gray-500"
                >
                  Loading more...
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDashboard;
