// import { Modal } from "@mui/material";
// import React from "react";

// const EditResources = () => {
//   return (
//     <>
//    <Modal>

//    </Modal>
//     </>
//   )
// };

// export default EditResources;

import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const EditResources: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        component="div"
      >
        <Box sx={{ p: 4, backgroundColor: "white" }}>
          <h2 id="simple-modal-title">Text in a modal</h2>
          <p id="simple-modal-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </Box>
      </Modal>
    </div>
  );
};

export default EditResources;
