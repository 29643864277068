// import React from 'react'

// const EditProjectDetails = () => {
//   return (
//     <div>EditProjectDetails</div>
//   )
// }

// export default EditProjectDetails

import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import upload from "../../assets/plus 1.png";
//import Project from "./Project";
//import Clients from "./Clients";
import "../../EmployeeManagement/employee.css";
import ProjectUpdate from "./ProjectUpdate";
import AddResources from "../AddResources";
import AddTask from "../AddTask";
import EditResources from "./EditResources";
import EditTask from "./EditTask";

export const EditProjectDetails = () => {
  const history = useNavigate();
  const [value, setValue] = React.useState<string>("1");
  const AddEmployee = () => {
    history("/add-employee");
  };

  //const [value, setValue] = useState("1");
  const location = useLocation();
  const id = location.state?.id;
  console.log(id,"check---id---tab-panel---->>>");
  

  useEffect(() => {
    if (location.pathname === "/edit-project-details") {
      setValue("1");
    } else if (/^\/edit-resources-details\/[^/]+$/.test(location.pathname)) {
      setValue("2");
    } else if (/^\/edit-task-details\/[^/]+$/.test(location.pathname)) {
      setValue("3");
    }
  }, [location.pathname]);

  // const handleChange = (event: any, newValue: any) => {
  //   setValue(newValue);
  // };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === '1') {
      history("/edit-project-details", { state: { id }});
    } else if (newValue === "2") {
      history(`/edit-resources-details/${id}`, { state: { id } });
    } else if (newValue === "3") {
      history(`/edit-task-details/${id}`, { state: { id } });
    }
  };
  return (
    <>          
      <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
        <div className="">
          <Box>
            <TabContext value={value}>
              <div className="flex justify-between">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      "& .MuiTab-root": {
                        color: "#89888E",
                        fontFamily: "Poppins",
                      },
                      "& .Mui-selected": {
                        color: "#000 !important",
                        fontFamily: "Poppins",
                      },
                    }}
                  >
                    <Tab
                      className="text-[#89888E]"
                      label="Project Details"
                      value="1"
                    />
                    <Tab label="Resources Details" value="2" />
                    <Tab label="Task Details" value="3" />
                  </TabList>
                </Box>
              </div>
              <TabPanel className="tabpanel" value="1">
                <ProjectUpdate />
              </TabPanel>
              <TabPanel className="tabpanel" value="2">
                <EditResources />
              </TabPanel>
              <TabPanel className="tabpanel" value="3">
                <EditTask />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
};
