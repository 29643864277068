import React, { useEffect, useState } from "react";
import { fetchEditTask } from "../common/services/Project";
import { useLocation, useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Receipt from "../ExpenseManagement/ImageView";
import ModalViewer from "../ExpenseManagement/ModalViewer";

interface TaskData {
  task_name: string;
  estimated_hours: string;
  actual_hours: string;
  start_date: string;
  end_date: string;
  status: string;
  project_id: string;
  description: string;
}

interface Resources {
  User: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
}

interface ApiResponse {
  rows: {
    id: number;
    expense_date: string;
    expense_amount: number;
    task_document: string[];
    receipt_filename: string[];
  }[];
}

const ViewTask = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [receipts, setReceipts] = useState<ApiResponse["rows"]>([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
  const [resources, setResources] = useState<Resources[]>([]);
  const [taskData, setTaskData] = useState<TaskData>({
    task_name: "",
    estimated_hours: "",
    actual_hours: "",
    start_date: "",
    end_date: "",
    status: "",
    project_id: "",
    description: "",
  });

  useEffect(() => {
    fetchEditTask(id)
      .then((data) => {
        if (data.result) {

          setTaskData({
            task_name: data.result?.task_name,
            estimated_hours: data.result?.estimated_hours,
            actual_hours: data.result?.actual_hours,
            start_date: data.result?.start_date,
            end_date: data.result?.end_date,
            status: data.result?.status,
            project_id: data.result?.project_id,
            description: data.result?.description,
          });
          setReceipts([data?.result]);
          setResources(data.result?.TaskResources);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);


  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };

  const closeModal = () => {
    setSelectedFileUrl(null);
  };

  return (
    <div className="px-4 pt-6 pb-12">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button
            onClick={() =>
              navigate(`/edit-task-details/${taskData?.project_id}`, {
                state: { id: taskData?.project_id },
              })
            }
          >
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Task Details
          </span>
        </div>
      </div>
      <div className="p-6 font-[Poppins] bg-white rounded-lg mt-6 shadow-md">
        <h2 className="text-lg font-semibold mb-4">Task Details</h2>
        <div className="viewBackground p-4 rounded-md">
          <div className="grid grid-cols-3 p-2 gap-4 border-b border-gray-200">
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Task Name
              </strong>
              <br />{" "}
              <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                {taskData.task_name}
              </strong>
            </p>
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Estimate Hours
              </strong>
              <br />{" "}
              <span className="text-[#1D1A22] font-[500] text-[16px] ">
                {taskData.estimated_hours || "--"}
              </span>
            </p>
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Actual Hours
              </strong>
              <br />
              <span className="text-[#1D1A22] font-[500] text-[16px] ">
                {taskData.actual_hours || "--"}{" "}
              </span>
            </p>
          </div>
          <div className="grid grid-cols-3 p-2 gap-4 border-b border-gray-200">
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Start Date
              </strong>
              <br />{" "}
              <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                {taskData.start_date}
              </strong>
            </p>
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                End Date
              </strong>
              <br />
              <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                {" "}
                {taskData.end_date || "--"}
              </strong>
            </p>
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Status
              </strong>
              <br />
              <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                {taskData?.status || "--"}
              </strong>
            </p>
          </div>

          <div className="grid grid-cols-1 p-2 gap-4 border-b border-gray-200">
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Description
              </strong>
              <br />{" "}
              <strong className=" text-[#1D1A22] font-[500] text-[16px]">
                {taskData?.description || "--"}
              </strong>
            </p>
          </div>
          <div className="grid grid-cols-1 p-2 gap-4 border-b border-gray-200">
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Task Resources
              </strong>
              <br />{" "}
              <strong className="flex justify-start gap-2 flex-wrap w-full items-center">
                {resources?.map((item) => (
                  <span className="text-[#1D1A22] font-[500] text-[16px] px-1 py-0.5 bg-[#EAE0FFBA]">
                    {item?.User?.firstName} {item?.User?.middleName}
                    {item?.User.lastName}
                  </span>
                ))}
              </strong>
            </p>
          </div>
          {receipts?.map((item) =>
            item?.task_document.length !== 0 ? (
              <div className="grid grid-cols-1 p-2 gap-4 border-b border-gray-200">
                <p>
                  <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                    Attachments
                  </strong>
                  <br />{" "}
                  <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                    {receipts?.map((receipt) =>
                      receipt?.task_document?.map((url, index) => (
                        <>
                          {" "}
                          <Receipt
                            key={index}
                            fileName={
                              receipt?.task_document[index]
                                ?.split("/")
                                ?.pop() || `Receipt ${index + 1}`
                            }
                            fileSize={`${(Math?.random() * 2 + 1)?.toFixed(
                              2
                            )} MB`} // Random file size for demonstration
                            onView={() => handleView(url)}
                          />
                        </>
                      ))
                    )}
                  </div>
                  {selectedFileUrl && (
                    <ModalViewer
                      isOpen={!!selectedFileUrl}
                      onRequestClose={closeModal}
                      fileUrl={selectedFileUrl}
                    />
                  )}
                </p>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewTask;
