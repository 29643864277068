import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";


interface TicketDocument {
    original_name: string;
    url: string;
}

interface Comment {
    count: number;
    rows: any[];
}

interface SupportData {
    id: number;
    name: string;
    description: string;
    status: string;
    priority: string;
    reporter_id: number;
    assignee_id: number;
    Department: {
        name: string
    };
    TicketDocuments: TicketDocument[];
    assignee: string;
    assigneeInitials: string;
    reporter: string;
    reporterInitials: string;
    comments: Comment;
}
    
interface TicketStatus {
    status: string;
    count: string;
}

interface TicketPriority {
    priority: string;
    count: string;
}

interface Dashboard {
    ticketData: TicketStatus[];
    ticketPriorityData: TicketPriority[];
}


interface SupportState {
    data: SupportData | null,
    dashboard: Dashboard | null,
    startDate: Date | null;
    endDate: Date | null;
    currentPage: number;
    itemsPerPage: number;
    count: number;
}

const initialState: SupportState = {
    data: null,
    dashboard: null,
    startDate: null,
    endDate: null,
    currentPage: 1,
    itemsPerPage: 10000,
    count: 0
};

const SupportSlice = createSlice({
    name: "support",
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {

        setSupport(state, action: PayloadAction<any>,) {
            state.data = action.payload;
            state.count = action.payload.count;
            // if (state.currentPage === 1) {
            //     state.data = action.payload;
            //   } else {
            //     state.data = {
            //       ...state.data,
            //       ...(state.data), ...action.payload,
            //     };
            //   }
            //   state.count = action.payload.count;
        },
        setDashBoard(state, action: PayloadAction<Dashboard>) {
            state.dashboard = action.payload;
        },
        setSupportPerPage(state, action: PayloadAction<any>) {
            state.itemsPerPage = action.payload;
        },
        setSupportCurrentPage(state, action: PayloadAction<any>) {
            state.currentPage = action.payload;
        },
        setStartDate(state, action: PayloadAction<Date | null>) {
            state.startDate = action.payload;
        },
        setEndDate(state, action: PayloadAction<Date | null>) {
            state.endDate = action.payload;
        },
        updateTicket: (state: any, action: PayloadAction<SupportData>) => {
            if (state.data) {
                state.data = state.data.map((ticket: any) =>
                    ticket.id === action.payload.id ? action.payload : ticket
                );
            }
        },
    },
});

export const SupportActions = SupportSlice.actions;
export default SupportSlice;