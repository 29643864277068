import React, { useEffect, useState } from "react";
import {
  Fetch_Announcement_Lst,
  Fetch_Birthday_Anniversary_Lst,
  Fetch_Leave_Dashboard_List,
  Fetch_WFH_Dashboard_List,
  Fetch_Work_From_Office,
} from "../common/services/Dashboard";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import noResult from "../../assets/noResult.svg";
import { enGB, enUS } from "date-fns/locale";
import avatar from "../../assets/avatar.svg";
import viewAll from "../../assets/viewAll.svg";
import ShowWfhModal from "./ShowWFH";

import "./Dashboard.css";
import ShowLeaveModal from "./showLeave";
import ShowWfoModal from "./showWFO";

interface Leave_Type {
  User: {
    firstName: string;
    lastName: string;
    profile_image: string;
  };
  wfh_session: string;
}

interface DashboardData {
  User: {
    firstName: string;
    lastName: string;
    profile_image: string;
  };
  leave_session: string;
}

interface WFO {
  User: {
    firstName: string;
    lastName: string;
    profile_image: string;
  };
}

interface BirthdayAnniversary {
  firstName: string;
  lastName: string;
  type: string;
}

interface Announcement {
  title: string;
  date: string;
  description: string;
}

const Dashboard = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const signupData = useSelector((state: any) => state.signup.details);
  const [tableData, setTableData] = useState<Leave_Type[]>();
  const [wfhCount, setWfhCount] = useState(0);
  const [wfoCount, setWfoCount] = useState(0);
  const [leaveCount, setLeaveCount] = useState(0);
  const [tableData1, setTableData1] = useState<DashboardData[]>();
  const [tableData2, setTableData2] = useState<BirthdayAnniversary[]>();
  const [tableData3, setTableData3] = useState<Announcement[]>();
  const [tableData4, setTableData4] = useState<WFO[]>();
  const [wfh, setWfh] = useState<boolean>(false);
  const [wfo, setWfo] = useState<boolean>(false);
  const [leave, setLeave] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          wfhResponse,
          leaveResponse,
          birthdayResponse,
          announcementResponse,
          wfoResponse,
        ] = await Promise.all([
          Fetch_WFH_Dashboard_List(10, 1),
          Fetch_Leave_Dashboard_List(10, 1),
          Fetch_Birthday_Anniversary_Lst(10, 1),
          Fetch_Announcement_Lst(10, 1),
          Fetch_Work_From_Office(10, 1),
        ]);

        setTableData(wfhResponse.data.result.rows);
        setWfhCount(wfhResponse.data.result.count);
        setTableData1(leaveResponse.data.result.rows);
        setLeaveCount(leaveResponse.data.result.count);
        setTableData2(birthdayResponse.data.result.rows);
        setTableData3(announcementResponse.data.result.rows);
        setTableData4(wfoResponse.data.result.rows);
        setWfoCount(wfoResponse.data.result.count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const displayWFH = tableData?.slice(0, 7);
  const displayLeave = tableData1?.slice(0, 7);
  const displayWFO = tableData4?.slice(0, 7);

  const currentDate = new Date();

  const formattedDate = format(currentDate, "EEEE, do MMMM yyyy", {
    locale: enGB,
  });

  return (
    <div className="bg-[#F9F9F9] pb-10">
      <header className="flex justify-between items-center h-10 px-4 pt-6 pb-2">
        <div>
          <p className="text-[#79747E] font-[400] font-[Poppins] text-[12px]">
            {formattedDate}
          </p>
          <h1 className="text-[#3F3F3F] font-[600] font-[Poppins] text-[18px]">
            {loginData && loginData.first_name ? (
              <span>
                {" "}
                <span className="text-[#3D3D3D] font-[400] pr-1">Welcome</span>
                {loginData.first_name} {loginData.last_name}
              </span>
            ) : signupData && signupData.sign_up_details.firstName ? (
              `Welcome ${signupData.sign_up_details.firstName} ${signupData.sign_up_details.lastName}`
            ) : (
              "Welcome"
            )}
          </h1>
        </div>
      </header>
      <div className="grid grid-cols-1 px-4 md:grid-cols-3 gap-6 mt-1">
        <div className="col-span-2">
          <div className="bg-white shadow-custom rounded-lg p-4 mt-4 min-h-[72vh]">
            {tableData1?.length !== 0 ? (
              <div className="min-h-[calc(36vh-28px)]">
                <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] pb-4">
                  On Leave Today ({leaveCount})
                </h2>

                <div className="grid grid-cols-4 gap-4">
                  {displayLeave?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user.User.profile_image || avatar}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                          {user?.User?.firstName} {user.User?.lastName}
                        </p>
                        <p className="text-[#49454F] font-[500] text-[12px] font-[Poppins] text-center">
                          {user?.leave_session}
                        </p>
                      </div>
                    </div>
                  ))}
                  {tableData1 && tableData1?.length > 7 ? (
                    <div className="w-full flex justify-center items-start">
                      <img
                        src={viewAll}
                        alt="view all button"
                        className="cursor-pointer h-12 w-12"
                        onClick={() => setLeave(true)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div
              className={`${tableData1?.length === 0 ? "" : "mt-6"}`}
              style={{ minHeight: "calc(36vh - 28px)" }}
            >
              <h2 className="text-[#1D1A22] font-[500] text-[20px] pb-4 font-[Poppins]">
                Remotely Available ({wfhCount})
              </h2>

              {tableData?.length !== 0 ? (
                <div className="grid grid-cols-4 gap-4">
                  {displayWFH?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user.User.profile_image || avatar}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                          {user.User.firstName} {user.User.lastName}
                        </p>
                        <p className="text-[#49454F] font-[500] text-[12px] font-[Poppins] text-center">
                          {user.wfh_session}
                        </p>
                      </div>
                    </div>
                  ))}
                  {tableData && tableData?.length > 7 ? (
                    <div className="w-full flex justify-center items-start">
                      <img
                        src={viewAll}
                        alt="view all button"
                        className="cursor-pointer h-12 w-12"
                        onClick={() => setWfh(true)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="h-[calc(100% - 30px)] w-full flex justify-center items-center">
                  <img src={noResult} alt="No results" />
                </div>
              )}
            </div>
            <div className="mt-6" style={{ minHeight: "calc(36vh - 28px)" }}>
              <h2 className="text-[#1D1A22] font-[500] text-[20px] pb-4 font-[Poppins]">
                Available In Office ({wfoCount})
              </h2>

              {tableData4?.length !== 0 ? (
                <div className="grid grid-cols-4 gap-4">
                  {displayWFO?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user.User.profile_image || avatar}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                        {user.User.firstName} {user.User.lastName}
                      </p>
                    </div>
                  ))}
                  {tableData4 && tableData4?.length > 7 ? (
                    <div className="w-full flex justify-center items-start">
                      <img
                        src={viewAll}
                        alt="view all button"
                        className="cursor-pointer h-12 w-12"
                        onClick={() => setWfo(true)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="h-[calc(100% - 30px)] w-full flex justify-center items-center">
                  <img src={noResult} alt="No results" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="min-h-[72vh] mt-4">
          <div className="bg-white shadow-custom rounded-lg p-4 min-h-[46vh]">
            <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] mb-4">
              Activities
            </h2>
            {tableData3?.length !== 0 ? (
              <div className="flex flex-col">
                <div className="overflow-y-auto scrollBar">
                  {tableData3?.map((item) => (
                    <div className="mb-4 bg-[#e6e2ee] border-s-4 p-2 border-solid border-[#AD8CF1] rounded-[5px] ">
                      <div className="flex justify-between w-full">
                        <p className="text-[#333333] text-[16px] font-[500] font-[Poppins]">
                          {item?.title}
                        </p>
                        <span className="font-[Poppins] font-[400] text-[#605D66] text-[12px]">
                          {item?.date}
                        </span>
                      </div>
                      <p className="font-[Poppins] font-[400] text-[#605D66] text-[12px]">
                        {item?.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center h-48">
                <img src={noResult} alt="No results" />
              </div>
            )}
          </div>
          <div className="bg-white shadow-custom rounded-lg p-4 mt-4 min-h-[24vh]">
            <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] mb-4">
              Birthdays & Anniversaries
            </h2>
            {tableData2?.length !== 0 ? (
              <div className="flex flex-col gap-2 pt-2">
                {tableData2?.map((item) => (
                  <div className="viewBackground p-1.5 rounded-md">
                    <p className="text-[#333333] font-[500] font-[Poppins] text-[16px]">
                      {item?.firstName} {item?.lastName}'s
                    </p>
                    <p className="text-[#8F8F8F] font-[400] font-[Poppins] text-[12px]">
                      {item?.type}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="h-full w-full flex justify-center items-center pt-2">
                <img src={noResult} alt="No results" className="h-20" />
              </div>
            )}
          </div>
        </div>
      </div>
      <ShowWfhModal isOpen={wfh} onClose={() => setWfh(false)} />
      <ShowLeaveModal isOpen={leave} onClose={() => setLeave(false)} />
      <ShowWfoModal isOpen={wfo} onClose={() => setWfo(false)} />
    </div>
  );
};

export default Dashboard;
