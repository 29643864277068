import { TextField, Box, Autocomplete, Popper, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Fetch_Employee_List_List_Management } from "../../redux/actions/Employee/Employee";
import {
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import profile from "../../assets/Ellipse 580.png";

interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  employee_id: string;
  profile_image: string;
}

interface EmployeeSearchProps {
  onEmployeeSelect: (employeeIds: string[]) => void;
  onEmployeeSelectId: (user_Ids: string[]) => void;
}

const EmployeeSearch: React.FC<EmployeeSearchProps> = ({
  onEmployeeSelect,
  onEmployeeSelectId,
}) => {
  const employee = useSelector((state: any) => state.emplist?.EmployeeData);
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const dispatch = useDispatch();

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  useEffect(() => {
    dispatch<any>(
      Fetch_Employee_List_List_Management(customer_id, 200, 1, searchQuery)
    );
  }, [dispatch, customer_id, searchQuery]);

  const handleOptionChange = (event: any, newValue: Employee[] | null) => {
    setSelectedEmployees(newValue || []);
    onEmployeeSelect((newValue || []).map((emp) => emp.employee_id));
    onEmployeeSelectId((newValue || []).map((emp) => emp.id));
  };

  const handleDelete = (employeeToDelete: Employee) => () => {
    const updatedSelectedEmployees = selectedEmployees.filter(
      (emp) => emp.id !== employeeToDelete.id
    );
    setSelectedEmployees(updatedSelectedEmployees);
    onEmployeeSelect(updatedSelectedEmployees.map((emp) => emp.employee_id));
    onEmployeeSelectId(updatedSelectedEmployees.map((emp) => emp.id));
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="employee-search"
        options={employee}
        limitTags={2}
        value={selectedEmployees}
        getOptionLabel={(option: Employee) =>
          `${option.firstName} ${option.lastName} (${option.employee_id})`
        }
        filterSelectedOptions
        onChange={handleOptionChange}
        inputValue={searchQuery}
        disableCloseOnSelect
        onInputChange={(event, newInputValue) => setSearchQuery(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            required
            label="Policy Approvers"
            placeholder={
              selectedEmployees.length === 0
                ? "Search with Employee name & ID"
                : ""
            }
          />
        )}
        sx={{
          "& .MuiOutlinedInput-root": {
            paddingRight: "56px !important",
          },
          "& .MuiAutocomplete-tag": {
            maxWidth: "140px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },

          "&:focus-within .MuiAutocomplete-tag": {
            maxWidth: "none",
            overflow: "visible",
            whiteSpace: "normal",
            textOverflow: "clip",
          },
        }}
        renderOption={(props, option: Employee) => (
          <li
            {...props}
            className="flex items-center  p-2 mx-2 mb-2 bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg"
          >
            <div>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "sans-serif",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {option.firstName} {option.lastName}
              </Typography>
              <Typography className="text-gray-600">
                {option.employee_id}
              </Typography>
            </div>
          </li>
        )}
      />
    </div>
  );
};

export default EmployeeSearch;
