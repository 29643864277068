import { format } from "date-fns";
import action from "../../assets/tableActionIcon.svg";
import { useNavigate } from "react-router-dom";
import { SELECTED_VIEW_GROUP_DETAILS } from "../../utils/routeConstant";

interface RenderChildrenrProps {
  children: any[];
  handleEdit: (id: number) => void;
  depth: number;
}

const RenderChildren: React.FC<RenderChildrenrProps> = ({
  handleEdit,
  children,
  depth,
}) => {
  const navigate = useNavigate();

  if (!children || children.length === 0) {
    return null;
  }

  let paddingLeft = "";
  let labelWidth = "";
  if (depth === 1) {
    paddingLeft = "pl-[2.5rem]";
    labelWidth = "w-[18.8rem]";
  } else if (depth === 2) {
    paddingLeft = "pl-[3.5rem]";
    labelWidth = "w-[17.8rem]";
  } else if (depth === 3) {
    paddingLeft = "pl-[4.5rem]";
    labelWidth = "w-[16.8rem]";
  } else if (depth === 4) {
    paddingLeft = "pl-[5.5rem]";
    labelWidth = "w-[15.8rem]";
  } else if (depth === 5) {
    paddingLeft = "pl-[6.5rem]";
    labelWidth = "w-[15.8rem]";
  }

  // Dynamically construct the class name with calculated left padding
  const cssClass = `w-full h-[52px] flex pl-5 py-1 ${paddingLeft} items-center gap-8 border-[0.6px] border-solid border-[#E0E3E7] justify-between`;

  return (
    <>
      {children.map((item: any) => {
        const fill =
          // item.status === "Group"
          //   ? "#00CBA0"
          //   : item.status === "inactive"
          //   ? "#FF9950"
          //   : "#FA1464";
            item.type === "Group"
            ? "red"
            : item.type === "Department"
            ? "purple"
            : "green";
        return (
          <>
            <tr
              className={cssClass}
              onClick={() => {
                handleEdit(item.id);
              }}
            >
              <td className={labelWidth}>
                <div className="flex flex-row items-center gap-1 shrink-0 ]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                  >
                    <circle cx="5.5" cy="5.35156" r="5" fill={fill} />
                  </svg>
                  {item.group_name && (
                    <span className="text-[#262626] shrink-0 font-[Poppins] text-[11px] leading-[13.981px] font-medium overflow-hidden w-fit overflow-ellipsis text-left">
                      {item.group_name}
                    </span>
                  )}
                </div>
              </td>
              <td className="w-[5rem]">
                <div className="flex items-center">
                  <div className="flex flex-row items-center gap-1 shrink-0">
                    <p className="text-[#262626] shrink-0 font-[Poppins] text-[11px] leading-[13.981px] font-medium text-left w-fit">
                      {item.createdAt
                        ? format(item.createdAt, "dd/MM/yyyy")
                        : ""}
                    </p>
                  </div>
                </div>
              </td>
              <td className="w-[6rem]">
                <div className="flex items-center">
                  <div className="flex flex-row items-center gap-1 shrink-0">
                    <p className="text-[#262626] shrink-0 font-[Poppins] text-[11px] leading-[13.981px] font-medium text-left w-fit">
                      {item.updatedAt
                        ? format(item.updatedAt, "dd/MM/yyyy")
                        : ""}
                    </p>
                  </div>
                </div>
              </td>
              <td className="w-[6rem]">
                <div className="flex items-center">
                  <div className="flex flex-row items-center gap-1 shrink-0">
                      <p className="text-[#262626] shrink-0 font-[Poppins] text-[11px] leading-[13.981px] font-medium w-fit whitespace-nowrap overflow-hidden overflow-ellipsis text-left">
                        {item.total_users || "-"}
                      </p>
                  </div>
                </div>
              </td>
              <td className="w-[4rem]">
                <button
                  className="align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none"
                  type="button"
                  onClick={() => {
                    navigate(`${SELECTED_VIEW_GROUP_DETAILS}`, {
                      state: item,
                    });
                  }}
                >
                  <img
                    src={action}
                    alt="Table Action Icon"
                    className="h-6 w-6"
                  />
                </button>
              </td>
            </tr>
            <RenderChildren
              handleEdit={handleEdit}
              children={item.children}
              depth={depth + 1}
            />
          </>
        );
      })}
    </>
  );
};

export default RenderChildren;
