import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface SignupState {
  details: SignupDetailsType | null;
  // access_token: string;
}
interface SignupDetailsType {
  signup_token: string | null;
  sign_up_details: {} | null;
  organisation_details: {} | null;
}
const initialState: SignupState = {
  details: null,
  // access_token: "",
};
const signupSlice = createSlice({
  name: "signup",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setSignupDetails(state, action: PayloadAction<SignupDetailsType>) {
      state.details = action.payload;
    },
    // setAccessToken(state, action: PayloadAction<string>) {
    //   state.access_token = action.payload;
    // },
    setOrganisationDetails(state, action: PayloadAction<{}>) {
      if (state.details) {
        state.details.organisation_details = action.payload;
      }
    },
  },
});

export const signupActions = signupSlice.actions;
export default signupSlice.reducer;
