// import React from 'react'

// export const ViewClient = () => {
//   return (
//     <div>ViewClient</div>
//   )
// }

import React, { useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import check from "../../image/checkmark-circle-02.svg";
import {
  approveRejectApi,
  fetchEditEmployeeExpense,
} from "../common/services/EmployeeExpense";
import toast from "react-hot-toast";
import { fetchEditClientList } from "../common/services/Clients";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface ExpenseData {
  client_name: string;
  email: string;
  country: string;
  state: string;
  address: string;
  phone_no: string;
  point_of_contact: string;
  status: string;
}

const ViewClient = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigate("/expense-management");
  };

  const [ExpenseData, setExpenseData] = useState<ExpenseData>({
    client_name: "",
    email: "",
    country: "",
    state: "",
    address: "",
    phone_no: "",
    point_of_contact: "",
    status: "",
  });

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    // setLoading(true);
    console.log("edit check");
    fetchEditClientList(id)
      .then((data) => {
        // console.log(data.result.rows[0].User.Department.name, "data--res----edit expense--->>>>");
        if (data.result) {
          // setUpdateData(data);
          const phonevalue = data.result.phone_code + " " + data.result.phone;
          console.log(
            data.result.state.id,
            "data.result.state.iddata.result.state.id"
          );

          setExpenseData({
            client_name: data.result.client_name,
            email: data.result.email,
            country: data.result.country.country_name,
            state: data.result.state.state_name,
            address: data.result.address,
            phone_no: data.result.phone,
            point_of_contact: data.result.point_of_contact,
            status: data.result.status,
          });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {status === "approved"
              ? "Your Request has been Approved"
              : "Your Request has been Rejected"}
          </Typography>
        </Box>
      </Modal>

      <div className="bg-[#FBFBFB] py-8 px-[18.5px]">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/client")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Client Details
            </span>
          </div>
        </div>

        <div className="p-6 bg-white rounded-lg mt-6 shadow-md">
          <h2 className="text-lg font-semibold mb-4">Client Details</h2>
          <div
            className=" bg-gradient-to-r p-4 rounded-md"
            style={{
              background:
                "linear-gradient(90deg, rgba(65, 101, 196, 0.05) 0%, rgba(142, 31, 210, 0.05) 100%)",
            }}
          >
            <div className="grid grid-cols-3 p-1 gap-4 border-b border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Client Name
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {ExpenseData.client_name || "--"}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Email ID
                </strong>
                <br />{" "}
                <span className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {ExpenseData.email || "--"}
                </span>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Country
                </strong>
                <br />
                <span className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {ExpenseData.country || "--"}
                </span>
              </p>
            </div>
            <div className="grid grid-cols-3 p-2 gap-4 border-b border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  State
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {ExpenseData.state || "--"}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Address
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {ExpenseData.address || "--"}
                </strong>
              </p>
            </div>
            <div className="grid grid-cols-3 p-2 gap-4 border-b border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Phone No
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {ExpenseData.phone_no || "--"}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Point Of Contact
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {ExpenseData.point_of_contact || "--"}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Status
                </strong>
                <br />
                <strong className="text-[#1D1A22] capitalize font-['Poppins'] font-[500] text-[16px] ">
                  <span
                    className={`bg-[#6BC0E44A] capitalize ${
                      ExpenseData?.status === "active"
                        ? "bg-[#D4F8C7]"
                        : ExpenseData?.status === "inactive"
                        ? "bg-[#FFC0767A]"
                        : ExpenseData?.status === "hold"
                        ? "bg-[#F8716A7A]"
                        : ExpenseData?.status === "archived"
                        ? "bg-[#4DB3DF]"
                        : "bg-[#D3D3D3]"
                    } px-2 py-1`}
                  >
                    {" "}
                    {ExpenseData.status || "--"}
                  </span>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewClient;
