import React, { useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Initialization from "./Initialization";
import ConfigureManagers from "./ConfigureManagers";
import ConfigureParameters from "./ConfigureParameters";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: "24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const CustomTabs: React.FC = () => {
  const [value, setValue] = React.useState(0);

  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    if (
      location.pathname === "/add-appraisal" ||
      location.pathname === "/initialization"
    ) {
      setValue(0);
    } else if (location.pathname === "/configure-managers") {
      setValue(1);
    } else if (location.pathname === "/configure-parameters") {
      setValue(2);
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      history("/add-project");
    } else if (newValue === 1) {
      history("/configure-managers");
    } else if (newValue === 2) {
      history("/configure-parameters");
    }
  };

  const Label = ({ number, title, description, isSelected }: any, style: string) => {
    return (
      <div className="w-full h-full py-4">
        <div className="h-full flex flex-col gap-6 justify-between text-left">
          <div className="flex flex-col">
            <span
              className={`font-[Poppins] font-semibold text-[17px] leading-[25.5px] ${
                isSelected ? "text-[#FFFFFF]" : "text-[#322F37]"
              } normal-case`}
            >
              {title}
            </span>
            <span
              className={`font-[Poppins] font-medium text-[10px] leading-[15px] ${
                isSelected ? "text-[#EDEDED]" : "text-[#322F37]"
              } normal-case`}
            >
              {description}
            </span>
          </div>
          <span
            className={`font-[Poppins] font-medium text-[17px] leading-[25.5px] border-b-2 ${
              isSelected
                ? "text-[#EDEDED] border-[#9CC4FF]"
                : "text-[#322F37] border-[#A0C6FF]"
            } normal-case`}
          >
            Step - {number}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-[90vh] w-full px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <Box>
        <Tabs
          value={value}
          // onChange={handleChange}
          aria-label="full width tabs example"
          variant="fullWidth"
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .Mui-selected": {
              background: "linear-gradient(90deg, #4165c4 0%, #8e1fd2 100%);",
              color: "#fff",
            },
            background: "#E8E0FF",
            borderRadius: "6px",
          }}
        >
          <Tab
            label={
              <Label
                number="1"
                title="Initialization"
                description="Initialize appraisal for a department or a business unit and enable to managers or employees"
                isSelected={value === 0}
              />
            }
            {...a11yProps(0)}
            className="text-lg"
          />
          <Tab
            label={
              <Label
                number="2"
                title="Configure Line Managers"
                description="Configure line managers by using the existing organization hierarchy or by assigning line managers to employees manually"
                isSelected={value === 1}
              />
            }
            {...a11yProps(1)}
            className="text-lg"
          />
          <Tab
            label={
              <Label
                number="3"
                title="Configure Appraisal Parameters"
                description="Set the appraisal parameters for all the employees or for an employee group for a department or a business unit"
                isSelected={value === 2}
              />
            }
            {...a11yProps(2)}
            className="text-lg"
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Initialization />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ConfigureManagers />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ConfigureParameters />
        </TabPanel>
      </Box>
    </div>
  );
};

export default CustomTabs;
