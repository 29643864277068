import { Skeleton, Stack } from "@mui/material";
import React from "react";

const TableSkeleton = () => {
  return (
    <div className="mt-4">
      <Stack spacing={1} className="mt-4">
        <Skeleton variant="rounded" width="55%" height={25} />
        <Skeleton variant="rounded" width="95%" height={12} />
        <Skeleton variant="rounded" width="85%" height={12} />
        <Skeleton variant="rounded" width="75%" height={12} />
        <Skeleton variant="rounded" width="75%" height={12} />
        <Skeleton variant="rounded" width="60%" height={20} />
        <Skeleton variant="rounded" width="40%" height={12} />
        <Skeleton variant="rounded" width="65%" height={12} />
        <Skeleton variant="rounded" width="95%" height={12} />
        <Skeleton variant="rounded" width="95%" height={12} />
      </Stack>
    </div>
  );
};

export default TableSkeleton;
