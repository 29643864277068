// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: collapse;
  width: 100%;
  font-family: Poppins;
}

th,
td {
  padding: 0.5rem;
  text-align: left;
}

thead {
  /* background-color: #E1EE1; */
  background: linear-gradient(90deg, rgba(65, 101, 196, 0.2) 0%, rgba(142, 31, 210, 0.2) 100%);
}
thead th{
  font-size: 14px;
font-style: normal;
font-weight: 500;
}

tbody tr {
  border-bottom: 1px solid #e0e3e7;
}

tbody tr:last-child {
  border-bottom: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmployeeManagement/table.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,oBAAoB;AACtB;;AAEA;;EAEE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;EAC9B,4FAA4F;AAC9F;AACA;EACE,eAAe;AACjB,kBAAkB;AAClB,gBAAgB;AAChB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["table {\n  border-collapse: collapse;\n  width: 100%;\n  font-family: Poppins;\n}\n\nth,\ntd {\n  padding: 0.5rem;\n  text-align: left;\n}\n\nthead {\n  /* background-color: #E1EE1; */\n  background: linear-gradient(90deg, rgba(65, 101, 196, 0.2) 0%, rgba(142, 31, 210, 0.2) 100%);\n}\nthead th{\n  font-size: 14px;\nfont-style: normal;\nfont-weight: 500;\n}\n\ntbody tr {\n  border-bottom: 1px solid #e0e3e7;\n}\n\ntbody tr:last-child {\n  border-bottom: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
