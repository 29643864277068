import React, { useEffect, useState } from "react";

import profile from "../../assets/Ellipse 580.png";

import TablePaginationDemo from "../common/Pagenation";
import { useLocation } from "react-router-dom";
import { Fetch_Project_Task_Resource_List } from "../common/services/ProjectReport";

interface Project {
  id: number;
  project_name: string;
  start_date: string;
  end_date: string;
}

interface User {
  user_id: number;
  employee_id: string;
  name: string;
  duration: number;
  profile_image: string;
}

interface Task {
  task_id: number;
  task_name: string;
  total_task_hour: number;
  users: User[];
}

interface ProjectData {
  project: Project[];
  tasks: Task[];
}

function ProjectTaskAndResources({
  search,
  startDate,
  endDate,
}: {
  search: string;
  startDate: Date | null;
  endDate: Date | null;
}) {
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const id = location.state?.id;
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Task[]>([]);

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  useEffect(() => {
    setLoading(true);
    Fetch_Project_Task_Resource_List({
      id,
      search,
      startDate,
      endDate,
    })
      .then((data) => {
        if (data?.result) {
          setTableData(data?.result?.tasks);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [endDate, id, search, startDate]);

  return (
    <div className="pt-4 flex flex-col gap-4">
      <div className="flex flex-col gap-3">
        <span className="font-[Poppins] font-semibold text-base text-[#1D1A22]">
          Tasks & Resources
        </span>
        {!loading && tableData?.length === 0 ? (
          <div className="w-full flex justify-center items-center h-10">
            No Resources Found
          </div>
        ) : null}
        <div className="flex flex-col gap-3">
          {tableData?.map((task) => (
            <div className="p-5 card-shadow rounded-md border-l-4 border-[#AD8CF1] flex flex-col gap-5 bg-white">
              <div className="flex justify-between items-center">
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22] w-[74%]">
                  {task?.task_name}
                </span>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  {task?.total_task_hour}{" "}
                  {task?.total_task_hour > 1 ? "Hrs" : "Hr"}
                </span>
              </div>
              <div className="grid grid-cols-3 gap-x-5 gap-y-2.5 custom:grid-cols-2">
                {task?.users?.map((user) => (
                  <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                    <div className="h-[40px] flex items-center gap-2.5">
                      <div className="h-full w-[40px] rounded-full">
                        <img
                          src={user?.profile_image || profile}
                          alt="profile"
                          className="h-full w-full rounded-full"
                        />
                      </div>
                      <div className="h-full flex flex-col justify-center">
                        <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                          {user?.name}
                        </span>
                        <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                          {user?.employee_id}
                        </span>
                      </div>
                    </div>
                    <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                      {user?.duration} {user?.duration > 1 ? "Hrs" : "Hr"}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>
    </div>
  );
}

export default ProjectTaskAndResources;
