import React, { useCallback, useEffect, useState, useRef } from "react";
import TablePaginationDemo from "../../common/Pagenation";
import edit from "../../../assets/Crud_Icons/edit.svg";
import { useNavigate } from "react-router-dom";
import { Fetch_Leave_Type_List } from "../../common/services/Leave";
import { debounce } from "lodash";
import Search from "../../SearchBar/Search";

interface Leave_Type {
  id: string;
  leave_type_name: string;
  allotted_leaves: string;
}

const LeaveType = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectLeaveType, setSelectLeaveType] = useState<Leave_Type[]>();
  const [searchTerm, setSearchTerm] = useState("");

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Leave_Type_List(
        searchTerm,
        numOfItems,
        page
      );
      if (response.status === 200) {
        setSelectLeaveType(response.data?.result?.rows);

        setCount(response.data.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };
  
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    fetchLeaveType();
  }, [page, searchTerm, startSession, endSession]);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }
  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000), // delay in ms
    [setSearchTerm]
  );

  return (
    <>
      <div className="mt-8">
        <Search onChange={updateSearchTerm} label="Search with Leave Type" />
      </div>

      <div className="overflow-x-auto mt-4">
        <table className="w-full min-w-max text-left table-auto border-[0.6px] bg-white border-[#E0E3E7]">
          <thead className="bg-gray-100">
            <tr className="h-11">
              <th className="px-6 py-2.5 w-1/3 text-[14px] text-[#1D1A22]">
                Leave Type
              </th>
              <th className="px-6 py-2.5 w-1/3 text-[14px] text-[#1D1A22] text-center">
                Total Leave
              </th>
              <th className="px-6 py-2.5 text-[14px] text-[#1D1A22] flex justify-end">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {selectLeaveType?.map((item, index) => (
              <tr
                key={index}
                className="h-10 border-b-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100"
              >
                <td className="text-[#3F4254] font-[500] text-[12px] px-6 py-1 w-1/3">
                  {item.leave_type_name}
                </td>
                <td className="text-[#3F4254] px-6 font-[500] text-[12px] py-1 w-1/3 text-center">
                  {String(item.allotted_leaves).trim()}
                </td>
                <td className="px-6 py-1 w-1/3">
                  <div className="flex justify-end items-center">
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      src={edit}
                      onClick={() =>
                        navigate(`/edit-leave-type/${item.id}`, {
                          state: { id: item.id },
                        })
                      }
                      alt="Edit"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
    </>
  );
};

export default LeaveType;
