import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import toast from "react-hot-toast";
import axios from "axios";
import { updateResources } from "../common/services/Project";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  employeeId: string;
}

interface EmployeeData {
  name: string;
  id: string;
  billableCost: string;
  actualCost: string;
}

interface Resources_Data {
  employee_name: string;
  employee_id: string;
  billable_cost: string;
  actual_cost: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, employeeId }) => {
  const [employeeData, setEmployeeData] = useState<EmployeeData | null>(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const id = location.state?.id;
  console.log(employeeId, "check---id---modal edit---->>>");
  console.log(id, "check---idididid---modal edit---->>>");

  const [resourcesData, setResourcesData] = useState<Resources_Data>({
    employee_name: "",
    employee_id: "",
    actual_cost: "",
    billable_cost: "",
  });

  const [errors, setErrors] = useState<Partial<Resources_Data>>({})

  const validate = (): boolean => {
    let tempErrors: Partial<Resources_Data> = {}
    if(resourcesData.employee_name === "")
      tempErrors.employee_name = "Employee Name is required"
    if(resourcesData.employee_id === "")
      tempErrors.employee_id = "employee Id is required"
    if(resourcesData.billable_cost === "")
      tempErrors.billable_cost = "Billable cost is required"
    if(resourcesData.actual_cost === "")
      tempErrors.actual_cost = "Actual Cost is required"

    setErrors(tempErrors)
    return Object.keys(tempErrors).length === 0;
  }

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setResourcesData({
      ...resourcesData,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } 
  };

  useEffect(() => {
    if (isOpen && employeeId) {
      // Fetch data from API

      const access_token = localStorage.getItem("access_token");
      fetch(
        `${process.env.REACT_APP_BASE_API}/api/v1/project-resources/${employeeId}`,
        {
          headers: {
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data.result, "data-resources-modal----->>>>>>>>>>>");

          setEmployeeData(data.result);

          setResourcesData({
            employee_name:
              data.result.User.firstName + " " + data.result.User.lastName,
            employee_id: data.result.User.employee_id,
            billable_cost: data.result?.billing_cost,
            actual_cost: data.result?.actual_cost,
          });
        })
        .catch((error) =>
          console.error("Error fetching employee data:", error)
        );
    }
  }, [isOpen, employeeId]);

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    // console.log("Request body:", body); // Print the body

    setLoading(true);
    try {
      const data = await updateResources(
        employeeId,
        Number(resourcesData.billable_cost),
        Number(resourcesData.actual_cost)
      );

      if (data && data.result) {
        console.log("final data:", data);
        onClose();
        setTimeout(() => {
          onClose();
          //navigate(`${ROLE_MANAGEMENT}`);
          // navigate(`add-task/${projectID}`);
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };
  const handleUpdate = (event: React.FormEvent) => {
    event.preventDefault();
    let res = validate();
    res &&
    submitChanges();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold py-4">Edit Resource Details</h2>
        <div>
          <div className="container mx-auto p-4">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="grid grid-cols-2 gap-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Employee Name"
                  name="employee_name"
                  value={resourcesData.employee_name}
                  onChange={handleFrormChange}
                  error={!!errors.employee_name}
                  helperText={errors.employee_name}
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Employee Id"
                  name="employee_id"
                  value={resourcesData.employee_id}
                  onChange={handleFrormChange}
                  error={!!errors.employee_id}
                  helperText={errors.employee_id}
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Billable Cost/hr (Dollar)"
                  name="actual_cost"
                  value={resourcesData.actual_cost}
                  onChange={handleFrormChange}
                  error={!!errors.actual_cost}
                  helperText={errors.actual_cost}
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Actual Cost/hr (Dollar)"
                  name="billable_cost"
                  value={resourcesData.billable_cost}
                  onChange={handleFrormChange}
                  error={!!errors.billable_cost}
                  helperText={errors.billable_cost}
                />
              </div>

              <div className="flex justify-end py-4">
                <button
                  className="mr-2 borderButton text borderButton px-4 py-2 rounded"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className=" text-white px-4 py-2 buttoncss rounded-lg"
                  onClick={(e) => handleUpdate(e)}
                >
                  Update
                </button>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
