import React, { useRef } from "react";

interface SuccessProps {
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessProps> = ({ onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  //? feature to close modal when mouse clicked outside
  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (
  //       modalRef.current &&
  //       !modalRef.current.contains(event.target as Node)
  //     ) {
  //       onClose(); // Close the modal if clicked outside
  //     }
  //   };

  //   // Attach the event listener when the component mounts
  //   document.addEventListener("mousedown", handleClickOutside);

  //   // Detach the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [onClose]);

  return (
    <div
      className="w-[463px] h-[267px] bg-white rounded-[14px] flex flex-col justify-center items-center"
      ref={modalRef}
    >
      <div className="w-[301px] text-center text-slate-800 text-xl font-semibold font-['Poppins']">A member of our team will contact you within 24 hours.</div>
      <div className="flex flex-col items-center shrink-0 justify-center gap-[23px] mt-[30px]">
      <button className="text-indigo-600 text-base font-medium font-['Poppins']" onClick={onClose}>Dismiss</button>
      </div>
    </div>
  );
};

export default SuccessModal;
