import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import upload from "../../assets/plus 1.png";
//import Project from "./Project";
//import Clients from "./Clients";
import "../EmployeeManagement/employee.css";
import Projects from "./Projects";
import Clients from "./Clients";
import maskPlus from "../../image/Mask group.svg";

const tabp={
  padding:"0px"
}


export const ProjectManagement = () => {
  const history = useNavigate();
  const [value, setValue] = React.useState<string>("1");
  const AddEmployee = () => {
    history("/add-employee");
  };

  //const [value, setValue] = useState("1");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/project-management") {
      setValue("1");
    } else if (location.pathname === "/client") {
      setValue("2");
    }
  }, [location.pathname]);

  // const handleChange = (event: any, newValue: any) => {
  //   setValue(newValue);
  // };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "1") {
      history("/project-management");
    } else if (newValue === "2") {
      history("/client");
    }
  };
  return (
    <>
      <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
        <div className="">
          <Box>
            <TabContext value={value}>
              <div className="flex justify-between">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      "& .MuiTab-root": {
                        color: "#89888E",
                        fontFamily:"poppins"
                      },
                      "& .Mui-selected": {
                        color: "#000000 !important",
                        fontFamily:"poppins"
                      },
                    }}
                  >
                    <Tab className="text-[#89888E]" label="Project" value="1" />
                    <Tab label="Client" value="2" />
                  </TabList>
                </Box>

                {value === "1" ? (
                  <div>
                    <button
                      onClick={() => history("/add-project")}
                      className="flex font-[Poppins] items-center font-[600] text-[14px] px-4 buttoncss text-white rounded-lg h-12"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Project
                    </button>
                  </div>
                ) : value === "2" ? (
                  <div>
                    <button
                      onClick={() => history("/add-clients")}
                      className="flex items-center font-[Poppins] font-[600] text-[14px] px-4 buttoncss text-white rounded-lg h-12"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Client
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <TabPanel className="tabpanel" value="1">
                <Projects />
              </TabPanel>
              <TabPanel className="tabpanel" value="2">
                <Clients />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
};
