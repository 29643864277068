import { Dayjs } from "dayjs";
import {
  FETCH_COMPANY_DETAILS,
  FETCH_POLICY_CATEGORY_LIST,
  FETCH_POLICY_LIST_LIST,
} from "../api";
import axios from "axios";
import { api_key_work as api_key, base_api } from "../../../utils/helperData";

export const Fetch_Company_Details_List = () => {
  const access_token = localStorage.getItem("access_token");
  return axios.get(`${FETCH_COMPANY_DETAILS}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const updateCompanyDetails = async (
  id: string,
  name: string | null,
  email: string | null,
  address: string | null,
  business_size: String | null,
  start_date: string | null | Dayjs,
  industry_vertical: number | null,
  city: string | null,
  state: string | null,
  postal_code: string | null,
  country: string | null,
  website: string | null,
  logo: string
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    name: name,
    email: email,
    address: address,
    business_size: business_size,
    start_date: start_date,
    industry_vertical: industry_vertical,
    city: city,
    state: state,
    postal_code: postal_code,
    country: country,
    website: website,
    logo: logo,
  };

  try {
    const response = await axios.patch(
      `${base_api}/api/v1/company/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createPolicy = async (
  // designation: string,
  name: string,
  policyCategory_id: string,
  group_id: string,
  department_id: number[],
  description: string,
  recurrence: string,
  version_no: string,
  approver_id: string[],
  effective_date: string | null | Dayjs,
  document_url: string[]
  //resourceList: number[]
) => {
  const access_token = localStorage.getItem("access_token");
  const data: {
    name: string;
    policyCategory_id: number;
    group_id: number;
    description: string;
    recurrence: string;
    version_no: number;
    approver_ids: string[];
    effective_date: string | Dayjs | null;
    document_url: string[] | [];
    department_id?: number[] | [];
  } = {
    //name: designation,
    name: name,
    policyCategory_id: Number(policyCategory_id),
    group_id: Number(group_id),
    description: description,
    recurrence: recurrence,
    version_no: Number(version_no),
    approver_ids: approver_id,
    effective_date: effective_date,
    document_url: document_url,
    //resource_list: resourceList,
  };
  if (department_id?.length > 0) {
    data.department_id = department_id;
  }
  try {
    const response = await axios.post(`${base_api}/api/v1/policy`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const createPolicyCategory = async (
  // designation: string,
  name: string,
  description: string
  //resourceList: number[]
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    //name: designation,
    name: name,
    description: description,
    //resource_list: resourceList,
  };

  try {
    const response = await axios.post(
      `${base_api}/api/v1/policy-category`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Policy_Category_List = (
  customer_id: number | undefined,
  searchTerm: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axios.get(
    `${FETCH_POLICY_CATEGORY_LIST}${query}&session_start=${localStorage.getItem(
      "start_session"
    )}&session_end=${localStorage.getItem("end_session")}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const fetchEditPolicyCategory = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axios.get(
      `${base_api}/api/v1/policy-category/${id}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Policy_List = (
  customer_id: number | undefined,
  searchTerm: string,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number
) => {
  const access_token = localStorage.getItem("access_token");
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axios.get(
    `${FETCH_POLICY_LIST_LIST}${query}&session_start=${localStorage.getItem(
      "start_session"
    )}&session_end=${localStorage.getItem("end_session")}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const fetchEditPolicy = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axios.get(`${base_api}/api/v1/policy/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updatePolicy = async (
  id: string,
  name: string,
  policyCategory_id: string,
  group_id: string,
  department_id: string,
  description: string,
  recurrence: string,
  version_no: string,
  reviewer_id: string | null,
  approver_id: string | null,
  effective_date: string | null | Dayjs,
  document_url: string[]
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    name: name,
    policyCategory_id: policyCategory_id,
    group_id: group_id,
    department_id: department_id,
    description: description,
    recurrence: recurrence,
    version_no: version_no,
    reviewer_id: reviewer_id,
    approver_id: approver_id,
    effective_date: effective_date,
    document_url: document_url,
  };

  try {
    const response = await axios.patch(
      `${base_api}/api/v1/policy/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchPolicyDetails = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axios.get(`${base_api}/api/v1/policy/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updatePolicyCategory = async (
  id: number,
  name: string,
  description: string
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    name: name,
    description: description,
  };

  try {
    const response = await axios.patch(
      `${base_api}/api/v1/policy-category/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Company_Details_List_List = () => {
  const access_token = localStorage.getItem("access_token");
  // console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${FETCH_COMPANY_DETAILS}`);
  return fetch(`${FETCH_COMPANY_DETAILS}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

// export const updateCompanyDetails = async (
//   id: string,
//   employee_id: string,
//   reason: string,
//   date_of_exit_initiation: string | Dayjs | null,
//   date_of_leaving: string | Dayjs | null,
//   department_id: string
// ) => {
//   const access_token = localStorage.getItem("access_token");
//   const body = {
//     employee_id: employee_id,
//     reason: reason,
//     date_of_exit_initiation: date_of_exit_initiation,
//     date_of_leaving: date_of_leaving,
//     department_id: department_id,
//   };

//   try {
//     const response = await axios.put(
//       `${base_api}/api/v1/user-exit/${id}`,
//       body,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-api-key": api_key,
//           Authorization: "Bearer " + access_token,
//         },
//       }
//     );

//     return response.data;
//   } catch (error: any) {
//     throw error;
//   }
// };

export const approveRejectApi = async (id: number, status: string) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    status: status,
  };

  try {
    const response = await axios.post(
      `${base_api}/api/v1/policy/approve-reject/${id}`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};
