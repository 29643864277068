import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { revertAll } from '../../ResetRedux/RevertAll';
 
interface PolicyManagementState {
    selectedNavItem: string;
    policyDepartmentData: {
        "count": number,
        "rows": any[],
        "currentPage": number,
        "itemsPerPage": number,
        "isNext": boolean
    };
    departmentListResponse: number[];
    barChartData: any[];
    createdPolicyId: number;
    createdPolicyData: {};
}
 
const initialState: PolicyManagementState = {
    selectedNavItem: "DASHBOARD",
    policyDepartmentData: {
        "count": 0,
        "rows": [],
        "currentPage": 1,
        "itemsPerPage": 10,
        "isNext": true,
    },
    departmentListResponse: [],
    barChartData: [],
    createdPolicyId: 0,
    createdPolicyData: {},
};
 
const dateFormatter = (isoTimestamp: string) => {
    const dateObject = new Date(isoTimestamp);
    const formattedDate = dateObject.toISOString().substring(0, 10);
   
    return formattedDate;
}

const policyManagementSlice = createSlice({
    name: 'policyManagement',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setSelectedNavItem(state, action: PayloadAction<any>){
            state.selectedNavItem = action.payload;
        },
        
        setPolicyDepartmentData(state, action: PayloadAction<any>){
            state.policyDepartmentData.count = action.payload.count;
 
            const updated =  action.payload.rows.map((item: any) => ({
                "id": item.id,
                "name": item.name,
                "parent_id": item.parent_id
            }));

            if(state.policyDepartmentData.isNext){
                state.policyDepartmentData.currentPage++;
                state.policyDepartmentData.rows = [
                    ...state.policyDepartmentData.rows,
                    ...updated
                ]
            }
        
            state.policyDepartmentData.isNext = state.policyDepartmentData.itemsPerPage*(state.policyDepartmentData.currentPage-1) < state.policyDepartmentData.count;   
        },

        setDepartmentListResponse(state, action: PayloadAction<any>){
            state.departmentListResponse = action.payload;
        },

        setBarChartData(state, action: PayloadAction<any>){
            state.barChartData = action.payload.map((item: any) => (
                {
                    "department_name": item.department_name,
                    "status": item.status,
                    "count": Number(item.count)
                }
            ));
        },
        
        setCreatedPolicyId(state, action: PayloadAction<any>){
            state.createdPolicyId = action.payload
        },
        setCreatePolicyData (state, action: PayloadAction<any>){
            state.createdPolicyData = action.payload
        }
    }
});
 
export const policyManagementActions = policyManagementSlice.actions;
export default policyManagementSlice;