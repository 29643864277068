import React from "react";
import { useNavigate } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Rating } from "@mui/material";

import right from "../../image/chevron-right.png";
import profile from "../../assets/Ellipse 580.png";

function ViewAppraisal() {
  const navigate = useNavigate();
  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-12">
        <div className="flex flex-row gap-[13px] justify-start items-center">
          <button onClick={() => navigate("/appraisal-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
            Appraisal details
          </span>
        </div>
        <div className="px-1.5">
          <div className="bg-white rounded-lg shadow-custom p-5">
            <div className=" flex flex-col gap-5">
              <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                Appraisal Details
              </span>
              <div className="px-2.5 py-5 viewBackground rounded-md">
                <div className="grid grid-cols-3 gap-y-[15px]">
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Business Unit
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Appvin, Noida
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Department
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Design
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Year Range
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      2024-25
                    </span>
                  </div>

                  <hr className="col-span-3 border-2 border-[#EDEDED]" />

                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Appraisal Mode
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Quarterly
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Appraisal Period
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Q1
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Appraisal Status
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Open
                    </span>
                  </div>

                  <hr className="col-span-3 border-2 border-[#EDEDED]" />

                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Enable to
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Employee
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employee's Due Date
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      5/08/2024
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Eligibility
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Full Time
                    </span>
                  </div>
                </div>
              </div>
              <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                Rating Details
              </span>
              <div className="px-2.5 py-5 viewBackground rounded-md">
                <div className="grid grid-cols-3 gap-y-[15px]">
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Rating Value "1"
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Unacceptable
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Rating Value "2"
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Needs Improvement
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Rating Value "3"
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Meets Expectations
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Rating Value "4"
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Exceeds Expectations
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Rating Value "5"
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      Best
                    </span>
                  </div>
                  <div></div>

                  <hr className="col-span-3 border-2 border-[#EDEDED]" />

                  <div className="col-span-3 flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Rating Status
                    </span>
                    <div className="grid grid-cols-3">
                      <div className="flex items-center gap-2">
                        <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                          Line Manager -{" "}
                        </span>
                        <span className="flex items-center px-5 py-1 bg-[#FFDECF] max-w-min">
                          <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                            Pending
                          </span>
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                          Line Manager -{" "}
                        </span>
                        <span className="flex items-center px-5 py-1 bg-[#D4F8C7] max-w-min">
                          <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                            Completed
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                Line Manager Details
              </span>
              <div className="px-2.5 py-5 viewBackground rounded-md">
                <div className="grid grid-cols-3 gap-y-[15px]">
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employee Name
                    </span>
                    <div className="flex flex-row gap-[7px] items-center">
                      <div className="h-[31px] w-[31px]">
                        <img
                          src={profile}
                          alt="profile"
                          className="h-full w-full rounded-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                          Brooklyn Simons
                        </span>
                        <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                          EMP0062
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Line Manager 1
                    </span>
                    <div className="flex flex-row gap-[7px] items-center">
                      <div className="h-[31px] w-[31px]">
                        <img
                          src={profile}
                          alt="profile"
                          className="h-full w-full rounded-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                          Brooklyn Simons
                        </span>
                        <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                          EMP0062
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 5">
                <div className="py-[5px]">
                  <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                    Parameter Details
                  </span>
                </div>
                <div className="overflow-x-auto overflow-y-auto shadow-sm">
                  <table className="w-full min-w-max text-left table-auto bg-white">
                    <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                      <tr className="h-11 w-full">
                        <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-5">
                          Questions
                        </th>
                        <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] pl-5">
                          Comment & Rating Access to
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border border-[#EBEBEB]">
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5 font-[Poppins] font-medium text-[14px] leading-[21px] text-[#49454F]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do?
                        </td>
                        <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] pl-5">
                          <div className="grid grid-cols-2 ">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked
                                  disabled
                                  sx={{
                                    color: "#1976d2", // Custom color for the checkbox
                                    "&.Mui-disabled": {
                                      color: "#000", // Keep the color of the checkbox even when disabled
                                    },
                                    "&.Mui-checked.Mui-disabled": {
                                      color: "#1976d2", // Keep the checked state color when disabled
                                    },
                                  }}
                                />
                              }
                              label="Manager Rating"
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#49454F",
                                },
                                "&.Mui-disabled .MuiFormControlLabel-label": {
                                  color: "#49454F", // Keep the label text color unchanged
                                },
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  sx={{
                                    color: "#1976d2", // Custom color for the checkbox
                                    "&.Mui-disabled": {
                                      color: "#000", // Keep the color of the checkbox even when disabled
                                    },
                                    "&.Mui-checked.Mui-disabled": {
                                      color: "#1976d2", // Keep the checked state color when disabled
                                    },
                                  }}
                                />
                              }
                              label="Employee Rating"
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#49454F",
                                },
                                "&.Mui-disabled .MuiFormControlLabel-label": {
                                  color: "#49454F", // Keep the label text color unchanged
                                },
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked
                                  disabled
                                  sx={{
                                    color: "#1976d2", // Custom color for the checkbox
                                    "&.Mui-disabled": {
                                      color: "#000", // Keep the color of the checkbox even when disabled
                                    },
                                    "&.Mui-checked.Mui-disabled": {
                                      color: "#1976d2", // Keep the checked state color when disabled
                                    },
                                  }}
                                />
                              }
                              label="Manager Comment"
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#49454F",
                                },
                                "&.Mui-disabled .MuiFormControlLabel-label": {
                                  color: "#49454F",
                                },
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  sx={{
                                    color: "#1976d2",
                                    "&.Mui-disabled": {
                                      color: "#000",
                                    },
                                    "&.Mui-checked.Mui-disabled": {
                                      color: "#1976d2",
                                    },
                                  }}
                                />
                              }
                              label="Employee Comment"
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#49454F",
                                },
                                "&.Mui-disabled .MuiFormControlLabel-label": {
                                  color: "#49454F",
                                },
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex flex-col gap-2 5">
                <div className="py-[5px]">
                  <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                    Employee Comments
                  </span>
                </div>
                <div className="overflow-x-auto overflow-y-auto shadow-sm">
                  <table className="w-full text-left table-auto bg-white">
                    <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                      <tr className="h-11 w-full">
                        <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-5">
                          Questions
                        </th>
                        <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] pl-5">
                          Comments
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border border-[#EBEBEB]">
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5 font-[Poppins] font-medium text-[13px] leading-[15.2px] text-[#49454F]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do?
                        </td>
                        <td className="font-[Poppins] font-medium text-[14px] leading-[15.2px] text-[#49454F] pl-5">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text
                        </td>
                      </tr>
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5 font-[Poppins] font-medium text-[13px] leading-[15.2px] text-[#49454F]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do?
                        </td>
                        <td className="font-[Poppins] font-medium text-[14px] leading-[15.2px] text-[#49454F] pl-5">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text
                        </td>
                      </tr>
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5 font-[Poppins] font-medium text-[13px] leading-[15.2px] text-[#49454F]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do?
                        </td>
                        <td className="font-[Poppins] font-medium text-[14px] leading-[15.2px] text-[#49454F] pl-5">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex flex-col gap-2 5">
                <div className="py-[5px]">
                  <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                    Line Manager Ratings
                  </span>
                </div>
                <div className="overflow-x-auto overflow-y-auto shadow-sm">
                  <table className="w-full text-left table-auto bg-white">
                    <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                      <tr className="h-11 w-full">
                        <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-5">
                          Comments
                        </th>
                        <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-5">
                          Rating
                        </th>
                        <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] pl-5">
                          Comments
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border border-[#EBEBEB]">
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5 font-[Poppins] font-medium text-[13px] leading-[15.2px] text-[#49454F]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do?
                        </td>
                        <td className="pl-5 font-[Poppins] font-medium text-[13px] leading-[15.2px] text-[#49454F]">
                          <Rating
                            name="size-large"
                            defaultValue={3}
                            size="large"
                            readOnly
                          />
                        </td>
                        <td className="font-[Poppins] font-medium text-[14px] leading-[15.2px] text-[#49454F] pl-5">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text
                        </td>
                      </tr>
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5 font-[Poppins] font-medium text-[13px] leading-[15.2px] text-[#49454F]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do?
                        </td>
                        <td className="pl-5 font-[Poppins] font-medium text-[13px] leading-[15.2px] text-[#49454F]">
                          <Rating
                            name="size-large"
                            defaultValue={4}
                            size="large"
                            readOnly
                          />
                        </td>
                        <td className="font-[Poppins] font-medium text-[14px] leading-[15.2px] text-[#49454F] pl-5">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text
                        </td>
                      </tr>
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5 font-[Poppins] font-medium text-[13px] leading-[15.2px] text-[#49454F]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do?
                        </td>
                        <td className="pl-5 font-[Poppins] font-medium text-[13px] leading-[15.2px] text-[#49454F]">
                          <Rating
                            name="size-large"
                            defaultValue={3}
                            size="large"
                            readOnly
                          />
                        </td>
                        <td className="font-[Poppins] font-medium text-[14px] leading-[15.2px] text-[#49454F] pl-5">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAppraisal;
