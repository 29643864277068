import React, { useEffect, useState, useCallback, ChangeEvent } from "react";
import right from "../../../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import plus from "../../../../image/Mask group.svg";
import profile from "../../../../assets/Ellipse 580.png";
import Search from "../../../SearchBar/Search";
import {
  Button as Btn,
  Modal,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import {
  createResourcesApi,
  Fetch_Resources_List,
} from "../../../common/services/Project";
import toast from "react-hot-toast";
import del from "../../../../assets/Crud_Icons/delete.svg";
import edit from "../../../../assets/Crud_Icons/edit.svg";
import {
  api_key_work as api_key,
  base_api,
  calculateVariance,
  convertHoursIntoHoursMinutes,
} from "../../../../utils/helperData";
import ModalResources from "../../Modal";
import TablePaginationDemo from "../../../common/Pagenation";
import ResourceSearch from "../ResourceSearch";
import CustomTabs from "../../../common/CustomTabs";
import CloseIcon from "@mui/icons-material/Close";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, Currency } from "lucide-react";
import { Button } from "../../../common/Button";
import DynamicTable from "../../../common/DynamicTable";
import { useDispatch } from "react-redux";
import {
  setProjectId,
  setProjectTab,
} from "../../../../redux/reducers/ProjectManagement/ProjectSlice2";
import { useSelector } from "react-redux";
import Search2 from "../../../SearchBar/Search2";
interface RowData {
  id: number;
  user_id: number;
  project_id: number;
  employee_type: string | null;
  customer_id: number;
  group_id: number | null;
  billing_cost: number;
  actual_cost: number | null;
  User: IUser;
  estimated_hours: number | null;
  actual_hours: number | null;
  currency: string | null;
}
interface IUser {
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string;
  employee_id: string;
  Department: {
    name: string;
  };
  Designation: {
    name: string;
  };
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancel: () => void;
}> = ({ onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Resource
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this item?
        </h4>
        <div className="flex space-x-4 justify-center">
          <Button onClick={onDelete} variant={"gradient"} className=" mr-2">
            Yes
          </Button>
          <Button onClick={onCancel} className="borderButton rounded-lg">
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

const EditResources = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.state?.path;
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<RowData | null>(null);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [currency, setCurrency] = useState<string | null>();
  const [projectName, setProjectName] = useState("");
  const role = useSelector(
    (state: any) => state?.auth?.login?.login_details?.role
  );
  const adminRole = ["HR", "Admin", "IT"].includes(role);

  const [projectResources, setProjectResources] = useState<
    {
      user_id: number;
      project_id: number;
      employee_type: string;
      action: string;
    }[]
  >([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<RowData | null>(null);
  const [modalMessage, setModalMessage] = useState<string>(""); // For dynamic modal message

  const handleConfirm = () => {
    if (selectedData) {
      changeStatus([
        {
          user_id: selectedData?.user_id,
          project_id: projectId,
          action: "update",
          employee_type:
            selectedData?.employee_type === "Employee" ? "Manager" : "Employee",
        },
      ]);
    }
    setIsModalVisible(false);
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  // const param = useParams();

  const handleDeleteClick = (item: RowData) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/project-resources/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item.id !== selectedItem.id)
          );
        } else {
        }
      } catch (error) {
      } finally {
        setShowModal(false);
        setSelectedItem(null);
      }
    }
  };

  const handleClick = (data: RowData) => {
    const message =
      data?.employee_type === "Manager"
        ? "Remove this resource from manager?"
        : "Make this resource as manager?";
    setSelectedData(data);
    setModalMessage(message); // Set the dynamic modal message
    setIsModalVisible(true);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleEmployeeSelect = (employeeIds: string[]) => {};

  const handleEmployeeSelectId = (userIds: string[]) => {
    if (!projectId) {
      return;
    }

    // if (isNaN(projectId)) {
    //   return;
    // }

    const newResources = userIds?.map((id) => {
      const existingResource = projectResources?.find(
        (resource) => resource.user_id === parseInt(id)
      );
      return {
        user_id: parseInt(id),
        project_id: projectId,
        action: "add",
        employee_type: existingResource
          ? existingResource.employee_type
          : "Employee",
      };
    });
    setProjectResources([...newResources]);
  };

  const handleSave = () => {
    if (projectResources?.length > 0) {
      postData();
    } else {
      toast.error("No resource selected.");
      setTimeout(() => {
        setOpen(false);
      }, 300);
    }
  };
  const changeStatus = async (
    res: {
      user_id: number;
      project_id: number;
      employee_type: string;
      action: string;
    }[]
  ) => {
    // setLoading(true);

    try {
      const response = await createResourcesApi(
        // customer_id,
        // uploadedFiles.map((files) => files?.originalName.split(".")),
        res
      );

      fetchData();
    } catch (error) {
      console.log({ error });
    }
    //  setLoading(false);
  };
  const columns: ColumnDef<RowData>[] = [
    {
      accessorKey: "name",
      accessorFn: (row) => `${row.User?.firstName} ${row.User?.lastName}`,
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Resources
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const data = row?.original;
        return (
          <div className="flex gap-2 items-center ml-[-18px]">
            <div
              onClick={() => {
                if (adminRole) handleClick(data);
              }}
              className="flex h-full items-center"
            >
              <div
                className={`h-8 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none w-[22px] flex items-center justify-center ${
                  data?.employee_type === "Manager"
                    ? "bg-[#FEE2E2]"
                    : "bg-[#EAE0FF]"
                } ${!adminRole ? "cursor-default" : "cursor-pointer"}`}
              >
                <span className="font-[Poppins] font-semibold text-[12px] leading-[18px] text-[#1D1A22]">
                  {data?.employee_type === "Manager" ? (
                    <span>M</span>
                  ) : (
                    <span>E</span>
                  )}
                </span>
              </div>
            </div>

            <img
              className="w-8 h-8 rounded-full ml-3"
              src={data?.User?.profile_image || profile}
              alt="Profile"
            />
            <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                {data?.User?.firstName} {data?.User?.lastName} (
                {data?.User?.Designation?.name || "--"})
              </span>
              <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                {data?.User?.employee_id || ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.Department?.name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "billing_cost",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Billable Cost/hr
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      // accessorFn: (row) => row?.billing_cost ?? "-",
      // cell: ({ row }) => {
      //   const name: number = row?.original?.billing_cost;
      //   const currency: string = row?.original?.currency ?? "";
      //   return (
      //     <div className="">
      //       {currency} {name || "--"}
      //     </div>
      //   );
      // },
      accessorFn: (row) => row?.billing_cost ?? "-", // Null if billing_cost is missing
      cell: ({ row }) => {
        const billingCost = row?.original?.billing_cost;
        const currency: string = row?.original?.currency ?? "";

        // Agar billingCost null, undefined, ya 0 ho to sirf "-"
        if (
          billingCost === null ||
          billingCost === undefined ||
          billingCost === 0
        ) {
          return <div>-</div>;
        }

        return (
          <div>
            {currency} {billingCost}
          </div>
        );
      },
    },
    {
      accessorKey: "estimated_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Allocated hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.estimated_hours ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">
            {row?.original?.estimated_hours
              ? // ? `${row?.original?.estimated_hours} hrs`
                convertHoursIntoHoursMinutes(row?.original?.estimated_hours)
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "actual_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Filled hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.actual_hours ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">
            {row?.original?.actual_hours
              ? // ? `${row?.original?.actual_hours} hrs`
                convertHoursIntoHoursMinutes(row?.original?.actual_hours)
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "variance",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Variance
        </Button>
      ),
      // accessorFn: (row) =>
      //   row?.status
      //     ? getPolicyStatus[row.status as keyof typeof getPolicyStatus]
      //     : "-",
      cell: ({ row }) => {
        const data = row?.original;
        let diff;
        if (data?.actual_hours && data?.estimated_hours) {
          diff = calculateVariance(
            data?.estimated_hours ?? 0,
            data?.actual_hours ?? 0
          );
        }

        return <div className="">{diff ? diff : "-"}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                handleEditClick(
                  String(row?.original?.id),
                  row?.original?.currency ? String(row?.original?.currency) : ""
                )
              }
            >
              <img
                src={edit}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>

            <Button
              type="button"
              className="p-0"
              onClick={() => handleDeleteClick(row.original)}
            >
              <img
                src={del}
                alt="Delete"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];
  const postData = async () => {
    try {
      const response = await createResourcesApi(projectResources);
      // const projectId = response.data?.result[0]?.project_id || param.id;

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        navigate(`/edit-resources-details/${projectId}`, {
          state: { id: projectId },
        });
        fetchData();
      }, 300);
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await Fetch_Resources_List(
        projectId,
        searchTerm,
        numOfItems,
        page
      );
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setProjectName(response?.data?.result?.projectName?.project_name ?? "");
        // setCurrency(response?.data?.result?.rows?.user_id?.currency);
        setCount(response?.data?.result?.count ?? 0);
      } else {
        console.log("Error fetching resources");
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [page, numOfItems, searchTerm]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEditClick = (id: string, currency: string) => {
    setSelectedEmployeeId(id);
    setIsModalOpen(true);
    setCurrency(currency || "");
  };

  const onClose = () => {
    setIsModalOpen(false);
    fetchData();
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 100), // delay in ms
    [setSearchTerm]
  );

  const tabs = [
    { label: "Manager", value: "0" },
    { label: "Employee", value: "1" },
  ];

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleBackClick = () => {
    // dispatch(setProjectId(param?.id ?? ""));
    // dispatch(setProjectTab(0));
    // console.log(projectTab)
    // navigate("/edit-project-details");
    if (path === "/view-project-details") {
      navigate(path, {
        state: { tab: "resources", id: projectId },
      });
    } else {
      dispatch(setProjectTab(0));
    }
    // navigate(-1)
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <>
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              height: "80vh",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: "10px 20px",
              borderRadius: "8px",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Resource
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              mt={2}
              gap="16px"
              alignItems="start"
            >
              <ResourceSearch
                onEmployeeSelect={handleEmployeeSelect}
                onEmployeeSelectId={handleEmployeeSelectId}
                projectId={projectId}
                selectedResources={tableData.map((r) => r.user_id)}
              />
              <Btn
                variant="contained"
                color="primary"
                sx={{
                  background: "linear-gradient(to right, #6a11cb, #2575fc)",
                  height: "55px",
                  width: "150px",
                }}
                onClick={handleSave}
              >
                Save
              </Btn>
            </Box>
          </Box>
        </>
      </Modal>

      <div className="mt-1">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button
              onClick={() => {
                // navigate(`/edit-project-details`, {
                //   state: { id: param.id },
                // });
                handleBackClick();
              }}
            >
              <img
                src={right}
                alt="right arrow sign"
                className="h-4 w-4 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-base font-semibold leading-normal">
              {`Edit Resources (${projectName})`}
            </span>
          </div>
        </div>

        <div className="bg-white border-[#E2E8F0] shadow-custom p-4 rounded-lg mt-2 pb-10">
          {/* <div className="flex mb-4 ml-3 text-[#374151] font-poppins text-[22px] font-[500] justify-between">
            <div>Resources</div>
            <button
              onClick={() => setOpen(true)}
              className="flex items-center font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
            >
              <img className="w-4 h-4 mr-2" src={plus} alt="plus icon" />
              Add Resource
            </button>
          </div> */}

          <div className="flex justify-between">
            <div className="ml-1 text-[#374151] font-poppins text-[22px] font-[500] mt-1">
              Resources
            </div>
            <button
              onClick={() => setOpen(true)}
              className="flex items-center font-[600] text-[14px] px-4 buttoncss text-white h-10 rounded-lg"
            >
              <img className="w-4 h-4 mr-2" src={plus} alt="plus icon" />
              Add Resource
            </button>
          </div>

          <div className="flex justify-between mt-2">
            <div className="flex justify-center items-center">
              <Search2
                onChange={updateSearchTerm}
                label="Search with resource name"
              />
            </div>
          </div>

          <div className="overflow-x-auto mt-5">
            <div className="table_main_content w-full relative overflow-auto bg-white">
              <DynamicTable<RowData>
                data={tableData}
                loading={false}
                columns={columns}
                enableSorting
                enablePagination
              />
            </div>

            {count >= 10 && (
              <TablePaginationDemo
                count={count}
                handleItemsChange={handleNumberOfPages}
                handlePageChange={handlePageChange}
                currentPage={page}
                numOfItems={numOfItems}
              />
            )}
          </div>

          {isModalVisible && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white flex flex-col gap-4 w-[314px] h-[227px] p-[24px] rounded-xl shadow-md">
                <div className="flex flex-col items-center h-[123px] w-[266px] gap-[10px]">
                  <div className="flex flex-col items-center">
                    <span>
                      <img
                        className="object-contain h-[3rem] w-auto rounded-full"
                        src={selectedData?.User?.profile_image}
                        alt=""
                      />
                    </span>
                    <span className="font-Inter font-medium">
                      {selectedData?.User?.firstName}
                    </span>
                    <span className="text-[#64748B] w-[65px] h-[20px]">
                      {selectedData?.User?.employee_id}
                    </span>
                  </div>
                  {/* <p>Make this resource as manager?</p> */}

                  {/* Dynamic message */}
                  <p className="font-Inter font-medium text-center whitespace-nowrap text-[16px] leading-[19px] text-gray-700 w-[266px] h-[19px]">
                    {modalMessage}
                  </p>
                </div>

                <div className="flex justify-center gap-[16px] -mt-[-7px]">
                  <button
                    onClick={() => setIsModalVisible(false)}
                    className="flex p-[13.18px,50px,14.82px,51px] justify-center items-center rounded-[8px] self-stretch gap-[8px] py-[2px] px-[16px] borderButton h-[40px] w-[93px]"
                  >
                    <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                      No
                    </div>
                  </button>
                  <button
                    onClick={handleConfirm}
                    className="flex items-center justify-center font-[600] text-[14px] py-[2px] px-[16px] buttoncss text-white gap-[8px] rounded-[8px] h-[40px] w-[93px]"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          )}

          {showModal && (
            <DeleteConfirmationModal
              onDelete={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}

          {selectedEmployeeId && (
            <ModalResources
              isOpen={isModalOpen}
              onClose={onClose}
              employeeId={selectedEmployeeId}
              fetchData={fetchData}
              currency={currency}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EditResources;
