import listView from "../../assets/listview.svg";
import search from "../../assets/Search.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import action from "../../assets/tableActionIcon.svg";
import { debounce } from "lodash";
import { companyStructureActions } from "../../redux/reducers/CompanyStructure/CompanyStructureSlice";
import { fetchUserGroupAccessList } from "../../redux/actions/CompanyStructure/companyStructureActions";
import RenderChildren from "./RenderChildren";
import { useNavigate } from "react-router-dom";
import { SELECTED_VIEW_GROUP_DETAILS } from "../../utils/routeConstant";
import DatePicker from "react-datepicker";
import date from "../../assets/date.svg";
import { createGlobalStyle } from "styled-components";
import moment from "moment";
import toast from "react-hot-toast";
//import { Label } from "../../@/components/ui/Common/Elements/Label/Label";

interface Users {
  firstName: string;
  lastName: string;
}

interface RowData {
  id: number;
  name: string;
  email: string;
  address: string;
  createdAt: string;
  updatedAt: string;
  Users: Users;
  total_users: number;
}

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;



const tableHeadings = [
  { id: "name", label: "Name", sort: false, width: "w-[20rem]" },
  // { id: "addedBy", label: "Added By", sort: false, width: "w-[15rem]" },
  { id: "createdAt", label: "Added On", sort: false, width: "w-[5rem]" },
  { id: "updatedAt", label: "Last Updated", sort: false, width: "w-[6rem]" },
  { id: "users", label: "No. Of Users", sort: false, width: "w-[6rem]" },
  { id: "action", label: "Actions", sort: false, width: "w-[4rem]" },
];

interface CustomerManagementTableProps {
  handleCurrentPageChange: (value: string) => void;
  handleEdit: (id: number) => void;
}

const dateFormatter = (isoTimestamp: string) => {
  const dateObject = new Date(isoTimestamp);
  const formattedDate = dateObject.toISOString().substring(0, 10);

  return formattedDate;
};

const CompanyView: React.FC<CustomerManagementTableProps> = ({
  handleCurrentPageChange,
  handleEdit,
}) => {
  //! VARIABLES

  let customer_id = 0;
  let access_token = "";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ! USE SELECTOR

  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const cnt = useSelector(
    (state: any) => state.CompanyStructure.companyStructureData.count
  );
  const currentPage = useSelector(
    (state: any) => state.CompanyStructure.companyStructureData.currentPage
  );
  const numOfItems = useSelector(
    (state: any) => state.CompanyStructure.companyStructureData.itemsPerPage
  );
  const groupData = useSelector(
    (state: any) => state.CompanyStructure.userGroupData.rows
  );

  // ! STATES

  const [count, setCount] = useState(cnt);
  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState<any>();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  //! CONDITIONS

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }


  //! USE EFFECT

  useEffect(() => {
    setCount(cnt);
  }, [cnt]);

  useEffect(() => {
    setTableData(groupData);
  }, [groupData]);

  useEffect(() => {
    if (selectedDates[0] && selectedDates[1]) {
      setIsCalendarOpen(false);
    }
  }, [selectedDates]);

  useEffect(() => {
    const fetchData = async () => {
      let url = `/api/v1/groups/company-structure?customer_id=${customer_id}&page=${currentPage}&size=${numOfItems}`;

      if (searchTerm !== "") {
        url += `&search=${searchTerm}`;
      }

      const startDate = selectedDates[0];
      const endDate = selectedDates[1];

      if (startDate instanceof Date && endDate instanceof Date) {
        const start = moment(startDate).format("YYYY-MM-DD");
        const end = moment(endDate).format("YYYY-MM-DD");
        url += `&start_date=${start}&end_date=${end}`;
      }

      if (startDate && !endDate) {
        return;
      }

      try {
        const resData = await dispatch<any>(
          fetchUserGroupAccessList(access_token, url)
        );

        const userGroupData = resData.result;


        dispatch(
          companyStructureActions.setUserGroupData(
            userGroupData || { count: 0, rows: [] }
          )
        );
      } catch (error) {
        if (error instanceof Error) {
          toast.dismiss();
          toast.error(error?.message);
        } else {
        }
      }
    };

    fetchData();
  }, [searchTerm, selectedDates, numOfItems, currentPage]);

  //! FUNCTIONS / HANDLER FUNCTIONS

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000),
    [setSearchTerm]
  );

  const filteredTableData = useMemo(() => {
    if (!tableData) {
      return [];
    }
    return tableData;
  }, [tableData, searchTerm]);

  function handleSortType(item: any) {
    if (item.sort) {
      const id = item.id;

      if (sortType && sortType.key === id) {
        const newOrder = sortType.order === "ASC" ? "DESC" : "ASC";
        setSortType({ key: id, order: newOrder });
      } else {
        setSortType({ key: id, order: "DESC" });
      }
    }
  }

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
  };

  return (
    <div className="px-5 w-full h-full">
      <div className="flex flex-col p-5 gap-4 w-full rounded-md bg-white">
        {/* <div className="flex flex-row gap-[26px] justify-center items-center">
          <img
            src={rightArrow}
            alt="right arrow sign"
            className="h-6 w-6 shrink-0 bg-black rounded-full"
          />
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            {crmType}
          </span>
        </div> */}
        <div className="flex flex-col justify-end">
          <div className="flex flex-row gap-[13px] justify-start items-center pb-5">
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Company Structure List
            </span>
          </div>
          <div className="flex flex-start self-stretch justify-between w-full h-11">
            <div className="flex gap-5 h-full">
              <div className="h-full w-[222px] flex pr-2 pl-[11px] justify-between items-center flex-[1,0,0] rounded-md border-[1px] border-solid border-[#CACACA]">
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full text-[#B5B5C3] font-[Poppins] text-[13px] font-light leading-normal bg-transparent outline-none"
                  onChange={updateSearchTerm}
                />
                <img
                  src={search}
                  alt="search sign"
                  className="h-[19px] w-[19px] shrink-0"
                />
              </div>
              {/* <div className="w-[27.13%] flex py-2.5 px-3 justify-between items-center flex-[1,0,0] rounded-md border-[1px] border-solid border-[#CACACA] bg-white"></div> */}
              <div className="flex w-[295px] h-full pr-4 justify-between text-[#B5B5C3] font-[Poppins] text-[13px] font-light items-center rounded-md border-[1px] border-solid border-[#CACACA]">
                <GlobalStyle />
                <DatePicker
                  selected={selectedDates[1]}
                  onChange={handleDateChange}
                  startDate={selectedDates[0]}
                  endDate={selectedDates[1]}
                  selectsRange
                  className="w-full flex py-2.5 px-3 h-[38px] justify-between items-center flex-[1,0,0]  bg-transparent border-transparent"
                  customInput={<input />}
                  isClearable
                  dateFormat="MMMM d, yyyy"
                  placeholderText="MM/DD/YYYY - MM/DD/YYYY"
                  open={isCalendarOpen}
                  onInputClick={() => {
                    setIsCalendarOpen(!isCalendarOpen);
                  }}
                  // onClickOutside={() => {setIsCalendarOpen(!isCalendarOpen)}}
                  // onCalendarClose={() => {setIsCalendarOpen(!isCalendarOpen)}}
                  // onCalendarOpen={() => {setIsCalendarOpen(!isCalendarOpen)}}
                />
                {
                  <img
                    src={date}
                    alt="date icon"
                    onClick={() => {
                      setIsCalendarOpen(!isCalendarOpen);
                    }}
                    className="flex items-center justify-between relative"
                  ></img>
                }
              </div>
            </div>
            {/* Toggle Button to toggle between tree and list view */}
            <button
              className="flex w-[166px] py-[11px] px-[15px] justify-center items-center gap-2.5 rounded-lg h-11"
              onClick={() => handleCurrentPageChange("tree")}
              // onClick={handleListView}
            >
              <img src={listView} alt="toggle button" />
            </button>
          </div>
          {/* <div className="flex flex-row justify-end gap-[20px] mt-2 mr-5">
            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="red" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] text-xs font-medium leading-[13.981px]">
                Bussiness Unit
              </span>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="purple" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-xs font-medium leading-[13.981px]">
                Department Unit
              </span>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="green" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-xs font-medium leading-[13.981px]">
                Process Unit
              </span>
            </div>
          </div> */}
        </div>

        <div>
          <table className="w-full min-w-max table-auto border-[0.6px] border-[#E0E3E7]">
            <thead className="sticky top-0">
              <tr className="flex flex-row w-full items-center pl-6 pr-2.5 py-2.5 rounded-[3px] gap-8 h-11 justify-between">
                {tableHeadings.map((item) => {
                  const cssClass =
                    "flex cursor-pointer transition-colors items-center " +
                    item.width;

                  return (
                    <th
                      className={cssClass}
                      key={customer_id}
                      onClick={() => handleSortType(item)}
                    >
                      <span className="shrink-0 font-[Poppins] text-[14px] font-medium leading-[11.185px] tracking-[0.72px] capitialize">
                        {item.label}
                      </span>
                      {item.sort && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-4 w-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                          ></path>
                        </svg>
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody className="">
              {filteredTableData?.map((item: any) => {
                if (item.id !== 575990 && item.group_name !== null) {
                  const fill =
                    item.type === "Group"
                      ? "red"
                      : item.type === "Department"
                      ? "purple"
                      : "green";
                  return (
                    <>
                      <tr
                        className="w-full h-[52px] flex pl-5 py-1 items-center gap-8 border-[0.6px] border-solid border-[#E0E3E7] justify-between"
                        onClick={() => {
                          handleEdit(item.id);
                        }}
                      >
                        <td className="w-[20rem]">
                          <div className="flex flex-row items-center gap-1 shrink-0 ]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                            >
                              <circle cx="5.5" cy="5.35156" r="5" fill={fill} />
                            </svg>
                            {item.group_name && (
                              <span className="text-[#262626] shrink-0 font-[Poppins] text-[12px] leading-[13.981px] font-medium overflow-hidden w-[110px] overflow-ellipsis text-left">
                                {item.group_name}
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="w-[5rem]">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center gap-1 shrink-0">
                              <p className="text-[#262626] shrink-0 font-[Poppins] text-[12px] leading-[13.981px] font-medium text-left">
                                {item.createdAt
                                  ? dateFormatter(item.createdAt)
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="w-[6rem]">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center gap-1 shrink-0">
                              <p className="text-[#262626] shrink-0 font-[Poppins] text-[12px] leading-[13.981px] font-medium text-left">
                                {item.updatedAt
                                  ? dateFormatter(item.updatedAt)
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="w-[6rem]">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center gap-1 shrink-0">
                              {item.total_users && (
                                <p className="text-[#262626] shrink-0 font-[Poppins] text-[12px] leading-[13.981px] font-medium w-[170px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                                  {item.total_users || "-"}
                                </p>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="w-[4rem]">
                          <button
                            className="align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none"
                            type="button"
                            onClick={() => {
                              navigate(`${SELECTED_VIEW_GROUP_DETAILS}`, {
                                state: item,
                              });
                            }}
                          >
                            <img
                              src={action}
                              alt="Table Action Icon"
                              className="h-6 w-6"
                            />
                          </button>
                        </td>
                      </tr>
                      <RenderChildren
                        handleEdit={handleEdit}
                        children={item.children}
                        // selectedId={selectedId}
                        depth={1}
                      />
                    </>
                  );
                }
              })}
              {filteredTableData?.length === 0 && (
                <tr className="w-full h-[52px] flex pl-5 py-1 items-center gap-8 border-[0.6px] border-solid border-[#E0E3E7] justify-between">
                  <td className="w-full flex flex-row justify-center items-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="mb-[70px]"> */}
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row justify-start gap-[20px] mt-2 mr-5">
            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="red" />
              </svg>
              <label className="text-[#262626] text-[10px] leading-[13.981px]">
                Bussiness Unit
              </label>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="purple" />
              </svg>
              <label className="text-[#262626] text-[10px] leading-[13.981px]">
                Department Unit
              </label>
            </div>

            {/* <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="green" />
              </svg>
              <label className="text-[#262626] text-[10px] leading-[13.981px]">Process Unit</label>
            </div> */}
          </div>
          {/* <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            numOfItems={numOfItems}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default CompanyView;
