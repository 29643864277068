import React, {
  useState,
  useCallback,
  ChangeEvent,
  useEffect,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import filter from "../../assets/filter.svg";
import download from "../../assets/download_new.svg";
import profile from "../../assets/Ellipse 580.png";
import eye from "../../assets/Crud_Icons/view.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import TablePaginationDemo from "../common/Pagenation";
import Filter from "../Filter/Filter";
import Search from "../SearchBar/Search";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import { Fetch_Employee_DSR_List } from "../common/services/EmployeeDSR";
import { Chip } from "@material-ui/core";
import moment from "moment";
import TableSkeleton from "../common/TableSkeleton";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface RowData {
  id: number;
  User: {
    firstName: string;
    middleName: string;
    lastName: string;
    profile_image: string;
    employee_id: string;
  };
  Project: {
    id: string;
    project_name: string;
  };
  Task: {
    id: string;
    task_name: string;
  };
  date: string;
  duration: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface DSRData {
  [key: string]: any; // Adjust this based on your data structure
}

export const EmployeeDSR = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<DepartmentUnit[]>();
  const [dateRange, setDateRange] = useState(false);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [dsrData, setDsrData] = useState<DSRData[]>([]);
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | DepartmentUnit
  >();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const handleBusinessUnitSelect = (option: Business_Unit | DepartmentUnit) => {
    setTempSelectedBusinessUnit(option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | DepartmentUnit
  ) => {
    setTempSelectedDepartmentUnit(option);
  };

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchDSR = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Employee_DSR_List(
        customer_id,
        searchTerm,
        numOfItems,
        page,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id),
        startDate,
        endDate
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);

        // Report
        const fetchedData: DSRData[] = response.data?.result?.rows;

        const columnMapping: { [key: string]: string } = {
          "User.firstName": "Name",
          date: "Date",
          "Project.project_name": "Project",
          "Task.task_name": "Task",
          notes: "Description",
          duration: "Efforts (Hrs.)",
          createdAt: "Time of filling",
        };
        const filteredData = fetchedData.map((row) => {
          const filteredRow: Partial<DSRData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split(".");

            if (keys.length === 1) {
              if (row.hasOwnProperty(keys[0])) {
                if (keys[0] === "createdAt" && row[keys[0]]) {
                  filteredRow[keys[0]] = moment(row[keys[0]]).format(
                    "DD MMM YYYY, HH:mm"
                  );
                } else {
                  filteredRow[keys[0]] = row[keys[0]];
                }
              }
            } else if (keys.length === 2) {
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null;
              }
            }
          });

          return filteredRow;
        });

        setDsrData(filteredData);

        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    fetchDSR();
  }, [
    searchTerm,
    page,
    numOfItems,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
    startDate,
    endDate,
    startSession,
    endSession,
  ]);

  const handleDownload = () => {
    if (dataFetched) {
      const worksheetData = [
        columns.map((col) => col.displayName),
        ...dsrData.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ),
      ];

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Employee DSR Data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.warn("Data has not been fetched yet.");
    }
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      // fetchData(event.target.value); // call API to fetch data
    }, 100), // delay in ms
    []
  );

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleDeleteDepartment = () => {
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    fetchDSR();
  };

  const handleDeleteBusiness = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchDSR();
  };

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  return (
    <div className="px-4 pt-6 pb-12 bg-[#F9F9F9] min-h-[90vh]">
      <div className="flex flex-col gap-5">
        <div className="h-12">
          <div className="h-[30px]">
            <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
              Employee DSR
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2 5">
          <div className="h-[40px]">
            <div className="h-full flex justify-between items-center">
              <Search
                onChange={updateSearchTerm}
                label="Search with employee Name"
              />
              <div className="h-full flex items-center gap-5">
                <div className="h-full flex items-center gap-2">
                  {dateRange && (
                    <Chip
                      label={`${moment(selectedDates[0]).format(
                        "YYYY-MM-DD"
                      )} - ${moment(selectedDates[1]).format("YYYY-MM-DD")}`}
                      onDelete={handleDeleteDate}
                      size="small"
                    />
                  )}
                  {departmentSelect && (
                    <Chip
                      label={selectedDepartmentUnit?.name}
                      onDelete={handleDeleteDepartment}
                      size="small"
                    />
                  )}
                  {businessSelect && (
                    <Chip
                      label={selectedBusinessUnit?.name}
                      onDelete={handleDeleteBusiness}
                      size="small"
                    />
                  )}
                </div>
                <button
                  className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                  onClick={() => setIsOpen(true)}
                >
                  <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                    Filter
                  </span>
                </button>
                <div className="h-full">
                  <button
                    className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text"
                    onClick={handleDownload}
                  >
                    <img
                      src={download}
                      alt="download"
                      className="h-[15px] w-[15px]"
                    />
                    <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                      Download Report
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-[31px]">
            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                Approved
              </span>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FF9950" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                Pending
              </span>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FA1464" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                Rejected
              </span>
            </div>
          </div>
          <div className="w-full">
            {loading ? (
              <>
                <table className="border-[0.6px] border-[#EBEBEB] overflow-x-auto min-w-max">
                  <thead className="h-[46px] bg-gradient-to-r font-[Poppins] font-medium text-[14px] leading-[14px] text-[#1D1A22] theadBg">
                    <th className="pl-4">Employee Name & ID</th>
                    <th className="pl-4">Project Name & ID</th>
                    <th className="pl-4">Task ID</th>
                    <th className="pl-4">Date</th>
                    <th className="pl-4">Efforts</th>
                    {/* <th className="pl-4">Task Status</th> */}
                    <th className="">Action</th>
                  </thead>
                </table>
                <TableSkeleton />
              </>
            ) : (
              <div className="overflow-auto">
                <table className="border-[0.6px] border-[#EBEBEB] overflow-x-auto min-w-max">
                  <thead className="h-[46px] bg-gradient-to-r font-[Poppins] font-medium text-[14px] leading-[14px] text-[#1D1A22] theadBg">
                    <th className="pl-4">Employee Name & ID</th>
                    <th className="pl-4">Project Name & ID</th>
                    <th className="pl-4">Task ID</th>
                    <th className="pl-4">Date</th>
                    <th className="pl-4">Efforts</th>
                    {/* <th className="pl-4">Task Status</th> */}
                    <th className="">Action</th>
                  </thead>
                  <tbody className="h-[60px] border-0 border-b-[1px] border-solid border-[#EBEBEB] bg-white">
                    {tableData.map((items) => (
                      <tr className="hover:bg-gray-100">
                        <td className="px-4">
                          <div className="flex gap-[7px] items-center h-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 8 8"
                              fill="none"
                            >
                              <circle
                                cx="3.99537"
                                cy="3.74537"
                                r="3.49537"
                                // fill={
                                //   items.status === "active"
                                //     ? "#00CBA0"
                                //     : items.status === "inactive"
                                //     ? "#FF9950"
                                //     : items.status === "archived"
                                //     ? "#FA1464"
                                //     : "#D3D3D3" // default color if status is unknown
                                // }
                                fill="#D3D3D3"
                              />
                            </svg>
                            <div className="h-[40px] flex items-center gap-2.5">
                              <div className="h-full w-[40px] rounded-full">
                                <img
                                  src={items.User.profile_image || profile}
                                  alt="profile"
                                  className="h-full w-full rounded-full"
                                />
                              </div>
                              <div className="h-full flex flex-col justify-center">
                                <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                                  {items?.User.firstName}{" "}
                                  {items?.User?.middleName}{" "}
                                  {items?.User?.lastName}
                                </span>
                                <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                                  {items?.User?.employee_id}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="pl-4">
                          <div className="flex flex-col justify-center h-full">
                            <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                              {items?.Project?.project_name?.length > 18
                                ? `${items?.Project?.project_name?.substring(
                                    0,
                                    18
                                  )}...`
                                : items?.Project?.project_name}
                            </span>
                            <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                              {items?.Project?.id}
                            </span>
                          </div>
                        </td>
                        <td className="pl-4">
                          <div className="flex flex-col justify-center h-full">
                            <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                              {items?.Task?.task_name?.length > 18
                                ? `${items?.Task?.task_name?.substring(
                                    0,
                                    18
                                  )}...`
                                : items?.Task?.task_name}
                            </span>
                            <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                              {items?.Task?.id}
                            </span>
                          </div>
                        </td>
                        <td className="pl-4 font-[Poppins] font-medium text-[12px] leading-[11.9px] tracking-[6%] text-[#49454F]">
                          {moment(items?.date).format("DD MMM YYYY")}
                        </td>
                        <td className="pl-4 font-[Poppins] font-medium text-[12px] leading-[11.9px] tracking-[6%] text-[#49454F]">
                          {items?.duration}
                        </td>
                        {/* <td className="pl-4 font-[Poppins] font-medium text-[12px] leading-[11.9px] tracking-[6%] text-[#49454F]">
                          <div className="px-2.5 py-1 rounded-full border border-[#77C798] bg-[#ECFDF3] max-w-min">
                            <span className="font-[Poppins] font-medium text-[14px] leading-[16px] text-[#137F40] max-w-min">
                              Completed
                            </span>
                          </div>
                        </td> */}
                        <td className="">
                          <div className="flex justify-start items-center">
                            <img
                              src={eye}
                              alt="view"
                              onClick={() =>
                                navigate(`/employee-dsr-details`, {
                                  state: { id: items.id },
                                })
                              }
                              className="p-0.5 hover:bg-[#E6E6E6] cursor-pointer rounded-md"
                            />
                            <img
                              src={edit}
                              alt="edit"
                              // onClick={() =>
                              //   navigate(`/edit-dsr-details`, {
                              //     state: { id: items.id },
                              //   })
                              // }
                              onClick={() => navigate("/edit-dsr-details")}
                              className="p-0.5 hover:bg-[#E6E6E6] cursor-pointer rounded-md"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={1}
              numOfItems={10}
            />
          )}
        </div>
      </div>
      <Filter
        isDate
        isOther
        heading="Employee DSR Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </div>
  );
};
