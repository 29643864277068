import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import right from "../../../../image/chevron-right.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import upload from "../../../../assets/plus 1.png";
import profile from "../../../../assets/Ellipse 580.png";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  TextField,
  Button as Btn,
} from "@mui/material";
import { debounce } from "lodash";
import {
  createResourcesApi,
  Fetch_Resources_List,
} from "../../../common/services/Project";
import toast from "react-hot-toast";
import del from "../../../../assets/Crud_Icons/delete.svg";
import edit from "../../../../assets/Crud_Icons/edit.svg";
import {
  api_key_work as api_key,
  base_api,
} from "../../../../utils/helperData";
import ModalResources from "../../Modal";
import Search from "../../../SearchBar/Search";
import TablePaginationDemo from "../../../common/Pagenation";
import ResourceSearch from "../ResourceSearch";
import EmployeeSearch from "../../../EmployeeManagement/EmployeeSearch";
import ManagerSearch from "../../ManagerSearch";
import { ArrowUpDown, X } from "lucide-react";
import { TabContext } from "@mui/lab";
import CustomTabs from "../../../common/CustomTabs";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../../common/Button";
import { Brightness1Sharp } from "@mui/icons-material";
import DynamicTable from "../../../common/DynamicTable";
import Search2 from "../../../SearchBar/Search2";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setProjectTab } from "../../../../redux/reducers/ProjectManagement/ProjectSlice2";

interface ProjectResource {
  user_id: number;
  project_id: number;
  employee_type: string;
  action?: string;
}

interface IUser {
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string;
  employee_id: string;
  Department: {
    name: string;
  };
  Designation: {
    name: string;
  };
}

interface RowData {
  id: number;
  user_id: number;
  project_id: number;
  employee_type: string | null;
  customer_id: number;
  group_id: number | null;
  billing_cost: number;
  actual_cost: number | null;
  User: IUser;
  estimated_hours: number | null;
  actual_hours: number | null;
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;

  onCancel: () => void;
  text?: string;
}> = ({ onDelete, onCancel, text }) => {
  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          {text ? text : "Delete Expense Type"}
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this item?
        </h4>
        <div className="flex space-x-4 justify-center">
          <button onClick={onCancel} className="borderButton rounded-lg">
            No
          </button>
          <Button variant={"gradient"} onClick={onDelete} className=" mr-2">
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
};

const tabs = [
  { label: "Manager", value: "0" },
  { label: "Employee", value: "1" },
];

const AddResources = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<RowData | null>(null);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  // const [projectID, setProjectId] = useState(null);
  const [currency, setCurrency] = useState<string | null>("");
  const role = useSelector(
    (state: any) => state?.auth?.login?.login_details?.role
  );
  const adminRole = ["HR", "Admin", "IT"].includes(role);
  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<RowData | null>(null);
  const [modalMessage, setModalMessage] = useState<string>(""); // For dynamic modal message

  const handleConfirm = () => {
    if (selectedData) {
      changeStatus([
        {
          user_id: selectedData.user_id,
          project_id: projectId,
          action: "update",
          employee_type:
            selectedData.employee_type === "Employee" ? "Manager" : "Employee",
        },
      ]);
    }
    setIsModalVisible(false);
  };

  const handleClick = (data: RowData) => {
    const message =
      data?.employee_type === "Manager"
        ? "Remove this resource from manager?"
        : "Make this resource as manager?";
    setSelectedData(data);
    setModalMessage(message); // Set the dynamic modal message
    setIsModalVisible(true);
  };

  const columns: ColumnDef<RowData>[] = [
    {
      accessorKey: "name",
      accessorFn: (row) => `${row.User?.firstName} ${row.User?.lastName}`,
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Resources
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const data = row?.original;
        return (
          <div className="flex gap-2 items-center ml-[-18px]">
            <div
              onClick={() => {
                if (adminRole) handleClick(data);
              }}
              className="flex h-full items-center"
            >
              <div
                className={`h-8 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none w-[22px] flex items-center justify-center ${
                  data?.employee_type === "Manager"
                    ? "bg-[#FEE2E2]"
                    : "bg-[#EAE0FF]"
                } ${!adminRole ? "cursor-default" : "cursor-pointer"}`}
              >
                <span className="font-[Poppins] font-semibold text-[12px] leading-[18px] text-[#1D1A22]">
                  {data?.employee_type === "Manager" ? (
                    <span>M</span>
                  ) : (
                    <span>E</span>
                  )}
                </span>
              </div>
            </div>
            <img
              className="w-8 h-8 rounded-full ml-3"
              src={data?.User?.profile_image || profile}
              alt="Profile"
            />
            <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                {data?.User?.firstName} {data?.User?.lastName} (
                {data?.User?.Designation?.name || "--"})
              </span>
              <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                {data?.User?.employee_id || "-"}
              </span>
            </div>
          </div>

          // <div className="flex gap-2 items-center relative">
          //   <div className="flex h-full items-center absolute left-[-16px] top-0">
          //     <div
          //       className={`h-8 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none w-[22px] flex items-center justify-center ${
          //         data?.employee_type === "Manager"
          //           ? "bg-[#FEE2E2]"
          //           : "bg-[#EAE0FF]"
          //       }`}
          //     >
          //       <button
          //         onClick={() => handleClick(data)}
          //         className="font-[Poppins] font-semibold text-[12px] leading-[18px] text-[#1D1A22]"
          //       >
          //         {data?.employee_type === "Manager" ? (
          //           <span>M</span>
          //         ) : (
          //           <span>E</span>
          //         )}
          //       </button>
          //     </div>
          //   </div>

          //   <img
          //     className="w-8 h-8 rounded-full ml-3"
          //     src={data?.User?.profile_image || profile}
          //     alt="Profile"
          //   />
          //   <div className="flex flex-col justify-between">
          //     <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
          //       {data?.User?.firstName} {data?.User?.lastName} (
          //       {data?.User?.Designation?.name || "-"})
          //     </span>
          //     <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
          //       {data?.User?.employee_id || ""}
          //     </span>
          //   </div>
          // </div>
        );
      },
    },
    {
      accessorKey: "department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.Department?.name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "billing_cost",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Billable Cost/hr
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      // accessorFn: (row) => row?.billing_cost ?? "-",
      // cell: ({ row }) => {
      //   const name: number = row?.original?.billing_cost;
      //   return <div className="">{currency} {name || "--"}</div>;
      // },
      accessorFn: (row) => row?.billing_cost ?? "-", // Null if billing_cost is missing
      cell: ({ row }) => {
        const billingCost = row?.original?.billing_cost;
        // const currency: string = row?.original?.currency ?? "";

        // Agar billingCost null, undefined, ya 0 ho to sirf "-"
        if (
          billingCost === null ||
          billingCost === undefined ||
          billingCost === 0
        ) {
          return <div>-</div>;
        }

        return (
          <div>
            {currency} {billingCost}
          </div>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={() => handleEditClick(String(row?.original?.id) ?? "")}
            >
              <img
                src={edit}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>

            <Button
              type="button"
              className="p-0"
              onClick={() => handleDeleteClick(row.original)}
            >
              <img
                src={del}
                alt="Delete"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];
  const [projectResources, setProjectResources] = useState<ProjectResource[]>(
    []
  );

  const [open, setOpen] = useState(false);

  const [type, setType] = useState<string[]>([]);

  const handleClose = () => setOpen(false);

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 100),
    [setSearchTerm]
  );

  // const param = useParams();
  const location = useLocation();

  const handleDeleteClick = (item: RowData) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/project-resources/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item.id !== selectedItem.id)
          );
        } else {
          toast.error("Failed to delete the item");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setShowModal(false);
        setSelectedItem(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleEmployeeSelect = (employeeIds: string[]) => {};

  const handleEmployeeSelectId = (userIds: string[]) => {
    if (!projectId) {
      return;
    }

    // const projectId = parseInt(param.id);
    if (isNaN(projectId)) {
      return;
    }

    const newResources = userIds.map((id) => {
      const existingResource = projectResources.find(
        (resource) => resource.user_id === parseInt(id)
      );
      return {
        user_id: parseInt(id),
        project_id: projectId,
        action: "add",
        employee_type: existingResource
          ? existingResource.employee_type
          : "Employee",
      };
    });
    setProjectResources([...newResources]);
  };

  const handleSave = () => {
    if (projectResources?.length > 0) {
      postData();
    } else {
      setTimeout(() => {
        setOpen(false);
      }, 300);
    }
  };

  const postData = async () => {
    try {
      const response = await createResourcesApi(projectResources);

      if (response && response.data) {
        setTimeout(() => {
          setOpen(false);
          fetchData();
        }, 1000);
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const changeStatus = async (res: ProjectResource[]) => {
    // setLoading(true);

    try {
      const response = await createResourcesApi(
        // customer_id,
        // uploadedFiles.map((files) => files?.originalName.split(".")),
        res
      );

      fetchData();
    } catch (error) {
      console.log({ error });
    }
    //  setLoading(false);
  };

  const fetchData = async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Resources_List(
        projectId,
        searchTerm,
        numOfItems,
        page

        // startDate,
        // endDate
      );
      //tabledata.User.profile_image
      if (response.status === 200) {
        setTableData(response.data?.result?.rows ?? []);
        setCount(response.data?.result?.count ?? 0);
        setCurrency(response?.data?.result?.rows?.[0]?.currency);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, numOfItems, searchTerm]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");

  const handleEditClick = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
    setIsModalOpen(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <>
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              height: "80vh",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: "10px 20px",
              borderRadius: "8px",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Resource
              </Typography>
              <IconButton onClick={handleClose}>
                <X />
              </IconButton>
            </Box>

            {/* <div className="flex justify-between">
                <CustomTabs tabs={tabs} onChange={handleChange} />
              </div> */}
            {/* <Tabs
              value={value}
              onChange={handleChange}
              aria-label="resource tabs"
            >
              <Tab label="Manager" />
              <Tab label="Employee" />
            </Tabs> */}

            <Box
              display="flex"
              justifyContent="space-between"
              mt={2}
              gap="16px"
              alignItems="start"
            >
              <ResourceSearch
                onEmployeeSelect={handleEmployeeSelect}
                onEmployeeSelectId={handleEmployeeSelectId}
                projectId={projectId}
                selectedResources={tableData.map(
                  (resource) => resource.user_id
                )}
              />
              <Btn
                variant="contained"
                color="primary"
                sx={{
                  background: "linear-gradient(to right, #6a11cb, #2575fc)",
                  height: "55px",
                  width: "150px",
                }}
                onClick={handleSave}
              >
                Save
              </Btn>
            </Box>
          </Box>
        </>
      </Modal>

      <div>
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button
              onClick={() => {
                dispatch(setProjectTab(0));
                // navigate("/add-project", {
                //   state: { id: projectId },
                // });
              }}
            >
              <img
                src={right}
                alt="right arrow sign"
                className="h-4 w-4 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-base font-semibold leading-normal">
              Add Resources
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => {
                dispatch(setProjectTab(0));
                // navigate("/add-project", {
                //   state: { id: projectId },
                // });
              }}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <Button
              variant={"gradient"}
              onClick={() => {
                dispatch(setProjectTab(2));
                // navigate(`/add-task/${projectId}`)
              }}
            >
              <div
                // onClick={handleOpen}
                className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
              >
                {" "}
                Save & Continue
              </div>
            </Button>
          </div>
        </div>

        <div className="flex justify-between mt-5">
          <Search2
            onChange={updateSearchTerm}
            label="Search with resource name"
          />
          <Button onClick={() => setOpen(true)} variant={"gradient"}>
            <img className="w-4 h-4 mr-2" src={upload} alt="plus icon" />
            Add Resources
          </Button>
        </div>

        <div className="overflow-x-auto my-5">
          <div className="table_main_content w-full relative overflow-auto bg-white ">
            <DynamicTable<RowData>
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>

          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={page}
              numOfItems={numOfItems}
            />
          )}

          {isModalVisible && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white flex flex-col gap-4 w-[314px] h-[227px] p-[24px] rounded-xl shadow-md">
                <div className="flex flex-col items-center h-[123px] w-[266px] gap-[10px]">
                  <div className="flex flex-col items-center">
                    <span>
                      <img
                        className="object-contain h-[3rem] w-auto rounded-full"
                        src={selectedData?.User?.profile_image}
                        alt=""
                      />
                    </span>
                    <span className="font-Inter font-medium">
                      {selectedData?.User?.firstName}
                    </span>
                    <span className="text-[#64748B] w-[65px] h-[20px]">
                      {selectedData?.User?.employee_id}
                    </span>
                  </div>
                  {/* <p>Make this resource as manager?</p> */}

                  {/* Dynamic message */}
                  <p className="font-Inter font-medium text-center text-[16px] leading-[19px] text-gray-700 w-[266px] h-[19px]">
                    {modalMessage}
                  </p>
                </div>

                <div className="mt-4 flex justify-center gap-[16px] -mt-[-7px]">
                  <Button onClick={handleConfirm} variant={"gradient"}>
                    Yes
                  </Button>
                  <button
                    onClick={() => setIsModalVisible(false)}
                    className="rounded-lg borderButton"
                  >
                    <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                      No
                    </div>
                  </button>
                </div>
              </div>
            </div>
          )}

          {showModal && (
            <DeleteConfirmationModal
              text="Delete Resources"
              onDelete={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}

          <ModalResources
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            employeeId={selectedEmployeeId}
            fetchData={fetchData}
            currency={currency}
          />
        </div>
      </div>
    </>
  );
};

export default AddResources;
