import { Suspense, lazy, useEffect, useState } from "react";
import logo from "../../assets/group-18.svg";
import upArrow from "../../assets/images/upArrow.png";
import downArrow from "../../assets/images/downArrow.png";
import "./sidebar.css";
import arrowLeft from "../../assets/arrowDoubleLeft.svg";
import SideBarCollapsed from "./SideBarCollapsed";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SideBarActions } from "../../redux/reducers/SideBar/SideBarSlice";
const RenderChildren = lazy(() => import("./RenderChildren"));

// ! INTERFACES

interface SidebarData {
  resource_id: number;
  resource_name: string;
  parent_id: number;
  icon: string;
  route: string;
  children: [];
}
const Sidebar = () => {
  //! STATES
  const [data, setData] = useState<SidebarData[]>([]);
  const [collapsed, setCollapsed] = useState(false);
  const [showChildren, setShowChildren] = useState<string[]>([]);
  const [isHovered, setIsHovered] = useState(false);

  //! DISPATCH & USESELECTOR
  const dispatch = useDispatch();
  const sideBarData = useSelector((state: any) => state.SideBar?.sidebar?.data);

  const selected_resource_id = useSelector(
    (state: any) => state?.SideBar?.selected_resource_id
  );

  //* to handle showOptions we can create an array where the parent id can be stored in that array to show the children inside it, only show children of those parent id which is present inside the array. We will create a handler function which will be triggered on click of that parent which will remove the parent id (if present inside the array) or add the parent id (if not there).

  useEffect(() => {
    setData(sideBarData || []);
  }, [sideBarData]);

  useEffect(() => {
    if (data) {
      data.map((item: any) => {
        setShowChildren((prevState) => {
          return [...prevState, item.resource_name];
        });
      });
    }
  }, [data]);

  //! HANDLER FUNCTIONS

  const handleCollapsed = () => {
    setCollapsed((prevState) => !prevState);
  };

  const handleShowChildren = (resource_name: string) => {
    setShowChildren((prevState: any) => {
      if (prevState.includes(resource_name)) {
        return prevState.filter((item: any) => item !== resource_name);
      } else {
        return [...prevState, resource_name];
      }
    });
  };

  //! useEffect()

  useEffect(() => {
    setData(sideBarData || []);
  }, [sideBarData]);

  return (
    <div
      className={`flex sticky top-0 left-0 mb-[32px] ${
        collapsed ? "sidebar-collapsed" : "sidebar-expanded"
      }`}
      style={{ height: "calc(100% - 26px)" }}
    >
      {collapsed ? (
        <SideBarCollapsed
          data={data}
          setIsCollapsed={handleCollapsed}
          setIsHovered={setIsHovered}
        />
      ) : (
        <div
          className={`sidebar flex text-2xl font-bold overflow-y-auto flex-col w-[285px] max-w-[315px]`}
          style={{
            scrollbarWidth: "none",
            overflow: "scroll",
            /* Firefox */ WebkitOverflowScrolling: "touch" /* iOS */,
          }}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="header1">
            <div className="header2 flex items-center">
              <img
                src={logo}
                className="logo"
                style={{ height: "35px", marginRight: "10px" }}
                alt="logo"
              />
              Go-EMS
            </div>
            <button className="left_arrow_btn" onClick={handleCollapsed}>
              <img src={arrowLeft} className="h-full w-full" alt="left arrow" />
            </button>
          </div>
          <div
            className="py-2 overflow-y-auto"
            style={{
              scrollbarWidth: "none",
              overflow: "scroll",
              /* Firefox */ WebkitOverflowScrolling: "touch" /* iOS */,
            }}
          >
            {data?.map((item) => {
              const isNavLinkRendered =
                item?.route !== undefined && item?.route !== null;
              return (
                <div
                  key={item.resource_id}
                  className="flex flex-col pl-5 w-full"
                >
                  {/*Parent Div *-------------------*/}
                  {isNavLinkRendered ? (
                    <NavLink
                      to={item.route}
                      className={`pop-up flex flex-row gap-2.5 py-1.5 pr-1.5 whitespace-nowrap w-[241px] h-[34px] hover:bg-[#5D42D1] pl-2 hover:rounded-lg items-center ${
                        selected_resource_id === item.resource_id
                          ? "bg-[#5D42D1] rounded-lg"
                          : ""
                      }`}
                      onClick={() =>
                        dispatch(
                          SideBarActions.setSelectedChild(item.resource_id)
                        )
                      }
                    >
                      <img
                        src={item.icon}
                        className="flex w-5 h-5 aspect-square"
                        alt="img"
                      />
                      <div
                        style={{
                          transform: "translateY(-2.5px)",
                        }}
                        className="grow self-start py-1.5 text-slate-300 text-xs font-semibold font-['Poppins']"
                      >
                        {item.resource_name}
                      </div>
                      {item.children.length ? (
                        <button
                          onClick={() => {
                            handleShowChildren(item.resource_name);
                          }}
                        >
                          {showChildren.includes(item.resource_name) ? (
                            <img
                              src={upArrow}
                              className="w-6 aspect-square"
                              alt="Up Arrow"
                            />
                          ) : (
                            <img
                              src={downArrow}
                              className="w-3 h-2 mr-1.5 aspect-square"
                              alt="Down Arrow"
                            />
                          )}
                        </button>
                      ) : (
                        <></>
                      )}
                    </NavLink>
                  ) : (
                    <div className="flex gap-2.5 py-1.5 pr-1.5 mt-2 whitespace-nowrap">
                      <img
                        src={item.icon}
                        className="w-5 h-5 mt-1 aspect-square"
                        alt="img"
                      />
                      <div className="grow justify-center self-start py-1.5 text-slate-300 text-xs font-semibold font-['Poppins']">
                        {item.resource_name}
                      </div>
                      {item.children.length ? (
                        <button
                          onClick={() => {
                            handleShowChildren(item.resource_name);
                          }}
                        >
                          {showChildren.includes(item.resource_name) ? (
                            <img
                              src={upArrow}
                              className="w-6 aspect-square"
                              alt="Up Arrow"
                            />
                          ) : (
                            <img
                              src={downArrow}
                              className="w-3 h-2 mr-1.5 aspect-square"
                              alt="Down Arrow"
                            />
                          )}
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}

                  {/*Children Div *--------------*/}
                  {showChildren.includes(item.resource_name) && (
                    <Suspense fallback={<div>Loading</div>}>
                      <RenderChildren
                        children={item.children}
                        depth={1}
                        showChildrenData={showChildren}
                      />
                    </Suspense>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
