import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";
import moment from "moment";

interface ReviewersList {
  initial: string;
  email: string;
  id: number;
}

interface CreatePolicyState {
  selectedPolicyNavItem: string;
  timelineCurrentStep: string;
  creationPolicyDetail: {
    author: string;
    author_id: number;
    approverName: string;
    approver_id: number;
    reviewer: any;
    status: string;
    recurrence: string;
    createdAt: string;
    reviewDate: Date | null;
    approverInitials: string;
    department_name: string;
    entity_name: string;
    reviewerInitials: string;
    reviewerName: any;
    policyDocument: any;
  };
  policyReviewData: {
    approver: string;
    reviewer: any;
    result: string;
    comment: string;
  };
  reviewersList: ReviewersList[];
  updatedData: {
    approver: string;
    recurrence: string;
    reviewDate: Date | null;
    department_name: string;
    entity_name: string;
  };
  attachmentFile: any;
  policyAuditLog: {
    count: number;
    rows: any[];
    currentPage: number;
    itemsPerPage: number;
    startDate: Date | null;
    endDate: Date | null;
  };
}

const initialState: CreatePolicyState = {
  selectedPolicyNavItem: "POLICY",
  timelineCurrentStep: "Creation of Policy",
  creationPolicyDetail: {
    author: "",
    author_id: -1,
    approverName: "",
    approver_id: -1,
    reviewer: "",
    status: "",
    recurrence: "",
    createdAt: "",
    reviewDate: null,
    approverInitials: "",
    department_name: "",
    entity_name: "",
    reviewerInitials: "",
    reviewerName: "",
    policyDocument: null,
  },
  policyReviewData: {
    approver: "",
    reviewer: [],
    result: "",
    comment: "",
  },
  reviewersList: [],
  updatedData: {
    approver: "",
    recurrence: "",
    reviewDate: null,
    department_name: "",
    entity_name: "",
  },
  attachmentFile: undefined,
  policyAuditLog: {
    count: 0,
    rows: [],
    currentPage: 1,
    itemsPerPage: 10,
    startDate: null,
    endDate: null,
  },
};

const dateFormatter = (isoTimestamp: string) => {
  const dateObject = new Date(isoTimestamp);
  const formattedDate = dateObject.toISOString().substring(0, 10);

  return formattedDate;
};

function extractDate(isoString: string) {
  const dateObject = new Date(isoString);
  const date = dateObject.toISOString().split("T")[0];
  return date;
}

function extractTime(isoString: string) {
  const dateObject = new Date(isoString);
  const time = dateObject.toTimeString().split(" ")[0];
  return time;
}

const createPolicySlice = createSlice({
  name: "createPolicy",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setSelectedPolicyNavItem(state, action: PayloadAction<any>) {
      state.selectedPolicyNavItem = action.payload;
    },

    setTimeLineCurrentStep(state, action: PayloadAction<any>) {
      state.timelineCurrentStep = action.payload;
    },

    setCreationPolicyDetail(state, action: PayloadAction<any>) {
      if (action.payload.rows) {
        state.creationPolicyDetail = {
          author: action.payload.rows[0].authorName
            ? action.payload.rows[0].authorName.firstName +
              " " +
              action.payload.rows[0].authorName.lastName
            : "",
          author_id: action?.payload?.rows[0].author_id,
          approverName: action.payload.rows[0].approverName
            ? action.payload.rows[0].approverName.firstName +
              " " +
              action.payload.rows[0].approverName.lastName
            : "",
          approver_id: action?.payload?.rows[0].approver_id,
          reviewer: action.payload.rows[0].reviewer_id,
          status: action.payload.rows[0].status,
          recurrence: action.payload.rows[0].recurrence,
          createdAt: dateFormatter(action.payload.rows[0].createdAt),
          reviewDate: action.payload.rows[0].review_date,
          approverInitials: action.payload.rows[0].approverInitials ?? "",
          department_name: action.payload.rows[0].Department.name,
          entity_name: action.payload.rows[0].Group.name,
          reviewerInitials: action.payload.rows[0].reviewerName
            ? action.payload.rows[0].reviewerName[0].initials
            : "",
          reviewerName: action.payload.rows[0].reviewerName
            ? action.payload.rows[0].reviewerName[0].name
            : action.payload.rows[0].reviewer_id,
          policyDocument: action.payload.rows[0].policyDocument,
        };

        state.reviewersList = action.payload.rows[0].reviewerName
          ? action.payload.rows[0].reviewerName.map((item: any) => ({
              initial: item.initials,
              email: item.name,
              id: item.id,
            }))
          : [];

        state.updatedData = {
          approver: action.payload.rows[0].approver_id,
          recurrence: action.payload.rows[0].recurrence,
          reviewDate:
            action.payload.rows[0].review_date ??
            moment().format("D MMMM yyyy").toString(),
          department_name: action.payload.rows[0].Department.name,
          entity_name: action.payload.rows[0].Group.name,
        };

        state.attachmentFile = action.payload.rows[0].policyDocument.length
          ? {
              id: action.payload.rows[0].policyDocument[
                action.payload.rows[0].policyDocument.length - 1
              ].id,
              name: action.payload.rows[0].policyDocument[
                action.payload.rows[0].policyDocument.length - 1
              ].original_name,
              url: action.payload.rows[0].policyDocument[
                action.payload.rows[0].policyDocument.length - 1
              ].url,
              lastModifiedDate: moment(
                action.payload.rows[0].policyDocument[
                  action.payload.rows[0].policyDocument.length - 1
                ].createdAt
              )
                .format("D MMMM yyyy")
                .toString(),
            }
          : undefined;
      } else {
        state.creationPolicyDetail = {
          author: "",
          author_id: -1,
          approverName: "",
          approver_id: -1,
          reviewer: "",
          status: "",
          recurrence: "",
          createdAt: "",
          reviewDate: null,
          approverInitials: "",
          department_name: "",
          entity_name: "",
          reviewerInitials: "",
          reviewerName: "",
          policyDocument: null,
        };
      }
    },

    setPolicyReviewData(state, action: PayloadAction<any>) {
      state.policyReviewData = {
        approver: action.payload[0].approverName,
        reviewer: action.payload[0].reviewerName,
        result: action.payload[0].result,
        comment: "",
      };
    },

    setReviewersList(state, action: PayloadAction<any>) {
      state.reviewersList = action.payload;
    },

    setUpdatedData(state, action: PayloadAction<any>) {
      state.updatedData = action.payload;
    },

    setAttachmentFile(state, action: PayloadAction<any>) {
      state.attachmentFile = action.payload;
    },

    setAuditLogData(state, action: PayloadAction<any>) {
      state.policyAuditLog.rows = action.payload.rows.map((item: any) => ({
        id: item.id ?? "",
        action: item.action ?? "",
        createdOnDate: extractDate(item.createdAt) ?? "",
        createdOnTime: extractTime(item.createdAt) ?? "",
        name: item.name ?? "",
        initials: item.initials ?? "",
        isComment: item?.flag ?? false,
        comment: item?.flag ? item?.PolicyComment?.comment : "",
      }));

      state.policyAuditLog.count = action.payload.count;
    },

    setAuditLogCurrentPage(state, action: PayloadAction<any>) {
      state.policyAuditLog.currentPage = action.payload;
    },

    setAuditLogItemsPerPage(state, action: PayloadAction<any>) {
      state.policyAuditLog.itemsPerPage = action.payload;
    },
  },
});

export const createPolicyActions = createPolicySlice.actions;
export default createPolicySlice;
