import React from "react";

import file from "../../assets/file.svg";

interface ReceiptProps {
  fileName: string;
  fileSize: string;
  onView: () => void;
}

const Receipt: React.FC<ReceiptProps> = ({ fileName, fileSize, onView }) => {
  return (
    <div className="flex items-center justify-between flex-wrap px-4 py-2 border-2 border-[#AD8CF194] rounded-md bg-white gap-2.5">
      <div className="flex gap-2.5 w-[60%]">
        <img src={file} alt="file icon" />
        <div className="w-[80%]">
          <div className="font-semibold font-[Poppins] overflow-hidden text-ellipsis whitespace-nowrap">
            {String(fileName)?.split("/")?.pop()}
          </div>
          <div className="text-sm font-[Poppins] text-gray-500">{fileSize}</div>
        </div>
      </div>
      <button
        onClick={onView}
        className="border-2 font-[Poppins] px-3.5 py-1 border-[#000] rounded-md"
        style={{ borderColor: "var(--22, #4165C4)" }}
      >
        <span className="text">View</span>
      </button>
    </div>
  );
};

export default Receipt;
