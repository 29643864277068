import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styles from "./Modal.module.css";

interface ModalProps {
  open: Boolean;
  onClose: () => void;
  children: React.ReactNode;
  cssClass: string;
}

const Modal: React.FC<ModalProps> = ({ children, open, onClose, cssClass }) => {
  const dialog = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const modal = dialog.current;

    if (modal === null) {
      return;
    }

    if (open) {
      modal.showModal();
    }

    return () => modal.close();
  }, [open]);

  return createPortal(
    <dialog
      ref={dialog}
      className={`${cssClass} ${styles.dialog}`}
      onClose={onClose}
    >
      {children}
    </dialog>,
    document.getElementById("modal") as HTMLDivElement
  );
};

export default Modal;
