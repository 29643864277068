// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTablePagination-root {
    overflow: hidden;
}

.MuiTablePagination-selectLabel {
    color: #7B7A7A;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
}

.MuiTablePagination-input {
    color: black;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-underline-offset: 8px;
}

.MuiButtonBase-root {
    color: black;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
}

.MuiTablePagination-displayedRows {
    color: #7B7A7A;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
}

.MuiSvgIcon-root {
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Pagenation.css"],"names":[],"mappings":"AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,+BAA+B;IAC/B,0BAA0B;AAC9B;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n.MuiTablePagination-root {\n    overflow: hidden;\n}\n\n.MuiTablePagination-selectLabel {\n    color: #7B7A7A;\n    font-family: Poppins;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: normal;\n}\n\n.MuiTablePagination-input {\n    color: black;\n    font-family: Poppins;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: normal;\n    text-decoration-line: underline;\n    text-underline-offset: 8px;\n}\n\n.MuiButtonBase-root {\n    color: black;\n    font-family: Poppins;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: normal;\n}\n\n.MuiTablePagination-displayedRows {\n    color: #7B7A7A;\n    font-family: Poppins;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: normal;\n}\n\n.MuiSvgIcon-root {\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
