import React, { useCallback, useEffect, useState } from "react";
import edit from "../../assets/Crud_Icons/edit.svg";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import TablePaginationDemo from "../common/Pagenation";
import { createGlobalStyle } from "styled-components";
import download from "../../image/Vector (1).png";
import filter from "../../assets/filter.svg";
import moment from "moment";
import eye from "../../assets/Crud_Icons/view.svg";
import { Fetch_Project_List } from "../common/services/Project";
import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import { Chip } from "@material-ui/core";
import TableSkeleton from "../common/TableSkeleton";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Leave_Type {
  id: string;
  project_name: string;
  project_type: string;
  createdAt: string;
  updatedAt: string;
  Client: {
    client_name: string;
  };
  ProjectResources: ProjectResource[];
  status: string;
}

interface ProjectResource {
  User: {
    firstName: string;
  };
}

const Projects = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState<Leave_Type[]>();
  const [dateRange, setDateRange] = useState(false);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Project_List(
        searchTerm,
        numOfItems,
        page,
        startDate,
        endDate,
        customer_id
      );

      if (response.status === 200) {
        setTableData(response.data.result.rows);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType();
  }, [page, numOfItems, searchTerm, startDate, endDate]);

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000), // delay in ms
    [setSearchTerm]
  );

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {};

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {};

  return (
    <>
      <div className="flex justify-between mt-6">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center w-full">
            <Search
              onChange={updateSearchTerm}
              label="Search with Project Name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex items-center gap-2">
                {dateRange && (
                  <Chip
                    label={`${moment(selectedDates[0]).format(
                      "YYYY-MM-DD"
                    )} - ${moment(selectedDates[1]).format("YYYY-MM-DD")}`}
                    onDelete={handleDeleteDate}
                    size="small"
                  />
                )}
              </div>
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text">
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-end mt-6 gap-[31px]">
        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Completed
          </span>
        </div>

        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FF9950" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Ongoing
          </span>
        </div>

        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FA1464" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            On hold
          </span>
        </div>

        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#4DB3DF" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Upcoming
          </span>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        {loading ? (
          <>
            <table className="w-full text-left border-[0.6px] bg-white border-[#E0E3E7]">
              <thead className="bg-[#E1E1E1] w-full">
                <tr className="h-11 w-full">
                  <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22] w-[28%]">
                    Project Name
                  </th>
                  <th className="pl-8 pr-2.5 py-2.5 text-[14px]  text-[#1D1A22] w-[20%]">
                    Project Manager
                  </th>
                  <th className="pl-8 pr-2.5 py-2.5 text-[14px]  text-[#1D1A22] w-[19%]">
                    Client Name
                  </th>
                  <th className="pl-8 pr-2.5 py-2.5 text-[14px]  text-[#1D1A22] w-[19%]">
                    Project Type
                  </th>
                  <th className="pr-6 pl-2.5 py-2.5 text-[14px] text-[#1D1A22] flex justify-end">
                    Action
                  </th>
                </tr>
              </thead>
            </table>
            <TableSkeleton />
          </>
        ) : (
          <table className="w-full text-left border-[0.6px] bg-white border-[#E0E3E7]">
            <thead className="bg-[#E1E1E1] w-full">
              <tr className="h-11 w-full">
                <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22] w-[28%]">
                  Project Name
                </th>
                <th className="pl-8 pr-2.5 py-2.5 text-[14px]  text-[#1D1A22] w-[20%]">
                  Project Manager
                </th>
                <th className="pl-8 pr-2.5 py-2.5 text-[14px]  text-[#1D1A22] w-[19%]">
                  Client Name
                </th>
                <th className="pl-8 pr-2.5 py-2.5 text-[14px]  text-[#1D1A22] w-[19%]">
                  Project Type
                </th>
                <th className="pr-6 pl-2.5 py-2.5 text-[14px] text-[#1D1A22] flex justify-end">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((project) => (
                <tr
                  key={project.id}
                  className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100"
                >
                  <td className="pl-6 pr-2.5 py-1 w-[28%]">
                    <div className="h-full w-full flex items-center gap-[15px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <circle
                          cx="3.99537"
                          cy="3.74537"
                          r="3.49537"
                          fill={
                            project.status === "completed"
                              ? "#00CBA0"
                              : project.status === "ongoing"
                              ? "#FF9950"
                              : project.status === "onhold"
                              ? "#FA1464"
                              : project.status === "upcoming"
                              ? "#4DB3DF"
                              : "#D3D3D3" // default color if status is unknown
                          }
                        />
                      </svg>
                      <span className="text-[#3F4254] font-[500] text-[12px] overflow-hidden text-ellipsis whitespace-nowrap w-32">
                        {project?.project_name}
                      </span>
                    </div>
                  </td>
                  <td className="text-[#3F4254] font-[500]  text-[12px] pl-8 pr-2.5 py-1 w-[18%]">
                    {project.ProjectResources.map(
                      (resource) => resource?.User?.firstName
                    ).join(", ")}
                  </td>
                  <td className="text-[#3F4254] font-[500]  text-[12px] pl-8 pr-2.5 py-1 w-[18%]">
                    {project.Client?.client_name}
                  </td>
                  <td className="text-[#3F4254] font-[500]  text-[12px] pl-8 pr-2.5 py-1 w-[18%]">
                    {project?.project_type}
                  </td>
                  <td className="pr-6 pl-2.5 py-1">
                    <div className="flex justify-end items-center gap-2">
                      <img
                        src={eye}
                        onClick={() =>
                          navigate("/view-project-details", {
                            state: { id: project.id },
                          })
                        }
                        alt="View"
                        className="p-0.5 hover:bg-[#E6E6E6] cursor-pointer rounded-md"
                      />
                      <img
                        src={edit}
                        onClick={() =>
                          navigate(`/edit-project-details`, {
                            state: { id: project.id },
                          })
                        }
                        alt="Edit"
                        className="p-0.5 hover:bg-[#E6E6E6] cursor-pointer rounded-md"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
      <Filter
        isDate
        isOther={false}
        heading="Project Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </>
  );
};

export default Projects;
