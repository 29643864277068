import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { revertAll } from '../../ResetRedux/RevertAll';
 
interface InvoiceState {
    invoiceData: {
        "count": number,
        "rows": any[],
        "currentPage": number,
        "itemsPerPage": number,
        "startDate": Date | null,
        "endDate": Date | null,
    };
}
 
const initialState: InvoiceState = {
    invoiceData: {
        "count": 0,
        "rows": [],
        "currentPage": 1,
        "itemsPerPage": 10,
        "startDate": null,
        "endDate": null,
    },
};
 
const dateFormatter = (isoTimestamp: string) => {
    const dateObject = new Date(isoTimestamp);
    const formattedDate = dateObject.toISOString().substring(0, 10);
   
    return formattedDate;
}

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setInvoiceData(state, action: PayloadAction<any>){
            state.invoiceData.rows = action.payload.rows.map((item: any) => ({
                "id": item.id ?? "",
                "invoiceNumber": item.invoice_number ?? "",
                "plan": item.plan ?? "",
                "date": dateFormatter(item.createdAt) ?? "",
                "discount": item.discount ?? "",
                "totalTax": item.tax ?? "",
                "totalAmount": item.total_amount ?? "",
                "status": item.status ?? "",
                "url": item.url ?? "",
            }));
 
            state.invoiceData.count = action.payload.count;
        },

        setInvoiceCurrentPage(state, action: PayloadAction<any>){
            state.invoiceData.currentPage = action.payload;
        },

        setInvoiceItemsPerPage(state, action: PayloadAction<any>){
            state.invoiceData.itemsPerPage = action.payload;
        },

        setStartDate(state, action: PayloadAction<Date | null>) {
            state.invoiceData.startDate = action.payload;
        },
          setEndDate(state, action: PayloadAction<Date | null>) {
            state.invoiceData.endDate = action.payload;
        },        
    }
});
 
export const invoiceActions = invoiceSlice.actions;
export default invoiceSlice;