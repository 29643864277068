import React, { useCallback, useEffect, useState, useRef } from "react";
import del from "../../../assets/Crud_Icons/delete.svg";
import edit from "../../../assets/Crud_Icons/edit.svg";
import filter from "../../../assets/filter.svg";
import TablePaginationDemo from "../../common/Pagenation";
import { useNavigate } from "react-router-dom";
import download from "../../../assets/download_new.svg";
import { Fetch_Holiday_List } from "../../common/services/Holiday";
import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import { debounce } from "lodash";
import Search from "../../SearchBar/Search";
import Filter from "../../Filter/Filter";
import { Chip } from "@material-ui/core";
import moment from "moment";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface RowData {
  holiday_date: string;
  holiday_name: string;
  description: string;
  id: string;
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancel: () => void;
}> = ({ onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Holiday
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this item?
        </h4>
        <div className="flex space-x-4 justify-center">
          <button
            onClick={onDelete}
            className="buttoncss text-white px-12 py-2 rounded mr-2"
          >
            Yes
          </button>
          <button
            onClick={onCancel}
            className="borderButton text-black rounded px-12 py-2"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const Holiday = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<RowData | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState(false);
  const bussness: Business_Unit[] = [];
  const department: Department_Unit[] = [];
  const sortType = { key: "holiday_date", order: "ASC" };

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }
  const handleDeleteClick = (item: RowData) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000), // delay in ms
    [setSearchTerm]
  );

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/holiday/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(tableData.filter((item) => item.id !== selectedItem.id));
        } else {
          console.error("Failed to delete the item");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setShowModal(false);
        setSelectedItem(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Holiday_List(
        numOfItems,
        page,
        searchTerm,
        startDate,
        endDate,
        sortType
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    fetchData();
  }, [
    page,
    numOfItems,
    searchTerm,
    startDate,
    endDate,
    startSession,
    endSession,
  ]);

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {};

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {};

  return (
    <>
      <div className="flex justify-between mt-6 w-full">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center w-full">
            <Search
              onChange={updateSearchTerm}
              label="Search with Holiday Name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex items-center gap-2">
                {dateRange && (
                  <Chip
                    label={`${moment(selectedDates[0]).format(
                      "YYYY-MM-DD"
                    )} - ${moment(selectedDates[1]).format("YYYY-MM-DD")}`}
                    onDelete={handleDeleteDate}
                    size="small"
                  />
                )}
              </div>
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text">
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto mt-4 shadow-sm">
        <table className="w-full min-w-max text-left table-auto border-[0.6px] bg-white border-[#E0E3E7]">
          <thead className="bg-[#E1E1E1]">
            <tr className="h-11">
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22] ">
                Date
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22] ">
                Holiday
              </th>
              <th className="pl-10 pr-2.5 py-2.5 text-[14px] text-[#1D1A22] ">
                Description
              </th>
              <th className="flex justify-end pr-6 pl-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((items) => (
              <tr className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100">
                <td className="text-[#3F4254] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {moment(items.holiday_date).format("DD MMM YYYY")}
                </td>
                <td className="text-[#3F4254] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {items.holiday_name}
                </td>
                <td className="text-[#3F4254] font-[500] text-[12px] pl-10 pr-2.5 py-1">
                  {items?.description || "--"}
                </td>
                <td className="pr-6 pl-2.5py-1">
                  <div className="flex justify-end items-end gap-2">
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      src={edit}
                      onClick={() =>
                        navigate("/edit-holiday", {
                          state: { id: items.id },
                        })
                      }
                      alt="Edit"
                    />
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      src={del}
                      alt="Delete"
                      onClick={() => handleDeleteClick(items)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showModal && (
          <DeleteConfirmationModal
            onDelete={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}

      <Filter
        isDate
        isOther={false}
        heading="Holiday Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </>
  );
};

export default Holiday;
