import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface CustomerManagementState {
  count: number;
  CustomerManagementData: any[];
  customerData: {};
  currentPage: number;
  itemsPerPage: number;
  startDate: Date | null;
  endDate: Date | null;
  isLoading: boolean,
}

const initialState: CustomerManagementState = {
  count: 0,
  CustomerManagementData: [],
  customerData: {},
  currentPage: 1,
  itemsPerPage: 10,
  startDate: null,
  endDate: null,
  isLoading: false,
};

const CustomerManagementSlice = createSlice({
  name: "CustomerManagement",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setCustomerManagementData(state, action: PayloadAction<any>) {
      state.CustomerManagementData = action.payload.rows;
      state.isLoading = true;
      state.count = action.payload.count;
      console.log(action.payload.rows);
    },
    setCustomerManagementDataCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload;
    },
    setCustomerManagementItemsPerPage(state, action: PayloadAction<any>) {
      state.itemsPerPage = action.payload;
    },

    setStartDate(state, action: PayloadAction<Date | null>) {
      state.startDate = action.payload;
    },
    setEndDate(state, action: PayloadAction<Date | null>) {
      state.endDate = action.payload;
    },


    setCustomerData(state, action: PayloadAction<any>) {
      const updated = action.payload;
      console.log(updated);
      state.customerData = {
        id: updated.id,
        status: updated.status,
        firstName: updated.firstName,
        lastName: updated.lastName,
        email: updated.email,
        address: updated.address,
        phone: updated.phone,
        country_code: updated.country_code,
        customer_details: updated.Customer,
      };

      console.log(state.customerData, "stae customer managemen ");
    },
  },
});

export const CustomerManagementActions = CustomerManagementSlice.actions;
export default CustomerManagementSlice;
