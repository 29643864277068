import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import { Box, MenuItem, TextField } from "@mui/material";
import MyStatefulEditor from "../ProjectManagement/EditEditor";

function EditDSR() {
  const navigate = useNavigate();
  const [editorContent, setEditorContent] = useState<string>("");

  const Status = [
    { value: "Completed", label: "Completed" },
    { value: "Pending", label: "Pending" },
  ];
  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-[50px]">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/employee-dsr")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
              Edit DSR Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/employee-dsr")}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            >
              <div className="text text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <button
              type="submit"
              form="form"
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
              //   onClick={handleUpdate}
            >
              <div className="text-white text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
                Update
              </div>
            </button>
          </div>
        </div>
        <div className="rounded-lg shadow-custom p-4 bg-white">
          <div className="flex flex-col gap-8">
            <span className="font-[Poppins] font-medium tect-[16px] leading-[16px] text-[#262626]">
              Task Details
            </span>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              display="flex"
              flexDirection="column"
              gap="32px"
            >
              <div className="grid grid-cols-3 gap-8">
                <TextField
                  disabled
                  id="outlined-required"
                  label="Task Name"
                  name="task_name"
                //   value="Hello"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                    "& .MuiInputBase-root.Mui-disabled": {
                      color: "#00000099",
                    },
                    "& .MuiOutlinedInput-notchedOutline.Mui-disabled": {
                      borderColor: "#00000099",
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: "#00000099",
                    },
                  }}
                />
                <TextField
                  label="Duration"
                  name="duration"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }}
                  variant="outlined"
                />
                <TextField
                  select
                  id="outlined-required"
                  label="Status"
                  name="status"
                  // value={editData.title}
                  // onChange={handleFormChange}
                >
                  {Status?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-span-3">
                <MyStatefulEditor
                  content={editorContent}
                  onChange={setEditorContent}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditDSR;
