import moment from "moment";
import { api_key_work as api_key } from "../../../utils/helperData";
import {
  FETCH_GROUP_LISTING,
  SEND_COMPANY_STRUCTURE_BUSSINESS_UNIT,
  SEND_COMPANY_STRUCTURE_DEPARTMENT,
  SEND_COMPANY_STRUCTURE_PROCESS,
  VIEW_GROUP_DETAILS,
} from "../api";

export const Company_Structure_Group_List = (customer_id: number) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;
  return fetch(`${FETCH_GROUP_LISTING}${query}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Company_Structure_View_Group_List = (
  customer_id: number,
  page: number,
  numOfItems: number,
  sortType: any,
  group_id: number,
  type:any,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&page=${page}&size=${numOfItems}`;
  if (sortType !== undefined) {
    query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (startDate instanceof Date && endDate instanceof Date) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return fetch(`${VIEW_GROUP_DETAILS}${query}&id=${group_id}&type=${type}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Send_Create_Bussiness_Unit_Data = (
  name: string,
  spoc_id: number,
  customer_id: number,
  parent_id: any
) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SEND_COMPANY_STRUCTURE_BUSSINESS_UNIT}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "x-api-key": api_key,
    },
    body: JSON.stringify({
      name,
      spoc_id,
      customer_id,
      parent_id,
    }),
  });
};

export const Send_Create_Department_Unit_Data = (
  name: string,
  spoc_id: number,
  customer_id: number,
  group_id: any,
  parent_id: any
) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SEND_COMPANY_STRUCTURE_DEPARTMENT}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "x-api-key": api_key,
    },
    body: JSON.stringify({
      name,
      spoc_id,
      customer_id,
      group_id,
      parent_id,
    }),
  });
};

export const Send_Create_Process_Unit_Data = (
  name: string,
  spoc_id: number,
  customer_id: number,
  department_id: any,
  parent_id: any
) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SEND_COMPANY_STRUCTURE_PROCESS}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "x-api-key": api_key,
    },
    body: JSON.stringify({
      name,
      spoc_id,
      customer_id,
      department_id,
      parent_id: null,
    }),
  });
};


export const Get_Company_Structure_Department = (customer_id:number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SEND_COMPANY_STRUCTURE_DEPARTMENT}/${customer_id}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};