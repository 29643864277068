import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface ChangePasswordState {
  changePasswordData: {};
  isLoading: boolean,
}

const initialState: ChangePasswordState = {
  changePasswordData: {},
  isLoading: false,
};

const ChangePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setChangePasswordData(state, action: PayloadAction<any>) {
      const updated = action.payload;
      console.log(updated);
      state.changePasswordData = {
        id: updated.id,
        current_password:updated.current_password,
        password: updated.password  ,
        confirm_password: updated.confirm_password,
      };

      console.log(state.changePasswordData, "stae changePasswordData managemen ");
    },
  },
});

export const ChangePasswordActions = ChangePasswordSlice.actions;
export default ChangePasswordSlice;

