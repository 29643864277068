import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import right from "../../../image/chevron-right.png";
import maskPlus from "../../../image/Mask group.svg";

import AddQuestionModal from "./AddQuestion";

function EditParameters() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSave = () => {};

  return (
    <div className="flex flex-col gap-6 mt-6">
      <div className="h-12 flex justify-between items-start">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/configure-managers")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
            Edit Parameters Details
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            onClick={() => navigate("/appraisal-management")}
          >
            <div className="text text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
          >
            <div
              className="text-white text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize"
              onClick={() => navigate("/appraisal-management")}
            >
              Update
            </div>
          </button>
        </div>
      </div>
      <div className="flex justify-end h-11">
        <button
          className="py-[9.5px] px-[19px] flex justify-between items-center buttoncss rounded-lg gap-[7px]"
          onClick={() => setIsOpen(true)}
        >
          <img src={maskPlus} alt="add icon" />
          <span className="font-[Poppins] font-medium text-[14px] leading-[29px] text-white">
            Add Question
          </span>
        </button>
      </div>
      {isOpen ? (
        <AddQuestionModal
          onCancel={() => setIsOpen(false)}
          onSave={handleSave}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default EditParameters;
