import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { updateExpenseType } from "../common/services/ExpenseType";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Expense_Type_Unit } from "../../redux/actions/ExpenseType/ExpenseTypeAction";
import { useSelector } from "react-redux";
import axios from "axios";

interface FormValues{
  expense_category_name:string,
}


const EditExpenseType = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const expenseType = useSelector((state: any) => state.expenseType.ExpenseType);

  console.log(expenseType,'expenseType----------->>>>>>>>>>>');
  

  const dispatch = useDispatch();
  const handleOpen = () => {
    validate();
    submitChanges();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/clients");
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/clients");
  }

  const { api } = useParams();
  const location = useLocation();
  const id = location.state?.id;

  console.log(api,"api",id,"id");

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  useEffect(() => {
    console.log("callll----->>>>");
    dispatch<any>(Expense_Type_Unit(customer_id,navigate, id));
  }, []);

  useEffect(() => {
    if (expenseType) {

      setFormValues({
        expense_category_name:expenseType.expense_category_name
      })
    }
  }, [expenseType]);

  const [formValues, setFormValues] = useState<FormValues>({
    expense_category_name:"",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({})

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {}
    if(formValues.expense_category_name === "")
      tempErrors.expense_category_name = "Category Name is required"

    setErrors(tempErrors)
    return Object.keys(tempErrors).length === 0;
  }

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    // console.log("Request body:", body); // Print the body

    setLoading(true);
    try {
      const data = await updateExpenseType(
        id,
        formValues.expense_category_name
      );

      if (data && data.result) {
        console.log("final data:", data);
        //toast.success(data.message)
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          navigate("/clients");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  return (
    <div className="min-h-[90vh] px-4 py-6 bg-[#F9F9F9]">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/clients")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Edit Expense Type Details
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={handleCancel}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
          </button>
        </div>
      </div>

      <div className="mt-10 rounded-md overflow-hidden shadow-custom bg-white">
        <div className="container mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Declaration Type"
                placeholder="Enter Declaration"
                name="expense_category_name"
                value={formValues.expense_category_name}
                onChange={handleFrormChange}
                error={!!errors.expense_category_name}
                helperText={errors.expense_category_name}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EditExpenseType;
