import React, { useEffect, useState } from "react";
import polygon from "../../assets/Polygon 1.png";
import icon from "../../assets/icon.png";
import { Fetch_Company_Details_List } from "../common/services/companyManagement";

interface CompanyDetails {
  id: number;
  name: string;
  country_code: string | null;
  status: string;
  is_active: boolean;
  email: string;
  hr_email: string | null;
  address: string;
  address_secondary: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  business_size: string;
  industry_vertical: number;
  createdby: string | null;
  logo: string;
  website: string | null;
  start_date: string | null;
  modifiedby: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  industry_vertical_name: string | null;
}

interface Leave_Type {
  business_size: string;
}

export const Details = () => {
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails | null>(
    null
  );

  const fetchLeaveType = async () => {
    try {
      const response = await Fetch_Company_Details_List();

      if (response.status === 200) {
        // console.log("Data: company management Tpye", response.data);
        setCompanyDetails(response.data.result);

      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchLeaveType();
  }, []);

  return (
    <div className="pt-11">
      <div className="flex flex-col gap-0 bg-white shadow-md">
        <div
          className="h-14 flex flex-row items-center border border-[#E0E0E0] bg-gradient-to-r"
          style={{
            background:
              "linear-gradient(90deg, rgba(65, 101, 196, 0.2) 0%, rgba(142, 31, 210, 0.2) 100%)",
          }}
        >
          <div className="relative flex flex-row gap-[12px] items-center py-4 px-5">
            <div className="flex flex-row items-center">
              <img src={companyDetails?.logo} alt="" className="relative w-8" />
              {/* <img src={logo} alt="" className="absolute translate-x-2" /> */}
            </div>
            <span
              className="h-5 font-['Poppins'] flex flex-row items-center font-semibold text-[19px] leading-[11px] text-transparent bg-clip-text"
              style={{
                background:
                  "linear-gradient(90deg, rgba(65, 101, 196, 1) 0%, rgba(142, 31, 210, 1) 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {/* {companyDetails.} */}
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <table className="text-left w-[45%]">
            <tbody>
              <tr>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#322F37] w-[45%]">
                  Organisation Name:
                </td>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#605D66]">
                  {companyDetails?.name}
                </td>
              </tr>
              <tr>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#322F37] w-[45%]">
                  Business Size:
                </td>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#605D66]">
                  {companyDetails?.business_size}
                </td>
              </tr>
              <tr>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#322F37] w-[45%]">
                  Country:
                </td>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#605D66]">
                  {companyDetails?.country}
                </td>
              </tr>
              <tr>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#322F37] w-[45%]">
                  City:
                </td>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#605D66]">
                  {companyDetails?.city}
                </td>
              </tr>
              <tr>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#322F37] w-[45%]">
                  Postal Code:
                </td>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#605D66]">
                  {companyDetails?.postal_code}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="text-left w-[55%]">
            <tbody>
              <tr>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#322F37]">
                  Organisation's Email:
                </td>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#605D66]">
                  {companyDetails?.email}
                </td>
              </tr>
              <tr>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#322F37]">
                  Company Type:
                </td>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#605D66]">
                  {companyDetails?.industry_vertical_name}
                </td>
              </tr>
              <tr>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#322F37]">
                  State:
                </td>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#605D66]">
                  {companyDetails?.state}
                </td>
              </tr>
              <tr>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#322F37]">
                  Address:
                </td>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#605D66]">
                  {companyDetails?.address}
                </td>
              </tr>
              <tr>
                <td className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#322F37]">
                  Website:
                </td>
                <td
                  className="h-11 font-['Poppins'] font-medium text-[14px] leading-[18px] text-[#605D66] cursor-pointer hover:text-blue-700"
                  onClick={() =>
                    window.open("https://appvintech.com/", "_blank")
                  }
                >
                  {companyDetails?.website || "--"}
                </td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
