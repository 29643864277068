import { TagData, GroupStatus, ResponseItem } from "../redux/types/policyManagement";
import { Group } from "../redux/types/userManagement";
import security from "../assets/frame.svg"
import ethics from "../assets/security-user.svg";
import ai from "../assets/external-drive.svg";
import esg from "../assets/attach-circle.svg";
import privacy from "../assets/shield-security.svg";
// import { Dispatch } from "redux";


// type ActionSet = {
//   setCurrentPage: (page: number) => any;
// };

// export const handleNavigationCommon = (
//     dispatch: Dispatch,
//     setIsNavigatingToSpecificRoute: (value: boolean) => void,
//     navigate: (route: string, options?: { state?: any }) => void,
//     actions: ActionSet
//   ) => (route: string, state?: any, page?: number, persistPage: boolean=false) => {
//     if (persistPage) {
//       setIsNavigatingToSpecificRoute(true);
//       dispatch(actions.setCurrentPage(page ?? 1));
//     } else {
//       setIsNavigatingToSpecificRoute(false);
//       dispatch(actions.setCurrentPage(1));
//     }
  
//     navigate(route, { state });
//   };


export const serviceData = [
  { id: 1, src: privacy, label: "Privacy & data protection", available: true },
  { id: 2, src: security, label: "Security", available: false },
  { id: 3, src: ethics, label: "Digital trust ethics", available: false },
  { id: 4, src: ai, label: "AI", available: false },
  { id: 5, src: esg, label: "ESG", available: false },
];

export const ViewUserForm = [
  {
    autoComplete: "createdAt",
    name: "createdAt",
    id: "createdAt",
    label: "Added Date",
    placeholder: "Added Date",
  },
  {
    autoComplete: "updatedAt",
    name: "updatedAt",
    id: "updatedAt",
    label: "Last Updated Date",
    placeholder: "Updated Date",
  },
];

export const maturityData = [
  {
    id: 6,
    title: "Ad hoc",
    description:
      "Procedures or processes are generally informal,  incomplete and inconsistently applied.",
  },
  {
    id: 7,
    title: "Repeatable",
    description:
      "Procedures or processes exist;however they are not fully documented and do not and cover all relevant aspects",
  },
  {
    id: 8,
    title: "Defined",
    description:
      "Procedures and processes are fully documented and implemented, and cover all relevant aspects",
  },
  {
    id: 9,
    title: "Managed",
    description:
      "Reviews are conducted to access the effectiveness of the controls in place.",
  },
  {
    id: 10,
    title: "Optimized",
    description:
      "Regular review and feedback are used to ensure continuous improvement towards optimization of the given process.",
  },
];

export const questionnaireData = [
  "Do you want to start a new privacy implementation project?",
  "Do you want to review an existing privacy, data protection or security project?",
  "Is a comprehensive privacy policy available (evidence based)?",
  "Are all privacy, data protection and security role defined?",
  "Do you operate a website or any mobile app?",
  "Are data subject request procedures operational, checked and audited?",
  "Would you like to perform an impact analysis?",
  "Are all relevant, data protection and security measures in place(and can be evidenced)?",
  "Is your data breach protocol checked and audited by a trusted third party?",
  "Do you transfer personal data outside your country?",
  "Do you prefer to be assisted by a privacy expert?",
];

export const resultData = [
  {
    id: 11,
    title: "Basic",
    amount: "500",
    text1: "6 free modules",
    text2: "First Month Free",
    text3: "1 User",
    label1: "Register of processing Activities(RPA)",
    label2: "Data protection impact assessment ...",
    modules: "4",
  },
  {
    id: 12,
    title: "Standard",
    amount: "1000",
    text1: "10 free modules",
    text2: "First Month Free",
    text3: "5 User",
    label1: "Register of processing Activities(RPA)",
    label2: "Data protection impact assessment ...",
    modules: "8",
  },
  {
    id: 13,
    title: "Advance",
    amount: "2000",
    text1: "20 free modules",
    text2: "First Month Free",
    text3: "10 User",
    label1: "Register of processing Activities(RPA)",
    label2: "Data protection impact assessment ...",
    modules: "18",
  },
  {
    id: 14,
    title: "Enterprise",
    amount: "3000",
    text1: "30 free modules",
    text2: "First Month Free",
    text3: "20 User",
    label1: "Register of processing Activities(RPA)",
    label2: "Data protection impact assessment ...",
    modules: "28",
  },
];

export const moduleInfo = [
  {
    id: 10,
    title: "Advance",
    module: "20",
    data: [
      {
        label: "Register of Processing Activities (ROPA)",
        description:
          "Lorem ipsum dolor sit amet consectetur. Scelerisque sit praesent condimentum ut rhoncus augue mauris. Duis donec nec adipiscing eu scelerisque diam nunc tellus facilisi. Nec orci amet ullamcorper mi ut viverra. Ligula vitae in elit enim donec. Pretium eu consequat tincidunt enim et sodales fringilla. Ut viverra mi venenatis aliquet laoreet sit odio. Rutrum sed faucibus tortor urna massa. Congue ut lectus id eu molestie. Viverra aliquet vestibulum mattis quis aliquet. Praesent dolor a ullamcorper amet sit tristique massa arcu. Faucibus sit commodo malesuada duis cras volutpat molestie elementum id. Sit eget sit non pellentesque pellentesque dolor mi volutpat. Eu leo eget facilisis amet bibendum. Pretium id tellus purus porttitor.",
      },
      {
        label: "Data Protection Impact Assessment (DPIA)",
        description:
          "Lorem ipsum dolor sit amet consectetur. Scelerisque sit praesent condimentum ut rhoncus augue mauris. Duis donec nec adipiscing eu scelerisque diam nunc tellus facilisi. Nec orci amet ullamcorper mi ut viverra. Ligula vitae in elit enim donec. Pretium eu consequat tincidunt enim et sodales fringilla. Ut viverra mi venenatis aliquet laoreet sit odio. Rutrum sed faucibus tortor urna massa. Congue ut lectus id eu molestie. Viverra aliquet vestibulum mattis quis aliquet. Praesent dolor a ullamcorper amet sit tristique massa arcu. Faucibus sit commodo malesuada duis cras volutpat molestie elementum id. Sit eget sit non pellentesque pellentesque dolor mi volutpat. Eu leo eget facilisis amet bibendum. Pretium id tellus purus porttitor.",
      },
      {
        label: "e-Dictionary Data Protection",
        description:
          "Lorem ipsum dolor sit amet consectetur. Scelerisque sit praesent condimentum ut rhoncus augue mauris. Duis donec nec adipiscing eu scelerisque diam nunc tellus facilisi. Nec orci amet ullamcorper mi ut viverra. Ligula vitae in elit enim donec. Pretium eu consequat tincidunt enim et sodales fringilla. Ut viverra mi venenatis aliquet laoreet sit odio. Rutrum sed faucibus tortor urna massa. Congue ut lectus id eu molestie. Viverra aliquet vestibulum mattis quis aliquet. Praesent dolor a ullamcorper amet sit tristique massa arcu. Faucibus sit commodo malesuada duis cras volutpat molestie elementum id. Sit eget sit non pellentesque pellentesque dolor mi volutpat. Eu leo eget facilisis amet bibendum. Pretium id tellus purus porttitor.",
      },
      {
        label: "Handbook Data Protection Officer (DPO)",
        description:
          "Lorem ipsum dolor sit amet consectetur. Scelerisque sit praesent condimentum ut rhoncus augue mauris. Duis donec nec adipiscing eu scelerisque diam nunc tellus facilisi. Nec orci amet ullamcorper mi ut viverra. Ligula vitae in elit enim donec. Pretium eu consequat tincidunt enim et sodales fringilla. Ut viverra mi venenatis aliquet laoreet sit odio. Rutrum sed faucibus tortor urna massa. Congue ut lectus id eu molestie. Viverra aliquet vestibulum mattis quis aliquet. Praesent dolor a ullamcorper amet sit tristique massa arcu. Faucibus sit commodo malesuada duis cras volutpat molestie elementum id. Sit eget sit non pellentesque pellentesque dolor mi volutpat. Eu leo eget facilisis amet bibendum. Pretium id tellus purus porttitor.",
      },
      {
        label: "Security Checklist Basic",
        description:
          "Lorem ipsum dolor sit amet consectetur. Scelerisque sit praesent condimentum ut rhoncus augue mauris. Duis donec nec adipiscing eu scelerisque diam nunc tellus facilisi. Nec orci amet ullamcorper mi ut viverra. Ligula vitae in elit enim donec. Pretium eu consequat tincidunt enim et sodales fringilla. Ut viverra mi venenatis aliquet laoreet sit odio. Rutrum sed faucibus tortor urna massa. Congue ut lectus id eu molestie. Viverra aliquet vestibulum mattis quis aliquet. Praesent dolor a ullamcorper amet sit tristique massa arcu. Faucibus sit commodo malesuada duis cras volutpat molestie elementum id. Sit eget sit non pellentesque pellentesque dolor mi volutpat. Eu leo eget facilisis amet bibendum. Pretium id tellus purus porttitor.",
      },
    ],
  },
];

export const paymentConfirmationDetails = {
  amount: 2000,
  mail: "abc@xyz.com",
  phone: 621260106,
  address: "Churchill-laan 266/III 1078 GA AMSTERDAM, Netherlands",
  verificationFees: 1,
  base: 775,
  coupon: "LRFREE",
};

export const CustomerManagementForm = [
  {
    autoComplete: "given-name",
    name: "customerName",
    id: "customerName",
    label: "Customer Name",
    placeholder: "Enter Customer name",
  },
  {
    autoComplete: "email",
    name: "email",
    id: "email",
    label: "E-mail",
    placeholder: "Enter Email",
  },
  {
    autoComplete: "address",
    name: "address",
    id: "address",
    label: "Address",
    placeholder: "Enter Address",
  },
];

export const UserManagementForm = [
  {
    autoComplete: "given-name",
    name: "firstName",
    id: "firstName",
    label: "First Name",
    placeholder: "Enter First name",
  },
  {
    autoComplete: "family-name",
    name: "lastName",
    id: "lastName",
    label: "Last Name",
    placeholder: "Enter Last name",
  },
  {
    autoComplete: "email",
    name: "email",
    id: "email",
    label: "E-mail",
    placeholder: "Enter Email",
  },
];

export const labelStyle = {
  color: 'rgba(0, 0, 0, 0.60)',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: "500",
  lineHeight: '18px',
};

export const inputStyle = {
  color: "#464646",
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: "500",
  lineHeight: 'normal',
  letterSpacing: "-0.165px",
  display: 'flex',
  padding: '0px 12px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: '10px',
  border: 'var(--Our_Colors-Grays-400, #C5CDD4)',
  height: "56px",
  width: '100%'
};

export const menuStyle = {
  color: "#464646",
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: "400",
  lineHeight: 'normal',
  letterSpacing: "-0.165px"
};

export const RoleManagementForm = [
  {
    autoComplete: "given-name",
    name: "roleName",
    id: "roleName",
    label: "Role Name",
    placeholder: "Enter role name",
  },
];

export const DesignationForm = [
  {
    autoComplete: "given-name",
    name: "designation",
    id: "designation",
    label: "Designation",
    placeholder: "Enter Designation name",
  },
];

// export const ViewUserForm = [
//     { autoComplete:"createdAt", name:"createdAt", id:"createdAt", label:"Added Date", placeholder:"Added Date"},
//     { autoComplete:"updatedAt", name:"updatedAt", id:"updatedAt", label:"Last Updated Date", placeholder:"Updated Date"},
// ]

export const api_key_work = process.env.REACT_APP_KEY || "10l33t140//:c04ht001";
export const api_key_home = process.env.REACT_APP_KEY_HOME || ":l6al9/62op3336olh00";
//export const base_api = process.env.REACT_APP_BASE_API || "http://13.201.13.107:5000";
export const base_api = process.env.REACT_APP_BASE_API || "https://devapi.go-emp.com";
export const session_query = `session_start=${localStorage.getItem("start_session")}&session_end=${localStorage.getItem("end_session")}`
console.log("test279",base_api)

const currencyFormatter = (num: any) => {
  return String(num) + ".00";
}

const validateEmail = (email: any) => {
  // Regular expression for a valid email address
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return emailPattern.test(email);
}

const validatePhoneNumber = (phoneNumber: any) => {
  // const num = phoneNumber.toString();

  // var regex = /^[0-9]{8,16}$/;
  // if (num.match(regex)) {
  //   return true;
  // } else {
  //   return false;
  // }

  const cleanedNumber = phoneNumber.toString();

  const regex = /^(\+?\d{1,3})?(\d{6,15})$/;

  if (cleanedNumber.match(regex)) {
    return true;
  } else {
    return false;
  }
}

const flattenGroupData = (groupData: Group[]): any => {
  const flattenedRecords: Group[] = [];

  function flattenGroup(group: Group[]) {
    group.forEach(item => {
      const record = {
        id: item.id,
        group_name: item.group_name,
        parent_id: item.parent_id
      };
      flattenedRecords.push(record);
      if (item.children && item.children.length > 0) {
        flattenGroup(item.children);
      }
    });
  }

  flattenGroup(groupData);

  return flattenedRecords;
}

const convertToISO8601 = (dateStr: string): string => {
  try {
    // Parse the input date string
    const inputDate: Date = new Date(dateStr);

    // Convert to ISO 8601 format (UTC)
    const iso8601Date: string = inputDate.toISOString();
    return iso8601Date;
  } catch (error) {
    return "Invalid date format. Please provide a valid date string.";
  }
}

const convertResponse = (response: ResponseItem[]): { [key: string]: GroupStatus } => {
  if (!response) {
    return {};
  }

  let result: { [key: string]: GroupStatus } = {};
  for (let item of response) {
    let groupName = item.department_name;
    let status = item["status"].toLowerCase().replace(" ", "_");
    let count = parseInt(item["count"]);

    if (!(groupName in result)) {
      result[groupName] = {
        "not_uploaded": null,
        "draft": null,
        "approved": null,
        "under_review": null,
        "published": null
      };
    }

    result[groupName][status] = count;
  }

  return result;
}

const separateTagData = (data: Record<string, TagData>): Record<string, any[]> => {
  const tags = Object.keys(data);
  const not_uploaded_data: any[] = [];
  const draft_data: any[] = [];
  const approved_data: any[] = [];
  const under_review_data: any[] = [];
  const published_data: any[] = [];

  tags.forEach(tag => {
    const { not_uploaded, draft, approved, under_review, published } = data[tag];
    not_uploaded_data.push(not_uploaded);
    draft_data.push(draft);
    approved_data.push(approved);
    under_review_data.push(under_review);
    published_data.push(published);
  });

  return { tags, not_uploaded_data, draft_data, approved_data, under_review_data, published_data };
}

const calculateStatsAndPercentage = (
  not_uploaded_data: number[],
  draft_data: number[],
  approved_data: number[],
  under_review_data: number[],
  published_data: number[]
): { stats: Record<string, number>; percentages: Record<string, string> } => {
  // Calculate the total for each label
  const stats: Record<string, number> = {
    "Not Uploaded": not_uploaded_data.reduce((acc, val) => acc + val, 0),
    "Draft": draft_data.reduce((acc, val) => acc + val, 0),
    "Approved": approved_data.reduce((acc, val) => acc + val, 0),
    "Needs Review": under_review_data.reduce((acc, val) => acc + val, 0),
    "Published": published_data.reduce((acc, val) => acc + val, 0)
  };

  // Calculate the total across all arrays
  const total = Object.values(stats).reduce((acc, val) => acc + val, 0);

  // Calculate the percentage for each label
  const percentages: Record<string, string> = {};
  for (const label in stats) {
    let percentage = (stats[label] / total) * 100;
    // Check if the percentage is an integer
    if (!Number.isInteger(percentage)) {
      // If not, add decimal places
      percentage = Number(percentage.toFixed(2));
    }
    percentages[label] = String(percentage);
  }

  return { stats, percentages };
}

export { currencyFormatter, convertResponse, separateTagData, calculateStatsAndPercentage, validateEmail, validatePhoneNumber, flattenGroupData, convertToISO8601 };



export interface Ticket {
  id: number;
  name: string;
  priority: string;
  description: string;
}

export interface ApiResponse {
  success: boolean;
  status_code: number;
  message: string;
  result: {
    count: number;
    rows: Ticket[];
  };
  time: number;
}


export interface TicketStatus {
  id: number;
  name: string;
  description: string;
  status: string;
  priority: string;
  Reporter: {
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  Department: {
    name: string;
  };
}

export interface ApiStatusResponse {
  success: boolean;
  status_code: number;
  message: string;
  result: {
    count: number;
    rows: TicketStatus[];
  };
  time: number;
}

export interface TicketPayload {
  files: File[];
  name: string;
  description: string;
  assignee_id: number;
  department_id: number;
  customer_id: number;
  type: string;
  status: string;
  priority: string;
}



export const getButtonStyle = (priority: string) => {
  switch (priority) {
    case "LOW":
      return { backgroundColor: "#E1F0FF", border: "1px solid #1677FF" };
    case "MEDIUM":
      return { backgroundColor: "#FFFBEB", border: "1px solid #FFE789" };
    case "HIGH":
      return { backgroundColor: "#FFDEDE", border: "1px solid #FF4746" };
    default:
      return { backgroundColor: "gray" };
  }
};

export const getRectangleStyle = (priority: string) => {
  switch (priority) {
    case "LOW":
      return { background: "#1677FF" };
    case "MEDIUM":
      return { background: " #FFE789" };
    case "HIGH":
      return { background: "#FF4746" };
    default:
      return { background: "#1677FF" };
  }
};

export const getStatusStyle = (status: string) => {
  switch (status) {
    case "CLOSE":
      return { background: "#D9D9D9", border: "1px solid #747474" };
    case "OPEN":
      return { background: "#FFDEDE", border: "1px solid #FF4746" };
    default:
      return { background: "#FFFBEB", border: "1px solid #FFE789" };
  }
};