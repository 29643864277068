// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabpanel{
    padding: 0px !important;
  }
  
  .indicator-default {
      background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);
      height: 4px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    
    .indicator-assigned-asset {
      background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);
      height: 4px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    
    .indicator-asset-category {
      background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);
      height: 4px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .indicator-asset-request {
      background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);
      height: 4px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }`, "",{"version":3,"sources":["webpack://./src/components/AssetManagement/assets.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;EACzB;;EAEA;MACI,4DAA4D;MAC5D,WAAW;MACX,2BAA2B;MAC3B,4BAA4B;IAC9B;;IAEA;MACE,4DAA4D;MAC5D,WAAW;MACX,2BAA2B;MAC3B,4BAA4B;IAC9B;;IAEA;MACE,4DAA4D;MAC5D,WAAW;MACX,2BAA2B;MAC3B,4BAA4B;IAC9B;;IAEA;MACE,4DAA4D;MAC5D,WAAW;MACX,2BAA2B;MAC3B,4BAA4B;IAC9B","sourcesContent":[".tabpanel{\n    padding: 0px !important;\n  }\n  \n  .indicator-default {\n      background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);\n      height: 4px;\n      border-top-left-radius: 8px;\n      border-top-right-radius: 8px;\n    }\n    \n    .indicator-assigned-asset {\n      background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);\n      height: 4px;\n      border-top-left-radius: 8px;\n      border-top-right-radius: 8px;\n    }\n    \n    .indicator-asset-category {\n      background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);\n      height: 4px;\n      border-top-left-radius: 8px;\n      border-top-right-radius: 8px;\n    }\n\n    .indicator-asset-request {\n      background: linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%);\n      height: 4px;\n      border-top-left-radius: 8px;\n      border-top-right-radius: 8px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
