//public routes
export const HOMEPAGE = "/";
export const LOGIN = "/login";
export const LOGIN_WITH_PARAM = "/login/:id";
export const SIGNUP = "/signup";
export const CANNOT_LOGIN = "/cannot-login";
export const RECOVERY = "/recovery";
export const ORGANISATION_DETAILS = "/orgnisatioal-details";
export const VERIFY_EMAIL = "/verify-email";
export const SET_PASSWORD = "/set-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const PUBLIC_ROUTES = [
  HOMEPAGE,
  LOGIN,
  LOGIN_WITH_PARAM,
  SIGNUP,
  CANNOT_LOGIN,
  RECOVERY,
  ORGANISATION_DETAILS,
  VERIFY_EMAIL,
  SET_PASSWORD,
  FORGOT_PASSWORD,
];

// protected route
export const HOME = "/home";
export const PRIVACY_POLICY = "/privacy/*";
export const FINANCE = "/finance/*";
export const INVOICE = "/finance/invoice";
export const ACTIVITY_LOG = "/privacy/ropa/*";
export const ACTIVITY_AUDIT_LOG = "/privacy/ropa/audit-log/*";
export const PRIVACY_POLICY_MANAGEMENT = "/privacy/privacy-policy/*";
export const PRIVACY_POLICY_CREATE_POLICY =
  "/privacy/privacy-policy/policy/view/*";
export const SCANLINK = "/scanlink";
export const POLICY = "/dashboard/policy";
export const ONBOARDING = "/onboarding";
export const ROLE_MANAGEMENT = "/crm/role-management";
export const USER_MANAGEMENT = "/user-management";
export const CUSOMER_MANAGEMENT = "/crm/customer-management";
// export const ROLE_MANAGEMENT_ROLE_DETAILS = "/crm/role-management/roledetails";
export const ROLE_MANAGEMENT_ROLE_DETAILS = "/roledetails";
export const ROLE_MANAGEMENT_ADD_ROLE = "/crm/role-management/addrole";
export const ROLE_MANAGEMENT_EDIT_ROLE = "/crm/role-management/editrole";
// export const ROLE_MANAGEMENT_EDIT_ROLE_DETAILS =
//   "/crm/role-management/editroledetails";
export const ROLE_MANAGEMENT_EDIT_ROLE_DETAILS="/editroledetails";
export const ALL_POLICY = "/policy/all-policy";
export const COMPANY_STRUCTURE = "/company-structure";
export const ONBOARDING_QUESTIONS = "/onboarding-questionnaire";
export const ROPA_DASHBOARD = "/privacy/ropa-dashboard";
export const SELECTED_VIEW_GROUP_DETAILS =
  "/company-structure/group-details";
export const EDIT_GROUP = "/crm/company-structure/edit-group-user";
export const ABOUT_GOTRUST = "/about-gotrust";
export const PROFILE = "/setting/profile";
export const CHANGE_PASSWORD = "/setting/profile/change-password";
export const SUPPORT = "/support";
export const SUPPORT_DASBOARD = "/support/dasboard";
export const ALL_TICKET = "/support/all-ticket";
export const CLOSE_TICKET = "/support/close-ticket";
export const OPEN_TICKET = "/support/open-ticket";
export const CREATE_TICKET = "/support/create-ticket";
export const VIEW_TICKET_DETAILS = "/support/view-ticket";
export const EDIT_TICKET_DETAILS = "/support/edit-ticket";
export const ROPA_REGISTER = "/privacy/ropa/register";
export const ROPA_BASIC_INFORMATION = "/privacy/ropa/basics-information";
export const DOCUMENTS = "/about-gotrust/how-to-use/documents";
export const VIDEOS = "/about-gotrust/how-to-use/videos";
export const BLOGS = "/about-gotrust/blogs";
export const VIEW_DETAIL_BLOGS = "/about-gotrust/blogs/detailed-blog";
export const COMMUNITY = "/about-gotrust/community";
export const ROPA_RIVIEW = "/privacy/ropa/review";
export const EMPLOYEE_SUPPORT = "/support/employee";



//Privacy and Data Protection - Risk Assessment
export const RISK_ASSESSMENT = "/assessment/privacy/*";
// export const ASSESSMENT_CONTROLS = `/assessment/privacy/controls`;
// export const ASSESSMENT_AUDIT_LOG = `/assessment/privacy/audit-log`;
export const ROPA_ADD_QUESTION = "/privacy/ropa/question";

export const PROTECTED_ROUTE = [
  HOME,
  DOCUMENTS,
  VIDEOS,
  BLOGS,
  VIEW_DETAIL_BLOGS,
  COMMUNITY,
  PRIVACY_POLICY,
  FINANCE,
  INVOICE,
  ACTIVITY_LOG,
  ACTIVITY_AUDIT_LOG,
  PRIVACY_POLICY_MANAGEMENT,
  PRIVACY_POLICY_CREATE_POLICY,
  SCANLINK,
  POLICY,
  ONBOARDING,
  ROLE_MANAGEMENT,
  USER_MANAGEMENT,
  CUSOMER_MANAGEMENT,
  ROLE_MANAGEMENT_ROLE_DETAILS,
  ROLE_MANAGEMENT_ADD_ROLE,
  ROLE_MANAGEMENT_EDIT_ROLE,
  ROLE_MANAGEMENT_EDIT_ROLE_DETAILS,
  ALL_POLICY,
  COMPANY_STRUCTURE,
  ONBOARDING_QUESTIONS,
  ROPA_DASHBOARD,
  SELECTED_VIEW_GROUP_DETAILS,
  EDIT_GROUP,
  ABOUT_GOTRUST,
  PROFILE,
  CHANGE_PASSWORD,
  SUPPORT,
  SUPPORT_DASBOARD,
  ALL_TICKET,
  CLOSE_TICKET,
  OPEN_TICKET,
  CREATE_TICKET,
  VIEW_TICKET_DETAILS,
  EDIT_TICKET_DETAILS,
  ROPA_REGISTER,
  ROPA_BASIC_INFORMATION,
  ROPA_RIVIEW,
  RISK_ASSESSMENT,
  // ASSESSMENT_CONTROLS,
  // ASSESSMENT_AUDIT_LOG
  ROPA_ADD_QUESTION,
];
