import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import moment from "moment";

import right from "../../image/chevron-right.png";
import { Fetch_Announcement_Details } from "../common/services/Announcements";

interface AnnouncementDetais {
  title: string;
  date: string;
  business: string;
  status: string;
  description: string;
}

interface Departments {
  Department: {
    name: string;
  };
}

function ViewAnncouncement() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [details, setDetails] = useState<AnnouncementDetais>({
    title: "",
    date: "",
    business: "",
    status: "",
    description: "",
  });
  const [departments, setDepartments] = useState<Departments[]>([]);

  useEffect(() => {
    Fetch_Announcement_Details(id)
      .then((data) => {
        if (data.result) {
          setDetails({
            title: data.result.title,
            date: data.result?.date,
            business: data.result.Group.name,
            status: data.result.status,
            description: data.result.description,
          });
          setDepartments(data.result.AnnouncementDepartments);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const date = details.date
  const formattedDate = moment(date).format("DD MMM YYYY")
  
  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-12">
        <div className="flex flex-row gap-[13px] items-center">
          <button onClick={() => navigate("/announcement-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Announcements Details
          </span>
        </div>
        <div className="px-2">
          <div className="flex flex-col gap-5 p-5 rounded-lg shadow-custom bg-white">
            <span className="font-[Poppins] font-semibold text-xl">
              Announcement Details
            </span>
            <div className="rounded-md px-2.5 py-5 viewBackground grid grid-cols-3 gap-y-[15px]">
              <div className="flex flex-col gap-[7px] pl-2.5">
                <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                  Title
                </span>
                <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                  {details.title}
                </span>
              </div>
              <div className="flex flex-col gap-[7px]">
                <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                  Status
                </span>
                <span
                  className={`px-[19px] py-[1px] rounded-sm w-min font-[Poppins] font-medium text-base text-[#262626] ${
                    details.status === "published"
                      ? "bg-[#D4F8C7]"
                      : details.status === "scheduled"
                      ? "bg-[#FFC0767A]"
                      : ""
                  }`}
                >
                  {details.status}
                </span>
              </div>
              {details.status === "scheduled" ? (
                <div className="flex flex-col gap-[7px]">
                  <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                    Scheduled Date
                  </span>
                  <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                    {formattedDate}
                  </span>
                </div>
              ) : (
                <div className="flex flex-col gap-[7px] pl-2.5">
                  <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                    Business Unit
                  </span>
                  <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                    {details.business}
                  </span>
                </div>
              )}

              <hr className="col-span-3 border-2 border-[#EDEDED]" />
              {details.status === "scheduled" ? (
                <div className="flex flex-col gap-[7px] pl-2.5">
                  <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                    Business Unit
                  </span>
                  <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                    {details.business}
                  </span>
                </div>
              ) : (
                <></>
              )}
              <div
                className={`flex flex-col col-span-2 gap-[7px] ${
                  details.status === "published" ? "pl-2.5" : ""
                }`}
              >
                <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                  Departments
                </span>
                <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                  {departments?.map((dept) => dept.Department.name).join(", ")}
                </span>
              </div>
              <div className="flex flex-col gap-[7px]"></div>
              <hr className="col-span-3 border-2 border-[#EDEDED]" />
              <div className="col-span-3 flex flex-col gap-[7px] pl-2.5">
                <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                  Description
                </span>
                <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                  {details.description}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAnncouncement;
