import * as React from "react";
import Grid from "@mui/material/Grid";
//import { Checkbox } from "@material-tailwind/react";
import RenderResourcesChildren from "./RenderChildren";
import Checkbox from "@mui/material/Checkbox";

interface RenderResourcesProps {
  renderData: any[];
  isChecked: boolean;
  cssClass: string;
  resourceList: any[];
  handleResourceListChildren: (event: any, id: any, parent_id: any) => void;
  handleResourceListParent: (
    event: any,
    id: any,
    parent_id: any,
    temp: any[]
  ) => void;
}

const RenderResources: React.FC<RenderResourcesProps> = ({
  renderData,
  isChecked,
  cssClass,
  resourceList,
  handleResourceListChildren,
  handleResourceListParent,
}) => {
  return (
    <Grid
      container
      style={{
        gap: "48px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        alignSelf: "stretch",
      }}
    >
      {renderData?.map((obj: any) => {
        if (!obj.parent_id) {
          return (
            <div key={obj.resource_id} className="gap-7 flex flex-row w-full">
              <div className="flex flex-row gap-3 w-[32%] items-center h-4 min-w-[270px]">
                <Checkbox
                  //  ripple={false}
                  //  crossOrigin=""
                  value={obj.resource_id}
                  id={obj.resource_id}
                  name="resources"
                  checked={
                    isChecked
                      ? true
                      : resourceList.includes(Number(obj?.resource_id))
                  }
                  // containerProps={{
                  //   className: "p-0",
                  // }}
                  onChange={(event) => {
                    if (!isChecked && !obj.parent_id) {
                      handleResourceListParent(
                        event,
                        obj.resource_id,
                        obj.parent_id,
                        []
                      );
                    } else if (!isChecked && obj.parent_id) {
                      handleResourceListChildren(
                        event,
                        obj.resource_id,
                        obj.parent_id
                      );
                    }
                  }}
                  className={cssClass}
                  // onPointerEnterCapture={0}
                  //onPointerLeaveCapture={0}
                />
                <label
                  htmlFor={obj.resource_name}
                  className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal"
                >
                  {obj.resource_name}
                </label>
              </div>
              <RenderResourcesChildren
                childData={obj.children}
                isChecked={isChecked}
                depth={1}
                cssClass={cssClass}
                resourceList={resourceList}
                handleResourceListChildren={handleResourceListChildren}
                handleResourceListParent={handleResourceListParent}
              />
            </div>
          );
        }
      })}
    </Grid>
  );
};

export default RenderResources;
