import * as React from "react";
//import { Checkbox } from "@material-tailwind/react";
import Checkbox from '@mui/material/Checkbox';

interface RenderResourcesChildrenProps {
  childData: any;
  isChecked: boolean;
  depth: number;
  cssClass: string;
  resourceList: any[];
  handleResourceListChildren: (event: any, id: any, parent_id: any) => void;
  handleResourceListParent: (
    event: any,
    id: any,
    parent_id: any,
    temp: any[]
  ) => void;
}

const RenderResourcesChildren: React.FC<RenderResourcesChildrenProps> = ({
  childData,
  isChecked,
  depth,
  cssClass,
  resourceList,
  handleResourceListChildren,
  handleResourceListParent,
}) => {
  return (
    <div className="flex flex-col w-full gap-5">
      {childData?.map((obj: any) => {
        return (
          <div key={obj.resource_id} className="gap-7 flex flex-row w-full">
            <div className="flex flex-row gap-3 w-[32%] items-center h-4 min-w-[270px]">
              <Checkbox
               // ripple={false}
               // crossOrigin=""
                value={obj.resource_id}
                id={obj.resource_id}
                name="resources"
                checked={
                  isChecked
                    ? true
                    : resourceList.includes(Number(obj.resource_id))
                }
                // containerProps={{
                //   className: "p-0",
                // }}
                onChange={(event) => {
                  if (!isChecked && !obj.parent_id) {
                    handleResourceListParent(
                      event,
                      obj.resource_id,
                      obj.parent_id,
                      []
                    );
                  } else if (!isChecked && obj.parent_id) {
                    handleResourceListChildren(
                      event,
                      obj.resource_id,
                      obj.parent_id
                    );
                  }
                }}
                className={cssClass}
                //onPointerEnterCapture={undefined}
               // onPointerLeaveCapture={undefined}
              />
              <label
                htmlFor={obj.resource_name}
                className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal"
              >
                {obj.resource_name}
              </label>
            </div>
            <RenderResourcesChildren
              childData={obj.children}
              isChecked={isChecked}
              depth={depth + 1}
              cssClass={cssClass}
              resourceList={resourceList}
              handleResourceListChildren={handleResourceListChildren}
              handleResourceListParent={handleResourceListParent}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RenderResourcesChildren;
