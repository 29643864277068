import rightArrow from "../../assets/chevron-right.svg";

import * as React from "react";
import TextField from "@mui/material/TextField";
// import Checkbox from '@mui/material/Checkbox';
import { Checkbox } from "@material-tailwind/react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import toast from "react-hot-toast";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Footer from "../Layout/footer";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCustomerResourseList } from "../../redux/actions/RoleManagement/RoleManagementActions";
import {
  Edit_Role,
  fetchEditRole,
  updateRole,
} from "../common/services/roleManagement";
import { useDispatch } from "react-redux";
import { userManagementActions } from "../../redux/reducers/userManagement/userManagementSlice";
import { ROLE_MANAGEMENT } from "../../utils/routeConstant";
import RenderResources from "../common/ResourceList/Resources";

interface Resource {
  resource_id: string;
  parent_id: string;
  resource_name: string;
}

const labelStyle = {
  color: "rgba(0, 0, 0, 0.60)",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
};
const inputStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
  display: "flex",
  padding: "0px 12px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRadius: "10px",
  border: "var(--Our_Colors-Grays-400, #C5CDD4)",
  height: "56px",
  width: "325px",
};
const menuStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
};

interface Group {
  resource_id: number;
  resource_name: string;
  parent_id: number | null;
  children?: Group[];
}

const flattenGroupData = (groupData: Group[]): any => {
  const flattenedRecords: any[] = [];

  function flattenGroup(group: any[]) {
    console.log(group);
    group.forEach((item: any) => {
      const record = {
        resource_id: item.resource_id,
        resource_name: item.resource_name,
        parent_id: item.parent_id,
        children: item?.children?.length,
      };
      flattenedRecords.push(record);
      if (item.children && item.children.length > 0) {
        flattenGroup(item.children);
      }
    });
  }

  flattenGroup(groupData);

  return flattenedRecords;
};

const validationData: any = ["roleName", "status", "resources"];

const EditRole = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleGroupChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);

    handleIsValidItem("status");
  };

  /////getting access checkboxes
  const [customerResourceList, setCustomerResourceList] = useState<any[]>([]);
  const [flattenResources, setFlattenResources] = useState<any[]>([]);

  useEffect(() => {
    async function fetchResourceList() {
      setLoading(true);
      try {
        const data = await getCustomerResourseList();
        if (data) {
          setCustomerResourceList(data);
          setFlattenResources(flattenGroupData(data));
        } else {
          console.error("Failed to fetch customer resource list.");
        }
      } catch (error) {
        console.error("Error fetching customer resource list:", error);
      }
      setLoading(false);
    }

    fetchResourceList();
  }, []);

  /////////////////////getting data pre-filled ////////////////

  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let access_token = "";
  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
  }

  const location = useLocation();
  const id = location.state?.id;
  const [updateData, setUpdateData] = useState<any>(null);

  const [roleName, setRoleName] = useState("");
  const [status, setStatus] = useState("");
  const [resourceList, setResource_list] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchEditRole(id)
      .then((data) => {
        if (data.result) {
          setUpdateData(data);
          console.log("data.result.status", data.result.status);
          setRoleName(data.result.role_name);
          setStatus(data.result.status);
          // setResource_list(
          //   data.result.UserRolePrivileges.map(
          //     (privilege: any) => privilege.resource_id
          //   )
          // );
          setResource_list(
            flattenGroupData(data?.result?.resources)?.map(
              (item: any) => item.resource_id
            )
          );
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  /////////////put start ///////////

  const [initialResourceList, setInitialResourceList] = useState<number[]>([]);

  useEffect(() => {
    console.log(updateData);
    if (
      updateData &&
      updateData.result &&
      updateData.result.resources &&
      resourceList.length
    ) {
      setInitialResourceList([...resourceList]);
    } else {
      console.error(
        "updateData, updateData.result or updateData.result.resources is null or undefined"
      );
    }
  }, [updateData]);

  function handleResourceListChildren(event: any, id: any, parent_id: any) {
    let newResourceList: any[] = [...resourceList];
    const subResourceObjects = flattenResources.filter(
      (idx: Resource) => idx.parent_id === id
    );
    if (event.target.checked) {
      // If the checkbox is being checked, add the resource_id of the child and its parent to resourceList
      const currentElement = flattenResources.find(
        (item) => item.resource_id === Number(id)
      );
      const selectedElement = flattenResources.find(
        (item) => item.resource_id === Number(event.target.id)
      );
      if (parent_id) {
        newResourceList = [...newResourceList, Number(id), Number(parent_id)];
        const parentElement = flattenResources.find(
          (item) => item.resource_id === parent_id
        );

        if (parentElement && parentElement?.parent_id) {
          newResourceList = [
            ...handleResourceListChildren(
              event,
              parent_id,
              parentElement?.parent_id
            ),
            ...newResourceList,
          ];
        }
      }
      console.log(currentElement);
      if (selectedElement && selectedElement?.children) {
        newResourceList = handleResourceListParent(
          event,
          currentElement?.resource_id,
          currentElement?.parent_id,
          [...newResourceList]
        );
      }
      if (!parent_id) {
        newResourceList = [...newResourceList, Number(id)];
      }
    } else {
      // If the checkbox is being unchecked, remove the resource_id of the child from resourceList
      const currentElement = flattenResources.find(
        (item) => item.resource_id === Number(id)
      );

      if (currentElement && currentElement?.children) {
        newResourceList = handleResourceListParent(
          event,
          currentElement?.resource_id,
          currentElement?.parent_id,
          [...newResourceList]
        );
      }
      // If this is the last child of its parent that's selected, also remove the parent
      if (
        !newResourceList.some((item) =>
          subResourceObjects.find(
            (subObj: Resource) => Number(subObj.resource_id) === item
          )
        )
      ) {
        newResourceList = newResourceList.filter((item) => item !== Number(id));
      }
      setResource_list(newResourceList);
    }

    newResourceList = removeDuplicate(newResourceList);

    return newResourceList;
  }

  function handleResourceListParent(
    event: any,
    id: any,
    parent_id: any,
    temp: any[]
  ) {
    let newResourceList: any[] = [...temp, ...resourceList];

    const subResourceObjects = flattenResources.filter(
      (idx: Resource) => idx.parent_id === id
    );
    if (event.target.checked) {
      // If the checkbox is being checked, add the resource_id of the parent and all its children to resourceList
      newResourceList = [
        ...newResourceList,
        Number(id),
        ...subResourceObjects.map((subObj: Resource) =>
          Number(subObj.resource_id)
        ),
      ];
    } else {
      // If the checkbox is being unchecked, remove the resource_id of the parent and all its children from resourceList
      newResourceList = newResourceList.filter(
        (item) =>
          item !== Number(id) &&
          !subResourceObjects
            .map((subObj: Resource) => Number(subObj.resource_id))
            .includes(item)
      );
    }

    newResourceList = removeDuplicate(newResourceList);

    return newResourceList;
  }

  function removeDuplicate(tempList: any[]) {
    let checkRepeat = new Set();
    tempList.forEach((item: any) => checkRepeat.add(item));

    return Array.from(checkRepeat);
  }

  function handleResourceListChildrenChange(
    event: any,
    id: any,
    parent_id: any
  ) {
    const tempList = handleResourceListChildren(event, id, parent_id);

    const resourceListArray = removeDuplicate(tempList);

    setResource_list(resourceListArray);
    handleIsValidItem("resources");
  }

  function handleResourceListParentChange(event: any, id: any, parent_id: any) {
    const tempList = handleResourceListParent(event, id, parent_id, []);

    const resourceListArray = removeDuplicate(tempList);

    setResource_list(resourceListArray);
    handleIsValidItem("resources");
  }

  console.log(resourceList);

  const submitChanges = async () => {
    const resourceChanges: { resource_id: number; type: string }[] = [];

    console.log(flattenResources);
    console.log("final", resourceList);
    console.log("initial", initialResourceList);

    resourceList.forEach((resource_id) => {
      if (!initialResourceList.includes(resource_id)) {
        resourceChanges.push({ resource_id, type: "add" });
      }
    });

    initialResourceList.forEach((resource_id) => {
      if (!resourceList.includes(resource_id)) {
        resourceChanges.push({ resource_id, type: "delete" });
      }
    });

    // console.log("Request body:", body); // Print the body

    setLoading(true);
    try {
      const data = await updateRole(id, roleName, resourceChanges, status);

      if (data && data.result) {
        console.log("final data:", data);
        dispatch(
          userManagementActions.resetUserRoleData({
            count: 0,
            rows: [],
            currentPage: 1,
            itemsPerPage: 10,
            isNext: true,
          })
        );
        navigate(`/role-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  //////////////////////////////////////////////////
  const [isValid, setIsValid] = React.useState<{ [key: string]: any }>({});

  function handleValidation() {
    const updated: { [key: string]: any } = { ...isValid };

    for (let item of validationData) {
      updated[item] = updated[item] || false;
    }

    updated["roleName"] = !roleName;
    updated["status"] = !status;
    updated["resources"] = !resourceList.length;

    setIsValid(updated);

    if (!roleName || !resourceList.length || !status) {
      return;
    }
  }

  function handleIsValidItem(item: string) {
    const updated = { ...isValid };

    updated[`${item}`] = false;

    setIsValid(updated);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  const navigate = useNavigate();

  const submitChangesAndNavigate = async () => {
    handleValidation();

    if (!roleName || !resourceList.length || !status) {
      return;
    }

    await submitChanges();
    navigate(`/role-management`);
  };

  let cssClass =
    "h-4 w-4 rounded-md shrink-0 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:before:opacity-0 ";
  cssClass += isValid["resources"]
    ? "bg-[#FFD8D8] border-[#DC2626]"
    : "border-gray-900/20";

  return (
    <div className="bg-[#F9F9F9] px-4 pt-6 pb-12 ">
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
          <div className="h-full">
            <div className="flex flex-row items-center h-12 justify-between">
              {/* <div className="flex flex-row gap-[10px] justify-center items-start">
                <button onClick={() => navigate("/role-management")}>
                  <img
                    src={rightArrow}
                    alt="right arrow sign"
                    className="h-6 w-6 shrink-0 bg-black rounded-full"
                  />
                </button>
                <div className="flex flex-col">
                  <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                    Role Management
                  </span>
                  <span className="text-black font-[Poppins] text-base font-semibold leading-normal">
                    Edit Role
                  </span>
                </div>
              </div> */}

              <div className="flex flex-row gap-[10px] justify-center items-center">
                <div className="h-6 w-6">
                  <button
                  //onClick={() => navigate("/dashboard")}
                  >
                    <img
                      src={rightArrow}
                      alt="right arrow sign"
                      className="h-6 w-6 shrink-0 bg-black rounded-full"
                      onClick={() => navigate('/role-management')}
                    />
                  </button>
                </div>

                <div className="flex flex-col">
                  <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                    Edit Role Details
                  </span>
                  {/* <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                  Add Role
                </span> */}
                </div>
              </div>

              <div className="flex flex-start gap-5">
                <button
                  onClick={() => navigate("/role-management")}
                  // onClick={() => navigate(`${ROLE_MANAGEMENT}`)}
                  className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
                >
                  <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                    Cancel
                  </div>
                </button>
                <button
                  onClick={submitChangesAndNavigate}
                  className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg buttoncss h-12"
                >
                  <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                    Update
                  </div>
                </button>
              </div>
            </div>

            <div className="w-full mt-5">
              <div className=" py-[25px] flex flex-col flex-start gap-8 shrink-0 rounded-[9.601px] shadow-custom bg-white">
                <Container component="main">
                  <Box sx={{ paddingBottom: "32px" }}>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: "0px" }}
                    >
                      <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mb-[30px]">
                        Role Details
                      </div>
                      <Grid
                        container
                        style={{
                          gap: "20px",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                          alignSelf: "stretch",
                        }}
                      >
                        {/* {RoleManagementForm.map((item: any) => ( */}
                        <Grid container spacing={6}>
                          <Grid item xs={"auto"}>
                            <TextField
                              autoComplete="given-name"
                              name="roleName"
                              id="roleName"
                              label="Role Name"
                              placeholder="Enter role name"
                              required
                              error={isValid[`roleName`]}
                              helperText={
                                isValid[`roleName`]
                                  ? "This field is required!"
                                  : ""
                              }
                              onChange={(event) => {
                                console.log("onChange event triggered");
                                console.log(
                                  "event.target.value:",
                                  event.target.value
                                );
                                setRoleName(event.target.value);
                                handleIsValidItem("roleName");
                              }}
                              value={roleName}
                              InputLabelProps={{
                                shrink: true,
                                sx: labelStyle,
                              }}
                              InputProps={{
                                sx: {
                                  ...inputStyle,
                                  "& input:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 1000px #fff inset",
                                    WebkitTextFillColor: "inherit",
                                  },
                                },
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl>
                              <InputLabel
                                id="demo-simple-select-label"
                                shrink
                                sx={{
                                  ...labelStyle,
                                  color: isValid[`status`]
                                    ? "#d32f2f"
                                    : "rgba(0, 0, 0, 0.60)",
                                }}
                              >
                                Status
                              </InputLabel>
                              <Select
                                key={status}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                error={isValid[`status`]}
                                sx={{
                                  ...inputStyle,
                                  "& input:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 1000px #fff inset",
                                    WebkitTextFillColor: "inherit",
                                  },
                                }}
                                value={status}
                                onChange={handleGroupChange}
                                displayEmpty
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    label="Status"
                                  />
                                }
                                MenuProps={{
                                  PaperProps: {
                                    style: menuStyle,
                                  },
                                }}
                              >
                                <MenuItem value={"active"} style={menuStyle}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "80%",
                                      alignItems: "center",
                                    }}
                                  >
                                    Active
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="11"
                                      height="11"
                                      viewBox="0 0 11 11"
                                      fill="#00CBA0"
                                    >
                                      <circle cx="5.5" cy="5.35156" r="5" />
                                    </svg>
                                  </div>
                                </MenuItem>
                                <MenuItem value={"archived"} style={menuStyle}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "80%",
                                      alignItems: "center",
                                    }}
                                  >
                                    Archived
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="11"
                                      height="11"
                                      viewBox="0 0 11 11"
                                      fill="#FA1464"
                                    >
                                      <circle cx="5.5" cy="5.35156" r="5" />
                                    </svg>
                                  </div>
                                </MenuItem>
                                <MenuItem value={"inactive"} style={menuStyle}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "80%",
                                      alignItems: "center",
                                    }}
                                  >
                                    In-active
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="11"
                                      height="11"
                                      viewBox="0 0 11 11"
                                      fill="#FF9950"
                                    >
                                      <circle cx="5.5" cy="5.35156" r="5" />
                                    </svg>
                                  </div>
                                </MenuItem>
                              </Select>

                              {isValid[`status`] && (
                                <FormHelperText error>
                                  This field is required!
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* ))} */}
                      </Grid>

                      <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mt-[30px] mb-[41px]">
                        Accesses given to User
                      </div>
                      {isValid["resources"] && (
                        <div className="text-[#DC2626] font-[Poppins] text-xs font-normal leading-5 tracking-[-0.24px] mt-[-10px] mb-3.5">
                          Ensure at least one option is selected!
                        </div>
                      )}
                      {/* <Grid
                        container
                        style={{
                          gap: "48px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          alignSelf: "stretch",
                          marginBottom: "60px",
                        }}
                      >
                        {customerResourceList.map((obj: Resource) => {
                          if (!checkRepeat.has(obj.resource_id)) {
                            const subResourceObjects =
                              customerResourceList.filter(
                                (idx: Resource) =>
                                  idx.parent_id === obj.resource_id
                              );

                            let cssClass = "h-4 w-4 rounded-md shrink-0 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:scale-105 hover:before:opacity-0 ";
                            cssClass += isValid["resources"] ? "bg-[#FFD8D8] border-[#DC2626]" : "border-gray-900/20";

                            const subResources = subResourceObjects.map(
                              (subObj: Resource) => {
                                checkRepeat.add(subObj.resource_id); // Add sub resource_id to the set
                                return (
                                  <li
                                    className="w-[366px] flex flex-row items-start"
                                    key={subObj.resource_id}
                                  >
                                    <Checkbox
                                      ripple={false}
                                      crossOrigin=""
                                      value="insights"
                                      id={"insights"}
                                      containerProps={{
                                        className: "p-0",
                                      }}
                                      checked={resourceList.includes(
                                        Number(subObj.resource_id)
                                      )} // Check if the resource_id of the current item is in the resourceList array
                                      onChange={(event) => {
                                        let newResourceList: number[];
                                        if (event.target.checked) {
                                          // If the checkbox is being checked, add the resource_id of the child and its parent to resourceList
                                          setResource_list([
                                            ...resourceList,
                                            Number(subObj.resource_id),
                                            Number(obj.resource_id),
                                          ]);
                                        } else {
                                          // If the checkbox is being unchecked, remove the resource_id of the child from resourceList
                                          newResourceList = resourceList.filter(
                                            (id) => id !== Number(subObj.resource_id)
                                          );
                                          // If this is the last child of its parent that's selected, also remove the parent
                                          if (!newResourceList.some((id) => subResourceObjects.find((subObj: Resource) => Number(subObj.resource_id) === id)
                                          )) {
                                            newResourceList = newResourceList.filter((id) => id !== Number(obj.resource_id));
                                          }
                                          setResource_list(newResourceList);
                                        }

                                        handleIsValidItem("resources");
                                      } }
                                      className={cssClass} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                    />
                                    <label
                                      htmlFor={subObj.resource_id}
                                      className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal pl-3 mt-[-3px]"
                                    >
                                      {subObj.resource_name}
                                    </label>
                                  </li>
                                );
                              }
                            );

                            return (
                              <div
                                key={obj.resource_id}
                                className="gap-7 flex flex-row w-full"
                              >
                                <div className="flex flex-row gap-3 w-[28%] items-center h-3">
                                  <Checkbox
                                    ripple={false}
                                    crossOrigin=""
                                    value="insights"
                                    id={"insights"}
                                    containerProps={{ className: "p-0" }}
                                    checked={resourceList.includes(
                                      Number(obj.resource_id)
                                    )} // Check if the resource_id of the current item is in the resourceList array
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        // If the checkbox is being checked, add the resource_id of the parent and all its children to resourceList
                                        setResource_list([
                                          ...resourceList,
                                          Number(obj.resource_id),
                                          ...subResourceObjects.map(
                                            (subObj: Resource) => Number(subObj.resource_id)
                                          ),
                                        ]);
                                      } else {
                                        // If the checkbox is being unchecked, remove the resource_id of the parent and all its children from resourceList
                                        setResource_list(
                                          resourceList.filter(
                                            (id) => id !==
                                              Number(obj.resource_id) &&
                                              !subResourceObjects
                                                .map((subObj: Resource) => Number(
                                                  subObj.resource_id
                                                )
                                                )
                                                .includes(id)
                                          )
                                        );
                                      }
                                      handleIsValidItem("resources");
                                    } }
                                    className={cssClass} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                  />

                                  <label
                                    htmlFor={obj.resource_name}
                                    className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal"
                                  >
                                    {obj.resource_name}
                                  </label>
                                </div>
                                <div className="flex flex-row gap-3 items-start">
                                  <ul className="grid grid-cols-2 gap-5">
                                    {subResources}
                                  </ul>
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </Grid> */}

                      <RenderResources
                        renderData={customerResourceList}
                        isChecked={false}
                        cssClass={cssClass}
                        resourceList={resourceList}
                        handleResourceListChildren={
                          handleResourceListChildrenChange
                        }
                        handleResourceListParent={
                          handleResourceListParentChange
                        }
                      />
                    </Box>
                  </Box>
                </Container>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {loading && (
        <div className="bg-black opacity-70 fixed top-0 left-0 h-screen w-screen">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
            role="status"
          >
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditRole;
