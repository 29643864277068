import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import { Edit_Expense_Type } from "../../../components/common/services/ExpenseType";
import { ExpenseActions } from "../../reducers/ExpenseType/ExpenseTypeSlice";

const handleErrorResponse = (status: number, navigate: NavigateFunction) => {
  if ([401, 402, 403, 405, 500].includes(status)) {
    navigate("/server-error");
    return true;
  }
  return false;
};

export const Expense_Type_Unit =
  (customer_id: number, navigate: NavigateFunction, target_id: number) =>
  async (dispatch: Dispatch) => {
    console.log("customer_id expense", customer_id);
    try {
      const response = await Edit_Expense_Type(customer_id);
      console.log("responsive expense-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log("Fetched data:", data?.result);

      // Apply the find method to filter data by target_id
      const foundItem = data.result?.rows?.find(
        (item: { id: number }) => item?.id === target_id
      );
      if (!foundItem) {
        throw new Error(`Item with id ${target_id} not found`);
      }

      console.log("Found item:", foundItem);

      // Dispatch the found item
      dispatch(ExpenseActions.setExpenseTypeData(foundItem));
      dispatch(ExpenseActions.setExpenseAllTypeData(data.result.rows));
      return foundItem;
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };

export const Expense_All_Data =
  (customer_id: number, navigate: NavigateFunction) =>
  async (dispatch: Dispatch) => {
    console.log("customer_id expense", customer_id);
    try {
      const response = await Edit_Expense_Type(customer_id);
      console.log("responsive expense-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log("Fetched data:", data?.result);

      dispatch(ExpenseActions.setExpenseAllTypeData(data.result.rows));
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };
