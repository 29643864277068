import * as React from 'react';
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import TablePagination from '@mui/material/TablePagination';
import { useSelector } from 'react-redux';

import './Pagenation.css';
 
interface PagenationProps {
  count: number;
  handleItemsChange: (value: number) => void;
  handlePageChange: (value: number) => void;
  currentPage: number;
  numOfItems: number;
}
 
const TablePaginationDemo: React.FC<PagenationProps> = ({
  count,
  handleItemsChange,
  handlePageChange,
  currentPage,
  numOfItems
}) => {
 
  const [page, setPage] = React.useState(currentPage-1);
  const [rowsPerPage, setRowsPerPage] = React.useState(numOfItems);
 
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    handlePageChange(newPage+1);
  };
 
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleItemsChange(parseInt(event.target.value, 10));
    setPage(0);
    handlePageChange(1);
  };
 
 
  return (
    <StyledEngineProvider injectFirst>
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      showFirstButton
      showLastButton
      rowsPerPageOptions={[10, 20, 50, 100, 200]}
      labelRowsPerPage="Items Per Page"
      classes={{
        // selectLabel:
        //   "text-[#7B7A7A] font-[Poppins] text-[10px] font-medium leading-normal",
        // displayedRows:
        //   "text-[#7B7A7A] font-[Poppins] text-[20px] font-medium leading-normal",
        // input:
        //   "text-black underline font-[Poppins] text-[10px] font-medium leading-normal",
        selectIcon: "",
      }}
    />
    </StyledEngineProvider>
  );
};
 
export default TablePaginationDemo;
 