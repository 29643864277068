import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import check from "../../image/checkmark-circle-02.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField, Box, Typography, Modal, MenuItem } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MyStatefulEditor from "../ProjectManagement/EditEditor";
import FileUpload from "../ExpenseManagement/EditFileUoload";
import toast from "react-hot-toast";
import axios from "axios";
import {
  Fetch_Policy_Category_List,
  fetchEditPolicy,
  updatePolicy,
} from "../common/services/companyManagement";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import EmployeeSearch from "./EditEmployeeSearch";
import EmployeeSearch2 from "./PolicyApprover";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormValues {
  policy_name: string;
  policy_category: string;
  group: string;
  department: string;
  description: string;
  recurrence: string;
  version_number: string;
}

interface RowData {
  id: string;
  name: string;
}

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
  original: string;
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

export const EditPolicyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const location = useLocation();
  const id = location.state?.id;

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [joining, setJoining] = React.useState<Dayjs | string | null>(null);
  const [editorContent, setEditorContent] = useState<string>("");
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [selectedEmployee2, setSelectedEmployee2] = useState<any>(null);
  const handleOpen = () => {
    submitChanges();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/policies");
  };

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [initialFiles, setInitialFiles] = useState<UploadedFile[]>([]);
  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
    console.log("Files updated:", files);
  };

  const [formValues, setFormValues] = useState<FormValues>({
    policy_name: "",
    policy_category: "",
    department: "",
    group: "",
    description: "",
    recurrence: "",
    version_number: "",
  });

  const Recurrency_Period = [
    { value: "ANNUALLY", label: "ANNUALLY" },
    { value: "BI_ANNUALLY", label: "BI_ANNUALLY" },
    { value: "QUATERLY", label: "QUATERLY" },
    { value: "MONTHLY", label: "MONTHLY" },
    { value: "NEVER", label: "NEVER" },
  ];

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
    console.log(loginData.customer_id, "loginData.customer_id--->>>>");
  }

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Policy_Category_List(
        customer_id,
        searchTerm,
        numOfItems,
        page
      );
      if (response.status === 200) {
        console.log("Data: Policy categories", response.data);
        setTableData(response.data?.result);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    console.log("after fetch page", page);
  }, [page, numOfItems]);

  useEffect(() => {
    console.log("chek print");

    const fetchHistory = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );
        console.log(historyData, "historyData---->>>");

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    console.log("chek print");

    const fetchDepartment = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );
        console.log(historyData, "historyData department---->>>");

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  useEffect(() => {
    console.log("edit check");
    fetchEditPolicy(id)
      .then((data) => {
        if (data.result) {
          const { document_url, receipt_name } = data.result;
          const files = document_url?.map((name: string, index: number) => ({
            originalName: document_url[index],
          }));

          setUploadedFiles(files);
          setInitialFiles(files);

          setSelectedEmployee({
            id: data.result?.Reviewer?.id,
            firstName: data.result?.Reviewer?.firstName,
          });
          setSelectedEmployee2({
            id: data.result?.Approver?.id,
            firstName: data.result?.Approver?.firstName,
          });
          setFormValues({
            policy_name: data.result.name,
            policy_category: data.result.policyCategory_id,
            department: data.result?.department_id,
            group: data.result?.group_id,
            description: data.result?.description,
            recurrence: data.result?.recurrence,
            version_number: data.result?.version_no,
          });
          setJoining(dayjs(data.result?.renewal_date));
          setEditorContent(data.result?.description);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  console.log(uploadedFiles, "edit upload files------->>>>>>>>");

  const handleEmployeeSelect = (employeeId: string) => {};

  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployee({
      id: employeeId,
    });
  };

  const handleEmployeeSelect2 = (employeeId: string) => {};

  const handleEmployeeSelectId2 = (employeeId: string) => {
    setSelectedEmployee2({
      id: employeeId,
    });
  };

  const submitChanges = async () => {
    setLoading(true);
    try {
      const data = await updatePolicy(
        id,
        formValues.policy_name,
        formValues.policy_category,
        formValues.group,
        formValues.department,
        editorContent,
        formValues.recurrence,
        formValues.version_number,
        selectedEmployee.id,
        selectedEmployee2.id,
        dayjs(joining).format("YYYY-MM-DD"),
        uploadedFiles.map((files) => files.url)
      );

      if (data.status_code === 200) {
        console.log("final data:", data);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          navigate("/policies");
        }, 1000);
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  return (
    <div className="px-4 pt-6 pb-8">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Policy edited successfully!
          </Typography>
        </Box>
      </Modal>
      <div className="w-full flex flex-row justify-between">
        <div className="w-full flex flex-row px-4 items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/policies")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit Policy Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/policies")}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid border-[#242424] h-12"
            >
              <div className="text-[#2C2C2C] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <button
              type="submit"
              form="form"
              onClick={handleOpen}
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Update
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="mt-6 rounded overflow-hidden shadow-lg bg-white m-4">
        <div className="container mt-10 mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Policy Name"
                placeholder="Enter Project"
                value={formValues.policy_name}
                onChange={handleFrormChange}
                name="policy_name"
              />
              <TextField
                required
                id="outlined-required"
                label="Policy category"
                select
                placeholder="Policy Category"
                name="policy_category"
                value={formValues.policy_category}
                onChange={handleFrormChange}
              >
                {tableData.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                select
                label="Business Unit"
                name="group"
                defaultValue="hello"
                variant="outlined"
                value={formValues.group}
                onChange={handleFrormChange}
              >
                {bussness?.map((option) => (
                  <MenuItem key={Number(option.id)} value={Number(option.id)}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              {loading ? (
                <p>Loading...</p>
              ) : (
                <EmployeeSearch
                  onEmployeeSelect={handleEmployeeSelect}
                  onEmployeeSelectId={handleEmployeeSelectId}
                  employeeToEdit={selectedEmployee}
                />
              )}

              {loading ? (
                <p>Loading...</p>
              ) : (
                <EmployeeSearch2
                  onEmployeeSelect={handleEmployeeSelect2}
                  onEmployeeSelectId={handleEmployeeSelectId2}
                  employeeToEdit={selectedEmployee2}
                />
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Effective Date"
                  value={joining}
                  onChange={(newValue) => {
                    setJoining(newValue);
                  }}
                />
              </LocalizationProvider>
              <TextField
                required
                id="outlined-required"
                label="Reccurence Period"
                select
                value={formValues.recurrence}
                onChange={handleFrormChange}
                name="recurrence"
              >
                {Recurrency_Period.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                select
                name="department"
                label="Department"
                defaultValue="hello"
                value={formValues.department}
                onChange={handleFrormChange}
              >
                {department?.map((option) => (
                  <MenuItem key={Number(option.id)} value={Number(option.id)}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="Version Number"
                value={formValues.version_number}
                onChange={handleFrormChange}
                name="version_number"
              ></TextField>
            </div>
            <div className="mt-4 pl-2">
              <span className="font-['Poppins'] font-medium text-[12px] leading-normal text-[#322F37]">
                Policy Description
              </span>
              <MyStatefulEditor
                content={editorContent}
                onChange={setEditorContent}
              />
            </div>
            <div className="mt-4 pl-2">
              <span className="font-['Poppins'] font-medium text-[12px] leading-normal text-[#322F37]">
                Documents
              </span>
              <FileUpload
                existingFiles={initialFiles}
                onFilesChange={handleFilesChange}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};
