import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import eye from "../../assets/Crud_Icons/view.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import profile from "../../assets/Ellipse 580.png";
import TablePaginationDemo from "../common/Pagenation";
import { useNavigate } from "react-router-dom";
import filter from "../../assets/filter.svg";
import download from "../../assets/download_new.svg";
import { Fetch_WFH_List, Fetch_WFH_List1 } from "../common/services/Leave";
import * as XLSX from "xlsx";
import { debounce } from "lodash";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Filter from "../Filter/Filter";
import Search from "../SearchBar/Search";
import { Chip } from "@material-ui/core";
import moment from "moment";

interface Department {
  name: string;
}
interface RowData {
  User: {
    firstName: string;
    lastName: string;
    middleName: string;
    employee_id: string;
    profile_image: string;
    Department: Department | null;
  };
  no_of_days: string;
  from_date: string;
  to_date: string;
  id: string;
  wfh_status: string;
  wfh_session: string;
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any; // Adjust this based on your data structure
}

export const WFH = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<DepartmentUnit[]>();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState(false);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [dataFetched, setDataFetched] = useState(false);

  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | DepartmentUnit
  >();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_WFH_List(
        numOfItems,
        page,
        searchTerm,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id),
        startDate,
        endDate
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const fetchData1 = async () => {
    setLoading(true);
    try {
      const response = await Fetch_WFH_List1();
      if (response.status === 200) {
        // setTableData(response.data.result.rows);

        const fetchedData: EmployeeData[] = response.data?.result?.rows;

        const columnMapping: { [key: string]: string } = {
          Name: "Name",
          "User.Department.name": "Department",
          from_date: "Start Date",
          to_date: "End Date",
          wfh_status: "WFH Approval Status",
        };

        const filteredData = fetchedData.map((row) => {
          const filteredRow: Partial<EmployeeData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split("."); // Split the key to handle nested fields

            if (key === "Name") {
              // Combine firstName and lastName under the "Name" column
              filteredRow["Name"] = `${row.User.firstName || ""} ${
                row.User.lastName || ""
              }`.trim();
            } else if (keys.length === 1) {
              // Handle non-nested fields
              if (row.hasOwnProperty(keys[0])) {
                if (keys[0] === "from_date" && row[keys[0]]) {
                  filteredRow[keys[0]] = moment(row[keys[0]]).format(
                    "DD MMM, YYYY"
                  );
                } else if (keys[0] === "to_date" && row[keys[0]]) {
                  filteredRow[keys[0]] = moment(row[keys[0]]).format(
                    "DD MMM, YYYY"
                  );
                } else filteredRow[keys[0]] = row[keys[0]];
              }
            } else if (keys.length === 2) {
              // Handle nested fields
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                // Store the nested value under the child key
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            } else if (keys.length === 3) {
              // Handle three-level nested fields
              const [grandParentKey, parentKey, childKey] = keys;

              if (
                row[grandParentKey] &&
                row[grandParentKey][parentKey] &&
                row[grandParentKey][parentKey].hasOwnProperty(childKey)
              ) {
                // If the third level exists, assign its value
                filteredRow[key] = row[grandParentKey][parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            }
          });

          return filteredRow;
        });

        setTableData1(filteredData);

        // Set the columns for the table
        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData1();
  }, []);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };
    fetchDepartment();
  }, []);

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from tableData1
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...tableData1.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "work-from-home.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.warn("Data has not been fetched yet.");
    }
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      // fetchData(event.target.value); // call API to fetch data
    }, 100), // delay in ms
    []
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    fetchData();
  }, [
    page,
    numOfItems,
    searchTerm,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
    startDate,
    endDate,
    startSession,
    endSession,
  ]);

  const handleBusinessUnitSelect = (option: Business_Unit | DepartmentUnit) => {
    setTempSelectedBusinessUnit(option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | DepartmentUnit
  ) => {
    setTempSelectedDepartmentUnit(option);
  };

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleDeleteDepartment = () => {
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    fetchData();
  };

  const handleDeleteBusiness = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchData();
  };

  return (
    <div className="pb-14">
      <div className="pt-6 flex justify-between space-x-2 w-full">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center w-full">
            <Search
              onChange={updateSearchTerm}
              label="Search with Employee Name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex items-center gap-2">
                {dateRange && (
                  <Chip
                    label={`${moment(selectedDates[0]).format(
                      "YYYY-MM-DD"
                    )} - ${moment(selectedDates[1]).format("YYYY-MM-DD")}`}
                    onDelete={handleDeleteDate}
                    size="small"
                  />
                )}
                {departmentSelect && (
                  <Chip
                    label={selectedDepartmentUnit?.name}
                    onDelete={handleDeleteDepartment}
                    size="small"
                  />
                )}
                {businessSelect && (
                  <Chip
                    label={selectedBusinessUnit?.name}
                    onDelete={handleDeleteBusiness}
                    size="small"
                  />
                )}
              </div>
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button
                  onClick={handleDownload}
                  className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text"
                >
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-end mt-6 gap-[31px]">
        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Approve
          </span>
        </div>

        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FF9950" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Pending
          </span>
        </div>

        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FA1464" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Rejected
          </span>
        </div>
      </div>

      <div className="overflow-x-auto mt-4 shadow-sm">
        <table className="w-full min-w-max text-left table-auto font-[Poppins] border-[0.6px] bg-white border-[#E0E3E7]">
          <thead className="">
            <tr className="h-11">
              <th className="pl-6 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Employee Name & ID
              </th>
              <th className="pl-6 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Department
              </th>
              <th className="pl-6 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Full/Half Day
              </th>
              <th className="pl-6 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Start Date
              </th>
              <th className="pl-6 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                End Date
              </th>
              <th className="pl-6 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="font-[Poppins]">
            {tableData?.map((items) => (
              <tr
                key={items.id}
                className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100"
              >
                <td className="flex items-center space-x-2 pl-6 pr-2.5 py-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="3.99537"
                      cy="3.74537"
                      r="3.49537"
                      fill={
                        items.wfh_status === "Approved"
                          ? "#00CBA0"
                          : items.wfh_status === "Pending"
                          ? "#FF9950"
                          : items.wfh_status === "Rejected"
                          ? "#FA1464"
                          : "#D3D3D3" // default color if status is unknown
                      }
                    />
                  </svg>
                  <img
                    className="w-8 h-8 rounded-full"
                    src={items.User.profile_image || profile}
                    alt="Profile"
                  />
                  <div className="flex flex-col justify-between">
                    <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                      {items.User.firstName} {items.User.lastName}
                    </span>
                    <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                      {items.User.employee_id || ""}
                    </span>
                  </div>
                </td>
                <td className="text-[#3F4254] font-[Poppins] font-[500] pl-6 text-[12px] pr-2.5 py-1">
                  {items.User.Department?.name || "--"}
                </td>
                <td className="text-[#3F4254] font-[Poppins] pl-6 font-[500] text-[12px] pr-2.5 py-1">
                  {items.wfh_session || "--"}
                </td>
                <td className="text-[#3F4254] font-[Poppins] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {moment(items.from_date).format("DD MMM YYYY")}
                </td>
                <td className="text-[#3F4254] font-[Poppins] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {moment(items.to_date).format("DD MMM YYYY")}
                </td>
                <td className="pl-6 pr-2.5 py-1">
                  <div className="flex h-full w-full gap-2 items-center">
                    <img
                      src={eye}
                      onClick={() =>
                        navigate("/work-from-home-details", {
                          state: { id: items.id },
                        })
                      }
                      alt="View"
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                    />
                    <img
                      src={edit}
                      onClick={() =>
                        navigate("/edit-work-from-home", {
                          state: { id: items.id },
                        })
                      }
                      alt="Edit"
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
      <Filter
        isDate
        isOther
        heading="Work From Home Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </div>
  );
};
