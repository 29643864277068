import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { revertAll } from '../../ResetRedux/RevertAll';
 
interface UserManagementState {
    currentPage: string;
    userManagementData: {
        "count": number,
        "rows": any[],
        "currentPage": number,
        "itemsPerPage": number,
        "startDate": Date | null,
        "endDate": Date | null,
    };
    userRoleData: {
        "count": number,
        "rows": any[],
        "currentPage": number,
        "itemsPerPage": number,
        "isNext": boolean
    };
    userData: {};
    userGroupData: {
        "rows": any[],
        "currentPage": number,
        "itemsPerPage": number
    };
}
 
const initialState: UserManagementState = {
    currentPage: "table",
    userManagementData: {
        "count": 0,
        "rows": [],
        "currentPage": 1,
        "itemsPerPage": 10,
        "startDate": null,
        "endDate": null,
    },
    userRoleData: {
        "count": 0,
        "rows": [],
        "currentPage": 1,
        "itemsPerPage": 10,
        "isNext" : true,
    },
    userData: {},
    userGroupData: {
        "rows": [],
        "currentPage": 1,
        "itemsPerPage": 10,
    },
};
 
const dateFormatter = (isoTimestamp: string) => {
    const dateObject = new Date(isoTimestamp);
    const formattedDate = dateObject.toISOString().substring(0, 10);
   
    return formattedDate;
}
 
interface GroupData {
    id: number;
    name: string;
    parent_id: number | null;
    children?: GroupData[];
}
 
const updateDataStructure = (data: GroupData[]): any => {
    return data.map(({ id, name, parent_id, children }) => ({
        id,
        "group_name": name,
        parent_id,
        children: children ? updateDataStructure(children) : []
    }));
};
 
const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setCurrentPage(state, action: PayloadAction<any>){
            state.currentPage = action.payload;
        },

        setUserManagementData(state, action: PayloadAction<any>){
            // const updated = [...state.userManagementData, action.payload.rows];
            // state.userManagementData = updated;

            console.log(action.payload,"action1.payload");

            state.userManagementData.rows = action.payload.map((item: any) => ({
                "id": item.id ?? "",
                "status": item.status ?? "",
                "firstName": item.firstName ?? "",
                "lastName": item.lastName ?? "",
                "email": item.email ?? "",
                "phone": item.phone ?? "",
                "country_code": item.country_code ?? "",
                "addedDate": dateFormatter(item.createdAt) ?? "",
                "updatedDate": dateFormatter(item.updatedAt) ?? "",
                "group": item.group_data && item.group_data.length !== 0  ? item.group_data[0].name: "",
                "role_name": item.Role ? item.Role.role_name : ""
            }));
            console.log(action.payload,"action.payload");
            
 
            state.userManagementData.count = action.payload.count;
        },
 
        setUserManagementDataCurrentPage(state, action: PayloadAction<any>){
            state.userManagementData.currentPage = action.payload;
        },
 
        setUserManagementItemsPerPage(state, action: PayloadAction<any>){
            state.userManagementData.itemsPerPage = action.payload;
        },
 
        setStartDate(state, action: PayloadAction<Date | null>) {
            state.userManagementData.startDate = action.payload;
        },
          
        setEndDate(state, action: PayloadAction<Date | null>) {
            state.userManagementData.endDate = action.payload;
        },
 
        setUserRoleData(state, action: PayloadAction<any>){
            state.userRoleData.count = action.payload.count;
 
            const updated =  action.payload.rows.map((item: any) => ({
                "id": item.id,
                "role_name": item.role_name
            }));
 
            if(state.userRoleData.isNext){
           
            state.userRoleData.currentPage++;
            state.userRoleData.rows = [
                ...state.userRoleData.rows,
                ...updated
            ]
        }
       
        state.userRoleData.isNext = state.userRoleData.itemsPerPage*(state.userRoleData.currentPage-1) < state.userRoleData.count;  
        },

        resetUserRoleData(state, action: PayloadAction<any>){
            state.userRoleData = action.payload;
        },
 
        setUserData(state, action: PayloadAction<any>){
            // const updated = [...state.userManagementData, action.payload.rows];
            // state.userManagementData = updated;
 
            const updated = action.payload;
            
            state.userData = {
                "id": updated.id ?? "",
                "status": updated.status ?? "",
                "firstName": updated.firstName ?? "",
                "lastName": updated.lastName ?? "",
                "email": updated.email ?? "",
                "phone": updated.phone ?? "",
                "country_code": updated.country_code ?? "",
                "createdAt": updated.createdAt ? dateFormatter(updated.createdAt) : "",
                "updatedAt": updated.updatedAt ? dateFormatter(updated.updatedAt) : "",
                "group": updated.group_data && updated.group_data.length !== 0 ? updated.group_data[0].name : "",
                // "group": updated.firstName,
                "role_name": updated.Role ? updated.Role.role_name : "",
                "role_id": updated.Role ? updated.Role.id : "",
                "group_access": updated.group_data && updated.group_data.length !== 0 ? [updated.group_data[0].id] : [0],
            };
        },
       
        setUserGroupData(state, action: PayloadAction<any>){
            // state.userGroupData.rows = action.payload.map((item: any) => ({
            //     "id": item.id,
            //     "group_name": item.name,
            //     "parent_id": item.parent_id
            // }));
 
            state.userGroupData.rows = updateDataStructure(action.payload);
        },
    }
});
 
export const userManagementActions = userManagementSlice.actions;
export default userManagementSlice;