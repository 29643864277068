import { useSelector } from "react-redux";
import { QuestionnaireItem } from "../../../redux/types/onboarding";
import { api_key_work as api_key } from "../../../utils/helperData";
import {
  FETCH_MATURITY_DATA,
  FETCH_RESULT_DATA,
  FETCH_SERVICES_DATA,
  SEND_ONBOARDING_DATA,
  FETCH_INDUSTRY_VERTICAL,
  FETCH_QUESTIONS,
  SEND_ANSWERS,
} from "../api";

interface FormData {
  question_id: number;
  answer: string;
}

export const Fetch_Service_Data_Api = () => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${FETCH_SERVICES_DATA}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Industry_Vertical_Data_Api = () => {
  const signup_token = localStorage.getItem("signup_token");
 const access_token = localStorage.getItem("access_token");
  return fetch(`${FETCH_INDUSTRY_VERTICAL}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + signup_token,
    },
  });
};

export const Fetch_Industry_Vertical_Data_Api_Login = () => {
  const signup_token = localStorage.getItem("signup_token");
 const access_token = localStorage.getItem("access_token");
  return fetch(`${FETCH_INDUSTRY_VERTICAL}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Questions_Data_Api = (industry_vertical_id: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${FETCH_QUESTIONS}${industry_vertical_id}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Send_Answers_Data = (answersData: FormData[]) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SEND_ANSWERS}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "x-api-key": api_key,
    },
    body: JSON.stringify({answers:answersData}),
  });
};

export const Fetch_Maurity_Data_Api = () => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${FETCH_MATURITY_DATA}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Result_Data_Api = () => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${FETCH_RESULT_DATA}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Package_Details_Data_Api = (id: number | undefined) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${FETCH_RESULT_DATA}/${id}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Countries = () => {
  const userData = useSelector((state: any) => state.signup.details);
  const currentPage = 1;
  const signup_token = userData.signup_token;
  let query = `?page=${currentPage}&size=25`;
  return fetch(`${Fetch_Countries}${query}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: signup_token,
    },
  });
};

export const Send_Onboarding_Data = (
  service_id: number[],
  ambition_id: number[],
  questionnaires: QuestionnaireItem[],
  package_id: number | undefined
) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SEND_ONBOARDING_DATA}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "x-api-key": api_key,
    },
    body: JSON.stringify({
      service_id,
      ambition_id,
      questionnaires,
      package_id,
    }),
  });
};
