import React, { useState } from "react";
import downarrow from "../../image/Icon.png";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface SelectDropdownProps {
  selectname: string;
  options: Business_Unit[] | Department_Unit[] | undefined;
  onOptionSelect: (option: Business_Unit | Department_Unit) => void; // New callback prop
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  selectname,
  options,
  onOptionSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectname);

  const toggleDropdown = () => setIsOpen(!isOpen);
  // const handleOptionClick = (option: Business_Unit[] | Department_Unit[]) => {
  //   setSelected(option);
  //   setIsOpen(false);
  //   onOptionSelect(option); // Call the callback with the selected option
  // };

  const handleOptionClick = (option: Business_Unit | Department_Unit) => {
    setSelected(option.name);
    setIsOpen(false);
    onOptionSelect(option); // Call the callback with the entire option object
  };

  return (
    <>
      {window.location.pathname === "/attendance-management" ||
      window.location.pathname === "/leave-holiday-management" ||
      window.location.pathname === "/work-from-home" ||
      window.location.pathname === "/expense-management" ? (
        <div className="relative inline-block">
          <div
            className="border border-gray-300 h-[40px] rounded-md p-2 flex justify-between items-center cursor-pointer"
            onClick={toggleDropdown}
          >
            <span className="text-[12px] text-[#938f99] font-[500] font-[Poppins]">
              {selected}
            </span>
            <img src={downarrow} alt="" />
            {/* <svg
              className={`w-5 h-5 transition-transform duration-300 ${
                isOpen ? "transform rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l6 6a1 1 0 11-1.414 1.414L10 5.414 4.707 10.707a1 1 0 01-1.414-1.414l6-6A1 1 0 0110 3z"
                clipRule="evenodd"
              />
            </svg> */}
          </div>
          {isOpen && (
            <ul className="absolute left-0 font-[Poppins] right-0 bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto z-10">
              {options?.map((option) => (
                <li
                  key={Number(option.id)}
                  className={`p-2 cursor-pointer hover:bg-gray-100 ${
                    selected === option.name ? "bg-gray-100" : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  <div className="font-[Poppins]">{option.name}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <div className="relative inline-block">
          <div
            className="border border-gray-300 h-[40px] rounded-md p-2 flex justify-between items-center cursor-pointer"
            onClick={toggleDropdown}
          >
            <span className="text-[12px] text-[#938f99] font-[500] font-[Poppins]">
              {selected}
            </span>
            <img src={downarrow} alt="" />
            {/* <svg
              className={`w-5 h-5 transition-transform duration-300 ${
                isOpen ? "transform rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l6 6a1 1 0 11-1.414 1.414L10 5.414 4.707 10.707a1 1 0 01-1.414-1.414l6-6A1 1 0 0110 3z"
                clipRule="evenodd"
              />
            </svg> */}
          </div>
          {isOpen && (
            <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto z-10">
              {options?.map((option) => (
                <li
                  key={Number(option.id)}
                  className={`p-2 cursor-pointer hover:bg-gray-100 ${
                    selected === option.name ? "bg-gray-100" : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  <div className="font-[Poppins]">{option.name}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default SelectDropdown;
