import { Dispatch } from 'redux'; 
import { userManagementActions } from '../../reducers/userManagement/userManagementSlice';
import { Fetch_User_Data, Get_User_Role_Access_List, Send_Create_User_Data, Send_Update_User_Data, User_Management_List_Api } from '../../../components/common/services/userManagement';
import InternalServer from '../../../components/common/InternalServer';

export const fetchUserManagementList = (
  url: string,
) => async (dispatch: Dispatch) => {

  const response = await User_Management_List_Api(url)
  const data = await response.json();

  if (!response.ok) {
    if(response.status === 500) {
      return <InternalServer />;
    }
    throw new Error('Could not fetch user data!');
  }

  return data;
}

export const fetchRoleList = (
  url: string
) => async (dispatch: Dispatch) => {
  const response = await User_Management_List_Api(url)

  const data = await response.json();

  if (!response.ok) {
    if(response.status === 500) {
      return <InternalServer />;
    }
    throw new Error('Could not fetch role data!');
  }

  return data;
}

export const sendCreateUserData = (
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  country_code: string,
  role_id: number,
  group_access: number[],
  resource_list: number[],
  customer_id: number
) => async (dispatch: Dispatch) => {

  const response = await Send_Create_User_Data(firstName, lastName, email, phone, country_code, role_id, group_access, resource_list, customer_id)

  if(response.status !== 200){
    if(response.status === 500) {
      return <InternalServer />;
    }
    return response;
  }
  
  const data = response?.data || null;

  return data;
}


export const fetchUserData = (
  id: any
) => async (dispatch: Dispatch) => {

    const response = await Fetch_User_Data(id)
    const data = await response.json();

    if (!response.ok) {
      if(response.status === 500) {
        return <InternalServer />;
      }
      throw new Error('Could not fetch user data!');
    }

    return data;
}


export const getUserRoleAccessList: any = async (role_id: number) => {
  const response = await Get_User_Role_Access_List(role_id)
  const data = await response.json();

  if (!response.ok) {
    if(response.status === 500) {
      return <InternalServer />;
    }
    throw new Error('Could not fetch user role data!');
  }
  
  return data;
};


export const fetchUserGroupAccessList = (
  url: string
) => async (dispatch: Dispatch) => {
  const response = await User_Management_List_Api(url)
  const data = await response.json();

  if (!response.ok) {
    if(response.status === 500) {
      return <InternalServer />;
    }
    throw new Error('Could not fetch group data!');
  }

  return data;
}


export const sendUpdateUserData = (
  firstName: string,
  lastName: string,
  phone: string,
  country_code: string,
  role_id: number,
  status: string,
  group_access: number[],
  user_id: number
) => async (dispatch: Dispatch) => {

  const response = await Send_Update_User_Data(firstName, lastName, phone, country_code, role_id, status, group_access, user_id)

  if (!response.ok) {
    if(response.status === 500) {
      return <InternalServer />;
    }
    throw new Error('Could not send update user data!');
  }
  
  const data = await response.json();
  return data;
}