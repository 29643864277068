import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import right from "../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import check from "../../image/checkmark-circle-02.svg";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  fetchEditExitEmployee,
  updateExitEmployee,
} from "../common/services/ExitEmployee";
import toast from "react-hot-toast";
import axios from "axios";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormSelectValues {
  empId: string;
  name: string;
  department: string;
  designation: string;
  reason: string;
}

export const EditExitProcedure = () => {
  const [initiationDate, setInitiationDate] = React.useState<Dayjs | null>(
    null
  );
  const [lastWorking, setLastWorking] = React.useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);


  const handleOpen = () => {
    if (validate()) {
      submitChanges();
    }
  };

  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/exit-procedure");
  };

  const [formValues, setFormValues] = useState<FormSelectValues>({
    empId: "",
    name: "",
    department: "",
    designation: "",
    reason: "",
  });

  const [errors, setErrors] = useState<Partial<FormSelectValues>>({});

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validate = (): boolean => {
    let tempErrors: Partial<FormSelectValues> = {};
    if (formValues.department === "")
      tempErrors.department = "Department Unit is required";
    if (formValues.designation === "")
      tempErrors.designation = "Designation Id is required";
    if (!formValues.empId.trim()) tempErrors.empId = "Employee Id is required";
    if (!formValues.name.trim()) tempErrors.name = "Full Name is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    // setLoading(true);
    fetchEditExitEmployee(id)
      .then((data) => {
        if (data.result) {
          // setUpdateData(data);

          setFormValues({
            empId: data.result.User.employee_id,
            name: data.result.User.firstName,
            department: data.result.Department.id,
            designation: data.result.User.Designation.id,
            reason: data.result.reason,
          });
          setInitiationDate(dayjs(data.result.User.date_of_leaving));
          setLastWorking(
            dayjs(data.result.User.date_of_exit_initiation)
          );
        }
      })
      .catch((error) => console.error(error))
  }, [id]);

  const submitChanges = async () => {
    try {
      const data = await updateExitEmployee(
        id,
        formValues.empId,
        formValues.reason,
        initiationDate,
        lastWorking,
        formValues.designation
      );

      if (data && data.result) {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          //navigate(`${ROLE_MANAGEMENT}`);
          navigate("/exit-procedure");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        //onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            className="flex items-center justify-center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Your data will be proceed SuccessFully
          </Typography>
        </Box>
      </Modal>
      <div>
        <div className="bg-[#FBFBFB] px-4 pt-6 pb-12 h-full">
          <div className="flex flex-row items-center h-12 justify-between">
            <div className="flex flex-row gap-[13px] justify-center items-center">
              <button onClick={() => navigate("/exit-procedure")}>
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                Edit Exit Procedure Details
              </span>
            </div>
            <div className="flex flex-start gap-5">
              <button
                onClick={handleCancel}
                className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
                //onClick={(event) => handleCurrentPageChange("table", event)}
              >
                <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Cancel
                </div>
              </button>
              <button
                type="submit"
                form="form"
                className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
                //   onClick={() => handleCurrentPageChange("addNew")}
              >
                <div
                  onClick={handleOpen}
                  className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
                >
                  Proceed
                </div>
              </button>
            </div>
          </div>

          <div className="rounded overflow-hidden shadow-lg bg-white mt-4">
            <div className="container mx-auto p-4">
              <div className="text-[#262626] font-[500] text-[14px] ml-2 font-['Poppins']">
                Procedure details
              </div>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1 },
                }}
                noValidate
                autoComplete="off"
              >
                <div className="grid grid-cols-3 mt-6 gap-4">
                  <TextField
                    required
                    id="outlined-required"
                    label="Employee Id"
                    disabled
                    name="empId"
                    value={formValues.empId}
                    onChange={handleFrormChange}
                  />
                  
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Of Exit Initiate"
                      value={initiationDate}
                      onChange={(newValue) => setInitiationDate(newValue)}
                      // className="w-full"
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Last Working Day"
                      value={lastWorking}
                      onChange={(newValue) => setLastWorking(newValue)}
                      // className="w-full"
                    />
                  </LocalizationProvider>
                </div>

                <div className="grid grid-cols-1 mt-6 gap-4">
                  <TextField
                    required
                    id="outlined-required"
                    label="Reason"
                    multiline
                    rows="3"
                    name="reason"
                    defaultValue="hello"
                    value={formValues.reason}
                    onChange={handleFrormChange}
                    error={!!errors.reason}
                    helperText={errors.reason}
                  ></TextField>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};