import React, { useState, useCallback, ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { debounce } from "lodash";

import edit from "../../assets/Crud_Icons/edit.svg";
import del from "../../assets/Crud_Icons/delete.svg";

import Search from "../SearchBar/Search";
import TablePaginationDemo from "../common/Pagenation";
import EditAssetCategoryModal from "./EditAssetCategory";
import DeleteConfirmationModal from "./DeleteAssetCategory";
import {
  Delete_Asset_Category,
  Fetch_Asset_Catergory,
  Update_Asset_Category,
  View_Asset_Category,
} from "../common/services/Asset";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import SuccessModal from "../common/SuccessModal";

export type AssetGategoryData = {
  id: number;
  category: string;
  quantity: number;
};

interface EditAssetCategoryData {
  total: string;
  category: string;
  id: string;
}

function AssetCategory() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [editItemId, setEditItemId] = useState<number>(-1);
  const [editAssetCategoryData, setEditAssetCategoryData] =
    useState<EditAssetCategoryData>({
      total: "",
      category: "",
      id: "",
    });
  const [selectedItem, setSelectedItem] = useState<AssetGategoryData | null>(
    null
  );
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [rowData, setRowData] = useState<AssetGategoryData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  const handleDeleteClose = () => {
    setIsDeleteOpen(false);
  };

  const handleEditClose = () => {
    setIsEditOpen(false);
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    }, 100),
    []
  );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const handleDeleteClick = (item: AssetGategoryData) => {
    setSelectedItem(item);
    setDeleteOpen(true);
  };

  const handleEdit = async (id: number) => {
    try {
      const data = await View_Asset_Category(id);
      if (data.result) {
        setEditAssetCategoryData({
          total: data.result.quantity.toString(),
          category: data.result.category,
          id: data.result.id,
        });
        setEditOpen(true);
      } else {
        toast.error("Error while fetching data");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Invalid Request");
    }
    setEditItemId(id);
  };

  const handleUpdate = async (assetCategory: string, totalAsset: string) => {
    try {
      const response = await Update_Asset_Category(
        editItemId,
        assetCategory,
        Number(totalAsset)
      );
      if (response.status === 200) {
        setTimeout(() => {
          setIsEditOpen(true);
          setEditOpen(false);
        }, 300);
        setTimeout(() => {
          setIsEditOpen(false);
          navigate("/asset-category", {
            state: { id: 2 },
          });
          handleEditClose();
        }, 1000);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const handleDelete = async () => {
    if (selectedItem) {
      try {
        const response = await Delete_Asset_Category(selectedItem.id);
        if (response.status === 200) {
          setRowData(rowData?.filter((item) => item.id !== selectedItem.id));
          setTimeout(() => {
            setIsDeleteOpen(true);
            setDeleteOpen(false);
          }, 300);
          setTimeout(() => {
            setIsDeleteOpen(false);
            handleDeleteClose();
          }, 1000);
        } else {
          toast.error("Failed to delete the item");
          setDeleteOpen(false);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setSelectedItem(null);
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Asset_Catergory(
        customer_id,
        numOfItems,
        page,
        searchTerm
      );
      if (response.status === 200) {
        setRowData(response.data.result.rows);
        setCount(response.data.result.count);
      } else {
        toast.error(response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customer_id, numOfItems, page, searchTerm, value]);

  useEffect(() => {
    setValue(location?.state?.id);
  }, [location?.state?.id]);

  return (
    <div className="pt-12">
      <div className="flex flex-col gap-5">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with Announcement"
            />
          </div>
        </div>
        <div className="overflow-x-auto overflow-y-auto shadow-sm">
          <table className="w-full min-w-max text-left table-auto bg-white">
            <thead className="rounded-t-[3px] border border-[#E0E0E0]">
              <tr className="h-11 w-full">
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-8 w-1/3">
                  Assets Category
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] text-center py-4 w-1/3">
                  Total Assets
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pr-8 flex justify-end">
                  Action
                </th>
              </tr>
            </thead>
            {!loading ? (
              rowData?.map((item) => (
                <tbody className="border border-[#EBEBEB]">
                  <tr className="h-12 border-t-0 border-l-0 border-r-0 border-b border-[#EBEBEB] hover:bg-gray-100">
                    <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] pl-8">
                      {item?.category}
                    </td>
                    <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-center">
                      {item?.quantity}
                    </td>
                    <td className="pr-5">
                      <div className="flex justify-end items-center gap-2">
                        <img
                          className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                          onClick={() => handleEdit(item.id)}
                          // onClick={() =>
                          //       navigate("/view-announcement", {
                          //         state: { id: item.id },
                          //       })
                          //     }
                          src={edit}
                          alt="Edit"
                        />
                        <img
                          className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                          onClick={() => handleDeleteClick(item)}
                          src={del}
                          alt="Delete"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))
            ) : (
              <>
                <td></td>
                <td className="flex justify-center items-center w-full">
                  <CircularProgress />
                </td>
                <td></td>
              </>
            )}
          </table>
          {!loading && rowData.length === 0 ? (
            <div className="w-full h-10 px-2.5 py-1.5 flex justify-center items-center bg-white shadow-sm font-[Poppins] text-base text-[#1D1A22] border border-[#EBEBEB]">
              Data not Found!
            </div>
          ) : (
            <></>
          )}
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>
      {editOpen ? (
        <EditAssetCategoryModal
          onCancel={() => setEditOpen(false)}
          onUpdate={handleUpdate}
          assetCategoryDetails={editAssetCategoryData}
        />
      ) : (
        <></>
      )}
      {deleteOpen ? (
        <DeleteConfirmationModal
          onCancelDelete={() => setDeleteOpen(false)}
          onDelete={handleDelete}
        />
      ) : (
        <></>
      )}
      <SuccessModal
        label="Asset Category Updated Successfully!"
        isOpen={isEditOpen}
        onclose={handleEditClose}
      />
      <SuccessModal
        label="Asset Category Deleted Successfully!"
        isOpen={isDeleteOpen}
        onclose={handleDeleteClose}
      />
    </div>
  );
}

export default AssetCategory;
