import React from "react";

const Footer = () => {
  return (
    <div className="fixed inset-x-0 bottom-0 w-full bg-gray-800 text-white text-center">
          <div className="flex justify-center items-center text-center px-16 py-1 w-full bg-gray-900">
            © 2024 Go-EMS. All rights reserved.
          </div>
        </div>
  );
};

export default Footer;
