import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { recovery } from "../../redux/actions/authActions";
import Icon from "../../assets/tick.svg";
import Header from "../common/header";
import { FORGOT_PASSWORD } from "../../utils/routeConstant";
import logo from "../../image/MicrosoftTeams-image (3).png";

const Recovery: React.FC = () => {
  let completeOtp: string = "";
  const numberOfDigits = 6;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [verify, setVerify] = useState(false);
  const [otp, setOtp] = useState<string[]>(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef<HTMLInputElement[]>([]);

  // Accessing the userData state from Redux store
  const userData = useSelector((state: any) => state.auth.login.login_details);
  const userEmail = userData.email;

  const handleChange = (value: string, index: number) => {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };
  // const handleOtp=()=>{
  //   navigate("/")
  // }

  const handleOtp = async () => {
    setVerify(true);
    // validation
    if (completeOtp.length < 6) {
      toast.dismiss();
      toast.error("Fill complete OTP!");
      return;
    }

    // Dispatch cannotLogin action
    try {
      toast.dismiss();
      toast.loading("Sending code...");
      const response = await dispatch<any>(recovery(userEmail, completeOtp));
      toast.dismiss();
      if (response.success) {
        toast.success(<b>{response.message}</b>);
        navigate(`/create-password`);
      }
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.response.data.message);
    } finally {
      setVerify(false);
    }
  };

  const handleBackspaceAndEnter = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && !e.currentTarget.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (
      e.key === "Enter" &&
      e.currentTarget.value &&
      index < numberOfDigits - 1
    ) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  if (otp.every((digit) => /\d/.test(digit))) {
    completeOtp = otp.join("");
  }

  return (
    <div className="h-[100vh] Mainbg">
      {/* <Header /> */}
      <div className="flex justify-center gap-8">
        <div className="w-[29.3%] h-[15%] ml-[8.5%] mt-[13.5%] flex-col justify-start gap-5 items-start inline-flex text-white">
          <section className="flex-col justify-start items-start flex w-[27rem] h-[15rem]">
            <div className="mb-6 w-full">
            <div className="flex flex-row items-center justify-start gap-[12.2px] shrink-0 [debug_commit:69da668] z-[2]">
                <img
                  className="logocss relative min-h-[61px]"
                  loading="lazy"
                  alt=""
                  src={logo}
                />
                <div className="flex flex-col items-start justify-center pt-[18px] px-0 pb-[17.9px] gap-[3.7px]">
                  {/* <div className="flex flex-row items-end justify-start">
                    <b className="relative leading-[57%] font-semibold mq800:text-[28px] mq800:leading-[16px] mq450:text-[21px] mq450:leading-[12px]">
                      GO-EMS
                    </b>
                  </div> */}
                  <div className="w-[95px] relative text-[14.6px] tracking-[0.09em] capitalize text-gainsboro hidden">
                    trust to go
                  </div>
                </div>
              </div>
              <div className="text-white text-4xl font-semibold font-['Poppins'] whitespace-nowrap h-[3rem]">
                Welcome to Go-EMS
              </div>
              <div className="text-white text-base font-normal font-['Inter'] uppercase leading-normal">
              Employee Management System
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-4 flex w-[24rem] h-36">
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Spectacular reduction of compliance costs
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Regulatory framework proof
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Unmatched easy of mind
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  One-click certification per the highest EU standards
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="w-[21.3rem] h-[21.25rem] bg-white flex flex-col items-center ml-[24%] mr-[15%] mt-[10%] mb-[13rem] rounded-xl">
          <div className="text-neutral-800 text-xl font-semibold font-['Poppins'] mt-[1.5rem] mb-[1.4rem]">
            Enter recovery code
          </div>
          <form className="flex flex-col w-[21rem]">
            <div className="text-center mb-[2rem]">
              <span className="text-black text-xs font-medium font-['Poppins']">
                Please enter 6 digit code we just sent to
                <br />
              </span>
              <span className="text-black text-[10px] font-medium font-['Poppins']">
                {userEmail || "your@email.com"}
              </span>
            </div>
            <div className="flex justify-center gap-2">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  value={digit}
                  maxLength={1}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                  ref={(reference: any) =>
                    (otpBoxReference.current[index] = reference!)
                  }
                  className="w-[2.3rem] h-[2.5rem] bg-zinc-100 rounded-[3px] border border-indigo-600 text-center"
                />
              ))}
            </div>
            <div className="flex justify-center mt-[2.5rem]">
              <button
                className="text-white w-[19rem] h-10 buttoncss rounded-[8px] text-sm font-semibold font-['Poppins']"
                onClick={handleOtp}
                type="button"
              >
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Recovery;
