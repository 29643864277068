// import React, { useEffect } from "react";
// import { Tabs, Tab, Box, Typography } from "@mui/material";
// import AddResources from "./AddResources";
// import AddProjectDetails from "./AddProjectDetails";
// import { useLocation, useNavigate } from "react-router-dom";
// import AddTask from "./AddTask";

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`tabpanel-${index}`}
//       aria-labelledby={`tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `tab-${index}`,
//     "aria-controls": `tabpanel-${index}`,
//   };
// }

// const CustomTabs: React.FC = () => {
//   const [value, setValue] = React.useState(0);

//   const location = useLocation();
//   const history = useNavigate();

//   useEffect(() => {
//     if (location.pathname === "/add-project") {
//       setValue(0);
//     } else if (/^\/add-resources\/[^/]+$/.test(location.pathname)) {
//       setValue(1);
//     } else if (/^\/add-task\/[^/]+$/.test(location.pathname)) {
//       setValue(2);
//     }
//   }, [location.pathname]);

//   console.log(value, "setvalue----->>>>>>>>");

//   // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//   //   setValue(newValue);
//   // };

//   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//     setValue(newValue);
//     if (newValue === 0) {
//       history("/add-project");
//     } else if (newValue === 1) {
//       history("/add-resources");
//     } else if (newValue === 2) {
//       history("/add-task");
//     }
//   };
//   const Lavel = () => {
//     return (
//       <>
//         <div className="w-full h-full p-[26px]">
//           <div className="flex flex-col gap-2.5">
//             <div className="w-12 h-12 flex justify-center items-center border-[3px] border-black rounded-full">
//               <span className="font-[Poppins] font-semibold text-[17px] leading-[25.5px] text-black">
//                 1
//               </span>
//             </div>
//             <div className="flex flex-col gap-[4px]">
//               <span className="font-[Poppins] font-semibold text-[17px] leading-[25.5px] text-left text-black">
//                 Project
//               </span>
//               <span className="font-[Poppins] font-medium text-xs text-black text-left">
//                 Configure the project details and define the project type along
//                 with the estimates
//               </span>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   };

//   const Lave2 = () => {
//     return (
//       <>
//         <div className="w-full h-full p-[26px]">
//           <div className="flex flex-col gap-2.5">
//             <div className="w-12 h-12 flex justify-center items-center border-[3px] border-black rounded-full">
//               <span className="font-[Poppins] font-semibold text-[17px] leading-[25.5px] text-black">
//                 2
//               </span>
//             </div>
//             <div className="flex flex-col gap-[4px]">
//               <span className="font-[Poppins] font-semibold text-[17px] leading-[25.5px] text-left text-black">
//                 Resources
//               </span>
//               <span className="font-[Poppins] font-medium text-xs text-black text-left">
//                 Add the project related resources, provide billable, cost rate
//                 and assign
//               </span>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   };

//   const Lavel3 = () => {
//     return (
//       <>
//         <div className="w-full h-full p-[26px]">
//           <div className="flex flex-col gap-2.5">
//             <div className="w-12 h-12 flex justify-center items-center border-[3px] border-black rounded-full">
//               <span className="font-[Poppins] font-semibold text-[17px] leading-[25.5px] text-black">
//                 3
//               </span>
//             </div>
//             <div className="flex flex-col gap-[4px]">
//               <span className="font-[Poppins] font-semibold text-[17px] leading-[25.5px] text-left text-black">
//                 Tasks
//               </span>
//               <span className="font-[Poppins] font-medium text-xs text-black text-left">
//                 Add the project related tasks, provide estimates,billable rate
//                 per hour and assign the resources
//               </span>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   };

//   return (
//     <div className="w-full p-4">
//       <Tabs
//         value={value}
//         // onChange={handleChange}
//         aria-label="full width tabs example"
//         variant="fullWidth"
//         sx={{
//           "& .MuiTabs-indicator": {
//             display: "none",
//           },
//           "& .Mui-selected": {
//             background: "linear-gradient(90deg, #4165c4 0%, #8e1fd2 100%);",
//             color: "#fff",
//           },
//           background: "#E8E0FF",
//         }}
//       >
//         <Tab label={<Lavel />} {...a11yProps(0)} className="text-lg" />
//         <Tab label={<Lave2 />} {...a11yProps(1)} className="text-lg" />
//         <Tab label={<Lavel3 />} {...a11yProps(2)} className="text-lg" />
//       </Tabs>
//       <TabPanel value={value} index={0}>
//         <AddProjectDetails />
//       </TabPanel>
//       <TabPanel value={value} index={1}>
//         <AddResources />
//       </TabPanel>
//       <TabPanel value={value} index={2}>
//         <AddTask />
//       </TabPanel>
//     </div>
//   );
// };

// export default CustomTabs;

import React, { useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import AddResources from "./AddResources";
import AddProjectDetails from "./AddProjectDetails";
import { useLocation, useNavigate } from "react-router-dom";
import AddTask from "./AddTask";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index,  ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: "24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const CustomTabs: React.FC = () => {
  const [value, setValue] = React.useState(0);

  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    if (location.pathname === "/add-project") {
      setValue(0);
    } else if (/^\/add-resources\/[^/]+$/.test(location.pathname)) {
      setValue(1);
    } else if (/^\/add-task\/[^/]+$/.test(location.pathname)) {
      setValue(2);
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      history("/add-project");
    } else if (newValue === 1) {
      history("/add-resources");
    } else if (newValue === 2) {
      history("/add-task");
    }
  };

  const Label = ({ number, title, description, isSelected }: any) => {
    return (
      <div className="w-full h-full p-[26px]">
        <div className="flex flex-col gap-2.5">
          <div
            className={`w-12 h-12 flex justify-center items-center border-[3px] rounded-full ${
              isSelected ? "border-white" : "border-black"
            }`}
            style={{ borderColor: isSelected ? "white" : "var(--22, #4165C4)" }}
          >
            <span
              className={`font-[Poppins] font-semibold text-[17px] leading-[25.5px] ${
                isSelected ? "text-white" : "text-black"
              }`}
            >
              {number}
            </span>
          </div>
          <div className="flex flex-col gap-[4px]">
            <span
              className={`font-[Poppins] font-semibold text-[17px] leading-[25.5px] text-left ${
                isSelected ? "text-white" : "text-black"
              }`}
            >
              {title}
            </span>
            <span
              className={`font-[Poppins] font-medium text-xs text-left ${
                isSelected ? "text-white" : "text-black"
              }`}
            >
              {description}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-[90vh] w-full px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <Box>
        <Tabs
          value={value}
          // onChange={handleChange}
          aria-label="full width tabs example"
          variant="fullWidth"
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .Mui-selected": {
              background: "linear-gradient(90deg, #4165c4 0%, #8e1fd2 100%);",
              color: "#fff",
            },
            background: "#E8E0FF",
          }}
        >
          <Tab
            label={
              <Label
                number="1"
                title="Project"
                description="Configure the project details and define the project type along with the estimates"
                isSelected={value === 0}
              />
            }
            {...a11yProps(0)}
            className="text-lg"
          />
          <Tab
            label={
              <Label
                number="2"
                title="Resources"
                description="Add the project related resources, provide billable, cost rate and assign"
                isSelected={value === 1}
              />
            }
            {...a11yProps(1)}
            className="text-lg"
          />
          <Tab
            label={
              <Label
                number="3"
                title="Tasks"
                description="Add the project related tasks, provide estimates,billable rate per hour and assign the resources"
                isSelected={value === 2}
              />
            }
            {...a11yProps(2)}
            className="text-lg"
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <AddProjectDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AddResources />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AddTask />
        </TabPanel>
      </Box>
    </div>
  );
};

export default CustomTabs;
