// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabpanel {
  padding: 0px !important;
}

.indicator {
  display: none !important;
  background: #000 !important;
  height: 4px !important;
  border-radius: 100px 100px 0px 0px;
}

.card-shadow {
  box-shadow: 4px 4px 4px 0px #0000001A;
}

@media (max-width: 1200px) {
  .custom\\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .task\\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ProjectEffortReport/effort.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,2BAA2B;EAC3B,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE;IACE,gDAAgD;EAClD;EACA;IACE,gDAAgD;EAClD;AACF","sourcesContent":[".tabpanel {\n  padding: 0px !important;\n}\n\n.indicator {\n  display: none !important;\n  background: #000 !important;\n  height: 4px !important;\n  border-radius: 100px 100px 0px 0px;\n}\n\n.card-shadow {\n  box-shadow: 4px 4px 4px 0px #0000001A;\n}\n\n@media (max-width: 1200px) {\n  .custom\\:grid-cols-2 {\n    grid-template-columns: repeat(2, minmax(0, 1fr));\n  }\n  .task\\:grid-cols-1 {\n    grid-template-columns: repeat(1, minmax(0, 1fr));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
