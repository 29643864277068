import Tree from "react-d3-tree";
import viewDetails from "../../assets/viewDetails.svg";
import "./styles.css";
import React, { useCallback, useEffect, useId, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  companyStructureGroupList,
  sendCreateBussinessUnitData,
  sendCreateDepartmentData,
  sendCreateProcessData,
} from "../../redux/actions/CompanyStructure/companyStructureActions";
import toast from "react-hot-toast";
import Modal from "./AddEntityModal";
import AddUnit from "./AddUnitModal/AddUnit";
import { SELECTED_VIEW_GROUP_DETAILS } from "../../utils/routeConstant";
import { useNavigate } from "react-router-dom";
import bussiness_man_icon from "../../assets/business_man.svg"

const containerStyles = {
  width: "100%",
  height: "100vh",
};

interface CustomerManagementTableProps {
  handleCurrentPageChange: (value: string) => void;
  handleEdit: (id: number) => void;
}
interface NodeDatum {
  group_name: string;
  children?: NodeDatum[];
  __rd3t?: {
    collapsed: boolean;
  };
  user_id: number;
  level: number;
  user_data: any;
  parent_group:any;
  group_data:any;
  id?: number;
  type: string;
  customer_id?: Number;
  parent_id?: number;
  group_id?: number;
}

interface ToggleNodeFunction {
  (): void;
}
const CompanyTree: React.FC<CustomerManagementTableProps> = ({
  handleCurrentPageChange,
}) => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [currentPartentId, setCurrentParrentId] = useState<any>();
  const [customerId, setCustomerId] = useState<any>();
  const [userId, setUserId] = useState<any>();
  const [currentGroupId, setCurrentGroupId] = useState<any>();
  const [departmentModalOpen, setDepartmentModalOpen] = useState(false);
  const [processModalOpen, setProcessModalOpen] = useState(false);
  const handleDepartmentCloseModal = () => {
    setDepartmentModalOpen(false);
  };
  const handleProcessCloseModal = () => {
    setProcessModalOpen(false);
  };

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(companyStructureGroupList(customer_id));
  }, [dispatch, customer_id]);

  let rows = useSelector(
    (state: any) => state.CompanyStructure.userGroupData.rows
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [groupSelectModalOpen, setGroupSelectModalOpen] = useState(false);
  const [departmentSelectModalOpen, setDepartmentSelectModalOpen] =
    useState(false);
  const [processSelectModalOpen, setProcessSelectModalOpen] = useState(false);

  const handleProcessSelectCloseModal = () => {
    setProcessSelectModalOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const handleDepartmentSelectCloseModal = () => {
    setDepartmentSelectModalOpen(false);
  };
  const handleGroupSelectCloseModal = () => {
    setGroupSelectModalOpen(false);
  };

  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

  const handleEmptyNodeClick = (e: React.MouseEvent, currentNode: any) => {
    const adjustedY = (e.clientY - window.scrollY) / window.devicePixelRatio;
    const adjustedX = (e.clientX - window.scrollX) / window.devicePixelRatio;
    

    const borderPadding = 10;

    // const parent_id = currentNode.parent_id
    const parent = findParentById(
      treeData,
      currentNode?.parent_id,
      currentNode?.parent_type
    );
    
    // setModalPosition({ x: finalX, y: finalY });
    const customer_id = currentNode.customer_id;
    const user_id = currentNode.user_id;
    const parent_id = currentNode.parent_id;
    const group_id1 = parent.group_id;
    // const level = currentNode.level;
    setCurrentParrentId(parent_id);
    setCustomerId(customer_id);
    setUserId(user_id);
    setCurrentGroupId(group_id1)
    switch (parent?.type) {
      case "Group":
        setGroupSelectModalOpen(true);
        break;
      case "Department":
        setDepartmentSelectModalOpen(true);
        break;
      case "Processes":
        setProcessSelectModalOpen(true);
        break;
    }
  };
  

  const findParentById = (nodeDatum: any, id: number, parent_type: string) => {
    for (const e of nodeDatum) {
      if (e.id === id && e.type === parent_type) return e;
      if (e.children) {
        const result: any = findParentById(e.children, id, parent_type);
        
        if (result) return result;
      }
    }
  };
  

  // .......... bussiness post data .....................

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get("name") as string;
    const spoc_id = Number(currentId);
    if (!name || !spoc_id) {
      return;
    }
    closeModal();
    try {
      toast.loading("Processing in...");
      const resData = await dispatch<any>(
        sendCreateBussinessUnitData(name, spoc_id, customerId, currentPartentId)
      );
      toast.dismiss();
      toast.success(<b>Business Unit created successfully!</b>);
      setModalOpen(false);

      // Update rows state
      const updatedRows = await dispatch<any>(
        companyStructureGroupList(customer_id)
      );
      if (updatedRows) {
        const treeDataWithLevel = updatedRows.map((node: any) =>
          addLevel(node, 1)
        );
        setTreeData(treeDataWithLevel);
      } else {
        console.error("Received undefined data when updating rows");
      }
    } catch (error) {
      console.error("Sending Create Business Unit data failed:", error);
      if (error instanceof Error) {
        toast.dismiss();
        toast.error(error.message); // You can keep this line to display the actual error
      } else {
      }
      toast.dismiss();
      toast.error("Already exists.");
    }
  }

  // .......... department api call .....................

  async function handleDepartmentSubmit(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const name = data.get("name") as string;
    const spoc_id = Number(currentId);
    const group_id = Number(currentGroupId);
    const parent_id = Number(currentPartentId);
    
    
    if (!name || !spoc_id || !currentPartentId) {
      return;
    }

    handleDepartmentCloseModal();
    try {
      toast.loading("Department in...");
      let grp_id;
      let prt_id;
      if(String(group_id)=="NaN"){
        prt_id=null;
        grp_id=parent_id;
      }else{
        prt_id=parent_id;
        grp_id=group_id;
      }

      // Send create department data
      const resData = await dispatch<any>(
        sendCreateDepartmentData( name, spoc_id,customerId,grp_id,prt_id )
      );
      toast.dismiss();
      toast.success(<b>Department created successfully!</b>);

      // Update rows state
      await dispatch<any>(companyStructureGroupList(customer_id));
    } catch (error) {
      if (error instanceof Error) {
        toast.dismiss();
        toast.error(error.message); // You can keep this line to display the actual error
      } else {
      }
      toast.dismiss();
      toast.error("Already exists.");
    }
  }

  // .......... process post data .....................

  async function handleProcessSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get("name") as string;
    const spoc_id = Number(currentId);
    if (!name || !spoc_id || !currentPartentId) {
      return;
    }

    // .......... process post data .....................

    handleProcessCloseModal();
    try {
      toast.loading("Processing in...");
      const resData = await dispatch<any>(
        sendCreateProcessData(name, spoc_id, customerId, currentPartentId, userId)
      );
      toast.dismiss();
      toast.success(<b>Process created successfully!</b>)
      await dispatch<any>(companyStructureGroupList(customer_id));
    } catch (error) {
      if (error instanceof Error) {
        toast.dismiss();
        toast.error(error.message);
      } else {
        console.log("An unknown error occurred:", error);
      }
      toast.dismiss();
      toast.error("Already exists.");
    }
  }

  const renderNodeWithCustomEvents = ({
    nodeDatum,
    toggleNode,
    isNodeSelected,
  }: {
    nodeDatum: NodeDatum;
    toggleNode: ToggleNodeFunction;
    isNodeSelected: boolean;
  }) => {
    const isEmptyOrNull =
      (!nodeDatum.group_name || nodeDatum.group_name.trim() === "") &&
      nodeDatum.type !== "empty";
    const strokeColor =
      nodeDatum.type === "Group"
        ? "red"
        : nodeDatum.type === "Department"
          ? "purple"
          : nodeDatum.type === "Processes"
            ? "green"
            : "black";
    const fillColor = isNodeSelected ? "#C6CBFF" : "white";
    const textColor = fillColor === "#C6CBFF" ? "black" : "black";
    if (isEmptyOrNull) {
      return (
        <g
          className="rectClass"
          onClick={(event) => handleEmptyNodeClick(event, nodeDatum)}
        >
          <rect
            rx="5px"
            ry="5px"
            width="50"
            height="50"
            x="-25"
            y="-20"
            stroke={strokeColor}
            strokeWidth="2px"
          />
          <text fill="black" x="-5" y="+10" className="nodeText">
            {nodeDatum.type !== "empty" ? nodeDatum.group_name || "+" : ""}
          </text>
        </g>
      );
    }
    const isTypeEmpty =
      nodeDatum.type === "empty" ||
      !Object.keys(nodeDatum).includes("group_name");
    if (isTypeEmpty) {
      return (
        <g className="rectClass">
          <rect
            rx="1px"
            ry="1px"
            // width="1"
            // height="1"
            x="-25"
            y="-20"
            stroke={strokeColor}
            strokeWidth="5px"
          />
        </g>
      );
    }
    if (nodeDatum?.__rd3t?.collapsed) {
      const getNodeWidth = (text: any) => {
        const padding = 10;
        const textLength = text.length * 6;
        return textLength + padding * 3;
      };
      const nodeWidth = getNodeWidth(
        `${nodeDatum.group_name} ${nodeDatum.user_data?.firstName} ${nodeDatum.user_data?.lastName}`
      );
      return (
        <g className="rectClass" onClick={toggleNode}>
          <rect
            rx="5px"
            ry="5px"
            width={nodeWidth}
            height="50"
            x={-nodeWidth / 2}
            y="-20"
            fill={fillColor}
            stroke={strokeColor}
            strokeWidth="5px"
          />
          <text
            fill={textColor}
            x="0"
            y="0"
            className="nodeText"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            <tspan x="0" dy="3" lengthAdjust="spacingAndGlyphs">
              {nodeDatum.group_name}
            </tspan>
            <tspan x="0" dy="15" style={{ fontSize: "10px" }}>
              {`${nodeDatum.user_data?.firstName} ${nodeDatum.user_data?.lastName}`}
            </tspan>
          </text>
          <g>
            <foreignObject
              x="-20"
              y="-35"
              width="50"
              height="50"
              style={{ textAlign: "center" }}
            >
              <img
                src={nodeDatum.user_data?.profile_image || bussiness_man_icon}
                height="40"
                alt="avatar"
                width="40"
                style={{ borderRadius: "50%" }}
              />
            </foreignObject>
          </g>
        </g>
      );
    } else if (!nodeDatum?.__rd3t?.collapsed) {
      const getNodeWidth = (text: any) => {
        const padding = 10;
        const textLength = text.length * 6;
        return textLength + padding * 3;
      };
      const nodeWidth = getNodeWidth(
        `${nodeDatum.group_name} ${nodeDatum.user_data?.firstName} ${nodeDatum.user_data?.lastName}`
      );
      const Name = `${nodeDatum.user_data?.firstName} ${nodeDatum.user_data?.lastName}`
      return (
        <g className="rectClass" onClick={toggleNode}>
          <rect
            rx="5px"
            ry="5px"
            width={nodeWidth}
            height="50"
            x={-nodeWidth / 2}
            y="-20"
            fill={fillColor}
            stroke={strokeColor}
            strokeWidth="2px"
          />
          <text
            fill={textColor}
            x="0"
            y="0"
            className="nodeText"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            <tspan x="0" dy="1" lengthAdjust="spacingAndGlyphs" style={{fontSize: "14px",fontWeight: "300"}}>
              {nodeDatum.group_name.length > 15 ? `${nodeDatum.group_name.substring(0, 15)}...` : nodeDatum.group_name}
            </tspan>
            <tspan x="0" dy="18" style={{ fontSize: "12px", fontWeight: "200" }}>
              {nodeDatum.user_data?.firstName ? (Name.length > 15 ? `${Name.substring(0, 15)}...` : Name) : ""}
            </tspan>
          </text>
          <g>
            <foreignObject
              x={nodeDatum.user_data?.profile_image ? "-13" : "-19"}
              y={nodeDatum.user_data?.profile_image ? "-45" : "-45"}
              width={nodeDatum.user_data?.profile_image ? "35" : "35"}
              height={nodeDatum.user_data?.profile_image ? "45" : "35"}
            >
              <img
                src={nodeDatum.user_data?.profile_image || bussiness_man_icon}
                height={nodeDatum.user_data?.profile_image ? "35" : "35"}
                alt="avatar"
                width={nodeDatum.user_data?.profile_image ? "26" : "35"}
                style={{ borderRadius: nodeDatum.user_data?.profile_image ? "50%" : "100%", border: nodeDatum.user_data?.profile_image ? "1px solid" : "100%" }}
              />
            </foreignObject>
          </g>
        </g>
      );
    } else {
      return (
        <g className="rectClass" onClick={toggleNode}>
          <rect
            rx="5px"
            ry="5px"
            width="150"
            height="30"
            x="-60"
            stroke={strokeColor}
            strokeWidth="5px"
          />
          <text fill="black" x="-55" y="+20" className="nodeText">
            {nodeDatum.group_name}
          </text>
        </g>
      );
    }
  };

  const [treeData, setTreeData] = useState<any>(rows);
  const navigate = useNavigate();

  const addLevel = useCallback((node: any, level: number) => {
    return {
      ...node,
      level: level,
      children: node.children
        ? node.children.map((child: any) => addLevel(child, level + 1))
        : [],
    };
  }, []);

  useEffect(() => {
    const treeDataWithLevel = rows.map((node: any) => addLevel(node, 1));
    setTreeData(treeDataWithLevel);
  }, [rows, addLevel]);
  const [currentId, setCurrentId] = React.useState<any>();
  const handleIdChange = (value: any) => {
    setCurrentId(value);
  };
  const [clickedNodeDatum, setClickedNodeDatum] = useState<NodeDatum | null>(
    null
  );
  const handleNodeClick = (nodeDatum: NodeDatum) => {
    setClickedNodeDatum((prevNodeDatum) =>
      prevNodeDatum === nodeDatum ? null : nodeDatum
    );
  };
  const navigateToGroupDetails = () => {
    if (clickedNodeDatum) {
      navigate(`${SELECTED_VIEW_GROUP_DETAILS}`, { state: clickedNodeDatum });
    }
  };  

  // const handleDetails = (id: number) => {
  //   dispatch<any>(GetSupportTictetDetails(id, navigate));
  //   navigate(`${VIEW_TICKET_DETAILS}`);
  // };
  // onClick={() => handleDetails(ticket.id)}

  const handleTypeSelection = (type: string) => {
    handleGroupSelectCloseModal();
    handleDepartmentSelectCloseModal();
    handleProcessSelectCloseModal();

    switch (type) {
      case "business":
        setModalOpen(true);
        break;
      case "department":
        setDepartmentModalOpen(true);
        break;
      case "process":
        setProcessModalOpen(true);
        break;
    }
  };

  return (
    <div className="px-[18.5px] h-full">
      <div className="flex flex-row mt-6 items-center h-12 justify-end">
        {/* <div className="flex flex-row gap-[12px] justify-center items-center">
          <img
            src={rightArrow}
            alt="right arrow sign"
            className="h-6 w-6 shrink-0 bg-black rounded-full"
          />
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            {crmType}
          </span>
        </div> */}
        {clickedNodeDatum && (
          <div className="flex pl-[36%] gap-4 rounded-lg h-12">
            <button className="w-[148px] h-12 justify-start items-start gap-5 inline-flex">
              <img
                src={viewDetails}
                alt="view-details"
                onClick={navigateToGroupDetails}
              />
            </button>
          </div>
        )}
        <button
          className="flex w-[166px] py-[11px] px-[15px] justify-center items-center gap-2.5 rounded-lg h-12"
          onClick={() => handleCurrentPageChange("list")}
        >
          <svg
            width="140"
            height="49"
            viewBox="0 0 140 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.725098"
              width="139"
              height="47"
              rx="11.5"
              fill="#D9D9D9"
              stroke="#C5CDD4"
            />
            <rect
              x="70.5"
              y="0.5"
              width="69"
              height="47"
              rx="11.5"
              fill="white"
              stroke="#C5CDD4"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#000"
              d="M19.6016 17.6027C19.6016 16.5932 20.4199 15.7749 21.4294 15.7749C22.4388 15.7749 23.2572 16.5932 23.2572 17.6027C23.2572 18.6122 22.4388 19.4305 21.4294 19.4305C20.4199 19.4305 19.6016 18.6122 19.6016 17.6027ZM25.085 17.6027C25.085 17.098 25.4941 16.6888 25.9989 16.6888H42.4491C42.9538 16.6888 43.363 17.098 43.363 17.6027C43.363 18.1074 42.9538 18.5166 42.4491 18.5166H25.9989C25.4941 18.5166 25.085 18.1074 25.085 17.6027ZM19.6016 24C19.6016 22.9905 20.4199 22.1722 21.4294 22.1722C22.4388 22.1722 23.2572 22.9905 23.2572 24C23.2572 25.0095 22.4388 25.8278 21.4294 25.8278C20.4199 25.8278 19.6016 25.0095 19.6016 24ZM25.085 24C25.085 23.4953 25.4941 23.0861 25.9989 23.0861H42.4491C42.9538 23.0861 43.363 23.4953 43.363 24C43.363 24.5047 42.9538 24.9139 42.4491 24.9139H25.9989C25.4941 24.9139 25.085 24.5047 25.085 24ZM19.6016 30.3973C19.6016 29.3878 20.4199 28.5695 21.4294 28.5695C22.4388 28.5695 23.2572 29.3878 23.2572 30.3973C23.2572 31.4068 22.4388 32.2251 21.4294 32.2251C20.4199 32.2251 19.6016 31.4068 19.6016 30.3973ZM25.085 30.3973C25.085 29.8926 25.4941 29.4834 25.9989 29.4834H42.4491C42.9538 29.4834 43.363 29.8926 43.363 30.3973C43.363 30.902 42.9538 31.3112 42.4491 31.3112H25.9989C25.4941 31.3112 25.085 30.902 25.085 30.3973Z"
            />
            <path
              fill="#5D42D1"
              d="M107.333 21.3333V20H106.667C106.313 20 105.974 20.1405 105.724 20.3905C105.474 20.6406 105.333 20.9797 105.333 21.3333V26.6667C105.333 27.0203 105.474 27.3594 105.724 27.6095C105.974 27.8595 106.313 28 106.667 28H107.333V26.6667C107.333 26.313 107.474 25.9739 107.724 25.7239C107.974 25.4738 108.313 25.3333 108.667 25.3333H112.667C113.02 25.3333 113.359 25.4738 113.609 25.7239C113.86 25.9739 114 26.313 114 26.6667V30.6667C114 31.0203 113.86 31.3594 113.609 31.6095C113.359 31.8595 113.02 32 112.667 32H108.667C108.313 32 107.974 31.8595 107.724 31.6095C107.474 31.3594 107.333 31.0203 107.333 30.6667V29.3333H106.667C105.959 29.3333 105.281 29.0524 104.781 28.5523C104.281 28.0522 104 27.3739 104 26.6667V24.6667H101.333V25.3333C101.333 25.687 101.193 26.0261 100.943 26.2761C100.693 26.5262 100.354 26.6667 100 26.6667H97.3333C96.9797 26.6667 96.6406 26.5262 96.3905 26.2761C96.1405 26.0261 96 25.687 96 25.3333V22.6667C96 22.313 96.1405 21.9739 96.3905 21.7239C96.6406 21.4738 96.9797 21.3333 97.3333 21.3333H100C100.354 21.3333 100.693 21.4738 100.943 21.7239C101.193 21.9739 101.333 22.313 101.333 22.6667V23.3333H104V21.3333C104 20.6261 104.281 19.9478 104.781 19.4477C105.281 18.9476 105.959 18.6667 106.667 18.6667H107.333V17.3333C107.333 16.9797 107.474 16.6406 107.724 16.3905C107.974 16.1405 108.313 16 108.667 16H112.667C113.02 16 113.359 16.1405 113.609 16.3905C113.86 16.6406 114 16.9797 114 17.3333V21.3333C114 21.687 113.86 22.0261 113.609 22.2761C113.359 22.5262 113.02 22.6667 112.667 22.6667H108.667C108.313 22.6667 107.974 22.5262 107.724 22.2761C107.474 22.0261 107.333 21.687 107.333 21.3333Z"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-row justify-end gap-[20px] mt-2 mr-5">
        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="red" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] text-xs font-medium leading-[13.981px]">
            Bussiness Unit
          </span>
        </div>

        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="purple" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-xs font-medium leading-[13.981px]">
            Department Unit
          </span>
        </div>

        {/* <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="green" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-xs font-medium leading-[13.981px]">
            Process Unit
          </span>
        </div> */}
      </div>
      <div>
        {groupSelectModalOpen && (
          <Modal
            x={modalPosition.x}
            y={modalPosition.y}
            open={groupSelectModalOpen}
            onClose={handleGroupSelectCloseModal}
            cssClass="selection-modal-wrapper"
          >
            <div className="select-modal-content">
              <button
                type="button"
                onClick={() => handleTypeSelection("business")}
              >
                Add Business Unit
              </button>
              <button
                type="button"
                onClick={() => handleTypeSelection("department")}
              >
                Add Department
              </button>
            </div>
          </Modal>
        )}
        {departmentSelectModalOpen && (
          <Modal
            open={departmentSelectModalOpen}
            onClose={handleDepartmentSelectCloseModal}
            cssClass="selection-modal-wrapper"
          >
            <div className="select-modal-content">
              <button
                type="button"
                onClick={() => handleTypeSelection("department")}
              >
                Add Department
              </button>
              {/* <button
                type="button"
                onClick={() => handleTypeSelection("process")}
              >
                Add Process
              </button> */}
            </div>
          </Modal>
        )}

        {modalOpen && (
          <Modal open={modalOpen} onClose={closeModal} cssClass="modal-wrapper">
            <AddUnit
              onClose={closeModal}
              handleIdChange={handleIdChange}
              onConfirm={handleSubmit}
              modalType="Bussiness"
            />
          </Modal>
        )}

        {departmentModalOpen && (
          <Modal
            open={departmentModalOpen}
            onClose={handleDepartmentCloseModal}
            cssClass="modal-wrapper"
          >
            <AddUnit
              onClose={handleDepartmentCloseModal}
              handleIdChange={handleIdChange}
              onConfirm={handleDepartmentSubmit}
              modalType="Department"
            />
          </Modal>
        )}

        {processModalOpen && (
          <Modal
            open={processModalOpen}
            onClose={handleProcessCloseModal}
            cssClass="modal-wrapper"
          >
            <AddUnit
              onClose={handleProcessCloseModal}
              handleIdChange={handleIdChange}
              onConfirm={handleProcessSubmit}
              modalType="Process"
            />
          </Modal>
        )}
        <div style={containerStyles}>
          {rows.length !== 0 && treeData.length !== 0 && (
            <Tree
              data={treeData}
              translate={{ x: 550, y: 60 }}
              pathFunc="step"
              depthFactor={150}
              renderCustomNodeElement={(rd3tProps: any) =>
                renderNodeWithCustomEvents({
                  ...rd3tProps,
                  toggleNode: () => handleNodeClick(rd3tProps.nodeDatum),
                  isNodeSelected: rd3tProps.nodeDatum === clickedNodeDatum,
                })
              }
              orientation="vertical"
              separation={{ siblings: 0.8, nonSiblings: 0.8 }}
              nodeSize={{ x: 300, y: 150 }}
              pathClassFunc={() => {
                return "tree-link-path";
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CompanyTree;