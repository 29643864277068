import React, { useCallback, useEffect, useState } from "react";

import { Button } from "../../common/Button";
import { ArrowUpDown, Filter, List, MapPinned, X } from "lucide-react";
import GoogleMapComponent from "../../common/Map/GoogleMap";
import {
  Fetch_User_Location,
  Fetch_User_Specific_Location,
} from "../../common/services/Attendence";
import toast from "react-hot-toast";
import profile from "../../../assets/avatar.svg";
import MultipleLocationsMap from "../../common/Map/MultipleLocationsMap";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import DynamicTable from "../../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import { LocationAndAddress } from "../MultiCheckInDetails";
import TablePaginationDemo from "../../common/Pagenation";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import LocationFilter, { FilterData } from "./EmpFilter";
import { useDispatch } from "react-redux";
import { setFilterLocationData } from "../../../redux/reducers/Attendance/LOcationFilter";
import dayjs from "dayjs";
import PersistedSearch from "./CustomSerach";
import DatePicker from "react-datepicker";

interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  employee_id: string;
  profile_image: string | null;
  department_id: number;
  group_id: number;
}

interface ILocationData {
  id: number;
  user_id: number;
  latitude: string;
  longitude: string;
  date: string;
  created_at: string;
  location: string;
  User: IUser;
}

interface ILocationFormat {
  lat: number;
  lng: number;
  profileImage: string | null;
  id: number;
  user_id: number;
  created_at: string;
  location: string;
  User: IUser;
  Group?: {
    id: number;
    businessUnit_address: string;
    businessUnit_coordinates: [number, number];
  };
}
interface IEmpLocationInfo {
  id: number;
  user_id: number;
  latitude: string;
  longitude: string;
  timestamp: string;
  location: string;
}

interface IEmpLocation {
  id: number;
  firstName: string;
  lastName: string;
  employee_id: string;
  profile_image: string;
  department_id: number;
  group_id: number;
  Group?: {
    id: number;
    businessUnit_address: string;
    businessUnit_coordinates: [number, number];
  };
  Locations: IEmpLocationInfo[];
}

export default function EmployeeLocation() {
  const [data, setData] = useState<IEmpLocation[]>([]);
  const [view, setView] = useState("map");
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const filter_data = useSelector((state: RootState) => state.locationSlice);
  const [depId, setDepId] = useState<{ id: string; name: string }[]>(
    filter_data?.department ?? []
  );
  const [busId, setBusId] = useState<{ id: string; name: string }[]>(
    filter_data?.group ?? []
  );
  const [dates, setDates] = useState<Date | null>(
    filter_data?.date ?? new Date()
  );
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<IEmpLocation | null>(
    null
  );
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const [locations, setLocations] = useState<ILocationFormat[]>([]);
  const fetchAttStats = useCallback(async () => {
    try {
      const group = busId?.map((item) => item.id);
      const dept = depId?.map((item) => item.id);

      const response = await Fetch_User_Location({
        date: dates,
        groupIds: group,
        department: dept,
        numOfItems: numOfItems,
        page: page,
        searchTerm: filter_data?.search ?? "",
        customer_id: customer_id,
      });
      if (response.status === 200) {
        const fetchedData: IEmpLocation[] = response?.data?.result?.rows || [];

        setData(fetchedData);
        setCount(response?.data?.result?.count || 0);
        const mappedLocations: ILocationFormat[] = fetchedData?.flatMap(
          (user) =>
            user?.Locations?.filter(
              (loc) => loc.latitude && loc.longitude
            )?.map((loc) => ({
              id: loc?.id,
              user_id: user?.id,
              lat: parseFloat(loc?.latitude),
              lng: parseFloat(loc?.longitude),
              profileImage: user?.profile_image || null,

              created_at: loc?.timestamp,
              location: loc?.location || "",
              User: {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                employee_id: user?.employee_id,
                profile_image: user?.profile_image,
                department_id: user?.department_id,
                group_id: user?.group_id,
              },
              Group: user?.Group,
            }))
        );

        setLocations(mappedLocations);
      } else {
        toast.error("Error fetching Location");
      }
    } catch {}
  }, [busId, customer_id, dates, depId, filter_data?.search, numOfItems, page]);
  const fetchUserLocations = useCallback(async () => {
    try {
      const response = await Fetch_User_Specific_Location({
        userId: selectedEmployee?.id || null,
        date: dates,
      });
      if (response.status === 200) {
        const fetchedData: IEmpLocation[] = response?.data?.result || [];

        const mappedLocations: ILocationFormat[] = fetchedData?.flatMap(
          (user) =>
            user?.Locations?.filter(
              (loc) => loc.latitude && loc.longitude
            )?.map((loc) => ({
              id: loc?.id,
              user_id: user?.id,
              lat: parseFloat(loc?.latitude),
              lng: parseFloat(loc?.longitude),
              profileImage: user?.profile_image || null,

              created_at: loc?.timestamp,
              location: loc?.location || "",
              User: {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                employee_id: user?.employee_id,
                profile_image: user?.profile_image,
                department_id: user?.department_id,
                group_id: user?.group_id,
              },
              Group: user?.Group,
            }))
        );

        setLocations(mappedLocations);
      } else {
        toast.error("Error fetching Location");
      }
    } catch {}
  }, [dates, selectedEmployee?.id]);

  useEffect(() => {
    if (selectedEmployee?.id) {
      fetchUserLocations();
    } else {
      fetchAttStats();
    }
  }, [fetchUserLocations, fetchAttStats, selectedEmployee]);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterLocationData({
        department: filterData?.department,
        group: filterData?.business,
      })
    );
    setBusId(filterData?.business ?? []);
    setDepId(filterData?.department ?? []);

    setIsOpen(false);
    setSelectedEmployee(null);
  };
  const handleDateChange = (val: Date | null) => {
    dispatch(
      setFilterLocationData({
        ...filter_data,
        date: val ?? new Date(),
      })
    );

    setDates(val ?? new Date());
    setSelectedEmployee(null);
  };

  const handleDelete = () => {
    setBusId([]);
    setDepId([]);

    dispatch(
      setFilterLocationData({
        department: [],
        group: [],
      })
    );
    setSelectedEmployee(null);
  };

  const handleView = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    if (value !== null) {
      setView(value);
    }
  };
  const columns: ColumnDef<ILocationFormat>[] = [
    {
      accessorKey: "date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.created_at
          ? new Date(row.created_at).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.created_at
          ? new Date(row.original.created_at).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return (
          <div className="font-inter text-[14px] font-medium leading-[20px] py-2">
            {name}
          </div>
        );
      },
    },
    {
      accessorKey: "time",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.created_at ? dayjs(row?.created_at).format("h:mm A") : "-",
      cell: ({ row }) => {
        return (
          <div>
            <LocationAndAddress
              dateTime={dayjs(row?.original?.created_at).format("h:mm A")}
              address={row?.original?.location}
              width={"w-fit"}
            />
          </div>
        );
      },
    },
  ];
  const handleRemoveFilter = (
    type: "department" | "group" | "date",
    id?: string
  ) => {
    if (type === "department") {
      const updatedDep = depId.filter((item) => item.id !== id);
      setDepId(updatedDep);
      dispatch(
        setFilterLocationData({
          department: updatedDep,
          group: busId,
          date: dates,
        })
      );
      setSelectedEmployee(null);
    } else if (type === "group") {
      const updatedBus = busId.filter((item) => item.id !== id);
      setBusId(updatedBus);
      dispatch(
        setFilterLocationData({
          department: depId,
          group: updatedBus,
          date: dates,
        })
      );
      setSelectedEmployee(null);
    } else if (type === "date") {
      setDates(null);
      dispatch(
        setFilterLocationData({ department: depId, group: busId, date: null })
      );
      setSelectedEmployee(null);
    }
  };
  const handleSearch = () => {
    setSelectedEmployee(null);
    setPage(1);
  };
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };
  return (
    <div className="mt-4 w-full h-[80vh] border rounded-lg flex ">
      <div className="w-[50%] h-full xl:w-[26%] bg-custom-bg-gray2 border-r p-4 flex flex-col gap-3 overflow-auto">
        <div className="flex justify-between flex-wrap gap-2 2xl:gap-0">
          <div className=" w-full 2xl:w-[47%]">
            <PersistedSearch
              label="Search "
              width="100%"
              onSearch={handleSearch}
            />
          </div>

          <DatePicker
            selected={dates}
            onChange={handleDateChange}
            onSelect={() => setIsCalendarOpen(false)}
            className="w-28 py-2 px-1 text-[12px] font-medium h-[38px] bg-white border border-[#CDCDCD] rounded-md text-center placeholder:text-gray-500 placeholder:font-poppins placeholder:text-[12px] placeholder:font-normal placeholder:leading-[24px] focus:outline-none focus:ring-0 caret-transparent cursor-pointer"
            dateFormat="MMM d, yyyy"
            open={isCalendarOpen}
            onInputClick={handleCalanderClick}
            onClickOutside={() => {
              handleCalanderClick();
            }}
            popperContainer={({ children }) => (
              <div style={{ zIndex: 9 }}>{children}</div>
            )}
            readOnly
          />

          <Button onClick={() => setIsOpen(true)} variant={"border"}>
            <Filter />
          </Button>
        </div>
        {(busId.length > 0 || depId.length > 0) && (
          <div className="">
            <div className="flex justify-between items-center">
              <span className="text-[14px] font-medium ">Applied Filters</span>
              <Button
                variant="ghost"
                className="text-blue-600 text-[14px] px-2 py-1 font-medium hover:text-blue-400"
                onClick={handleDelete}
              >
                Clear All
              </Button>
            </div>
            <div className="flex flex-wrap gap-2 mt-1">
              {busId.map((item) => (
                <div
                  key={item.id}
                  className="flex items-center px-3 py-1 bg-gray-200 text-gray-700 rounded-full text-sm"
                >
                  {item.name}
                  <X
                    className="ml-2 size-4 cursor-pointer"
                    onClick={() => handleRemoveFilter("group", item.id)}
                  />
                </div>
              ))}
              {depId.map((item) => (
                <div
                  key={item.id}
                  className="flex items-center px-3 py-1 bg-gray-200 text-gray-700 rounded-full text-sm"
                >
                  {item.name}
                  <X
                    className="ml-2 size-4 cursor-pointer"
                    onClick={() => handleRemoveFilter("department", item.id)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        <div>
          <div className="flex flex-col gap-2 flex-1 overflow-auto">
            {data?.map((user) => (
              <div
                key={user.id}
                onClick={() => {
                  setSelectedEmployee(user);
                  setData([user]);
                  setCount(0);
                  setView("map");
                }}
                className={`flex justify-between items-center p-2 rounded-md cursor-pointer ${
                  selectedEmployee?.id === user?.id
                    ? "bg-[#dde5f8]"
                    : "bg-inherit"
                }`}
              >
                <div className="flex items-center space-x-2 ">
                  <img
                    className="w-9 h-9 rounded-full"
                    src={user?.profile_image || profile}
                    alt="Profile"
                  />
                  <div className="flex flex-col justify-between">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#020617]">
                      {user?.firstName} {user?.lastName}
                    </span>
                    <span className="font-[Poppins] font-medium text-[14px] leading-[15px] text-[#64748B]">
                      {user?.employee_id || ""}
                    </span>
                  </div>
                </div>
                {selectedEmployee?.id === user?.id ? (
                  <X
                    className="text-secondary-text cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedEmployee(null);
                    }}
                  />
                ) : null}
              </div>
            ))}
          </div>
          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={page}
              numOfItems={numOfItems}
              noPadding={true}
            />
          )}
        </div>
      </div>
      <div className="w-[50%] h-full xl:w-[74%] p-4">
        {selectedEmployee ? (
          <div className="relative">
            <ToggleButtonGroup
              exclusive
              value={view}
              onChange={handleView}
              sx={{
                position: "absolute",
                top: "5px",
                right: "10px",
                zIndex: 100,
                backgroundColor: "white",
              }}
            >
              <ToggleButton value="map" aria-label="bold">
                <MapPinned />
              </ToggleButton>
              <ToggleButton value="list" aria-label="italic">
                <List />
              </ToggleButton>
            </ToggleButtonGroup>
            {view === "map" ? (
              <MultipleLocationsMap
                locations={locations}
                containerStyle={{
                  width: "100%",
                  height: "calc(80vh - 32px)",
                }}
              />
            ) : (
              <div className="flex flex-col gap-5 ">
                <div className="text-[22px] text-dark-grey font-medium font-[Poppins] mt-4">
                  Locations
                </div>
                <div className="overflow-auto shadow-sm ">
                  <div className="table_main_content w-full h-[calc(100vh-22rem)] relative overflow-auto bg-white">
                    <DynamicTable<ILocationFormat>
                      data={locations}
                      loading={false}
                      columns={columns}
                      enableSorting
                      enablePagination
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <GoogleMapComponent
            locations={locations}
            containerStyle={{
              width: "100%",
              height: "calc(80vh - 32px)",
            }}
          />
        )}
      </div>
      {isOpen && (
        <LocationFilter
          open={isOpen}
          handleClose={() => setIsOpen(false)}
          heading="Location Filter"
          onApply={handleApply}
          isDate
          isOther
        />
      )}
    </div>
  );
}
