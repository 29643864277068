import React, { useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchEditEmployee } from "../common/services/Employee";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

interface EmployeeData {
  empCode: string;
  empId: string;
  empName: string;
  empLastName: string;
  email: string;
  code: string;
  number: string;
  business_unit: string;
  role: string;
  department: string;
  designation: string;
  team_manager_firstName: string;
  team_manager_lastName: string;
  reporting_manager_firstName: string;
  reporting_manager_lastName: string;
  status: string;
  MoE: string;
  YOE: string;
  DOB: string;
  DOJ: string;
  DOL: string | null;
}

const ViewEmployeeList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPage = useSelector((state: any) => state.emplist.currentPage);
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    empCode: "",
    empId: "",
    empName: "",
    empLastName: "",
    email: "",
    code: "",
    number: "",
    business_unit: "",
    role: "",
    department: "",
    designation: "",
    team_manager_firstName: "",
    team_manager_lastName: "",
    reporting_manager_firstName: "",
    reporting_manager_lastName: "",
    status: "",
    MoE: "",
    YOE: "",
    DOB: "",
    DOJ: "",
    DOL: null,
  });
  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    fetchEditEmployee(id)
      .then((data) => {
        if (data.result) {
          setEmployeeData({
            empCode: data.result?.employee_code || "",
            empId: data.result?.employee_id,
            empName: data.result?.firstName,
            empLastName: data.result?.lastName,
            email: data.result?.email || "",
            code: data.result?.country_code,
            number: data.result?.phone || "",
            business_unit: data.result?.Group?.name,
            role: data.result?.Role?.role_name,
            department: data.result?.Department?.name,
            designation: data.result?.Designation?.name,
            team_manager_firstName: data.result?.team_manager?.firstName,
            team_manager_lastName: data.result?.team_manager?.lastName,
            reporting_manager_firstName:
              data?.result?.reporting_manager?.firstName,
            reporting_manager_lastName:
              data?.result?.reporting_manager?.lastName,
            status: data.result?.status,
            MoE: data.result?.mode_of_employment,
            YOE: data.result?.experience,
            DOB: data.result?.date_of_birth,
            DOJ: data.result?.date_of_joining,
            DOL: data.result?.date_of_leaving,
          });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);


  return (
    <>
      <div className="bg-[#FBFBFB] font-[Poppins] px-4 pt-6 pb-12">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/employee-management", {state: {page: currentPage}})}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Employee Details
            </span>
          </div>
        </div>
        <div className="p-6 bg-white rounded-lg mt-6 shadow-md">
          <h2 className="text-lg font-[Poppins] font-semibold">Details</h2>
          <div className="py-4">
            <div className="px-2.5 py-5 rounded-md viewBackground">
              <div className="flex flex-col gap-[15px]">
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employee Code
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.empCode || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employee ID
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.empId || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employee Name
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.empName || "--"}{" "}
                      {employeeData?.empLastName}
                    </span>
                  </div>
                </div>
                <hr className="h-[2px] bg-[#EDEDED]" />
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Email
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.email || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Phone Number
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.code} {employeeData?.number || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Role
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.role || "--"}
                    </span>
                  </div>
                </div>
                <hr className="h-[2px] bg-[#EDEDED]" />
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Business Unit
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.business_unit || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Department
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.department || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Designation
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.designation || "--"}
                    </span>
                  </div>
                </div>
                <hr className="h-[2px] bg-[#EDEDED]" />
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Team Manager
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.team_manager_firstName || "--"}{" "}
                      {employeeData?.team_manager_lastName}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Reporting Manager
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.reporting_manager_firstName || "--"}{" "}
                      {employeeData?.reporting_manager_lastName}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employment Status
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {/* {employeeData?.status || "--"} */}
                      {employeeData?.status === "active" ? "Active" :
                        employeeData?.status === "inactive" ? "Inactive" :
                        employeeData?.status === "archived" ? "Archived" :
                        "--"}
                    </span>
                  </div>
                </div>
                <hr className="h-[2px] bg-[#EDEDED]" />
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Mode of Employment
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {/* {employeeData?.MoE || "--"} */}
                      {employeeData?.MoE === "full_time" ? "Full Time" :
                        employeeData?.MoE === "part_time" ? "Part Time" :
                        employeeData?.MoE === "contract" ? "Contract" :
                        employeeData?.MoE === "internship" ? "Intern" :
                        "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Years of Experience
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.YOE || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Date of Birth
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {/* {moment(employeeData?.DOB).format("DD MMM YYYY") || "--"} */}
                      {employeeData?.DOB == null
                        ? "--"
                        : moment(employeeData?.DOB).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>
                <hr className="h-[2px] bg-[#EDEDED]" />
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Date of Joining
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {moment(employeeData?.DOJ).format("DD MMM YYYY") || "--"}
                    </span>
                  </div>
                  {employeeData.DOL ? (
                    <div className="flex flex-col justify-between h-[45px]">
                      <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                        Date of Leaving
                      </span>
                      <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                        {employeeData?.status === "active"
                          ? "--"
                          : moment(employeeData?.DOL).format("DD MMM YYYY") ||
                            "--"}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEmployeeList;
