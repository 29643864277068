import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import right from "../../image/chevron-right.png";
import profile from "../../assets/Ellipse 580.png";
import TablePaginationDemo from "../common/Pagenation";
import Receipt from "../ExpenseManagement/ImageView";
import { View_Asset } from "../common/services/Asset";
import moment from "moment";
import ModalViewer from "../ExpenseManagement/ModalViewer";

interface AssetData {
  AssetCategory: {
    category: string;
  };
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  Project: {
    project_name: string;
  };
  asset_name: string;
  asset_serial_number: string;
  assigned_date: string;
  acknowledgment_status: boolean;
  description: string;
  image_filename: string[];
}

function ViewAssetDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [details, setDetails] = useState<AssetData>();
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };

  const closeModal = () => {
    setSelectedFileUrl(null);
  };

  useEffect(() => {
    View_Asset(id)
      .then((data) => {
        if (data.result) {
          setDetails(data.result);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-5">
        <div className="flex flex-row gap-[13px] items-center">
          <button onClick={() => navigate("/assigned-asset")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Assigned Asset Details
          </span>
        </div>
        <div className="px-1.5">
          <div className="bg-white rounded-lg shadow-custom p-5">
            <div className=" flex flex-col gap-5">
              <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                Assigned Asset Details
              </span>
              <div className="px-2.5 py-5 viewBackground rounded-md">
                <div className="grid grid-cols-3 gap-y-[15px]">
                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Assigned to
                    </span>
                    <div className="flex flex-row gap-[7px] items-center">
                      <div className="h-[31px] w-[31px]">
                        <img
                          src={details?.User?.profile_image || profile}
                          alt="profile"
                          className="h-full w-full rounded-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                          {details?.User?.firstName || "--"}{" "}
                          {details?.User?.lastName}
                        </span>
                        <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                          {details?.User?.employee_id}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Serial Number
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      {details?.asset_serial_number || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Assigned Project
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      {details?.Project?.project_name || "--"}
                    </span>
                  </div>

                  <hr className="col-span-3 border-2 border-[#EDEDED]" />

                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Asset Category
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      {details?.AssetCategory?.category || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Asset Name
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      {details?.asset_name || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Assigned Date
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      {moment(details?.assigned_date).format("DD MMM, YYYY") ||
                        "--"}
                    </span>
                  </div>

                  <hr className="col-span-3 border-2 border-[#EDEDED]" />
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Acknowledgement Status
                    </span>
                    <span
                      className={`flex items-center px-5 py-1 rounded-sm ${
                        details?.acknowledgment_status
                          ? "bg-[#D4F8C7]"
                          : "bg-[#FFC0767A]"
                      } max-w-min`}
                    >
                      <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                        {details?.acknowledgment_status
                          ? "Submitted"
                          : "Pending"}
                      </span>
                    </span>
                  </div>

                  <hr className="col-span-3 border-2 border-[#EDEDED]" />

                  <div className="col-span-3 flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Description
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                    <div dangerouslySetInnerHTML={{ __html: details?.description as string }} />
                    </span>
                  </div>
                  <hr className="col-span-3 border-2 border-[#EDEDED]" />

                  <div className="col-span-3 flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Attachements
                    </span>
                    <div className="grid grid-cols-3 gap-2">
                      {details?.image_filename?.map((url, index) => (
                        <Receipt
                          fileName={String(url)}
                          fileSize={`${(Math.random() * 2 + 1)?.toFixed(2)} MB`}
                          onView={() => handleView(url)}
                        />
                      ))}
                      {selectedFileUrl && (
                        <ModalViewer
                          isOpen={!!selectedFileUrl}
                          onRequestClose={closeModal}
                          fileUrl={selectedFileUrl}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                Asset Declaration Details
              </span>
              <div className="px-2.5 py-5 viewBackground rounded-md">
                <div className="grid grid-cols-1 gap-y-[15px]">
                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Assigned to
                    </span>
                    <div className="flex flex-row gap-[7px] items-center">
                      <div className="h-[31px] w-[31px]">
                        <img
                          src={profile}
                          alt="profile"
                          className="h-full w-full rounded-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                          Brooklyn Simons
                        </span>
                        <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                          EMP0062
                        </span>
                      </div>
                    </div>
                  </div>

                  <hr className="border-2 border-[#EDEDED]" />

                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      ID Proof
                    </span>
                    <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22] w-[300px]">
                      <Receipt
                        fileName="Aadhar Card"
                        fileSize="10KB"
                        onView={() => {}}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 5">
                <div className="py-[5px]">
                  <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                    Assigned Assets History
                  </span>
                </div>
                <div className="overflow-x-auto overflow-y-auto shadow-sm">
                  <table className="w-full min-w-max text-left table-auto bg-white">
                    <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                      <tr className="h-11 w-full">
                        <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-5 w-1/3">
                          Employee Name & ID
                        </th>
                        <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 w-1/3 text-center">
                          Assigned Date
                        </th>
                        <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pr-5 flex justify-end">
                          Submission Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border border-[#EBEBEB]">
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5">
                          <div className="flex items-center gap-2.5">
                            <div className="h-full w-[40px] rounded-full">
                              <img
                                src={profile}
                                alt="profile"
                                className="h-full w-full rounded-full"
                              />
                            </div>
                            <div className="h-full flex flex-col justify-center">
                              <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                                Brooklyn Simmons
                              </span>
                              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                                EMP0062
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-center">
                          19/02/2024
                        </td>
                        <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-right pr-5">
                          19/02/2024
                        </td>
                      </tr>
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5">
                          <div className="flex items-center gap-2.5">
                            <div className="h-full w-[40px] rounded-full">
                              <img
                                src={profile}
                                alt="profile"
                                className="h-full w-full rounded-full"
                              />
                            </div>
                            <div className="h-full flex flex-col justify-center">
                              <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                                Brooklyn Simmons
                              </span>
                              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                                EMP0062
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-center">
                          19/02/2024
                        </td>
                        <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-right pr-5">
                          19/02/2024
                        </td>
                      </tr>
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5">
                          <div className="flex items-center gap-2.5">
                            <div className="h-full w-[40px] rounded-full">
                              <img
                                src={profile}
                                alt="profile"
                                className="h-full w-full rounded-full"
                              />
                            </div>
                            <div className="h-full flex flex-col justify-center">
                              <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                                Brooklyn Simmons
                              </span>
                              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                                EMP0062
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-center">
                          19/02/2024
                        </td>
                        <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-right pr-5">
                          19/02/2024
                        </td>
                      </tr>
                      <tr className="h-12 hover:bg-gray-100 border-[#EBEBEB]">
                        <td className="pl-5">
                          <div className="flex items-center gap-2.5">
                            <div className="h-full w-[40px] rounded-full">
                              <img
                                src={profile}
                                alt="profile"
                                className="h-full w-full rounded-full"
                              />
                            </div>
                            <div className="h-full flex flex-col justify-center">
                              <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                                Brooklyn Simmons
                              </span>
                              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                                EMP0062
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-center">
                          19/02/2024
                        </td>
                        <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-right pr-5">
                          19/02/2024
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* {tableData.length === 0 ? (
                    <div className="w-full h-10 px-2.5 py-1.5 flex justify-center items-center bg-white shadow-sm font-[Poppins] text-base text-[#1D1A22] border border-[#EBEBEB]">
                      Data not Found!
                    </div>
                  ) : (
                    <></>
                  )} */}
                </div>
                {count >= 10 && (
                  <TablePaginationDemo
                    count={count}
                    handleItemsChange={handleNumberOfPages}
                    handlePageChange={handlePageChange}
                    currentPage={1}
                    numOfItems={10}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAssetDetails;
