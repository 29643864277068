import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { revertAll } from '../../ResetRedux/RevertAll';

interface AssessmentAuditLog {
    auditLogData: [];
    pagination: {
      count: number;
      page: number;
      itemsPerPage: number;
    };
  }
 
interface RiskAssessmentState {
    currentAssessment: any;
    selectedNavItem: string;
    selected_entity_id: number;
    selectedLabelData: {
      isCollaborator: boolean,
      path: string,
      id: number | null,
      departmentName: string
    };
    assessmentAuditLog: AssessmentAuditLog;
    currentAssessmentAuditLog: AssessmentAuditLog;
}

const initialState: RiskAssessmentState = {
    currentAssessment: {
        key: "",
        value: ""
    },
    selectedNavItem: "CONTROLS",
    selected_entity_id: -1,
    selectedLabelData: {
      isCollaborator: false,
      path: "",
      id: null,
      departmentName: ""
    },
    assessmentAuditLog: {
        auditLogData: [],
        pagination: {
          count: 0,
          page: 1,
          itemsPerPage: 10,
        },
    },
    currentAssessmentAuditLog: {
      auditLogData: [],
      pagination: {
        count: 0,
        page: 1,
        itemsPerPage: 10,
      },
    },
}

const riskAssessmentSlice = createSlice({
    name: 'riskAssessment',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setSelectedNavItem(state, action: PayloadAction<any>){
            state.selectedNavItem = action.payload;
        },

        setCurrentAssessment(state, action: PayloadAction<any>){
            switch(action.payload) {
                case "lia": {
                    state.currentAssessment.key = action.payload;
                    state. currentAssessment.value = "Legitimate Interest Assessment (LIA)";
                    break;
                }

                case "pda": {   
                    state.currentAssessment.key = action.payload;
                    state. currentAssessment.value = "Privacy By Design Assessment (PDA)";
                    break;
                }

                case "pia": {   
                    state.currentAssessment.key = action.payload;
                    state. currentAssessment.value = "Privacy Impact Assessment (PIA)";
                    break;
                }

                case "tia": {   
                    state.currentAssessment.key = action.payload;
                    state. currentAssessment.value = "Transfer Impact Assessment (TIA)";
                    break;
                }
            }
        },
        
        setSelectedEntityId(state, action: PayloadAction<any>) {
            state.selected_entity_id = action.payload;
        },

        setAssessmentAuditLogData(state, action: PayloadAction<any>) {
          state.assessmentAuditLog.auditLogData = action.payload;
        },

        setAssessmentAuditLogPaginationCount(state, action: PayloadAction<any>) {
          state.assessmentAuditLog.pagination.count = action.payload;
        },

        setAssessmentAuditLogPaginationPage(state, action: PayloadAction<any>) {
          state.assessmentAuditLog.pagination.page = action.payload;
        },

        setAssessmentAuditLogPaginationItemsPerPage(state, action: PayloadAction<any>) {
          state.assessmentAuditLog.pagination.itemsPerPage = action.payload;
        },

        setCurrentAssessmentAuditLogData(state, action: PayloadAction<any>) {
          state.currentAssessmentAuditLog.auditLogData = action.payload;
        },

        setCurrentAssessmentAuditLogPaginationCount(state, action: PayloadAction<any>) {
          state.currentAssessmentAuditLog.pagination.count = action.payload;
        },

        setCurrentAssessmentAuditLogPaginationPage(state, action: PayloadAction<any>) {
          state.currentAssessmentAuditLog.pagination.page = action.payload;
        },

        setCurrentAssessmentAuditLogPaginationItemsPerPage(state, action: PayloadAction<any>) {
          state.currentAssessmentAuditLog.pagination.itemsPerPage = action.payload;
        },

        setSelectedLabelData(state, action: PayloadAction<any>) {
          state.selectedLabelData.id = action?.payload?.id;
          state.selectedLabelData.path = action?.payload?.path;
          state.selectedLabelData.departmentName = action?.payload?.departmentName ?? "";
          state.selectedLabelData.isCollaborator = action?.payload?.isCollaborator;
        },
    }
});
 
export const riskAssessmentActions = riskAssessmentSlice.actions;
export default riskAssessmentSlice;