import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import right from "../../image/chevron-right.png";
import profile from "../../assets/Ellipse 580.png";
import maskPlus from "../../image/Mask group.svg";
import { approveRejectApi, View_Asset_Request } from "../common/services/Asset";
import { useSelector } from "react-redux";
import moment from "moment";
import toast from "react-hot-toast";

interface AssetRequestData {
  application_date: string;
  asset_name: string;
  description: string;
  status: string;
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  Project: {
    project_name: string;
  };
  AssetCategory: {
    category: string;
  };
}

interface Approvers {
  approval_id: number;
  approval_status: string;
  role: string;
}

function ViewAssetRequest() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const userId = loginData?.userId;
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [details, setDetails] = useState<AssetRequestData>();
  const [status, setStatus] = useState<string>("");
  const [approverData, setApproverData] = useState<Approvers[]>([]);
  const [showButtons, setShowButtons] = useState<boolean>(false);

  const handleReject = () => {
    rejectData();
  };

  const handleApproved = () => {
    approveData();
  };

  const approveData = async () => {
    const status = "Approved";
    try {
      const response = await approveRejectApi(id, status);
      toast.success("Request Approved Succesfully!");
      setTimeout(() => {
        navigate("/asset-request");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const rejectData = async () => {
    const status = "Rejected";
    try {
      const response = await approveRejectApi(id, status);
      toast.success("Request Rejected Succesfully!");
      setTimeout(() => {
        navigate("/asset-request");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    approverData?.map((approver) => {
      if (approver?.approval_id === userId) {
        setShowButtons(true);
        setStatus(approver.approval_status);
      }
    });
  }, [approverData]);

  useEffect(() => {
    View_Asset_Request(id)
      .then((data) => {
        if (data.result) {
          setDetails(data.result);
          setApproverData(data.result?.AssetRequestApprovals);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  console.log("1121", details);
  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-5">
        <div className="flex justify-between items-start">
          <div className="flex flex-row gap-[13px] items-center">
            <button onClick={() => navigate("/asset-request")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Asset Request Details
            </span>
          </div>
          {showButtons ? (
            <div className="flex flex-start gap-5">
              {details?.status === "Approved" ? (
                <button
                  onClick={() => navigate("/add-asset")}
                  className="flex items-center font-[Poppins] font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg h-10"
                >
                  <img
                    className="w-5 h-5 mr-2"
                    src={maskPlus}
                    alt="plus icon"
                  />
                  Assigned Asset
                </button>
              ) : (
                <></>
              )}

              {status !== "Rejected" ? (
                <button
                  onClick={handleReject}
                  className="flex w-[148px] bg-[#B3261E] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg h-12 hover:bg-[#96150e]"
                >
                  <div className="text-[#fff] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                    Reject
                  </div>
                </button>
              ) : (
                <></>
              )}
              {status !== "Approved" ? (
                <button
                  className="flex w-[138px] p-[13.18px,21px,14.82px,21px] bg-[#359349] justify-center items-center flex-[1,1,0] self-stretch rounded-lg text-white h-12 hover:bg-[#1f8735]"
                  onClick={handleApproved}
                >
                  <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                    Approve
                  </div>
                </button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="px-1.5">
          <div className="bg-white rounded-lg shadow-custom p-5">
            <div className=" flex flex-col gap-5">
              <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                Asset Request Details
              </span>
              <div className="px-2.5 py-5 viewBackground rounded-md">
                <div className="grid grid-cols-3 gap-y-[15px]">
                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employee Name & ID
                    </span>
                    <div className="flex flex-row gap-[7px] items-center">
                      <div className="h-[31px] w-[31px]">
                        <img
                          src={details?.User?.profile_image || profile}
                          alt="profile"
                          className="h-full w-full rounded-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                          {details?.User?.firstName || "--"}{" "}
                          {details?.User?.lastName}
                        </span>
                        <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                          {details?.User?.employee_id}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Assigned Project
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      {details?.Project?.project_name || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Application Date
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      {moment(details?.application_date).format(
                        "DD MMM, YYYY"
                      ) || "--"}
                    </span>
                  </div>

                  <hr className="col-span-3 border-2 border-[#EDEDED]" />

                  <div className="flex flex-col gap-[7px] pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Asset Category
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      {details?.AssetCategory?.category || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Asset Name
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      {details?.asset_name || "--"}
                    </span>
                  </div>
                  <hr className="col-span-3 border-2 border-[#EDEDED]" />
                  <div className="flex flex-col gap-[7px] col-span-3 pl-2.5">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Configurations/Description
                    </span>
                    <span className="font-[Poppins] font-medium text-base tracking-[0.15px] text-[#262626]">
                      {details?.description}
                    </span>
                  </div>
                  <hr className="col-span-3 border-2 border-[#EDEDED]" />
                  <div className="col-span-3 flex pl-2 w-full">
                    <p className="w-full">
                      <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                        Approval Status
                      </strong>
                      <br />{" "}
                      <div className="grid grid-cols-3 gap-4">
                        {approverData?.map((approver) => (
                          <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                            {approver.role}-{" "}
                            <span
                              className={`py-1 px-1.5 rounded-sm h-6 text-[12px] ${
                                approver.approval_status === "Approved"
                                  ? "bg-[#D4F8C7]"
                                  : approver.approval_status === "Pending"
                                  ? "bg-[#FFC0767A]"
                                  : approver.approval_status === "Rejected"
                                  ? "bg-[#F8716A7A]"
                                  : ""
                              }`}
                            >
                              {approver.approval_status}
                            </span>
                          </strong>
                        ))}
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAssetRequest;
