import React from "react";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../@/components/ui/table";
import { SkeletonCard } from "./skeletonCard";

interface DynamicTableProperties<TData> {
  data: TData[];
  columns: ColumnDef<TData, any>[];
  loading?: boolean;
  onRowClick?: (rowData: TData) => void;
  enableSorting?: boolean;
  enablePagination?: boolean;
  collapsible?: boolean;
  renderExpandedContent?: (rowData: TData) => React.ReactNode; // Function to render expanded row content
  tableClass?: string;
}

function DynamicTable<TData>({
  data,
  columns,
  loading = false,
  onRowClick,
  enableSorting = false,
  enablePagination = false,
  collapsible = false,
  renderExpandedContent,
  tableClass = "border-striped border min-w-max overflow-auto",
}: DynamicTableProperties<TData>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [expandedRows, setExpandedRows] = React.useState<Set<number>>(
    new Set()
  ); // Track expanded rows

  const table = useReactTable<TData>({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    ...(enableSorting && { getSortedRowModel: getSortedRowModel() }),
    ...(enablePagination && { getPaginationRowModel: getPaginationRowModel() }),
  });

  const pageCount = table.getPageCount();
  const currentPage = table.getState().pagination.pageIndex;

  // Generate an array of page numbers for pagination
  const pageNumbers = React.useMemo(() => {
    const totalPageCount = pageCount;
    const pages = [];

    // You can adjust the range of pages displayed here
    const maxPageNumbers = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPageNumbers / 2), 0);
    let endPage = startPage + maxPageNumbers - 1;

    if (endPage >= totalPageCount) {
      endPage = totalPageCount - 1;
      startPage = Math.max(endPage - maxPageNumbers + 1, 0);
    }

    for (let index = startPage; index <= endPage; index++) {
      pages.push(index);
    }

    return pages;
  }, [currentPage, pageCount]);

  // Toggle expanded row state
  const handleRowToggle = (rowIndex: number) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(rowIndex)) {
        newExpandedRows.delete(rowIndex); // Collapse
      } else {
        newExpandedRows.add(rowIndex); // Expand
      }
      return newExpandedRows;
    });
  };

  return (
    <Table className={tableClass}>
      <TableHeader className="text-[#4B5563] bg-white sticky top-0 z-[1000] font-[poppins] text-sm font-semibold">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id} className="p-3">
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody className="text-[#111827] font-[poppins] text-sm">
        {loading ? (
          // Render loading state
          <TableRow>
            <TableCell colSpan={columns?.length}>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </TableCell>
          </TableRow>
        ) : table.getRowModel()?.rows?.length > 0 ? (
          table.getRowModel().rows.map((row, rowIndex) => {
            return (
              <React.Fragment key={row.id}>
                {/* Table Row */}
                <TableRow
                  onClick={() => {
                    if (collapsible) handleRowToggle(rowIndex); // Only toggle if collapsible is true
                    onRowClick && onRowClick(row.original);
                  }}
                  className="cursor-pointer"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>

                {/* Expanded Row Content (Collapsible Section) */}
                {collapsible &&
                expandedRows.has(rowIndex) &&
                renderExpandedContent ? (
                  <>
                    {" "}
                    <TableRow>
                      <TableCell colSpan={columns?.length}>
                        <div className="">
                          {renderExpandedContent(row.original)}
                        </div>
                      </TableCell>
                    </TableRow>{" "}
                  </>
                ) : null}
              </React.Fragment>
            );
          })
        ) : (
          // Render no data state
          <TableRow>
            <TableCell colSpan={columns?.length} className="text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default DynamicTable;
