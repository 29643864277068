import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface EmployeeData {
  count: number;
  EmployeeData: any[];
  currentPage: number;
  itemsPerPage: number;

}

const initialState: EmployeeData = {
  count: 0,
  EmployeeData: [],
  currentPage: 1,
  itemsPerPage: 10,
};

const EmployeeListSlice = createSlice({
  name: "EmployeeData",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setEmployeeData(state, action: PayloadAction<any>) {
      state.EmployeeData = action.payload.rows;
      state.count = action.payload.count;
      console.log(action.payload.rows,"message ------>>>>");
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload;
    },
    setItemsPerPage(state, action: PayloadAction<any>) {
      state.itemsPerPage = action.payload;
    },
  },
});

export const EmployeeListActions = EmployeeListSlice.actions;
export default EmployeeListSlice;
