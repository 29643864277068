import { Skeleton } from "../../@/components/ui/skeleton";

export function SkeletonCard() {
  return (
    <div className="mb-3 mt-1 flex flex-col space-y-3">
      <Skeleton className="h-6 w-[30%]" />
      <Skeleton className="h-4 w-4/5" />
      <Skeleton className="h-4 w-3/5" />
      <Skeleton className="h-4 w-2/5" />
    </div>
  );
}
