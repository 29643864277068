import React, { useCallback, useEffect, useState, useRef } from "react";
import eye from "../../../assets/Crud_Icons/view.svg";
import edit from "../../../assets/Crud_Icons/edit.svg";
import profile from "../../../assets/Ellipse 580.png";
import TablePaginationDemo from "../../common/Pagenation";
import { useNavigate } from "react-router-dom";
import download from "../../../assets/download_new.svg";
import { debounce } from "lodash";
import Search from "../../SearchBar/Search";

interface RowData {
  firstName: string;
  lastName: string;
  middleName: string;
  employee_id: string;
  remaining_leave: number;
  total_leave: number;
  used_leave: number;
  profile_image: string;
  id: string;
}

function WFHBalance() {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [loading, setLoading] = useState(false);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
        setSearchTerm(event.target.value);
    }, 1000), // delay in ms
    [setSearchTerm]
  );

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {

      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  
//   const fetchData = async () => {
//     setLoading(true);
//     try {
//       const response = await Fetch_Leave_Balance_List(
//         numOfItems,
//         page,
//         searchTerm
//       );
//       if (response.status === 200) {
//         setTableData(response.data.result.rows);
//         setCount(response.data.result.count);
//       } else {
//         console.error("Error:", response.status, response.statusText);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//     setLoading(false);
//   };
  
  const isFirstRender = useRef(true);

//   useEffect(() => {
//     if (isFirstRender.current) {
//       isFirstRender.current = false;
//       return;
//     }
//     fetchData();
//   }, [
//     page,
//     numOfItems,
//     searchTerm,
//     startSession,
//     endSession,
//   ]);

  return (
    <>
      <div className="mt-6 flex justify-between">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with Employee Name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full">
                <button
                  // onClick={handleDownload}
                  className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text"
                >
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4 shadow-sm">
        <table className="w-full min-w-max text-left table-auto border-b-[0.6px] bg-white border-[#E0E3E7]">
          <thead className="bg-[#E1E1E1]">
            <tr className="h-11">
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Employee Name & ID
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                Total WFH
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                Used WFH
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                Remaining WFH
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {tableData?.map((items) => ( */}
            <tr className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100">
              <td className="flex items-center space-x-2 pl-6 pr-2.5 py-1">
                <img
                  className="w-8 h-8 rounded-full"
                  // src={items.profile_image || profile}
                  src={profile}
                  alt="Profile"
                />
                <div className="flex flex-col justify-between">
                  <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                    {/* {items?.firstName} {items?.lastName} */}
                    Brooklyn Simmons
                  </span>
                  <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                    {/* {items?.employee_id || ""} */}
                    EMP0062
                  </span>
                </div>
              </td>
              <td className="text-[#3F4254] text-center font-[500] text-[12px] pl-6 pr-2.5 py-1">
                {/* {items?.total_leave || "0"} */}0
              </td>
              <td className="text-[#3F4254] text-center font-[500] text-[12px] pl-6 pr-2.5 py-1">
                {/* {items?.used_leave || "0"} */}0
              </td>
              <td className="text-[#3F4254] text-center font-[500] text-[12px] pl-6 pr-2.5 py-1">
                {/* {items?.remaining_leave || "0"} */}0
              </td>
              <td className="pl-6 pr-2.5 py-1">
                <div className="flex justify-center items-center gap-2">
                  <img
                    className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                    src={eye}
                    //   onClick={() =>
                    //     navigate("/leave-balance-details", {
                    //       state: { id: items.id },
                    //     })
                    //   }
                    onClick={() => navigate("/view-wfh-balance")}
                    alt="View"
                  />
                  <img
                    className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                    src={edit}
                    alt="Edit"
                    //   onClick={() =>
                    //     navigate("/edit-leave-balance", {
                    //       state: { id: items.id },
                    //     })
                    //   }
                    onClick={() => navigate("/edit-wfh-balance")}
                  />
                </div>
              </td>
            </tr>
            {/* ))} */}
          </tbody>
        </table>
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
    </>
  );
}

export default WFHBalance;
