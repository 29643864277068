import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataItem, QuestionnaireDataItem, ResultDataItem, QuestionnaireItem, PackageDetailDataItem } from '../../types/onboarding';
import { revertAll } from '../../ResetRedux/RevertAll';

interface OnboardingState {
  serviceData: DataItem[];
  maturityData: DataItem[];
  questionnaireData: QuestionnaireDataItem[];
  resultData: ResultDataItem[],
  packageDetailData: PackageDetailDataItem[],
  serviceResponse: number[];
  maturityResponse: number[];
  questionnaireResponse: QuestionnaireItem[];
  resultId: number | undefined;
  errorMessage: string[];
  isInitial: boolean[];
}

const initialState: OnboardingState = {
  serviceData: [],
  maturityData: [],
  questionnaireData: [],
  resultData: [],
  packageDetailData: [],
  serviceResponse: [],
  maturityResponse: [],
  questionnaireResponse: [],
  resultId: undefined,
  errorMessage: ['', '', '', '', '', ''],
  isInitial: [true, true],
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setServiceData(state, action: PayloadAction<DataItem[]>){
      console.log(action.payload, "sliceS");
      state.serviceData = action.payload;
    },

    setMaturityData(state, action: PayloadAction<DataItem[]>){
      console.log(action.payload, "sliceM");
      state.maturityData = action.payload;
    },

    setQuestionnaireData(state, action: PayloadAction<QuestionnaireDataItem[]>){
      console.log(action.payload, "sliceQ");
      state.questionnaireData = action.payload;
    },

    setResultData(state, action: PayloadAction<ResultDataItem[]>){
      console.log(action.payload, "sliceR");
      state.resultData = action.payload;
    },

    setPackageDetailData(state, action: PayloadAction<PackageDetailDataItem[]>){
      console.log(action.payload, "slicePDD");
      state.packageDetailData = action.payload;
    },

    setServiceResponse(state, action: PayloadAction<number>) {
        const index = state.serviceResponse.indexOf(action.payload);

        if(index === -1){
            state.serviceResponse = [
                ...state.serviceResponse,
                action.payload
            ];
        }else{
            const updated = [...state.serviceResponse];
            updated.splice(index, 1);
            state.serviceResponse = updated;
        }
    },

    setMaturityResponse(state, action: PayloadAction<number>) {
      const index = state.maturityResponse.indexOf(action.payload);

      if(index === -1){
          state.maturityResponse = [
              ...state.maturityResponse,
              action.payload
          ];
      }else{
          const updated = [...state.maturityResponse];
          updated.splice(index, 1);
          state.maturityResponse = updated;
      }
    },

    setQuestionnaireResponse(state, action: PayloadAction<[number, boolean]>) {
      const newItemIndex = action.payload[0];
      const existingItem = state.questionnaireResponse.find((item) => item.question_id === newItemIndex);

      const updated = [...state.questionnaireResponse];
      
      if(existingItem === undefined){
          updated.push({
              "question_id": newItemIndex,
              "status": action.payload[1]
          });
      }else{
          const itemIndex = updated.indexOf(existingItem);

          if(updated[itemIndex].status === action.payload[1]){
              updated.splice(itemIndex, 1);
          }else{
              updated[itemIndex].status = action.payload[1];
          }
        }
        
        state.questionnaireResponse = updated;
    },

    setResultResponse(state, action: PayloadAction<number>) {
      state.resultId = action.payload;
    },

    setErrorMessage(state, action: PayloadAction<[string, number]>) {
      state.errorMessage[action.payload[1]] = action.payload[0];
    },

    setisInitial(state ,action:PayloadAction<number>) {
      if(action.payload === 0){
        state.isInitial[0] = false;
      }
      if(action.payload === 1){
        state.isInitial[1] = false;
      }
    },
  },
});

export const onboardingActions = onboardingSlice.actions;
export default onboardingSlice;