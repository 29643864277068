import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import profile from "../../assets/Ellipse 580.png";
import filter from "../../assets/filter.svg";
import view from "../../assets/Crud_Icons/view.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import { useLocation, useNavigate } from "react-router-dom";
import TablePaginationDemo from "../common/Pagenation";
import "./table.css";
import download from "../../assets/download_new.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import * as XLSX from "xlsx";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import { Fetch_Employee_Management_List } from "../common/services/Employee";
import Filter from "../Filter/Filter";
import Search from "../SearchBar/Search";
import { Chip } from "@material-ui/core";
import TableSkeleton from "../common/TableSkeleton";
import { EmployeeListActions } from "../../redux/reducers/Employee/EmployeeListSlice";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface RowData {
  firstName: string;
  lastName: string;
  employee_id: string;
  Department: {
    name: string;
  };
  Designation: {
    name: string;
  };
  total_user: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  id: number;
  profile_image: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any; // Adjust this based on your data structure
}

const EmployeeList = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const currentPage = useSelector((state: any) => state.emplist.currentPage);
  const [page, setPage] = useState(1);
  // const [persistPage, setpersistPage] = useState(false);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const navigate = useNavigate();

  // const dispatch = useDispatch();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit
  >();

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    const pageFromState = location.state?.page;

    if (pageFromState) {
      setPage(pageFromState);
      dispatch(EmployeeListActions.setCurrentPage(pageFromState));
    } else {
      setPage(1);
      // dispatch(EmployeeListActions.setCurrentPage(1))
    }
  }, [location.state]);

  function handlePageChange(value: number) {
    setPage(value);
    dispatch(EmployeeListActions.setCurrentPage(value));
  }

  // to handle pagination
  // const location = useLocation();

  // useEffect(() => {
  //   // if (location.pathname !== '/employee-list-details' && location.pathname !== '/employee-list') {
  //   if (!location.pathname.includes('/employee-list') && !location.pathname.includes('/employee-list-details')) {
  //     dispatch(EmployeeListActions.setCurrentPage(1));
  //   }
  //   fetchData();
  // }, [location, dispatch]);

  // useEffect(() => {
  //   const resetPage = () => {
  //     if (location.pathname === '/employee-list' || location.pathname === '/employee-list-details') {
  //       dispatch(EmployeeListActions.setCurrentPage(1));
  //     }
  //   };
    
  //   resetPage();
  // }, [location, dispatch]);

  // Navigates to a specific route while preserving pagination state
  // const handleNavigation = (route: string, state?: any, persistPage=false) => {
  //   // const specificRoutes = ["/employee-list-details", "/edit-employee"];

  //   if (persistPage) {
  //     // setpersistPage(true);
  //     dispatch(EmployeeListActions.setCurrentPage(page));
  //   } else {
  //     // setpersistPage(false);
  //     dispatch(EmployeeListActions.setCurrentPage(1));
  //   }

  //   navigate(route, { state });
  // };


  // const handleNavigation = handleNavigationCommon(
  //   dispatch,
  //   setpersistPage,
  //   navigate,
  //   EmployeeListActions // Pass the specific actions for this page
  // );

  // const handleNavigationCommon = (route: string, state?: any, persistPage?: boolean) => {

  //   if (persistPage===true) {
  //     setpersistPage(true);
  //     dispatch(EmployeeListActions.setCurrentPage(page));
  //   } else {
  //     setpersistPage(false);
  //     dispatch(EmployeeListActions.setCurrentPage(1));
  //   }

  //   navigate(route, { state });
  // };

  // Updates the current page in Redux on component mount or page change
  // useEffect(() => {
  //   dispatch(EmployeeListActions.setCurrentPage(page));
  // }, [page, dispatch]);

  // useEffect(() => {
  //   if (searchTerm) {
  //     dispatch(EmployeeListActions.setCurrentPage(1));
  //   }
  // }, [searchTerm, dispatch]);

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleFilterApply = () => {
    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
  };

  const handleDeleteDepartment = () => {
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    fetchData();
  };

  const handleDeleteBusiness = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchData();
  };

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const fetchData = async () => {
    setLoading(true);
    const pageForFetch = searchTerm ? 1 : page;
    try {
      const response = await Fetch_Employee_Management_List(
        customer_id,
        numOfItems,
        pageForFetch,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id),
        searchTerm
      );
      if (response.status === 200) {
        setTableData(response.data.result.userData.rows);
        setCount(response.data.result.userData.count);
        const fetchedData: EmployeeData[] =
          response.data?.result?.userData?.rows;

        const columnMapping: { [key: string]: string } = {
          employee_code: "Employee Code",
          Name: "Name",
          "Designation.name": "Designation",
          "Department.name": "Department",
        };

        const filteredData = fetchedData.map((row) => {
          const filteredRow: Partial<EmployeeData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split("."); // Split the key to handle nested fields

            if (key === "Name") {
              // Combine firstName and lastName under the "Name" column
              filteredRow["Name"] = `${row.firstName || ""} ${
                row.lastName || ""
              }`.trim();
            } else if (keys.length === 1) {
              // Handle non-nested fields
              if (row.hasOwnProperty(keys[0])) {
                filteredRow[keys[0]] = row[keys[0]];
              }
            } else if (keys.length === 2) {
              // Handle nested fields
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                // Store the nested value under the child key
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            }
          });

          return filteredRow;
        });

        setTableData1(filteredData);

        // Set the columns for the table
        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
        // console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      // console.error("Error:", error);
    }
    setLoading(false);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    fetchData();
  }, [
    searchTerm,
    page,
    numOfItems,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
    startSession,
    endSession,
  ]);

  // useEffect(() => {
  //   if (!persistPage) {
  //     dispatch(EmployeeListActions.setCurrentPage(1));
  //   }
  //   fetchData();
  // }, [persistPage, searchTerm, page, numOfItems, selectedBusinessUnit?.id, selectedDepartmentUnit?.id]);

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from tableData1
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...tableData1.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "employee_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.warn("Data has not been fetched yet.");
    }
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }

  // const options = [
  //   { value: 'option1', label: 'Option 1' },
  //   { value: 'option2', label: 'Option 2' },
  //   { value: 'option3', label: 'Option 3' },
  // ];

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      // setPage(1);
      // fetchData(event.target.value); // call API to fetch data
    }, 300), // delay in ms
    []
  );

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {
    setTempSelectedBusinessUnit(option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {
    setTempSelectedDepartmentUnit(option);
  };

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleCalanderClick = () => {};
  const handleDateChange = () => {};

  return (
    <>
      <div className="mt-6 flex justify-between">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with Employee Name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex items-center gap-2">
                {departmentSelect && (
                  <Chip
                    label={selectedDepartmentUnit?.name}
                    onDelete={handleDeleteDepartment}
                    size="small"
                  />
                )}
                {businessSelect && (
                  <Chip
                    label={selectedBusinessUnit?.name}
                    onDelete={handleDeleteBusiness}
                    size="small"
                  />
                )}
              </div>
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button
                  onClick={handleDownload}
                  className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text"
                >
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-end mt-4 gap-[31px]">
        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Active
          </span>
        </div>

        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FF9950" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Inactive
          </span>
        </div>

        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FA1464" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Archived
          </span>
        </div>
      </div>

      <div className="overflow-x-auto overflow-y-auto mt-4">
        {loading ? (
          <TableSkeleton />
        ) : (
        <table className="w-full min-w-max text-left table-auto border-solid bg-white border-[#E0E3E7] border-[0.6px]">
          <thead className="bg-[#E1E1E1]">
            <tr className="h-11">
              <th className="pl-6 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Employee Name & Id
              </th>
              <th className="pl-6 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Department
              </th>
              <th className="pl-14 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Designation
              </th>
              <th className="flex justify-end pl-2.5 pr-6 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item) => (
              <tr
                key={item.id}
                className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100"
              >
                <td className="flex items-center space-x-2 pl-6 pr-2.5 py-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="3.99537"
                      cy="3.74537"
                      r="3.49537"
                      fill={
                        item.status === "active"
                          ? "#00CBA0"
                          : item.status === "inactive"
                          ? "#FF9950"
                          : item.status === "archived"
                          ? "#FA1464"
                          : "#D3D3D3" // default color if status is unknown
                      }
                    />
                  </svg>
                  <img
                    className="w-8 h-8 rounded-full"
                    src={item.profile_image || profile}
                    alt="Profile"
                  />
                  <div className="flex flex-col justify-between">
                    <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                      {item.firstName} {item.lastName}
                    </span>
                    <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                      {item.employee_id || ""}
                    </span>
                  </div>
                </td>
                <td className="text-[#3F4254] font-[Poppins] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {item.Department?.name || "--"}
                </td>
                <td className="text-[#3F4254] font-[Poppins] font-[500] text-[12px] pl-14 pr-2.5 py-1">
                  {item.Designation?.name || "--"}
                </td>
                <td className="pr-6 pl-2.5 py-1">
                  <div className="flex justify-end items-center gap-2">
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      src={view}
                      onClick={() =>
                        navigate("/employee-list-details", {
                          state: { id: item.id },
                        })
                      }
                      alt="View"
                    />
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      onClick={() =>
                        navigate(`/edit-employee`, {
                          state: { id: item.id },
                        })
                      }
                      src={edit}
                      alt="Edit"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      <Filter
        isDate={false}
        isOther
        heading="Employee Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </>
  );
};

export default EmployeeList;
